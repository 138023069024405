import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";

import { styled } from "@mui/material/styles";

import theme from "../../../../../theme";

const Input = styled("input")({
  display: "none",
});

const UploadStep = ({ onContinue }) => {
  const { t } = useTranslation();

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsArrayBuffer(file);
    });
  };

  const handleOnChangeFile = async (event) => {
    const [file] = event.target.files;
    const arrayBuffer = await readFileAsync(file);
    const workbook = XLSX.read(arrayBuffer, {
      cellDates: true,
      dense: true,
      codepage: 65001,
    });

    onContinue(workbook, file);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "25rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "20rem",
          height: "15rem",
          border: `1px dashed ${theme.palette.color.slate[300]}`,
          borderRadius: theme.borderRadius.main,
          cursor: "pointer",
        }}
      >
        <label htmlFor="contained-button-file">
          <Input
            accept=".xlsx"
            id="contained-button-file"
            type="file"
            onChange={handleOnChangeFile}
          />
          <Button
            color="primary"
            component="span"
            variant="contained"
            disableRipple
            size="large"
            sx={{
              textTransform: "capitalize",
              fontWeight: theme.typography.fontWeightMediumBold,
              color: theme.palette.primary.main,
              boxShadow: theme.boxShadow,
              backgroundColor: theme.palette.primary[100],
              border: `1px solid ${theme.palette.primary[300]}`,
              "&:hover": {
                backgroundColor: theme.palette.primary.light,
              },
            }}
          >
            {t("Upload Excel File")}
          </Button>
        </label>
      </Box>
    </div>
  );
};

export default UploadStep;
