import { createSlice } from "@reduxjs/toolkit";
import _ from "underscore";
import format from "date-fns/format";
import initialData from "../../Helper/data";
const { cardActionList } = initialData;
const initialState = {
  dataSetData: {},
  datasetAccountList: [],
  dataSetList: [],
  chartData: [],
  dailyChartData: [],
  columnOrder: [],
  todayLiq: 0,
  accountBalanceUpdateDate: null,
  chartLineKeys: [],
  cardActionList: [...cardActionList],
  gropedTransactionData: [],
  highlightedScenarios: [],
  highlightedCostUnit: [],
  transactionData: [],
  isFilterOpen: false,
  updateChart: false,
  isKanbanView: true,
  searchText: "",
};

const boardSlice = createSlice({
  name: "boardSlice",
  initialState,
  reducers: {
    setIsFilterOpen(state, action) {
      state.isFilterOpen = action?.payload?.hasOwnProperty("flag")
        ? action?.payload?.flag
        : !state.isFilterOpen;
    },
    toggleView(state, action) {
      state.isKanbanView = action?.payload || !state.isKanbanView;
    },
    setSearchText(state, action) {
      state.searchText = action?.payload;
    },


    setDataSetData(state, action) {
      if (action.payload?.partial) {
        Object.keys(action.payload?.partial).forEach((key) => {
          state.dataSetData[key] = action.payload?.partial[key];
        });
      } else {
        state.dataSetData = action.payload;
      }
    },
    setDataSetAccountList(state, action) {
      state.datasetAccountList = action.payload;
    },
    setHighlightedScenarios(state, action) {
      state.highlightedScenarios = action.payload;
    },
    setUpdateChart(state, action) {
      state.updateChart = !state.updateChart;
    },

    setdatasetList(state, action) {
      state.dataSetList = action.payload;
    },
    setCardActionList(state, action) {
      state.cardActionList = action.payload;
    },
    setAccountBalanceUpdateDate(state, action) {
      state.accountBalanceUpdateDate = action.payload;
    },
    setColumnOrder(state, action) {
      state.columnOrder = action.payload;
    },
    setMonthlyChartData(state, action) {
      state.chartData = action.payload;
    },
    setDailyChartData(state, action) {
      state.dailyChartData = action.payload;
    },
    setChartLineKeys(state, action) {
      state.chartLineKeys = action.payload;
    },
    setTransactionData(state, action) {
      let data1 = action.payload?.filter((o1) => o1.gross_value >= 0);
      let data2 = action.payload?.filter((o1) => o1.gross_value < 0);
      let inflow = _.groupBy([...data1], ({ due_date }) =>
        due_date ? format(new Date(due_date), "yyyy-MM") : "Unassigned"
      );
      let outflow = _.groupBy([...data2], ({ due_date }) =>
        due_date ? format(new Date(due_date), "yyyy-MM") : "Unassigned"
      );
      state.transactionData = action.payload;
      state.gropedTransactionData = { inflow, outflow };
    },
 
    setTodayLiq(state, action) {
      state.todayLiq = action.payload;
    },
    setHighlightedCostUnit(state, action) {
      state.highlightedCostUnit = action.payload;
    },
  },
});
export const {
  toggleView,
  setDataSetData,
  setTransactionData,
  setTransactionDataDummy,
  setdatasetList,
  setColumnOrder,
  setMonthlyChartData,
  setTodayLiq,
  setDailyChartData,
  setChartLineKeys,
  setCardActionList,
  setDataSetAccountList,
  setAccountBalanceUpdateDate,
  setKanbanTransactionsInflow,
  setIsFilterOpen,
  setUpdateChart,
  setSearchText,
  setHighlightedScenarios,
  setHighlightedCostUnit,
} = boardSlice.actions;
export default boardSlice;
