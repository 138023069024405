import React, { useCallback, useMemo, useState } from "react";
import { CgClose, CgUndo } from "react-icons/cg";
import uniqBy from "lodash/uniqBy";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

import {
  ColumnType,
  fields,
  findUnmatchedRequiredFields,
  normalizeTableData,
} from "../helper";
import OverlayFooter from "../../../../../components/Overlay/OverlayFooter";

const MatchColumnsStep = ({ data, headerValues, onContinue, onBack }) => {
  const { t } = useTranslation();

  const dataExample = data.slice(0, 2);

  //state
  const [columns, setColumns] = useState(
    // Do not remove spread, it indexes empty array elements, otherwise map() skips over them
    [...headerValues].map((value, index) => ({
      type: ColumnType.empty,
      index,
      header: value ?? "",
    }))
  );
  const [showUnmatchedFieldsAlert, setShowUnmatchedFieldsAlert] =
    useState(false);
  // console.log("columns :>> ", columns);
  // console.log("showUnmatchedFieldsAlert :>> ", showUnmatchedFieldsAlert);

  //functions
  const uniqueEntries = (data, index) =>
    uniqBy(
      data.map((row) => ({ entry: row[index] })),
      "entry"
    ).filter(({ entry }) => !!entry);

  const setColumn = (oldColumn, field, data, autoMapSelectValues) => {
    switch (field?.fieldType.type) {
      case "select":
        const fieldOptions = field.fieldType.options;
        const uniqueData = uniqueEntries(data || [], oldColumn.index);
        const matchedOptions = autoMapSelectValues
          ? uniqueData.map((record) => {
              const value = fieldOptions.find(
                (fieldOption) =>
                  fieldOption.value === record.entry ||
                  fieldOption.label === record.entry
              )?.value;
              return value ? { ...record, value } : record;
            })
          : uniqueData;
        const allMatched =
          matchedOptions.filter((o) => o.value).length === uniqueData?.length;

        return {
          ...oldColumn,
          type: allMatched
            ? ColumnType.matchedSelectOptions
            : ColumnType.matchedSelect,
          value: field.key,
          matchedOptions,
        };
      case "checkbox":
        return {
          index: oldColumn.index,
          type: ColumnType.matchedCheckbox,
          value: field.key,
          header: oldColumn.header,
        };
      case "input":
        return {
          index: oldColumn.index,
          type: ColumnType.matched,
          value: field.key,
          header: oldColumn.header,
        };
      default:
        return {
          index: oldColumn.index,
          header: oldColumn.header,
          type: ColumnType.empty,
        };
    }
  };

  const setIgnoreColumn = ({ header, index }) => ({
    header,
    index,
    type: ColumnType.ignored,
  });

  const onChange = useCallback(
    (event, columnIndex) => {
      const { value } = event.target;
      const field = fields.find((field) => field.key === value);
      // console.log("field :>> ", field);
      const existingFieldIndex = columns.findIndex(
        (column) => "value" in column && column.value === field.key
      );
      // console.log("🚀 ~ MatchColumnsStep ~ columns:", columns);
      // console.log("existingFieldIndex", existingFieldIndex);
      setColumns(
        columns.map((column, index) => {
          if (columnIndex === index) {
            return setColumn(column, field, data, false);
          } else if (index === existingFieldIndex) {
            enqueueSnackbar(t("Columns cannot duplicate"), {
              variant: "warning",
              autoHideDuration: 5000,
            });
            return setColumn(column);
          } else {
            return column;
          }
        })
      );
    },
    [columns, data, t]
  );

  const onIgnore = useCallback(
    (columnIndex) => {
      setColumns(
        columns.map((column, index) =>
          columnIndex === index ? setIgnoreColumn(column) : column
        )
      );
    },
    [columns, setColumns]
  );

  const onRevertIgnore = useCallback(
    (columnIndex) => {
      setColumns(
        columns.map((column, index) =>
          columnIndex === index ? setColumn(column) : column
        )
      );
    },
    [columns, setColumns]
  );

  const unmatchedRequiredFields = useMemo(
    () => findUnmatchedRequiredFields(fields, columns),
    [fields, columns]
  );

  const handleOnContinue = useCallback(async () => {
    if (unmatchedRequiredFields?.length > 0) {
      setShowUnmatchedFieldsAlert(true);
    } else {
      await onContinue(
        normalizeTableData(columns, data, fields),
        data,
        columns
      );
    }
  }, [unmatchedRequiredFields?.length, onContinue, columns, data, fields]);

  // console.log("unmatchedRequiredFields :>> ", unmatchedRequiredFields);

  return (
    <Box>
      <Stack direction="row" gap={"2rem"}>
        {columns?.map((column) => (
          <Stack
            justifyContent="space-between"
            alignItems="center"
            mb={4}
            width={"10rem"}
          >
            <UserTableColumn
              column={column}
              onIgnore={onIgnore}
              onRevertIgnore={onRevertIgnore}
              entries={dataExample.map((row) => row[column.index])}
            />
            <Box
              gridColumn={`1/${columns?.length + 3}`}
              gridRow="2/3"
              borderRadius="1.2rem"
              border="1px solid"
              borderColor="border"
              pointerEvents="none"
            />
            <Box
              gridColumn={`1/${columns?.length + 3}`}
              gridRow="2/3"
              pointerEvents="none"
              py="1.125rem"
              bgGradient="linear(to bottom, backgroundAlpha, background)"
            />

            <TemplateColumn column={column} onChange={onChange} />
          </Stack>
        ))}
      </Stack>
      <OverlayFooter
        onClickAction={handleOnContinue}
        textAdd="Next"
        sx={{
          justifyContent: "flex-end",
          mt: "2rem",
          position: "absolute",
          bottom: "2rem",
          right: "2rem",
          left: "2rem",
        }}
      />
    </Box>
  );
};

export default MatchColumnsStep;

const UserTableColumn = (props) => {
  const {
    column: { header, index, type },
    entries,
    onIgnore,
    onRevertIgnore,
  } = props;
  return (
    <Stack justifyContent="space-between" alignItems="center" mb={4}>
      <Typography variant="subtitle1" fontWeight={"fontWeightMediumBold"}>
        {header}
      </Typography>
      {type === ColumnType.ignored ? (
        <CgUndo
          onClick={() => onRevertIgnore(index)}
          style={{ marginBlock: "2rem" }}
        />
      ) : (
        <CgClose
          aria-label="Ignore column"
          icon={<CgClose />}
          onClick={() => onIgnore(index)}
          style={{ marginBlock: "2rem" }}
        />
      )}
      {entries?.map((entry, index) => (
        <Typography
          variant="subtitle2"
          key={(entry || "") + index}
          sx={{
            color: "text.description",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {entry}
        </Typography>
      ))}
    </Stack>
  );
};

const TemplateColumn = ({ column, onChange }) => {
  const isIgnored = column.type === ColumnType.ignored;
  const isChecked =
    column?.type === ColumnType.matched ||
    column?.type === ColumnType.matchedCheckbox ||
    column?.type === ColumnType.matchedSelectOptions;
  const selectOptions = fields?.map(({ label, key }) => ({
    value: key,
    label,
  }));
  const selectValue = selectOptions?.find(
    ({ value }) => "value" in column && column.value === value
  );

  return (
    <Stack
      flexDir="column"
      justifyContent="center"
      sx={{ position: "relative" }}
    >
      {isIgnored ? (
        <Typography>Column ignored</Typography>
      ) : (
        <Select
          // labelId="demo-simple-select-label"
          // id="demo-simple-select"
          value={selectValue}
          onChange={(e) => onChange(e, column.index)}
          name={column.header}
          sx={{ width: "10rem" }}
        >
          {selectOptions?.map((item) => {
            return <MenuItem value={item.value}>{item.label}</MenuItem>;
          })}
        </Select>
      )}
      <div style={{ position: "absolute", bottom: "-2rem", left: "4rem" }}>
        {!isIgnored && isChecked ? <DoneIcon /> : null}
      </div>
    </Stack>
  );
};
