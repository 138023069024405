import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import _ from "underscore";
import { useQuery } from "@tanstack/react-query";
import {
  Box,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  useMediaQuery,
} from "@mui/material";
import {
  addMonths,
  eachMonthOfInterval,
  endOfMonth,
  format,
  startOfMonth,
  subMonths,
} from "date-fns";

import { Constant } from "../../../../Helper";
import BarCharts from "../../../../components/Charts/BarCharts";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import initialData, {
  getStatisticsDataWithParams,
  getTailwindColor,
} from "../../../../Helper/data";
import EmptyView from "./Component/EmptyView";
import QuickLinkView from "./Component/QuickLinkView";
import ComponentLoader from "../../../../components/ComponentLoader";

const link_state = ["Late", "Invoice open", "Others"];
const expanded_link_state = [
  "Invoice draft",
  "Invoice sent",
  "Open",
  "Invoice paid",
  "Partially paid",
  "All",
  "Late",
  "Invoice open",
  "Close",
];
const state_color =
  initialData?.defaultState?.["Invoice open"]?.color || "slate";
const InvoiceStatusWidget = ({ widgetType, widget }) => {
  const s2300 = useMediaQuery("(min-width:2300px)");
  //state
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState([]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "1rem",
      }}
    >
      <LogicFunctions
        widget={widget}
        setData={setData}
        setRawData={setRawData}
        setLoading={setLoading}
        setIsFetching={setIsFetching}
        widgetType={widgetType}
      />
      {isLoading || isFetching ? (
        <ComponentLoader
          loading
          hideNoDataPlaceholder
          height={"100%"}
          size={60}
        />
      ) : data?.length > 0 ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "100%",
              height: s2300 ? "75%" : "85%",
            }}
          >
            <BarCharts
              data={data}
              hideDefault
              barsData={[
                {
                  key: "invoice_status_paid_tooltip_label",
                  dataKey: "closed",
                  stackId: "a",
                  fill: getTailwindColor(state_color, 600),
                  color: state_color,
                  shade: 600,
                },
                {
                  key: "invoice_status_open_tooltip_label",
                  dataKey: "open",
                  stackId: "a",
                  fill: getTailwindColor(state_color, 200),
                  color: state_color,
                  shade: 200,
                },
                {
                  key: "invoice_status_late_tooltip_label",
                  dataKey: "overdue",
                  stackId: "a",
                  fill: getTailwindColor("red", 400),
                  color: "red",
                  shade: 400,
                },
              ]}
            />
          </div>
          <QuickLinks rawData={rawData} widget={widget} />
        </div>
      ) : (
        <EmptyView type={widgetType} />
      )}
    </div>
  );
};

export default InvoiceStatusWidget;

const LogicFunctions = ({
  setData,
  setLoading,
  setIsFetching,
  setRawData,
  widget,
}) => {
  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const stateByTitle = useSelector((state) => state.globalSlice.stateByTitle);

  const multiStatesIds = useMemo(() => {
    let _states = [];
    Constant.invoiceState.forEach((item) => {
      if (stateByTitle?.[item]?.[0]?.uuid) {
        _states.push(stateByTitle?.[item]?.[0]?.uuid);
      }
    });
    return _states;
  }, [stateByTitle]);

  const WidgetData = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        apiType: "invoice_status_over_time",
        widget: {
          start_date: widget?.start_date,
          end_date: widget?.end_date,
          scenarios: widget?.scenarios,
        },
      },
    ],
    queryFn: () => {
      let param = {
        type: "monthly",
        dataset: dataSetData?.uuid,
        group_by: ["state"],
        from_payment_date: widget?.start_date,
        to_payment_date: widget?.end_date,
        multiScenarioIds: widget?.scenarios,
        multiStatesIds,
      };
      if (!dataSetData.use_global_categories) {
        param.category_dataset = dataSetData?.uuid;
      } else {
        param.global_category = true;
      }
      const result = getStatisticsDataWithParams(param);

      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    priority: 3,
    enabled:
      !!dataSetData?.uuid &&
      !!widget?.start_date &&
      !!widget?.end_date &&
      multiStatesIds?.length > 0,
  });

  const isFetching =
    WidgetData.isFetching || WidgetData.isLoading || !WidgetData.isSuccess;

  //lifeCycle method
  useEffect(() => {
    setIsFetching(isFetching);
    if (isFetching) {
      setLoading(true);
    }
  }, [isFetching]);

  //life cycle method
  useDebounce(
    () => {
      if (
        WidgetData?.data &&
        !isFetching &&
        widget?.start_date &&
        widget?.end_date
      ) {
        setLoading(true);
        let dummyData = [];
        let formateString = "yyyy-MM-dd";
        formateString = "MMM-yyyy";
        const lowestDate = new Date(widget?.start_date);
        const highestDate = new Date(widget?.end_date);

        const period_start_date = startOfMonth(subMonths(new Date(), 6));
        const period_end_date = endOfMonth(addMonths(new Date(), 6));
        const start_date =
          lowestDate < period_start_date ? lowestDate : period_start_date;
        const end_date =
          highestDate > period_end_date ? highestDate : period_end_date;
        const dates = eachMonthOfInterval({
          start: new Date(start_date),
          end: new Date(end_date),
        });

        const periodData = _.groupBy(
          WidgetData?.data?.results || [],
          ({ month }) => format(new Date(month), formateString)
        );

        let obj = {};
        if (WidgetData?.data?.results?.length > 0) {
          const currentMonth = format(new Date(), "yyyy-MM");

          dates?.forEach((element) => {
            let date = format(new Date(element), formateString);
            obj = {
              due_date: date,
            };
            const isCurrentMonth =
              format(new Date(element), "yyyy-MM") === currentMonth;
            const isPastMonth =
              format(new Date(element), "yyyy-MM") < currentMonth;

            const item = periodData?.[date] ?? [];
            let closed = 0;

            const open = item
              ?.filter((o1) => !o1.state?.includes("paid"))
              ?.reduce(
                (total, entry) =>
                  parseFloat(total) +
                  parseFloat(entry?.outflow ?? 0) +
                  parseFloat(entry?.inflow ?? 0),
                0
              );
            if (isPastMonth || isCurrentMonth) {
              closed = item
                ?.filter((o1) => o1.state?.includes("paid"))
                ?.reduce(
                  (total, entry) =>
                    parseFloat(total) +
                    parseFloat(entry?.outflow ?? 0) +
                    parseFloat(entry?.inflow ?? 0),
                  0
                );
            }
            obj.closed = closed || 0;
            if (isPastMonth) {
              obj.overdue = open || 0;
            } else {
              obj.open = open || 0;
            }
            dummyData.push(obj);
          });
        }
        setTimeout(() => {
          setLoading(false);
          setData(dummyData);
          setRawData([...(WidgetData?.data?.results || [])]);
        }, 250);
      }
    },
    300,
    [
      WidgetData?.data?.results,
      isFetching,
      widget?.start_date,
      widget?.end_date,
    ],
    true
  );

  return null;
};

const QuickLinks = ({ rawData, widget }) => {
  const overlayRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  //functions
  const onClickOthers = () => setAnchorEl(overlayRef.current);

  const onClickAway = () => setAnchorEl(null);

  return (
    <Box
      ref={overlayRef}
      sx={{
        position: "relative",
        marginTop: "0.75rem",
        alignSelf: "flex-end",
        "& .base-Popper-root": {
          transform: "translate3d(16px, 32px, 0px) !important",
          zIndex: 1,
        },
      }}
    >
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement={"top-end"}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={onClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                elevation={1}
                sx={{
                  width: "35rem",
                  pt: "1rem",
                  px: "1rem",
                  mb: "1rem",
                  display: "flex",
                  flexWrap: "wrap",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
              >
                {expanded_link_state?.map((item) => (
                  <QuickLinkView
                    key={item}
                    title={item}
                    tooltip={
                      item === "Invoice paid"
                        ? `invoice-status-widget-link-invoice-paid-tooltip`
                        : null
                    }
                    state_color={state_color}
                    moreState={Constant.invoiceState}
                    data={rawData}
                    onClickClose={onClickAway}
                    start_date={widget?.start_date}
                    end_date={widget?.end_date}
                    sx={{
                      mb: "1rem",
                    }}
                  />
                ))}
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
      {link_state?.map((item) => (
        <QuickLinkView
          key={item}
          tooltip={
            item === "Invoice paid"
              ? `invoice-status-widget-link-invoice-paid-tooltip`
              : null
          }
          title={item}
          start_date={widget?.start_date}
          end_date={widget?.end_date}
          onClickOthers={onClickOthers}
          moreState={Constant.invoiceState}
          data={rawData}
        />
      ))}
    </Box>
  );
};
