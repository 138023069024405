import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import theme from "../../../theme";
import CustomPopover from "../../PopOver";
import { StyledTab, StyledTabs } from "../../../Styles/components/tab.style";
import initialData from "../../../Helper/data";
import { Constant } from "../../../Helper";
import Translate from "../../../hooks/HOC/Translate";
import { t } from "i18next";

const StaffRoutes = Constant.StaffRoutes;

const TabView = ({ isMainDrawerOpen = true }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  let abortController = new AbortController();
  const s1280 = useMediaQuery("(min-width:1280px)");
  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );

  const dataSetList = useSelector((state) => state.boardSlice?.dataSetList);
  const isStartPage =
    window?.location?.pathname?.includes("/dashboard") ||
    window?.location?.pathname === "/";
  //state
  const [tabStatus, setTabsStatus] = useState(false);

  //lifecycle
  useEffect(() => {
    if (isAllHeaderApiFetched) {
      if (location?.pathname.includes("/settings") || !dataSetData?.uuid) {
        setTabsStatus(false);
        return;
      }
      if (
        dataSetData?.uuid &&
        location?.pathname.includes(`/${initialData?.path?.organization}/`)
      ) {
        let url = "";
        let myArray = location?.pathname.split("/");
        myArray.splice(0, 3);
        myArray.forEach((path) => {
          url = url + "/" + path;
        });
        Constant.MainTabs?.forEach((element) => {
          if (element?.value?.includes(url)) {
            if (tabStatus !== element.id) setTabsStatus(Number(element?.id));
          }
        });
      }
    }
  }, [dataSetData?.uuid, isAllHeaderApiFetched, location?.pathname]);

  useEffect(() => {
    return () => {
      setTabsStatus({});
      abortController.abort();
    };
  }, []);

  //functions
  const handleChange = (event, newValue) => {
    if (newValue !== 5) {
      updateTab(newValue);
    }
  };

  const updateTab = (newValue) => {
    Constant.MainTabs.forEach((element) => {
      if (element?.id === newValue) {
        setTabsStatus(newValue);
        let dataset = null;
        if (dataSetData?.uuid) {
          dataset = dataSetData?.uuid;
        } else {
          dataset = localStorage.getItem("last_visited_dataset");
          if (!dataset) {
            dataset = dataSetList?.[0]?.uuid;
          }
        }
        if (dataset) {
          const url = `/${initialData?.path?.organization}/${dataset}${element?.value?.[0]}`;
          navigate(url);
        }
      }
    });
  };
  if (isStartPage) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
      }}
      className="dataset_header_step_0"
    >
      <StyledTabs
        orientation={s1280 ? "horizontal" : "vertical"}
        value={tabStatus}
        onChange={handleChange}
        edge="start"
      >
        {Constant.MainTabs?.map((item) => {
          return (
            <StyledTab
              component={item?.id === 5 ? StaffTab : undefined}
              value={item.id}
              disableRipple
              key={item?.id}
              wrapped
              icon={item?.icon}
              iconPosition="start"
              label={isMainDrawerOpen ? t(item?.title) : ""}
              id={item?.id}
              props={{ setTabsStatus, isMainDrawerOpen }}
              sx={{
                "& svg": {
                  fontSize: item.id === 6 ? "1.15rem" : "1.3rem",
                },
              }}
            />
          );
        })}
      </StyledTabs>
    </Box>
  );
};
export default TabView;

const StaffTab = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const s1280 = useMediaQuery("(min-width:1280px)");

  const overlayRef = useRef(null);

  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const dataSetList = useSelector((state) => state.boardSlice?.dataSetList);

  const [anchorEl, setAnchorEl] = useState(null);
  const [tab, setTab] = useState(StaffRoutes?.[0]);

  const onClickPopOver = (e) => {
    e.stopPropagation();
    updateTab(tab?.id || StaffRoutes?.[0]);
  };

  const onDoubleClickPopOver = (e) => {
    e.stopPropagation();
    setAnchorEl(overlayRef.current);
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  const updateTab = (newValue) => {
    if (
      tab?.id === newValue &&
      tab?.value?.some((item) => location.pathname?.includes(item))
    ) {
      setAnchorEl(overlayRef.current);
    } else {
      StaffRoutes?.forEach((element) => {
        if (element?.id === newValue) {
          props?.props?.setTabsStatus(5);
          let dataset = null;
          if (dataSetData?.uuid) {
            dataset = dataSetData?.uuid;
          } else {
            dataset = localStorage.getItem("last_visited_dataset");
            if (!dataset) {
              dataset = dataSetList?.[0]?.uuid;
            }
          }
          if (dataset) {
            const url = `/${initialData?.path?.organization}/${dataset}${element?.value?.[0]}`;
            navigate(url);
          }
        }
      });
    }
  };
  useEffect(() => {
    const staffView = StaffRoutes?.find((item) =>
      item?.value?.some((item) => location.pathname?.includes(item))
    );
    if (staffView) {
      setTab(staffView);
    }
  }, [location.pathname]);
  return (
    <CustomPopover
      type="anchor"
      showRight
      anchorEl={anchorEl}
      ref={overlayRef}
      onClose={onClosePopOver}
      onClick={onClickPopOver}
      onDoubleClick={onDoubleClickPopOver}
      buttonStyle={{ mr: s1280 ? "0.5rem" : 0 }}
      button={
        <Stack
          direction={"row"}
          ref={ref}
          {...props}
          sx={{
            mr: "0px !important",
            position: "relative",
            width: "100%",
            minWidth: props?.props?.isMainDrawerOpen
              ? "8.5rem !important"
              : "fit-content",
          }}
        >
          {tab?.icon}
          <Typography
            fontWeight={"fontWeightMediumBold"}
            sx={{
              fontSize: "0.9rem",
              color: props?.[`aria-selected`]
                ? theme.palette.primary.dark
                : theme.palette.color.slate[700],
            }}
          >
            {props?.props?.isMainDrawerOpen ? t(tab?.title) : ""}
          </Typography>
          <ArrowDropDownIcon
            fontSize="small"
            sx={{
              mr: "0px !important",
              position: "absolute",
              right: s1280 ? -12 : -6,
              transform: `rotate(${anchorEl ? 180 : 0}deg)`,
              transition: "all 0.3s ease-in-out",
            }}
          />
        </Stack>
      }
    >
      <StaffOverlayView onClose={onClosePopOver} updateTab={updateTab} />
    </CustomPopover>
  );
});

const StaffOverlayView = ({ onClose, updateTab }) => {
  const location = useLocation();
  const beta_enabled = useSelector(
    (state) => state.settingsSlice?.profile?.beta_enabled
  );
  const onClick = (item) => {
    onClose();
    updateTab(item?.id);
  };

  return (
    <MenuList
      sx={{
        p: "0.5rem",
        width: "25rem",
        gap: "0.5rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {StaffRoutes?.map((item) => {
        if (
          (Constant?.isProd || !beta_enabled) &&
          ["loan", "client", "supplier", "contract"]?.includes(item?.id)
        ) {
          return null;
        }
        const isSelected = item?.value?.some((item) =>
          location.pathname?.includes(item)
        );
        return (
          <MenuItem
            key={item?.id}
            sx={{
              minWidth: "14rem",
              minHeight: "2.5rem",
              p: "1rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              borderRadius: theme.borderRadius.main,
              backgroundColor: isSelected
                ? theme.palette.primary[100]
                : "transparent",
              "& svg": {
                fontSize: "1.35rem",
                color: `${isSelected ? theme.palette.primary.main : theme.palette.color.slate[500]} !important`,
              },
              "& .MuiListItemText-primary": {
                fontSize: "1rem",
                color: isSelected
                  ? theme.palette.primary.main
                  : theme.palette.color.mainTitle,
              },
              "& .MuiListItemText-secondary": {
                overflow: "hidden",
                display: "-webkit-box",
                textOverflow: "ellipsis",
                whiteSpace: "normal",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                msTextOverflow: "ellipsis",
                fontSize: "0.8rem",
                color: isSelected
                  ? theme.palette.primary.main
                  : theme.palette.color.description,
              },
              "&:hover": {
                backgroundColor: isSelected
                  ? theme.palette.primary[100]
                  : theme.palette.color.slate[100],
              },
            }}
            onClick={() => onClick(item)}
          >
            <ListItemIcon sx={{ mt: "0.15rem" }}>{item?.icon}</ListItemIcon>
            <ListItemText
              primary={t(`${item?.title}`)}
              secondary={<Translate i18nkey={`${item?.title}_description`} />}
              sx={{ color: theme.palette.color.slate[700] }}
            ></ListItemText>
          </MenuItem>
        );
      })}
    </MenuList>
  );
};
