import {
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { MdArrowForward } from "react-icons/md";

import { cloneDeep } from "../../../Helper/data";
import DropDown from "../../../components/Overlay/DropDown";
import CustomModal from "../../../components/Model/CustomModal";

// ----------------------------------------------------------------------

const initialDropdownState = {
  id: "",
  title: "",
  gross_value: "",
  due_date: "",
  state: "",
  note: "",
  tax_value: "",
  category: "",
  scenario: "",
  cost_unit: "",
};

const requiredFields = ["id", "title", "gross_value", "due_date"];

const GoogleSheetHeaderMappingDialogView = ({
  open,
  onClose,
  updateGSheetsHeaders,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [errors, setErrors] = useState(null);
  const [dropdownValues, setDropdownValues] = useState(initialDropdownState);

  useEffect(() => {
    if (open?.headersSetByUser) {
      setDropdownValues(open?.headersSetByUser);
    } else if (open?.headersFromSheet) {
      const initialDropdownStateClone = cloneDeep(initialDropdownState);
      open?.headersFromSheet.forEach((header) => {
        const key = header.replace(/\s+/g, "_").toLowerCase();
        if (initialDropdownStateClone.hasOwnProperty(key)) {
          initialDropdownStateClone[key] = header;
        }
      });
      setDropdownValues(initialDropdownStateClone);
    }
  }, [open?.headersSetByUser, open?.headersFromSheet]);

  const handleClickAdd = () => {
    const newErrors = {};
    requiredFields.forEach((key) => {
      if (!dropdownValues[key]) {
        newErrors[key] = "This field is required";
      }
    });
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      const headersObj = Object.keys(dropdownValues).reduce((acc, key) => {
        const value = dropdownValues[key];
        if (value) {
          acc[value] = key;
        }
        return acc;
      }, {});
      updateGSheetsHeaders(headersObj);
    }
  };

  // Handler for dropdown changes
  const handleDropdownChange = (event, value, name) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setDropdownValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <CustomModal
      textAdd={t("save")}
      heading={t("gsheet_header_mapping_overlay_title")}
      onClose={onClose}
      onAdd={handleClickAdd}
      open={open}
      rootStyle={{ zIndex: 1307 }}
      dialogContentStyle={{
        ...theme.thinScrollBar,
      }}
    >
      <Table sx={{ minWidth: "70rem", mb: "2.25rem" }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: theme.palette.color.slate[50] }}>
            <TableCell
              sx={{
                py: "0.25rem",
                pl: "2rem",
                fontSize: "0.8rem",
                color: theme.palette.color.slate[400],
              }}
            >
              {t("gsheet_header_mapping_attributes")}
            </TableCell>
            <TableCell sx={{ py: "0.5rem" }}></TableCell>
            <TableCell
              sx={{
                py: "0.25rem",
                pr: "0.5rem",
                fontSize: "0.8rem",
                color: theme.palette.color.slate[400],
              }}
            >
              {t("gsheet_header_mapping_file_column")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(initialDropdownState).map((dropdownKey, index) => (
            <TableRow key={index}>
              <TableCell
                sx={{
                  py: "0.5rem",
                  pl: "2rem",
                  fontSize: "0.8rem",
                  width: "32rem",
                  color: theme.palette.color.slate[700],
                  fontWeight: theme.typography.fontWeightBold,
                }}
              >
                {t(`gsheet_header_mapping_${dropdownKey}`)}
              </TableCell>
              <TableCell
                sx={{
                  py: "0.25rem",
                  opacity: dropdownValues?.[dropdownKey] ? 1 : 0.2,
                }}
              >
                <MdArrowForward />
              </TableCell>
              <TableCell
                sx={{
                  py: "0.35rem",
                  pr: "1.75rem",
                }}
              >
                <DropDown
                  hideTitle
                  likeGoogle
                  variant="filled"
                  placeholder={t("Select attribute")}
                  // helperText={errors?.[dropdownKey]}
                  error={Boolean(errors?.[dropdownKey])}
                  value={dropdownValues?.[dropdownKey] ?? null}
                  onChange={(e, value) =>
                    handleDropdownChange(e, value, dropdownKey)
                  }
                  options={open?.headersFromSheet}
                  getOptionLabel={(option) => option ?? ""}
                  renderOption={(option) => option ?? ""}
                  mb={0}
                  sx={{
                    "& .MuiInputBase-root": {
                      px: "0.5rem !important",
                      py: "0.15rem !important",
                      border: `1px solid ${Boolean(errors?.[dropdownKey]) ? theme.palette.color.red[300] : theme.palette.color.slate[300]}`,
                      borderRadius: `${theme.borderRadius.main} !important`,
                      "& input": {
                        fontSize: "0.7rem !important",
                        lineHeight: "0.7rem !important",
                        color:
                          theme.palette.color.grey[
                            dropdownValues?.[dropdownKey] ? 800 : 500
                          ],
                        fontWeight: theme.typography.fontWeightMediumBold,
                        fontFamily: theme.typography.fontFamily,
                      },
                    },
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </CustomModal>
  );
};

export default GoogleSheetHeaderMappingDialogView;
