import {
  Stack,
  TextField,
  useTheme,
  Divider,
  List,
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ClearIcon from "@mui/icons-material/Clear";
import { FaCheckCircle } from "react-icons/fa";

import { Constant } from "../../../Helper";

const FilterOverlay = ({
  selectedIds,
  options,
  anchorEl,
  translate,
  color = "slate",
  onChangeValue,
  onClickManage,
  onClickReset,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [searchText, setSearchText] = useState("");

  const onChangeSearchText = (e) => {
    setSearchText(e.target.value);
  };

  const onClearSearchText = () => {
    setSearchText("");
  };

  const onClickItem = useCallback(
    (item) => {
      const isIncludedInFilter = selectedIds?.includes(item?.uuid);
      let changedIds = [];
      if (isIncludedInFilter) {
        changedIds = selectedIds?.filter((o1) => o1 !== item?.uuid);
      } else {
        changedIds = [...selectedIds, item?.uuid];
      }
      if (onChangeValue) {
        onChangeValue(changedIds);
      }
    },
    [onChangeValue, selectedIds]
  );

  const items = useMemo(
    () =>
      !!anchorEl
        ? options?.map((item, index) => {
            if (item?.hide) {
              return null;
            }
            const id = item?.uuid;
            const title =
              translate || item?.translate ? t(item?.title) : item?.title;
            const isSelected = selectedIds?.includes(id);
            if (
              searchText &&
              !title?.toLowerCase()?.includes(searchText?.toLowerCase())
            ) {
              return null;
            }
            return (
              <ListItem
                key={id}
                secondaryAction={
                  isSelected ? (
                    <FaCheckCircle
                      color={
                        theme.palette.color[color][
                          isSelected && item?.isRequired ? 200 : 500
                        ]
                      }
                    />
                  ) : null
                }
                disablePadding
              >
                <ListItemButton
                  disabled={item?.disabled || (isSelected && item?.isRequired)}
                  onClick={
                    isSelected && item?.isRequired
                      ? undefined
                      : () => onClickItem(item)
                  }
                  sx={{
                    "&.MuiListItemButton-root.Mui-disabled": {
                      cursor: "not-allowed",
                      pointerEvents: "auto",
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  {item?.hideIcon ? null : (
                    <ListItemIcon
                      sx={{
                        minWidth: "40px",
                        "& svg": {
                          fontSize: "1.1rem",
                          color:
                            item?.icon &&
                            theme?.palette?.color?.[
                              item?.color || color
                            ]?.[500],
                        },
                      }}
                    >
                      <FiberManualRecordIcon
                        sx={{
                          color:
                            item?.fullColor ||
                            theme?.palette?.color?.[
                              item?.color ||
                                Constant.Random_Color?.[index] ||
                                "slate"
                            ]?.[500],
                        }}
                      />
                    </ListItemIcon>
                  )}
                  <ListItemText id={id} primary={title} />
                </ListItemButton>
              </ListItem>
            );
          })
        : [],
    [
      anchorEl,
      color,
      onClickItem,
      options,
      selectedIds,
      t,
      theme.palette.color,
      translate,
      searchText,
    ]
  );

  return (
    <Stack>
      <Stack direction="row" alignItems="center" sx={{ mt: "0.5rem" }}>
        <TextField
          id="outlined-size-small"
          size="small"
          name="name"
          autoComplete="off"
          value={searchText}
          onChange={onChangeSearchText}
          placeholder={t("Search")}
          sx={{
            width: "100%",
            fontWeight: theme.typography.fontWeightMedium,
            margin: "0px 0.5rem",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiInputBase-input": {
              fontSize: "0.9rem",
              py: "0.3rem",
              pl: "1rem",
              color: theme.palette.color[color][500],
              fontWeight: theme.typography.fontWeightMedium,
            },
          }}
        />
        <ClearIcon
          onClick={onClearSearchText}
          sx={{
            display: searchText?.length > 0 ? "block" : "none",
            mr: "0.9rem",
            fontSize: "1.1rem",
            cursor: "pointer",
          }}
        />
      </Stack>

      <Divider />
      <List
        dense
        sx={{
          width: "25rem",
          maxWidth: "100vw",
          maxHeight: "50vh",
          overflowY: "auto",
          backgroundColor: "background.paper",
          ...theme.thinScrollBar,
          "& .MuiListItemText-root span": {
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
          },
          "& .MuiListItemButton-root": {
            mx: "0.4rem",
            borderRadius: theme.borderRadius.borderRadiusXL,
          },
          "& .MuiListItemSecondaryAction-root": {
            display: "flex",
            alignItems: "center",
          },
        }}
      >
        {items}
      </List>

      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="flex-end"
        sx={{
          my: onClickManage || onClickReset ? "1rem" : 0,
          mx: "1rem",
        }}
      >
        {onClickManage ? (
          <Typography
            onClick={onClickManage}
            sx={{
              fontSize: "0.8rem !important",
              fontWeight: 600,
              mt: "0.5rem",
              cursor: "pointer",
              color: theme.palette.color[color][600],
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {t("Manage")}
          </Typography>
        ) : null}
        {onClickReset ? (
          <Typography
            onClick={onClickReset}
            sx={{
              fontSize: "0.8rem !important",
              fontWeight: 600,

              ml: "1rem",
              mt: "0.5rem",
              cursor: "pointer",
              color: theme.palette.color[color][600],
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {t("Reset Filter")}
          </Typography>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default FilterOverlay;
