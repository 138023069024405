import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  periodTranData: [],
  refPeriodTranData: [],
  dateRange: [new Date(), new Date()],
  periodDateRange: [new Date(), new Date()],
  aggregates: { value: "month", label: "Monthly aggregates" },
  isChartExpanded: true,
  isFooterExpanded: true,
  isColumnBarVisible: true,
};

const chartSlice = createSlice({
  name: "chartSlice",
  initialState,
  reducers: {
    setChartExapanded(state, action) {
      state.isChartExpanded = action.payload;
    },
  
    setFooterExpanded(state, action) {
      state.isFooterExpanded = action.payload;
    },
    setColumnBarVisible(state, action) {
      state.isColumnBarVisible = action.payload;
    },
    setRefPeriodTranData(state, action) {
      state.refPeriodTranData = action.payload;
    },
    setPeriodTranData(state, action) {
      state.periodTranData = action.payload;
    },
    setDateRange(state, action) {
      state.dateRange = action.payload;
    },
    setPeriodDateRange(state, action) {
      state.periodDateRange = action.payload;
    },
    setAggregates(state, action) {
      state.aggregates = action.payload;
    },
  },
});
export const {
  setColumnBarVisible,
  setRefPeriodTranData,
  setPeriodTranData,
  setPeriodDateRange,
  setDateRange,
  setAggregates,
  setChartExapanded,
  setFooterExpanded,
} = chartSlice.actions;
export default chartSlice;
