import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  ZAxis,
} from "recharts";
import { scaleLog } from "d3-scale";
import { useSelector } from "react-redux";
import { Color, Fonts } from "../../Helper";
import { remToPx, truncate } from "../../Helper/data";
import { alpha, Box, Paper, Typography } from "@mui/material";
import { formatValue } from "react-currency-input-field";
import useWidth from "../../hooks/useWidth";
import CustomizedAxisTick from "./CustomizedAxisTick";

const CustomScatterDot = (props) => {
  const {
    cx,
    cy,
    payload,
    xAxis,
    new: latest,
    trouble,
    formats,
    revenue,
    chartType,
  } = props;

  let index = 0;
  if (chartType === "Income") {
    index = xAxis?.categoricalDomain
      .sort((a, b) => a - b)
      .findIndex((o1) => o1 === parseInt(revenue));
  } else {
    index = xAxis?.categoricalDomain
      .sort((a, b) => b - a)
      .findIndex((o1) => o1 === parseInt(revenue));
  }

  let updatedIndex = xAxis?.categoricalDomain.length <= 20 ? index * 3 : index;
  let r = 30 + updatedIndex;
  return (
    <g>
      <circle
        cx={cx}
        cy={cy}
        r={r}
        fill={payload?.color}
        fillOpacity="40%"
        stroke={
          (latest &&
            formats.includes("new") &&
            alpha(Color.themeColor, 0.75)) ||
          (trouble && formats.includes("trouble") && alpha(Color.red, 0.75)) ||
          undefined
        }
        strokeWidth="3"
      />
      <g transform={`translate(${cx},${cy})`}>
        <text
          x={0}
          y={0}
          dy={"0.25rem"}
          textAnchor="middle"
          fontSize="0.8rem"
          fill={Color.black}
        >
          {truncate(payload?.customerName || payload?.title, r / 4)}
        </text>
      </g>
    </g>
  );
};

const TooltipItemView = ({ title, value }) => {
  return (
    <Typography
      sx={{
        display: "inline-flex",
        fontSize: "0.7rem",
        fontFamily: Fonts.Text,
        fontWeight: 400,
      }}
    >
      {title}:<b style={{ position: "absolute", right: "0.5rem" }}>{value}</b>
    </Typography>
  );
};

const CustomTooltipScatter = (props) => {
  let { active, payload, currencyFormate, chartType } = props;
  if (active && payload && payload.length) {
    let title = payload[0]?.payload?.title;
    let pre12MonthTotal = payload[0]?.payload?.pre12MonthTotal;
    let last12MonthTotal = payload[0]?.payload?.last12MonthTotal;
    let titleColor = payload[0]?.payload?.color;
    let latest = payload[0]?.payload?.new;
    let revenue = payload[0]?.value;
    let change = payload[1]?.value;
    return (
      <Paper
        sx={{
          width: "14.375rem",
          height: "fix-content",
          borderRadius: 2,
          backgroundColor: Color.white,
          display: "inline-flex",
          p: "0.5rem",
          flexDirection: "column",
          boxShadow: "0 4px -2px 0 rgba(0,0,0,0.13)",
          // position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          {latest && (
            <Typography
              sx={{
                display: "inline-flex",
                fontSize: "0.9rem",
                fontFamily: Fonts.Text,
                fontWeight: 700,
                width: "54%",
                color: alpha(Color.themeColor, 0.75),
              }}
            >
              {chartType === "Income" ? "New customer" : "New Suppliers"} :
            </Typography>
          )}
          <Typography
            sx={{
              display: "inline-flex",
              fontSize: "0.9rem",
              fontFamily: Fonts.Text,
              fontWeight: 800,
              color: titleColor,
              width: "45%",
              textOverflow: "ellipsis",
              whiteSpace: "pre",
              overflow: "hidden",
            }}
          >
            {title}
          </Typography>
        </Box>
        <TooltipItemView
          title={"Revenue"}
          value={formatValue({
            value: String(parseFloat(revenue).toFixed(2)),
            groupSeparator: currencyFormate.groupSeparator,
            decimalSeparator: currencyFormate.decimalSeparator,
            prefix: currencyFormate.prefix,
          })}
        />
        <TooltipItemView
          title={"Previous year revenue"}
          value={formatValue({
            value: String(parseFloat(pre12MonthTotal).toFixed(2)),
            groupSeparator: currencyFormate.groupSeparator,
            decimalSeparator: currencyFormate.decimalSeparator,
            prefix: currencyFormate.prefix,
          })}
        />
        <TooltipItemView
          title={"Last 12 month revenue"}
          value={formatValue({
            value: String(parseFloat(last12MonthTotal).toFixed(2)),
            groupSeparator: currencyFormate.groupSeparator,
            decimalSeparator: currencyFormate.decimalSeparator,
            prefix: currencyFormate.prefix,
          })}
        />
        <TooltipItemView
          title={"Change to previous year"}
          value={change + " %"}
        />
      </Paper>
    );
  }

  return null;
};

const ScatterChartView = React.memo(
  ({ data, type = "Income" }) => {
    const currentWidth = useWidth();
    const appliedFilterlist = useSelector(
      (state) => state.globalSlice.appliedFilterlist
    );
    const currencyFormate = useSelector(
      (state) => state.settingsSlice.currencyFormate
    );
    if (!appliedFilterlist?.reports?.client) {
      return null;
    }
    if (!appliedFilterlist?.reports?.client?.formate[type]) {
      return null;
    }

    const scale = scaleLog().base(Math.E);
    return (
      <ResponsiveContainer width="99.5%" height="96%">
        <ScatterChart
          width={"100%"}
          height={"100%"}
          margin={{
            top: remToPx(currentWidth, 1.25),
            right: remToPx(currentWidth, 1.875),
            left: remToPx(currentWidth, 1.25),
            bottom: remToPx(currentWidth, 0.3125),
          }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
            horizontal={false}
          />
          <XAxis
            dataKey={(v) => parseInt(v.revenue)}
            type="number"
            name="Revenue"
            domain={
              type === "Income"
                ? [0, (dataMax) => dataMax * 1.1]
                : [(dataMin) => dataMin * 1.1, 1]
            }
            tickCount={12}
            minTickGap={1}
            allowDataOverflow={false}
            // scale={"sqrt"}
            scale={type === "Income" ? "auto" : "sqrt"}
            // orientation={type === "Income" ? "bottom" : "top"}
            // mirror={type === "Income" ? false : true}
            reversed={type === "Income" ? false : true}
            tick={
              <CustomizedAxisTick
                dy={remToPx(currentWidth, 1)}
                dx={0}
                formatted
              />
            }
          />
          <YAxis
            dataKey={(v) => parseInt(v.change)}
            type="number"
            domain={
              type === "Income"
                ? [(dataMin) => dataMin, (dataMax) => dataMax * 1.1]
                : [(dataMin) => dataMin * 1.4, (dataMax) => dataMax + 5]
            }
            name="Change To Previous Year"
            tickCount={12}
            minTickGap={1}
            scale={type === "Income" ? "auto" : "sqrt"}
            // orientation={type === "Income" ? "left" : "right"}
            tick={
              <CustomizedAxisTick
                dy={remToPx(currentWidth, 0.2)}
                dx={-remToPx(currentWidth, 1)}
              />
            }
            tickFormatter={(value) => `${parseInt(value)} %`}
          />
          <ZAxis
            dataKey={(v) => parseInt(Math.abs(v.change))}
            range={[64, 512]}
            name="score"
            scale={scale}
          />
          <Tooltip
            cursor={{ fill: "transparent", strokeDasharray: "3 3" }}
            content={
              <CustomTooltipScatter
                currencyFormate={currencyFormate}
                chartType={type}
              />
            }
          />

          <Scatter
            name="Client Analysis"
            data={data}
            shape={
              <CustomScatterDot
                formats={appliedFilterlist?.reports?.client?.formate[type]}
                chartType={type}
              />
            }
          />
        </ScatterChart>
      </ResponsiveContainer>
    );
  },
  (prev, next) => {
    return prev.data === next.data;
  }
);
export default ScatterChartView;
