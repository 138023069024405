import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "underscore";
import {
  setAccounts,
  setAddFrom,
  setAdvanceVat,
  setAppliedFilters,
  setAppliedFilterslist,
  setBanks,
  setConsentAlert,
  setContacts,
  setCostUnits,
  setDataSource,
  setFilters,
  setIsAccountSelectOverlayOpen,
  setIsDsUuidLoading,
  setIsDsUuidUpdating,
  setIsFirstFetchDs,
  setIsFirstLoginOverlayOpen,
  setJoyRideStatus,
  setOnBoardingList,
  setRecurring_rules,
  setScenario,
  setStates,
  setTransactionSystem,
  setVats,
} from "../../store/slices/global";
import {
  setIsDailyChartLoading,
  setIsFatching,
  setIsKanbanUpdated,
  setIsLoading,
  setIsMonthChartLoading,
  setLoading,
  setRefreshData,
  setStageLoadingText,
} from "../../store/slices/appmain";
import { setSteps } from "../../store/slices/tour";
import authSlice from "../../store/slices/auth";
import {
  setAccountBalanceUpdateDate,
  setChartLineKeys,
  setDailyChartData,
  setDataSetData,
  setHighlightedScenarios,
  setMonthlyChartData,
  setTodayLiq,
  setTransactionData,
  setdatasetList,
} from "../../store/slices/board";
import {
  getAllTransactionsByParams,
  getDataSourceErrorById,
  getDefaultFilters,
  getExternalDataSourceConnection,
  getFinApiDataSourceConnection,
  updateLanguage,
} from "../../Helper/data";
import WarnInfoOverlay from "../WarnInfoOverlay";
import StageLoader from "../StageLoader";
import useDebounce from "../../hooks/3-useDebounce/useDebounce";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { queryClient } from "../../App";
import {
  setProfile,
  setWarnInfoOverlayStatus,
} from "../../store/slices/settings";
import { setCategories } from "../../store/slices/category";
import { addMonths, endOfMonth, format, set, subMonths } from "date-fns";
import { useNavigate } from "react-router";
import {
  setDailyTransactions,
  setMonthlyTransactions,
  setPopupStatus,
  setPopupStatus2,
  setPopupStatus3,
  setPopupStatus4,
  setPopupStatus5,
  setTransactionsOverlayStatus,
} from "../../store/slices/datasets";
import { useQuery } from "@tanstack/react-query";
import { resetReports } from "../../store/slices/report";
import { Constant } from "../../Helper";
import { setIsAllHeaderApiFetched } from "../../store/slices/common";

const defaultJoyrideOptions = [
  {
    data_type: "transaction",
    title: "joyride",
    filters: {
      header: false,
      dataSetList: false,
      dataSource: false,
      dashboard_overview: false,
      list: false,
      kanban: false,
    },
  },
];

const startDate = subMonths(set(new Date(), { date: 1 }), 1);
const result = addMonths(new Date(startDate), Constant.column_count);
const endDate = endOfMonth(new Date(result));
const CommonView = ({ setTodoList }) => {
  const dispatch = useDispatch();
  const alreadyStoredFilters = useRef(null);
  const isFirstFetch = useRef({
    joyride: true,
    dataset: true,
    filter: true,
  });
  const { t } = useTranslation();
  const navigate = useNavigate();

  //redux
  const accounts = useSelector((state) => state.globalSlice.accounts);
  const dataSource = useSelector((state) => state.globalSlice?.dataSource);
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const dataSetList = useSelector((state) => state.boardSlice?.dataSetList);

  const filters = useSelector((state) => state.globalSlice?.filters);
  const warnInfoOverlayStatus = useSelector(
    (state) => state.settingsSlice?.warnInfoOverlayStatus
  );
  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );
  const joyRideStatus = useSelector(
    (state) => state.globalSlice?.joyRideStatus
  );
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const isFirstLoginOverlayOpen = useSelector(
    (state) => state.globalSlice.isFirstLoginOverlayOpen
  );
  const isAccountSelectOverlayOpen = useSelector(
    (state) => state.globalSlice.isAccountSelectOverlayOpen
  );
  const appliedFilter = useSelector((state) => state.globalSlice.appliedFilter);
  const refreshToken = useSelector((state) => state.auth?.refreshToken);
  const accountByDS = useMemo(
    () => _.groupBy(accounts, ({ data_source }) => data_source),
    [accounts]
  );
  const refreshBalance = useSelector((state) => state.appSlice?.refreshBalance);

  const [allowDsFetch, setAllowDsFetch] = useState(false);

  //api
  const updateFilters = async (id, obj) => {
    await APICall("patch", EndPoints.filters + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          let data = [...filters];
          let index = filters?.findIndex(
            (o1) => o1?.uuid === response.data.uuid
          );
          data[index] = response.data;
          dispatch(setFilters(data));
        }
      }
    );
  };

  const updateToken = async () => {
    let obj = {
      refresh: refreshToken,
    };
    await APICall("post", EndPoints.refreshToken, obj).then((response) => {
      if (response.status === 200 && response.data) {
        let accessToken = response.data.access;
        localStorage.setItem("token", accessToken);
        localStorage.setItem("refreshToken", refreshToken);

        dispatch(
          authSlice.actions.setAuthTokens({
            token: accessToken,
            refreshToken: refreshToken,
          })
        );
      }
    });
  };

  const updateDataSetByID = async (
    id,
    obj,
    array,
    skipUpdateDataset = false
  ) => {
    await APICall("patch", EndPoints.datasets + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          alreadyStoredFilters.current = response.data?.filters;
          let data = [...array];
          let index = array.findIndex((o1) => o1.uuid === id);
          if (index > -1) {
            data[index] = response.data;
          }
          data.sort((a, b) => (a.position > b.position ? 1 : -1));
          dispatch(setdatasetList(data));
          if (!skipUpdateDataset) {
            dispatch(setDataSetData(response.data));
          }
        }
      }
    );
  };

  const getRecurringRules = async () => {
    const results = await getAllTransactionsByParams({
      endpoint: "recurring_rules",
    });
    if (results) {
      dispatch(setRecurring_rules(results));
    }
  };

  const getFilters = async () => {
    await APICall("get", EndPoints.filters).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        if (data.length === 0) {
          defaultJoyrideOptions.forEach((element, i) => {
            addFilters(element, i === defaultJoyrideOptions.length - 1);
          });
        } else {
          let filterData = data?.find((o1) => {
            return o1?.title === "joyride";
          });
          if (filterData) {
            dispatch(
              setJoyRideStatus({ ...filterData, data_type: "transaction" })
            );
          }
          let filterListData = data?.find((o1) => {
            return o1?.title === "list";
          });
          if (filterListData) {
            dispatch(
              setAppliedFilters({ ...filterListData, data_type: "transaction" })
            );
          }
          dispatch(setFilters(data));
        }
      }
    });
  };

  const addFilters = async (obj, isAllow = false) => {
    await APICall("post", EndPoints.filters, obj).then((response) => {
      if (response.status === 201 && response.data) {
        if (isAllow) {
          getFilters();
        }
      }
    });
  };

  const getMasterData = async () => {
    await APICall("get", EndPoints.master_data).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data;
        let allStates = data?.states?.results?.map((o1) => o1.title);
        let allScenario = data?.scenarios?.results?.map((o1) => o1.title);
        let filterDataSet = data?.datasets?.results?.find(
          (o1) => o1.title.toLowerCase() === "default"
        );
        if (!data?.profile?.first_login) {
          updateLanguage(data?.profile?.locale);
        }
        let base_id = data?.scenarios?.results?.find(
          (o1) => o1.title === "Base"
        )?.uuid;
        dispatch(setHighlightedScenarios([base_id]));
        dispatch(setBanks(data?.banks?.results));
        dispatch(setStates(data?.states?.results));
        dispatch(setScenario(data?.scenarios?.results));
        dispatch(setProfile(data?.profile));
        dispatch(setCostUnits(data?.costunits?.results));
        dispatch(
          setCategories({ data: data?.categories?.results, type: "all" })
        );
        dispatch(setVats(data?.taxes?.results));
        dispatch(setAdvanceVat(data?.tax_configuration));
        dispatch(setAccounts(data?.accounts?.results));
        dispatch(setContacts(data?.contacts?.results));
        dispatch(setDataSource(data?.data_sources?.results));
        dispatch(
          setOnBoardingList(
            data?.todos?.results.filter(
              (o1) => o1.category?.toLowerCase() === "onboarding"
            )
          )
        );

        if (
          filterDataSet &&
          filterDataSet?.length > 0 &&
          (!filterDataSet?.start_date || !filterDataSet?.end_date)
        ) {
          const start_date = format(new Date(startDate), "yyyy-MM-dd");
          updateDataSetByID(
            filterDataSet?.uuid,
            {
              start_date: start_date,
              end_date: format(new Date(endDate), "yyyy-MM-dd"),
              alias: "DFL",
              filters: getDefaultFilters({
                allStates,
                allScenario,
                start_date,
              }),
            },
            data?.datasets?.results
          );
        } else {
          data?.datasets?.results.sort((a, b) =>
            a.position > b.position ? 1 : -1
          );
          dispatch(setdatasetList(data?.datasets?.results));
        }
      }
    });
  };

  const getTransactionSystemUuid = async () => {
    await APICall("get", EndPoints.transaction_systems).then((response) => {
      if (response.status === 200 && response.data) {
        dispatch(setTransactionSystem(response.data.results));
      }
    });
  };

  const getAccountsApi = async () => {
    await APICall("get", EndPoints.accounts).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setAccounts(data));
      }
    });
  };

  useDebounce(
    () => {
      getAccountsApi();
    },
    100,
    [refreshBalance]
  );

  //functions
  const refetchData = () => {
    setTimeout(() => {
      let options = {
        predicate: (query) =>
          ["transactions", "List", "invoices"].includes(query.queryKey[0]) &&
          query.queryKey[1].dataset === dataSetData?.uuid,
        type: "active",
      };
      queryClient.invalidateQueries(options, { cancelRefetch: true });
      dispatch(setRefreshData(Date.now()));
    }, 500);
  };

  //life cycle method
  useEffect(() => {
    const tokenExpire = setInterval(
      () => {
        updateToken();
      },
      29 * 60 * 1000
    );

    global.allowFetch = { Inflow: [], Outflow: [] };
    global.isFirstFetchUuid = [];
    dispatch(setIsFirstFetchDs(0));
    dispatch(setIsAccountSelectOverlayOpen(null));
    dispatch(setIsFirstLoginOverlayOpen(false));
    dispatch(setIsAllHeaderApiFetched(false));
    dispatch(setStageLoadingText(null));
    dispatch(setLoading(false));
    dispatch(setIsLoading(false));
    dispatch(setIsFatching(false));
    dispatch(setIsKanbanUpdated(false));
    dispatch(setIsDsUuidLoading(null));
    dispatch(setIsDailyChartLoading(false));
    dispatch(setIsMonthChartLoading(false));
    dispatch(setDataSetData(null));
    dispatch(setDataSetData(null));
    dispatch(resetReports());
    dispatch(setChartLineKeys(null));
    dispatch(setAppliedFilterslist(null));
    dispatch(setStates([]));
    dispatch(setScenario([]));
    dispatch(setTransactionData([]));
    dispatch(setMonthlyTransactions([]));
    dispatch(setDailyTransactions([]));
    dispatch(setMonthlyChartData([]));
    dispatch(setDailyChartData([]));
    dispatch(setTodayLiq(0));
    dispatch(setAccountBalanceUpdateDate(null));
    dispatch(setPopupStatus(null));
    dispatch(setPopupStatus2(null));
    dispatch(setPopupStatus3(null));
    dispatch(setPopupStatus4(null));
    dispatch(setPopupStatus5(null));
    dispatch(setTransactionsOverlayStatus(null));
    dispatch(setHighlightedScenarios(null));
    dispatch(setScenario([]));
    dispatch(setCostUnits([]));
    dispatch(setCategories({ data: [], type: "all" }));
    dispatch(setVats([]));
    dispatch(setAdvanceVat(null));
    dispatch(setAccounts([]));
    dispatch(setDataSource([]));
    dispatch(setOnBoardingList([]));
    dispatch(
      setWarnInfoOverlayStatus({
        open: false,
        title: "",
        contentKey: "",
        allowClose: true,
        type: "",
      })
    );

    setTimeout(async () => {
      if (refreshToken) {
        queryClient.clear();
        await updateToken();
        await getMasterData();
        await getTransactionSystemUuid();
        await getFilters();
        await getRecurringRules();
        dispatch(setIsAllHeaderApiFetched(true));
      } else {
        navigate("/login");
      }
    }, 0);

    return () => {
      dispatch(setLoading(false));
      dispatch(setIsDsUuidLoading(null));
      dispatch(setStageLoadingText(false));
      dispatch(setIsFatching(false));
      dispatch(setIsLoading(false));
      dispatch(setIsFirstLoginOverlayOpen(false));
      dispatch(setSteps({ key: "empty", t }));
      clearInterval(tokenExpire);
    };
  }, []);

  useDebounce(
    () => {
      if (
        joyRideStatus?.uuid &&
        !isFirstLoginOverlayOpen &&
        !isAccountSelectOverlayOpen &&
        isAllHeaderApiFetched
      ) {
        updateFilters(joyRideStatus?.uuid, joyRideStatus);
        if (!isFirstFetch.current?.joyride) {
        } else {
          isFirstFetch.current.joyride = false;
        }
      }
    },
    2000,
    [joyRideStatus]
  );

  useDebounce(
    () => {
      if (
        appliedFilter?.uuid &&
        !isFirstLoginOverlayOpen &&
        !isAccountSelectOverlayOpen &&
        isAllHeaderApiFetched
      ) {
        if (!isFirstFetch.current?.filter) {
          updateFilters(appliedFilter?.uuid, appliedFilter);
        } else {
          isFirstFetch.current.filter = false;
        }
      }
    },
    2000,
    [appliedFilter]
  );

  useDebounce(
    () => {
      if (
        dataSetData?.uuid &&
        isAllHeaderApiFetched &&
        !isAccountSelectOverlayOpen &&
        !isFirstLoginOverlayOpen &&
        appliedFilterlist &&
        (!alreadyStoredFilters.current ||
          (alreadyStoredFilters.current &&
            !_.isEqual(alreadyStoredFilters.current, appliedFilterlist)))
      ) {
        if (!isFirstFetch.current?.dataset) {
          updateDataSetByID(
            dataSetData?.uuid,
            { filters: appliedFilterlist },
            dataSetList,
            true
          );
        } else {
          isFirstFetch.current.dataset = false;
        }
      }
    },
    2000,
    [appliedFilterlist]
  );

  useDebounce(
    () => {
      setAllowDsFetch(
        Boolean(
          isAllHeaderApiFetched &&
            !isAccountSelectOverlayOpen &&
            !isFirstLoginOverlayOpen
        )
      );
    },
    200,
    [
      isAllHeaderApiFetched,
      isAccountSelectOverlayOpen,
      isFirstLoginOverlayOpen,
    ],
    true
  );

  return (
    <>
      {allowDsFetch &&
        dataSource?.map((item) => {
          return (
            <DsFunction
              key={item?.uuid}
              ds={item}
              account={accountByDS?.[item?.uuid]?.[0] || null}
              refetchData={refetchData}
            />
          );
        })}

      {warnInfoOverlayStatus?.uiType === "overlay" &&
      warnInfoOverlayStatus?.open ? (
        <WarnInfoOverlay />
      ) : null}

      <StageLoader />
    </>
  );
};
export default CommonView;

const DsFunction = ({ ds, account, refetchData }) => {
  const dispatch = useDispatch();

  //lifecycle
  const updateProfile = async (obj, dataset) => {
    await APICall("patch", EndPoints.profile, obj).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data;
        dispatch(setProfile(data));
      }
    });
  };

  const getDataSourceById = async (id) => {
    let data = null;

    await APICall("get", EndPoints.integrations + `${id}/`).then((response) => {
      if (response.status === 200 && response.data) {
        data = response.data;
      }
    });
    return data;
  };

  return (
    <>
      {ds?.uuid && ds.type === 12 ? (
        <FinAPiDs
          ds={ds}
          account={account}
          getDataSourceById={getDataSourceById}
          updateProfile={updateProfile}
          refetchData={refetchData}
        />
      ) : null}
      {ds?.uuid && ds.type === 19 ? (
        <IntegrationsDs
          ds={ds}
          account={account}
          getDataSourceById={getDataSourceById}
          updateProfile={updateProfile}
          refetchData={refetchData}
        />
      ) : null}
    </>
  );
};

const FinAPiDs = ({
  ds,
  account,
  getDataSourceById,
  updateProfile,
  refetchData,
}) => {
  let subscribe = useRef(null);
  const dispatch = useDispatch();
  const addFrom = useSelector((state) => state.globalSlice.addFrom);
  const dataSource = useSelector((state) => state.globalSlice.dataSource);
  const profile = useSelector((state) => state.settingsSlice?.profile);
  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );

  const FinApiQueryKey = [
    "integrations",
    {
      data_source: ds?.uuid,
      apiType: "data_source_finapi_connections",
    },
  ];

  //api
  const data_source_by_uuid_finapi_connections = useQuery({
    queryKey: FinApiQueryKey,
    queryFn: async ({ signal }) => {
      const result = await getFinApiDataSourceConnection(ds?.uuid);
      if (result) {
        return result;
      }
    },
    refetchIntervalInBackground: 60000 * 10,
    backgroundFetch: true,
    priority: 5,
    enabled: false,
  });

  const data_source_by_uuid = useQuery({
    queryKey: [
      "integrations",
      {
        data_source: ds?.uuid,
        apiType: "data_source_first_fetch",
      },
    ],
    queryFn: ({ signal }) => {
      const result = getDataSourceById(ds?.uuid);
      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    priority: 5,
    enabled: false,
  });

  //consent check
  useDebounce(
    () => {
      if (
        !!account &&
        !data_source_by_uuid_finapi_connections.isFetching &&
        data_source_by_uuid_finapi_connections.data
      ) {
        if (!global.isConsentAlert) {
          global.isConsentAlert = [];
        }
        let foundInDataset = datasetAccountList?.find(
          (o1) => o1.uuid === ds?.uuid
        );
        if (
          data_source_by_uuid_finapi_connections?.data !== "COMPLETED" &&
          data_source_by_uuid_finapi_connections?.data !== "UPDATED" &&
          Boolean(foundInDataset)
        ) {
          if (!global.isConsentAlert?.includes(ds.uuid)) {
            global.isConsentAlert.push(ds.uuid);
          }
        } else {
          global.isConsentAlert = global.isConsentAlert?.filter(
            (o1) => o1 !== ds?.uuid
          );
        }
        dispatch(setConsentAlert(global.isConsentAlert?.length));
      }
    },
    2000,
    [account, ds, data_source_by_uuid_finapi_connections, datasetAccountList],
    true
  );

  //fetch connections
  useDebounce(
    () => {
      if (
        !!account &&
        !data_source_by_uuid_finapi_connections?.data &&
        !data_source_by_uuid_finapi_connections?.isFetching
      ) {
        data_source_by_uuid_finapi_connections?.refetch();
      }
    },
    200,
    [account, ds, data_source_by_uuid_finapi_connections, addFrom],
    true
  );

  //first interval fetch and check
  useEffect(() => {
    if (
      !!account &&
      ds.state === 1 &&
      !ds?.last_sync_date &&
      !data_source_by_uuid?.data?.last_sync_date &&
      !data_source_by_uuid_finapi_connections?.isFetching &&
      (data_source_by_uuid_finapi_connections?.data === "COMPLETED" ||
        data_source_by_uuid_finapi_connections?.data === "UPDATED")
    ) {
      if (!global.isFirstFetchUuid) {
        global.isFirstFetchUuid = [];
      }
      if (!global.isFirstFetchUuid?.includes(ds.uuid)) {
        global.isFirstFetchUuid.push(ds.uuid);
      }

      dispatch(setIsFirstFetchDs(global.isFirstFetchUuid?.length || 0));
      subscribe.current = setInterval(() => {
        data_source_by_uuid.refetch();
      }, 4000);
    }

    return () => {
      clearInterval(subscribe.current);
    };
  }, [
    account,
    ds,
    data_source_by_uuid_finapi_connections?.data,
    data_source_by_uuid_finapi_connections?.isFetching,
  ]);

  //first fetch complete
  useDebounce(
    () => {
      if (
        data_source_by_uuid?.data &&
        data_source_by_uuid?.isSuccess &&
        data_source_by_uuid?.data?.state === 1 &&
        data_source_by_uuid?.data?.last_sync_date
      ) {
        clearInterval(subscribe.current);
        if (profile?.first_login) {
          updateProfile({ first_login: false });
        }
        let updatedData = [];
        dataSource?.forEach((item) => {
          if (item?.uuid === data_source_by_uuid?.data?.uuid) {
            updatedData.push(data_source_by_uuid?.data);
          } else {
            updatedData.push(item);
          }
        });
        global.isFirstFetchUuid = global.isFirstFetchUuid?.filter(
          (o1) => o1 !== data_source_by_uuid?.data?.uuid
        );
        dispatch(setIsFirstFetchDs(global?.isFirstFetchUuid?.length || 0));
        dispatch(setAddFrom(null));
        dispatch(setDataSource(updatedData));
        refetchData();
      }

      if (data_source_by_uuid?.data && data_source_by_uuid?.isError) {
        clearInterval(subscribe.current);
      }
    },
    200,
    [data_source_by_uuid?.data, data_source_by_uuid?.status],
    true
  );

  return null;
};

const IntegrationsDs = ({
  ds,
  getDataSourceById,
  updateProfile,
  refetchData,
}) => {
  let subscribe = useRef(null);
  const prev_last_sync_date = useRef(null);
  // if (ds?.title === "Google Sheets API") {
  //   console.log("🚀 / prev_last_sync_date:", prev_last_sync_date.current);
  // }
  const dispatch = useDispatch();
  const addFrom = useSelector((state) => state.globalSlice.addFrom);
  const dataSource = useSelector((state) => state.globalSlice.dataSource);
  const profile = useSelector((state) => state.settingsSlice?.profile);

  const isDsUuidLoading = useSelector(
    (state) => state.globalSlice.isDsUuidLoading
  );
  const isDsUuidUpdating = useSelector(
    (state) => state.globalSlice.isDsUuidUpdating
  );
  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );
  const check_is_connected = ds?.note !== "Google Sheets API";

  //api
  const refetchAllTransaction = async (integration_id) => {
    await APICall(
      "post",
      EndPoints.integrations + `${integration_id}/external/fetch-all/`,
      {}
    )
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          data_source_by_uuid.refetch();
        }
      })
      .finally(() => {
        dispatch(setIsDsUuidLoading(null));
      })
      .catch(() => {
        // global.isFirstFetchUuid = global.isFirstFetchUuid?.filter(
        //   (o1) => o1 !== integration_id
        // );
        // dispatch(setIsFirstFetchDs(global?.isFirstFetchUuid?.length || 0));
      });
  };

  //query
  const data_source_by_uuid_external_connections = useQuery({
    queryKey: [
      "integrations",
      {
        data_source: ds?.uuid,
        apiType: "data_source_external_connections",
      },
    ],
    queryFn: async ({ signal }) => {
      const result = await getExternalDataSourceConnection(ds?.uuid);
      if (result) {
        return result;
      }
    },
    refetchIntervalInBackground: 60000 * 10,
    backgroundFetch: true,
    priority: 5,
    enabled: false,
  });

  const data_source_by_uuid = useQuery({
    queryKey: [
      "integrations",
      {
        data_source: ds?.uuid,
        apiType: "data_source_first_fetch",
      },
    ],
    queryFn: ({ signal }) => {
      const result = getDataSourceById(ds?.uuid);
      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    priority: 5,
    enabled: false,
  });

  //consent check
  useDebounce(
    () => {
      if (
        !isDsUuidUpdating &&
        !isDsUuidLoading &&
        !data_source_by_uuid_external_connections.isFetching &&
        data_source_by_uuid_external_connections.data
      ) {
        if (!global.isConsentAlert) {
          global.isConsentAlert = [];
        }
        let foundInDataset = datasetAccountList?.find(
          (o1) => o1.uuid === ds?.uuid
        );
        if (
          foundInDataset &&
          data_source_by_uuid_external_connections?.data !== "UPDATED"
        ) {
          if (!global.isConsentAlert?.includes(ds.uuid)) {
            global.isConsentAlert.push(ds.uuid);
          }
        } else {
          global.isConsentAlert = global.isConsentAlert?.filter(
            (o1) => o1 !== ds?.uuid
          );
        }
        dispatch(setConsentAlert(global.isConsentAlert?.length));
      }
    },
    2000,
    [
      ds,
      data_source_by_uuid_external_connections,
      datasetAccountList,
      isDsUuidLoading,
      isDsUuidUpdating,
    ],
    true
  );

  //fetch connections
  useDebounce(
    () => {
      if (
        (!isDsUuidLoading ||
          (isDsUuidLoading && isDsUuidLoading === ds?.uuid)) &&
        !data_source_by_uuid_external_connections.data &&
        !data_source_by_uuid_external_connections.isFetching
      ) {
        data_source_by_uuid_external_connections.refetch();
      }
    },
    200,
    [ds, data_source_by_uuid_external_connections, isDsUuidLoading, addFrom],
    true
  );

  //first fetch
  useEffect(() => {
    if (
      ds?.state === 1 &&
      !ds?.last_sync_date &&
      (check_is_connected ? !ds?.is_connected : true) &&
      !data_source_by_uuid_external_connections?.isFetching &&
      data_source_by_uuid_external_connections?.data === "UPDATED" &&
      (check_is_connected ? !data_source_by_uuid?.data?.is_connected : true) &&
      !data_source_by_uuid?.data?.last_sync_date
    ) {
      if (!global.isFirstFetchUuid) {
        global.isFirstFetchUuid = [];
      }
      if (!global.isFirstFetchUuid?.includes(ds.uuid)) {
        global.isFirstFetchUuid.push(ds.uuid);
      }

      dispatch(setIsFirstFetchDs(global.isFirstFetchUuid?.length || 0));
    }
  }, [
    ds,
    check_is_connected,
    data_source_by_uuid_external_connections?.isFetching,
    data_source_by_uuid_external_connections?.data,
  ]);

  //first interval fetch and check
  useEffect(() => {
    if (
      !data_source_by_uuid_external_connections?.isFetching &&
      data_source_by_uuid_external_connections?.data === "UPDATED" &&
      data_source_by_uuid?.data?.state === 1 &&
      data_source_by_uuid?.data?.is_connected &&
      (!data_source_by_uuid?.data?.last_sync_date ||
        (data_source_by_uuid?.data?.last_sync_date &&
          prev_last_sync_date.current ===
            data_source_by_uuid?.data?.last_sync_date))
    ) {
      if (!subscribe.current) {
        subscribe.current = setInterval(() => {
          data_source_by_uuid.refetch();
        }, 4000);
      }
    }

    return () => {
      clearInterval(subscribe.current);
    };
  }, [
    ds,
    data_source_by_uuid,
    data_source_by_uuid_external_connections?.isFetching,
    data_source_by_uuid_external_connections?.data,
  ]);

  //first fetch complete
  useDebounce(
    () => {
      if (
        data_source_by_uuid?.data &&
        data_source_by_uuid?.data?.state === 1 &&
        data_source_by_uuid?.data?.is_connected &&
        data_source_by_uuid?.data?.last_sync_date
      ) {
        if (
          prev_last_sync_date.current
            ? prev_last_sync_date.current !==
              data_source_by_uuid?.data?.last_sync_date
            : true
        ) {
          clearInterval(subscribe.current);
          prev_last_sync_date.current = null;
          if (profile?.first_login) {
            updateProfile({ first_login: false });
          }
          let updatedData = [];
          dataSource?.forEach((item) => {
            if (item?.uuid === data_source_by_uuid?.data?.uuid) {
              updatedData.push(data_source_by_uuid?.data);
            } else {
              updatedData.push(item);
            }
          });
          global.isFirstFetchUuid = global.isFirstFetchUuid?.filter(
            (o1) => o1 !== data_source_by_uuid?.data?.uuid
          );
          dispatch(setIsFirstFetchDs(global?.isFirstFetchUuid?.length || 0));
          dispatch(setAddFrom(null));
          dispatch(setDataSource(updatedData));
          getDataSourceErrorById(data_source_by_uuid?.data);
          refetchData();
        }
      }

      if (data_source_by_uuid?.data && data_source_by_uuid?.isError) {
        clearInterval(subscribe.current);
      }
    },
    200,
    [
      data_source_by_uuid?.data,
      data_source_by_uuid?.data?.is_connected,
      data_source_by_uuid?.data?.last_sync_date,
      data_source_by_uuid?.status,
    ],
    true
  );

  //final update
  useDebounce(
    () => {
      if (
        data_source_by_uuid_external_connections?.data === "UPDATED" &&
        data_source_by_uuid_external_connections?.isSuccess
      ) {
        if (isDsUuidLoading === ds?.uuid) {
          clearInterval(subscribe.current);
          prev_last_sync_date.current = ds?.last_sync_date;
          if (!global.isFirstFetchUuid) {
            global.isFirstFetchUuid = [];
          }
          if (!global.isFirstFetchUuid?.includes(ds.uuid)) {
            global.isFirstFetchUuid.push(ds.uuid);
          }

          dispatch(setIsFirstFetchDs(global.isFirstFetchUuid?.length || 0));
          refetchAllTransaction(ds?.uuid);
        }
        if (isDsUuidUpdating === ds?.uuid) {
          clearInterval(subscribe.current);
          dispatch(setIsDsUuidUpdating(null));
        }
      }
      if (
        data_source_by_uuid_external_connections?.data === "DISCONNECTED" &&
        data_source_by_uuid_external_connections?.isSuccess
      ) {
        clearInterval(subscribe.current);
        if (isDsUuidLoading === ds?.uuid) {
          dispatch(setIsDsUuidLoading(null));
        }
        if (isDsUuidUpdating === ds?.uuid) {
          dispatch(setIsDsUuidUpdating(null));
        }
      }
    },
    300,
    [
      ds?.uuid,
      ds?.last_sync_date,
      isDsUuidLoading,
      data_source_by_uuid_external_connections?.data,
      data_source_by_uuid_external_connections?.status,
    ],
    true
  );

  return null;
};
