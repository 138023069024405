import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import * as Sentry from "@sentry/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import ProtectedRoute from "./ProtectedRoute";
import Start from "../Pages/Start";
import Dataset from "../Pages/Datasets/index";
import Settings from "../Pages/Settings/index";
import Kanban from "../Pages/Datasets/Kanban";
import Transactions from "../Pages/Datasets/Transactions";
import Reports from "../Pages/Datasets/Reports/index";
import LoginScreen from "../Pages/Auth/LoginScreen";
import RegisterScreen from "../Pages/Auth/RegisterScreen";
import AccountActivateScreen from "../Pages/Auth/AccountActivateScreen";
import PasswordResetScreen from "../Pages/Auth/PasswordResetScreen";
import PassResetFormScreen from "../Pages/Auth/PassResetFormScreen";
import ErrorPage from "../Pages/ErrorPage";

import Header from "../components/Header";
import Categories from "../Pages/Settings/Categories/index";
import General from "../Pages/Settings/General/index";
import Profile from "../Pages/Settings/Profile/index";
import Vats from "../Pages/Settings/Vat/index";
import DataSource from "../Pages/Settings/DataSource/index";
import DataSetList from "../Pages/Settings/DataSetList/index";
import Rules from "../Pages/Settings/Rules";
import Subscription from "../Pages/Settings/Subscription";
import Scenarios from "../Pages/Settings/Scenarios";
import Users from "../Pages/Settings/Users";
import SubscriptionPaymentSuccessful from "../Pages/Settings/Subscription/SubscriptionPaymentSuccessful";
import Banner from "../components/Banner/Banner";

import initialData, { getTailwindColor } from "../Helper/data";
import CommonView from "./CommonView";
import Security from "../Pages/Settings/Security";
import { Constant } from "../Helper";

import Employees from "../Pages/Datasets/Employees";
import MainDrawerView from "../components/Header/MainDrawerView";
import Clients from "../Pages/Datasets/Employees/Clients";
import Suppliers from "../Pages/Datasets/Employees/Suppliers";
import Contracts from "../Pages/Datasets/Employees/Contracts";
import CostUnit from "../Pages/Datasets/Employees/CostUnit";
import Loan from "../Pages/Datasets/Employees/Loan";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
const renderMultiRoutes = ({ element: Element, paths, ...rest }) =>
  paths.map((path) => (
    <Route key={path} path={path} {...rest} element={Element} />
  ));
export default function AppRouter() {
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const isMainDrawerOpen = useSelector(
    (state) => state.settingsSlice?.isMainDrawerOpen
  );
  const beta_enabled = useSelector(
    (state) => state.settingsSlice?.profile?.beta_enabled
  );
  const subscription = useSelector(
    (state) => state.settingsSlice?.subscription
  );

  const [showBanner, setShowBanner] = useState(true);

  const showHeader =
    auth?.token &&
    auth?.refreshToken &&
    !location.pathname.includes("/login") &&
    !location.pathname.includes("/register") &&
    !location.pathname.includes("/registration/") &&
    !location.pathname.includes("/auth/");
  const isStartPage =
    location?.pathname?.includes("/dashboard") || location?.pathname === "/";

  useEffect(() => {
    if (subscription?.name === "Trial") {
      Constant.BANNER_HEIGHT = "1.5rem";
      setShowBanner(true);
    } else {
      Constant.BANNER_HEIGHT = "0rem";
      setShowBanner(false);
    }
  }, [subscription?.name]);

  return (
    <Box
      component="div"
      sx={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        "& .MuiSkeleton-root": {
          backgroundColor: getTailwindColor("grey", 100),
        },
      }}
    >
      {/* {Constant.isDev && (
        <ReactQueryDevtools
          initialIsOpen={false}
          position={"left-bottom"}
          buttonPosition={"left-bottom"}
        />
      )} */}

      {/* <RouterProvider
    router={router}
    fallbackElement={<h1>..Loading</h1>}
  /> */}
      <CommonView />
      {showHeader ? (
        <>
          {showBanner ? <Banner /> : null}
          <Header />
        </>
      ) : null}

      <Stack
        direction={"row"}
        sx={{
          width: "100%",
          height: showHeader
            ? `calc(100% - ${Constant.HEADER_HEIGHT} - ${Constant.BANNER_HEIGHT})`
            : "100%",
          overflow: "hidden",
        }}
      >
        {showHeader && !isStartPage ? <MainDrawerView /> : null}
        <Box
          sx={{
            width: {
              xs:
                showHeader && !isStartPage
                  ? isMainDrawerOpen
                    ? `calc(100% - ${Constant.DRAWER_WIDTH})`
                    : `calc(100% - ${Constant.DRAWER_WIDTH_CLOSED})`
                  : "100%",
              s1280: "100%",
            },
            height: "100%",
          }}
        >
          <SentryRoutes>
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/register" element={<RegisterScreen />} />
            <Route
              path="/register/activate/"
              element={<AccountActivateScreen />}
            />
            <Route
              path="/auth/password_reset/"
              element={<PasswordResetScreen />}
            />
            <Route path="/error" element={<ErrorPage />} />
            <Route
              path="/register/password_reset/"
              element={<PassResetFormScreen />}
            />

            <Route>
              {renderMultiRoutes({
                paths: ["/", "/dashboard"],
                element: (
                  <ProtectedRoute>
                    <Start />
                  </ProtectedRoute>
                ),
              })}
            </Route>

            <Route
              path={`/${initialData?.path?.organization}/:id`}
              element={
                <ProtectedRoute>
                  <Dataset />
                </ProtectedRoute>
              }
            >
              <Route
                path={`/${initialData?.path?.organization}/:id/table`}
                element={
                  <ProtectedRoute>
                    <Kanban />
                  </ProtectedRoute>
                }
              />

              <Route>
                {renderMultiRoutes({
                  paths: [
                    `/${initialData?.path?.organization}/:id/list/all`,
                    `/${initialData?.path?.organization}/:id/list/suggestion`,
                    `/${initialData?.path?.organization}/:id/list/categorized`,
                    `/${initialData?.path?.organization}/:id/list/uncategorized`,
                    `/${initialData?.path?.organization}/:id/list/bulk`,
                    `/${initialData?.path?.organization}/:id/list/bank`,
                    `/${initialData?.path?.organization}/:id/list/open`,
                    `/${initialData?.path?.organization}/:id/list/budget`,
                    `/${initialData?.path?.organization}/:id/list/reconcile`,
                    `/${initialData?.path?.organization}/:id/list/late`,
                    `/${initialData?.path?.organization}/:id/list/issues`,
                  ],
                  element: (
                    <ProtectedRoute>
                      <Transactions />
                    </ProtectedRoute>
                  ),
                })}
              </Route>
              <Route>
                {renderMultiRoutes({
                  paths: [
                    `/${initialData?.path?.organization}/:id/reports/overview`,
                  ],
                  element: (
                    <ProtectedRoute>
                      <Reports />
                    </ProtectedRoute>
                  ),
                })}
              </Route>
              <Route
                path={`/${initialData?.path?.organization}/:id/employees`}
                element={
                  <ProtectedRoute>
                    <Employees />
                  </ProtectedRoute>
                }
              />
              {Constant?.isProd || !beta_enabled ? null : (
                <Route
                  path={`/${initialData?.path?.organization}/:id/loans`}
                  element={
                    <ProtectedRoute>
                      <Loan />
                    </ProtectedRoute>
                  }
                />
              )}

              <Route
                path={`/${initialData?.path?.organization}/:id/cost-units`}
                element={
                  <ProtectedRoute>
                    <CostUnit />
                  </ProtectedRoute>
                }
              />

              {Constant?.isProd || !beta_enabled ? null : (
                <Route>
                  {renderMultiRoutes({
                    paths: [
                      `/${initialData?.path?.organization}/:id/clients/customers-portfolio-analysis`,
                      `/${initialData?.path?.organization}/:id/clients/top-customers`,
                      `/${initialData?.path?.organization}/:id/clients/customers-list`,
                      `/${initialData?.path?.organization}/:id/clients/merge-customers`,
                    ],
                    element: (
                      <ProtectedRoute>
                        <Clients />
                      </ProtectedRoute>
                    ),
                  })}
                </Route>
              )}

              {Constant?.isProd || !beta_enabled ? null : (
                <Route>
                  {renderMultiRoutes({
                    paths: [
                      `/${initialData?.path?.organization}/:id/suppliers/suppliers-portfolio-analysis`,
                      `/${initialData?.path?.organization}/:id/suppliers/top-suppliers`,
                      `/${initialData?.path?.organization}/:id/suppliers/suppliers-list`,
                      `/${initialData?.path?.organization}/:id/suppliers/merge-suppliers`,
                    ],
                    element: (
                      <ProtectedRoute>
                        <Suppliers />
                      </ProtectedRoute>
                    ),
                  })}
                </Route>
              )}

              {!beta_enabled ? null : (
                <Route>
                  {renderMultiRoutes({
                    paths: [
                      `/${initialData?.path?.organization}/:id/contracts/recurring-subscriptions`,
                    ],
                    element: (
                      <ProtectedRoute>
                        <Contracts />
                      </ProtectedRoute>
                    ),
                  })}
                </Route>
              )}
              <Route
                path={`/${initialData?.path?.organization}/:id/categories`}
                element={
                  <ProtectedRoute>
                    <Categories isMainPage={true} />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            >
              <Route
                path="/settings/security"
                element={
                  <ProtectedRoute>
                    <Security />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings/personal"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings/users"
                element={
                  <ProtectedRoute>
                    <Users />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings/general"
                element={
                  <ProtectedRoute>
                    <General />
                  </ProtectedRoute>
                }
              />

              <Route
                path={`/settings/${initialData?.path?.organizations}`}
                element={
                  <ProtectedRoute>
                    <DataSetList />
                  </ProtectedRoute>
                }
              />

              {/* <Route
            path="/settings/states"
            element={
              <ProtectedRoute>
                <States />
              </ProtectedRoute>
            }
          /> */}
              <Route
                path="/settings/scenarios"
                element={
                  <ProtectedRoute>
                    <Scenarios />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/settings/vats"
                element={
                  <ProtectedRoute>
                    <Vats />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings/rules"
                element={
                  <ProtectedRoute>
                    <Rules />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings/Integrations"
                element={
                  <ProtectedRoute>
                    <DataSource type={"general"} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings/subscription"
                element={
                  <ProtectedRoute>
                    <Subscription />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings/subscription/successful-payment/"
                element={
                  <ProtectedRoute>
                    <SubscriptionPaymentSuccessful />
                  </ProtectedRoute>
                }
              />
            </Route>
          </SentryRoutes>
        </Box>
      </Stack>
    </Box>
  );
}
