import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useState } from "react";
import theme from "../../../../../theme";
import OverlayFooter from "../../../../../components/Overlay/OverlayFooter";

const SelectSheetStep = ({ sheetNames, onContinue }) => {
  const [value, setValue] = useState(sheetNames[0]);

  const handleSheetSelectionChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Box pb={2}>
      <RadioGroup
        row
        size="small"
        name={"Select Sheet"}
        value={value}
        onChange={handleSheetSelectionChange}
        sx={{
          py: "0.4rem",
          px: "0.75rem",
          borderRight: `1px solid ${theme.palette.color.slate[400]}`,
          backgroundColor: theme.palette.color.slate[100],
          borderTopLeftRadius: theme.borderRadius.main,
          borderBottomLeftRadius: theme.borderRadius.main,
          "& .MuiFormControlLabel-label": {
            fontWeight: theme.typography.fontWeightMedium,
          },
        }}
      >
        {sheetNames.map((sheetName) => (
          <FormControlLabel
            key={sheetName}
            value={sheetName}
            control={<Radio />}
            label={sheetName}
          />
        ))}
      </RadioGroup>
      <OverlayFooter onClickAction={() => onContinue(value)} />
      {/* <TailwindButton variant="contained" onClick={}>
        Next
      </TailwindButton> */}
    </Box>
  );
};

export default SelectSheetStep;
