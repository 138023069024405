import { Paper, Typography } from "@mui/material";
import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  ComposedChart,
} from "recharts";
import { Color, Fonts } from "../../Helper";
import { remToPx } from "../../Helper/data";
import useWidth from "../../hooks/useWidth";
let options = [
  {
    id: 2,
    title: "Variable Cost",
    key: "VariableCost_12M",
    color: Color.theme.blue[500],
  },
  {
    id: 4,
    title: "Fix Costs",
    key: "FixCosts_12M",
    color: Color.theme.indigo[500],
  },
  {
    id: 5,
    title: "Return On sales",
    key: "OperatingIncome_12M",
    color: Color.theme.green[500],
  },
];

const CustomizedAxisTick = React.memo((props) => {
  const { x, y, payload, dy = 0, dx = 0, formatted = false } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={dy}
        dx={dx}
        fontSize={"0.7rem"}
        fontWeight={500}
        textAnchor="middle"
        fill="#666"
      >
        {formatted ? `${payload.value} %` : payload.value}
      </text>
    </g>
  );
});

const TooltipItemView = ({ title, value, color }) => {
  return (
    <Typography
      sx={{
        display: "inline-flex",
        fontSize: "0.7rem",
        alignItems: "center",
        fontFamily: Fonts.Text,
        fontWeight: 400,
      }}
    >
      <span
        style={{
          width: "0.5rem",
          height: "0.5rem",
          borderRadius: 5,
          backgroundColor: color,
          marginRight: "0.5rem",
        }}
      />
      {title}:<b style={{ position: "absolute", right: "0.5rem" }}>{value}</b>
    </Typography>
  );
};

const CustomTooltipScatter = (props) => {
  let { active, payload } = props;
  if (active && payload && payload.length) {
    let due_date_12M = payload[0]?.payload?.due_date_12M;
    let data = payload[0]?.payload;
    return (
      <Paper
        sx={{
          width: 230,
          height: "fix-content",
          borderRadius: 2,
          backgroundColor: Color.white,
          display: "inline-flex",
          p: 1,
          flexDirection: "column",
          boxShadow: "0 4px -2px 0 rgba(0,0,0,0.13)",
        }}
      >
        <Typography
          sx={{
            display: "inline-flex",
            fontSize: "0.9rem",
            fontFamily: Fonts.Text,
            fontWeight: 600,
            textOverflow: "ellipsis",
            whiteSpace: "pre",
            overflow: "hidden",
          }}
        >
          {due_date_12M}
        </Typography>
        {options?.map((item) => {
          return (
            <TooltipItemView
              key={item?.id}
              title={item?.title}
              color={item?.color}
              value={(data?.length !== 0 ? data[item?.key] : 0) + " %"}
            />
          );
        })}
      </Paper>
    );
  }

  return null;
};

const CostRatioReturnsCharts = React.memo(
  ({ data }) => {
    const currentWidth = useWidth();
    return (
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={data}
          margin={{
            top: remToPx(currentWidth, 0.3125),
            right: remToPx(currentWidth, 1.875),
            left: remToPx(currentWidth, 1.25),
            bottom: remToPx(currentWidth, 0.3125),
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="due_date_12M"
            tick={
              <CustomizedAxisTick
                dy={remToPx(currentWidth, 1)}
                dx={0}
                formatted={false}
              />
            }
            type="category"
          />
          <YAxis
            tick={
              <CustomizedAxisTick
                dy={remToPx(currentWidth, 0.25)}
                dx={-remToPx(currentWidth, 1.625)}
                formatted
              />
            }
            type="number"
          />
          <Tooltip
            cursor={{ fill: "transparent", strokeDasharray: "3 3" }}
            content={<CustomTooltipScatter />}
          />
          {options?.map((entry) => {
            return (
              <Area
                key={`Line-${entry?.id}`}
                type="monotone"
                dataKey={entry?.key}
                strokeWidth={2}
                stroke={entry?.color}
                fill={entry?.color}
                strokeOpacity={1}
                fillOpacity={0.1}
              />
            );
          })}
        </ComposedChart>
      </ResponsiveContainer>
    );
  },
  (prev, next) => {
    return prev.data === next.data && prev.height === next.height;
  }
);
export default CostRatioReturnsCharts;
