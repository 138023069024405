import { alpha, Stack, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import TailwindButton from "./TailwindButton";
import theme from "../../theme";
import { PiArrowElbowDownLeft } from "react-icons/pi";
import React from "react";

const OverlayFooter = ({
  modalType,
  loading,
  onCancel,
  onClickAction,
  textAdd,
  disable,
  tooltipAdd,
  sx,
}) => {
  const { t } = useTranslation();
  const keyEnter = React.useCallback(
    (event) => {
      if (!disable && !loading && event.key === "Enter") {
        event.preventDefault();
        onClickAction(event);
      }
    },
    [disable, loading, onClickAction]
  );

  React.useEffect(() => {
    document.addEventListener("keydown", keyEnter);

    return () => {
      document.removeEventListener("keydown", keyEnter);
    };
  }, [keyEnter]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={"flex-end"}
      sx={sx}
    >
      {onCancel ? (
        <TailwindButton
          disableElevation
          onClick={onCancel}
          text={t("Cancel")}
          variant="contained"
          type="edit"
          icon={<EscapeIcon />}
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            ml: "0rem",
            py: "0.35rem",
            px: "0.75rem",
          }}
        />
      ) : null}
      <Tooltip title={t(tooltipAdd)} arrow followCursor placement="top">
        <span>
          <TailwindButton
            disableElevation
            disable={disable}
            onClick={onClickAction}
            text={
              textAdd
                ? t(textAdd)
                : modalType === "add"
                  ? t("Add")
                  : t("Update")
            }
            variant="contained"
            loading={loading}
            icon={loading ? undefined : <EnterIcon />}
            sx={{
              py: loading ? "0.8rem" : "0.35rem",
              px: "0.75rem",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          />
        </span>
      </Tooltip>
    </Stack>
  );
};
export default OverlayFooter;

const EscapeIcon = () => (
  <span
    style={{
      marginLeft: "0.5rem",
      fontSize: "0.55rem",
      lineHeight: "0.5rem",
      width: "28px",
      height: "22px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: theme.typography.fontWeightBold,
      borderRadius: theme.borderRadius.main,
      border: `1px solid ${theme.palette.color.slate[300]}`,
    }}
  >
    {"ESC"}
  </span>
);

const EnterIcon = () => (
  <span
    style={{
      marginLeft: "0.5rem",
      width: "22px",
      height: "22px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: theme.typography.fontWeightBold,
      borderRadius: theme.borderRadius.main,
      border: `1px solid ${alpha(theme.palette.color.white, 0.3)}`,
    }}
  >
    <PiArrowElbowDownLeft strokeWidth={10} />
  </span>
);
