import { useSelector } from "react-redux";
import { Box, Modal } from "@mui/material";
import FinApiWebForm from "../../../Settings/DataSource/FinApiWebForm";

const FinApiOverlay = () => {
  const locale = useSelector((state) => state.settingsSlice?.profile?.locale);
  const isAccountSelectOverlayOpen = useSelector(
    (state) => state.globalSlice.isAccountSelectOverlayOpen
  );
  const token = isAccountSelectOverlayOpen?.payload?.token;
  if (!token || !locale) {
    return null;
  }

  return (
    <Modal
      open={true}
      // onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "50%",
        }}
      >
        <FinApiWebForm
          token={token}
          language={locale === "de_DE" ? "de" : "en"}
        />
      </Box>
    </Modal>
  );
};

export default FinApiOverlay;
