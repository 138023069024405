import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useMutation } from "@tanstack/react-query";
import { Outlet, useLocation, useNavigate } from "react-router";

import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Button from "@mui/lab/LoadingButton";

import APICall from "../../../APICall";
import EndPoints from "../../../APICall/EndPoints";
import { setContacts } from "../../../store/slices/global";
import Staff from "./pages/Staff";
import RevenueExpense from "./pages/RevenueExpense";
import { Color, Constant, Fonts, Images } from "../../../Helper";
import initialData, { cloneDeep } from "../../../Helper/data";
import HelpOverlay from "../../../components/HelpOverlay.js/index.js";
import Report from "./pages/Report";
import Forecast from "./pages/Forecast";
import Dashboard from "../Dashboard";
import CustomPopoverScratch from "../../../components/PopOver/CustomPopover";
import { setReportFilter } from "../../../store/slices/report";
import ReportWidget from "./ReportWidget";
import { thinScrollbarStyle } from "../../../Helper/data";
import useStatusHook from "../../../hooks/useStatusHook";
import CommonFunctions from "./ReportWidget/Component/CommonFunctions";
import useSubscriptions from "../../../hooks/useSubscriptions";
import ReportsPagesOverlay from "./ReportWidget/Component/ReportsPagesOverlay";
import AddButton from "../../../components/AddButton";
import UpgradePlanCard from "../../../components/UpgradePlanCard";
import ActionButton from "../../../components/ActionButton";
import ThemeTabs from "../../../components/ThemeTabs";
import CustomPopover from "../../../components/PopOver";

const RangeTypeOptions = [
  { rangeType: "Monthly", comparison: "Last Month" },
  { rangeType: "Quarterly", comparison: "Last Quarter" },
  { rangeType: "Annually", comparison: "Last Year" },
];

const ReportCategory = Constant?.ReportCategory;
const ReportWidgetOptions = Constant?.ReportWidgetOptions;
const Reports = () => {
  const isFirstLoginOverlayOpen = useSelector(
    (state) => state.globalSlice.isFirstLoginOverlayOpen
  );
  const isAccountSelectOverlayOpen = useSelector(
    (state) => state.globalSlice.isAccountSelectOverlayOpen
  );
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );

  if (
    isFirstLoginOverlayOpen ||
    isAccountSelectOverlayOpen ||
    !isAllHeaderApiFetched
  ) {
    return null;
  }

  return (
    <>
      {/* <OldReport /> */}
      <NewReport />
      <Outlet />
    </>
  );
};

export default Reports;

const NewReport = () => {
  const commonRef = useRef(null);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: Color.appThemeBg,
        overflowY: "auto",
        overflowX: "hidden",
        ...thinScrollbarStyle,
      }}
    >
      <ReportHeader commonRef={commonRef} />
      <ReportWidget />
      <CommonFunctions ref={commonRef} showLoader />
    </Box>
  );
};

const ReportHeader = ({ commonRef }) => {
  const { t } = useTranslation();
  //state
  const [listAnchor, setListAnchor] = useState(false);
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBlock: "1.5rem",
      }}
    >
      <div style={{ width: "20rem" }} />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          flex: 1,
        }}
      >
        <PageView />
        <Stack
          direction="row"
          alignItems="center"
          sx={{ position: "relative" }}
        >
          <ManagePages listAnchor={listAnchor} setListAnchor={setListAnchor} />

          <AddButtonView commonRef={commonRef} listAnchor={listAnchor} />

          <HelpOverlay
            text={t("Tab_Title_Reports")}
            hideDefault
            hideHeader
            data={[
              {
                id: 1,
                image: Images.reports_dashboard_insight,
                linkText: "report_help_text_title_1",
                link: `https://finban.io/finanzielle-metriken/`,
                description: "report_help_text_des_1",
              },
            ]}
            wrapperStyle={{
              position: "absolute",
              right: "-3.5rem",
            }}
          />
        </Stack>
      </Stack>
      <div style={{ width: "20rem" }} />
    </Box>
  );
};

const PageView = () => {
  const dispatch = useDispatch();
  const commonRef = useRef(null);

  //redux
  const Page = useSelector((state) => state.reportSlice.Page);
  const ReportPages = useStatusHook("ReportPages");
  const pagesList = ReportPages?.data;

  //functions
  const handleToggle = (e, value) => {
    let _page = pagesList?.find((p) => p.uuid === value);
    let _widgets = JSON.parse(JSON.stringify(_page?.widgets));
    dispatch(
      setReportFilter({
        key: "Page",
        value: _page,
      })
    );
    commonRef.current?.updatePageLayout({
      widgets: cloneDeep(_widgets),
    });
    global.reportManualUpdate = true;
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <CommonFunctions ref={commonRef} />
      {Page?.uuid && pagesList?.length > 0 ? (
        <ThemeTabs
          options={pagesList}
          tab={Page?.uuid}
          onChange={handleToggle}
          truncateText
          truncateTextCount={20}
        />
      ) : null}
    </Box>
  );
};

const FilterWidgetView = ({ item, commonRef }) => {
  const { t } = useTranslation();
  const [isSubscriptionValid] = useSubscriptions();
  //redux
  const Page = useSelector((state) => state.reportSlice.Page);

  //state
  const [loading, setLoading] = useState(false);

  const addReportPageMutation = useMutation({
    mutationFn: (obj) => addNewWidget(obj),
  });

  //api
  const addNewWidget = async (obj) => {
    setLoading(true);
    await APICall(
      "post",
      `${EndPoints.reporting_dashboards}${Page?.uuid}/widgets/`,
      obj
    )
      .then((response) => {
        if (response.status === 201 && response.data) {
          let result = response.data;
          let _widgets = [...(Page.widgets || [])].sort(
            (a, b) => a.position - b.position
          );
          if (!obj?.isWidgetsAlreadyAdded) {
            _widgets.push(result);
            commonRef?.current?.updatePageWidgets({
              widgets: _widgets,
              updateLayout: true,
              isAdd: true,
            });
          } else {
            _widgets.splice(result?.position - 1, 0, result);
            commonRef?.current?.updatePageWidgets({
              widgets: _widgets,
              updateLayout: true,
              updateBatchPosition: true,
            });
          }
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  };

  //functions
  const onAdd = () => {
    if (isSubscriptionValid({ showMessage: true })) {
      setLoading(true);
      let isWidgetsAlreadyAdded = Page?.widgets?.filter(
        (o1) => o1?.type === item?.type
      );

      let obj = {
        type: item?.type,
        width: item?.width,
        height: item?.height,
        start_date: item?.start_date || null,
        end_date: item?.end_date || null,
        position: (Page?.widgets?.length || 0) + 1,
        isWidgetsAlreadyAdded: isWidgetsAlreadyAdded?.length > 0,
      };
      if (isWidgetsAlreadyAdded?.length > 0) {
        const maxPosition = isWidgetsAlreadyAdded?.reduce(
          (max, { position }) => Math.max(max, position),
          0
        );
        obj.position = maxPosition + 1;
      }

      addReportPageMutation.mutate(obj);
    }
  };

  return (
    <Card
      elevation={4}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "18rem",
        height: "13rem",
        mb: "1rem",
        position: "relative",
        borderRadius: "4px !important",
        backgroundColor: Color.tailwind.slate[50],
        "&:hover": {
          "& #add_button": {
            display: "flex !important",
            cursor: "pointer",
          },
        },
      }}
    >
      <div
        style={{
          height: "60%",
        }}
      >
        {!item?.comingSoon ? null : (
          <p
            style={{
              fontFamily: Fonts.Text,
              fontSize: "1rem",
              fontWeight: 600,
              color: Color.tailwind.slate[700],
            }}
          >
            {t("Coming_Soon")}
          </p>
        )}
        <img
          src={Images?.[item?.type]}
          alt={item?.type}
          width={"100%"}
          height={"100%"}
          loading={"lazy"}
          decoding={"async"}
          referrerPolicy={"no-referrer"}
          style={{
            paddingTop: "1rem",
            paddingInline: "1rem",
            objectFit: "cover",
            objectPosition: "left Top",
          }}
        />
      </div>
      <div
        style={{
          height: "40%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: Color.white,
          position: "relative",
          paddingInline: "1rem",
          paddingBottom: "0.5rem",
        }}
      >
        <p
          style={{
            fontFamily: Fonts.Text,
            fontSize: "0.9rem",
            fontWeight: 600,
            color: Color.tailwind.slate[700],
            marginBlock: "0.7em",
          }}
        >
          {t(item?.type)}
        </p>
        <CategoryChain widgetCategoryList={item?.categories} />
        {item?.comingSoon ? null : (
          <Button
            loading={loading}
            onClick={onAdd}
            id="add_button"
            style={{
              display: loading ? "flex" : "none",
              color: Color.tailwind.slate[700],
              fontSize: "0.8rem",
              fontWeight: 600,
              fontFamily: Fonts.Text,
              borderRadius: 16,
              border: `1px solid ${Color.tailwind.slate[700]}`,
              width: "fit-content",
              marginRight: "0.25rem",
              padding: "0.4rem",
              lineHeight: "0.8rem",
              height: "1.8rem",
              position: "absolute",
              bottom: "0.5rem",
              right: "0.5rem",
              backgroundColor: Color.white,
            }}
          >
            {loading ? "" : t("Add")}
          </Button>
        )}
      </div>
    </Card>
  );
};

const CategoryChain = ({ widgetCategoryList }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {widgetCategoryList?.map((item) => {
        return (
          <div
            key={item}
            style={{
              color: Color.tailwind.slate[400],
              fontSize: "0.6rem",
              fontWeight: 500,
              fontFamily: Fonts.Text,
              borderRadius: 4,
              border: `1px solid ${Color.tailwind.slate[400]}`,
              width: "fit-content",
              marginRight: "0.25rem",
              padding: "0.15rem",
              lineHeight: "0.6rem",
              marginBottom: "0.25rem",
              backgroundColor: Color.white,
            }}
          >
            {t(item)}
          </div>
        );
      })}
    </div>
  );
};

const LeftView = ({ widgetCategory, setWidgetCategory }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const onChange = (e, item) => {
    let _widgetsCategory = [];

    if (e.target.value === "All") {
      if (widgetCategory?.length === ReportCategory?.length) {
        _widgetsCategory = [];
      } else {
        _widgetsCategory = ReportCategory?.map((item) => item?.title);
      }
    } else {
      if (widgetCategory.includes(e.target.value)) {
        _widgetsCategory = widgetCategory?.filter(
          (item) => item !== e.target.value
        );
      } else {
        _widgetsCategory = [...widgetCategory, e.target.value];
      }
    }
    setWidgetCategory(_widgetsCategory);
  };

  const onClickCard = () => {
    window.open(`https://finban.io/finanzielle-metriken/`, "_blank");
  };

  const onClickLink = () => {
    window.location.href = `mailto:support@finban.io?subject=${t("report_widget_mailto_sub_msg")}`;
  };

  return (
    <Box
      sx={{
        width: "45%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: "1.5rem",
        backgroundColor: Color.tailwind.slate[50],
      }}
    >
      <div>
        {ReportCategory?.map((item) => {
          return (
            <CheckItemView
              item={item}
              key={item?.title}
              checked={widgetCategory?.includes(item?.title)}
              onChange={onChange}
            />
          );
        })}
        <CheckItemView
          item={{ id: "All", title: "All" }}
          key={"All"}
          checked={widgetCategory?.length === ReportCategory?.length}
          onChange={onChange}
        />
      </div>
      <div>
        <UpgradePlanCard
          onClick={onClickCard}
          title="report_add_btn_link_title"
          description="report_add_btn_link_desc"
          showButton={false}
          sx={{
            height: "9rem",
            marginBottom: "1rem",
            boxShadow: "none",
            border: `1px solid ${theme.palette.color.violet[400]}`,
            cursor: "pointer",
            "&:hover": {
              backgroundColor: `${theme.palette.color.violet[100]} !important`,
            },
          }}
        />
        <Button onClick={onClickLink} sx={{ width: "fit-content" }}>
          <Typography
            sx={{
              color: Color.themeColor,
              fontSize: "0.8rem",
              lineHeight: "0.8rem",
              fontWeight: 500,
              textAlign: "left",
              textDecoration: "underline",
            }}
          >
            {t("Check our Metrics Page")}
          </Typography>
        </Button>
      </div>
    </Box>
  );
};

const CheckItemView = ({ item, checked, onChange }) => {
  const { t } = useTranslation();
  const count =
    item?.title === "All"
      ? ReportWidgetOptions?.length
      : ReportWidgetOptions?.filter((o1) =>
          o1.categories?.includes(item?.title)
        )?.length;
  return (
    <FormControlLabel
      label={`${t(item?.title)} (${count})`}
      sx={{
        mt: "0.5rem",
        "& .MuiTypography-root": {
          fontWeight: 400,
          fontFamily: Fonts.Text,
          fontSize: "0.9rem",
          color: Color.tailwind.slate[700],
        },
        "& .MuiCheckbox-root.Mui-checked": {
          color: "transparent",
        },
        "& .MuiSvgIcon-root": {
          fill: Color.tailwind.slate[700],
        },
      }}
      control={
        <Checkbox
          checked={checked}
          onChange={(e) => onChange(e, item)}
          value={item?.title}
          size="small"
          sx={{
            color: Color.tailwind.slate[700],
            py: "0.2rem",
          }}
        />
      }
    />
  );
};

const AddButtonView = ({ commonRef, listAnchor }) => {
  const { t } = useTranslation();
  const [widgetCategory, setWidgetCategory] = useState(["General"]);
  const [anchorEl, setAnchorEl] = useState(null);

  //redux
  const Page = useSelector((state) => state.reportSlice.Page);
  const beta_enabled = useSelector(
    (state) => state.settingsSlice?.profile?.beta_enabled
  );

  const RightView = useMemo(() => {
    return (
      <Box
        sx={{
          width: "60%",
          display: "inline",
          height: "100%",
          overflowY: "auto",
          flexDirection: "column",
          alignItems: "end",
          p: "1.5rem",
          ...thinScrollbarStyle,
          "&::-webkit-scrollbar": {
            width: "0.4rem",
          },
        }}
      >
        {ReportWidgetOptions?.map((item, index) => {
          if (
            !beta_enabled &&
            (item.type === "profitability" ||
              item.type === "burn-rate-12-month")
          ) {
            return null;
          }
          if (
            widgetCategory?.some((_category) =>
              item?.categories?.includes(_category)
            )
          ) {
            return (
              <FilterWidgetView key={index} item={item} commonRef={commonRef} />
            );
          } else {
            return null;
          }
        })}
      </Box>
    );
  }, [widgetCategory, beta_enabled, commonRef]);

  if (!Page) return null;

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  const onClickPopOver = (e) => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <CustomPopover
      type="anchor"
      anchorEl={anchorEl}
      onClose={onClosePopOver}
      button={
        <AddButton
          onClick={onClickPopOver}
          tooltiplabel={t("Add Widget")}
          label={t("Add Widget")}
          doNotListenKey={!!listAnchor}
          isNKey
        />
      }
    >
      <Box
        sx={{
          width: "42rem",
          height: "70vh",
          display: "flex",
          overflowY: "auto",
        }}
      >
        <LeftView
          widgetCategory={widgetCategory}
          setWidgetCategory={setWidgetCategory}
        />
        {RightView}
      </Box>
    </CustomPopover>
  );
};

const ManagePages = ({ listAnchor, setListAnchor }) => {
  const pageListOverlayRef = useRef(null);

  //functions
  const onClickManage = () => {
    setListAnchor(pageListOverlayRef.current);
  };

  const handleClosePopOver = () => {
    setListAnchor(null);
  };
  return (
    <>
      <ActionButton
        ref={pageListOverlayRef}
        onClick={onClickManage}
        label={"Manage Pages"}
        buttontype="manage2"
        sx={{ mr: "1rem" }}
        className="reports_step_0"
      />
      <ReportsPagesOverlay
        anchorEl={listAnchor}
        handleClosePopOver={handleClosePopOver}
      />
    </>
  );
};

const OldReport = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { ReportsMainTabOptions: tabOptions } = initialData;

  // const profile = useSelector((state) => state.settingsSlice?.profile);
  // const isFetching = useSelector((state) => state.appSlice?.isFetching);
  // const joyRideStatus = useSelector(
  //   (state) => state.globalSlice?.joyRideStatus
  // );
  // const isAllHeaderApiFetched = useSelector(
  //   (state) => state.commonSlice.isAllHeaderApiFetched
  // );
  //state
  const [selectedItem, setSelectedItem] = useState(false);
  const [selectedType, setSelectedType] = useState("Overview");
  const [allowJoyRideReports, setAllowJoyRideReports] = useState(false);
  const [allowJoyRideSuppliers, setAllowJoyRideSuppliers] = useState(false);
  const [allowJoyRide, setAllowJoyRide] = useState(false);

  useEffect(() => {
    getCustomers();
    // Emitter.on("dashboard_overview_1_step", (id) => {
    //   onClickItem(id);
    //   setAllowJoyRideReports(true);
    // });
    // Emitter.on("dashboard_overview_2_1_step", (id) => {
    //   onClickItem(id);
    //   setAllowJoyRide(true);
    // });
    // Emitter.on("dashboard_overview_3_step_after", (id) => {
    //   onClickItem(id);
    //   setAllowJoyRideSuppliers(true);
    // });
    // Emitter.on("dashboard_overview_0_step", (id) => {
    //   onClickItem(id);
    // });

    return () => {
      // Emitter.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    let array = location.pathname?.split("/");
    array?.splice(0, 4);
    let url = "";
    array?.forEach((path) => {
      url = url + "/" + path;
    });
    let key = array[array.length - 1];
    if (key !== "dashboard") {
      let filter = tabOptions?.find((o1) => o1.path.includes(url));
      if (filter) {
        setSelectedItem(filter?.id);
        setSelectedType(filter?.option);
      }
    } else {
      setSelectedItem(0);
    }
  }, [location.pathname]);

  // useUpdateEffect(() => {
  //   if (joyRideStatus?.filters && !profile?.first_login) {
  //     let { dashboard_overview } = joyRideStatus?.filters;
  //     if (
  //       isAllHeaderApiFetched &&
  //       !Boolean(dashboard_overview) &&
  //       !isFetching
  //     ) {
  //       dispatch(setSteps({ key: "dashboard_overview", t }));
  //       dispatch(startTour());
  //       let joyRideStatusFilter = joyRideStatus?.filters;
  //       let filterObj = {
  //         ...joyRideStatus,
  //         data_type: "transaction",
  //         filters: { ...joyRideStatusFilter, dashboard_overview: true },
  //       };
  //       dispatch(setJoyRideStatus(filterObj));
  //     }
  //   }
  // }, [
  //   isAllHeaderApiFetched,
  //   joyRideStatus?.filters,
  //   isFetching,
  //   profile?.first_login,
  // ]);

  //api
  const getCustomers = async () => {
    await APICall("get", EndPoints.customers).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setContacts(data));
      }
    });
  };

  //function
  const onClickItem = (id) => {
    let key = tabOptions?.find((o1) => o1.id === id);
    let array = location.pathname.split("/");
    let path = array.splice(0, 4);
    path.push(key.path[0].replace("/", ""));
    let url = path.join("/");
    navigate(url);
  };

  //RenderFunction
  const MainTabView = () => {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          py: "0.75rem",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: Color.white,
        }}
      >
        {tabOptions?.map((item) => {
          if (Constant.isProd) {
            if (
              item?.id === 3 ||
              item?.id === 6 ||
              item?.id === 7 ||
              item?.id === 8
            ) {
              return null;
            }
          }

          return (
            <Typography
              key={item.id}
              onClick={() => onClickItem(item.id)}
              className={item?.className || ""}
              sx={{
                mx: "1.5rem",
                fontSize: "1rem",
                fontWeight: selectedItem === item.id ? 600 : 500,
                fontFamily: Fonts.Text,
                color:
                  selectedItem === item.id
                    ? Color.themeColor
                    : Color.deepPurple400,
                cursor: "pointer",
                display: "inline-flex",
                transition: "200ms",
                "&:hover": {
                  fontWeight: 600,
                  color: Color.themeColor,
                },
              }}
            >
              {t(item?.option)}
              {Constant.isTest && ![3, 6, 7, 8].includes(item?.id)
                ? ` (p)`
                : null}
            </Typography>
          );
        })}
        {selectedItem === 0 ? (
          <HelpOverlay
            image={Images.dashboard_insight}
            text={"Dashboard View"}
            path={"dashboard"}
            tourKey="dashboard_overview"
            data={[
              {
                id: 1,
                image: Images.Kpi_insight,
                linkText: "KPIs & Analyses - Documentation",
                link: `https://finban.io/category/unternehmenskennzahlen-kpis/ `,
                description:
                  "Get more Information and Tips&Tricks for KPIs & Analyses",
              },
            ]}
          />
        ) : null}
      </Box>
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
        backgroundColor: Color.appThemeBg,
      }}
    >
      <MainTabView />
      <Box
        sx={{
          overflowY: "auto",
          width: "100%",
          height: "100%",
          "& .MuiTab-root": {
            minHeight: 55,
            maxWidth: 400,
            color: Color.deepPurple700,
            textTransform: "capitalize",
          },
        }}
      >
        {selectedItem === 0 ? (
          <Dashboard />
        ) : selectedItem === 2 ? (
          <Report
            allowJoyRideReports={allowJoyRideReports}
            setAllowJoyRideReports={setAllowJoyRideReports}
          />
        ) : selectedItem === 3 ? (
          <Staff />
        ) : selectedItem === 4 || selectedItem === 5 ? (
          <RevenueExpense
            selectedItem={selectedItem === 4 ? 1 : 2}
            selectedType={selectedType}
          />
        ) : selectedItem === 6 ? (
          {
            /* <Customers
            allowJoyRide={allowJoyRide}
            setAllowJoyRide={setAllowJoyRide}
          /> */
          }
        ) : selectedItem === 7 ? (
          {
            /* <Suppliers
            allowJoyRideSuppliers={allowJoyRideSuppliers}
            setAllowJoyRideSuppliers={setAllowJoyRideSuppliers}
          /> */
          }
        ) : selectedItem === 8 ? (
          <Forecast />
        ) : null}
      </Box>
    </Box>
  );
};
