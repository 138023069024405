import React, { useState, lazy, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useIsFetching } from "@tanstack/react-query";
import _ from "underscore";
import { Box, useMediaQuery } from "@mui/material";

import { subMonths, format } from "date-fns";

import initialData, {
  getPercentageChange,
  truncate,
} from "../../../Helper/data";
import { Color, Constant } from "../../../Helper";
import BoardHeader from "../../../components/BoardHeader/BoardHeader";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import Chart from "./kanbanChart/Chart";
import TableView from "../Table";
import "../../../styles.css";

const KanbanBoard = lazy(() => import("./KanbanBoard"));
const FooterCalculation = lazy(() => import("./FooterCalculation"));

let excludeStates = ["Ignored", "Written-off", "On-hold", "Canceled"];

const Kanban = () => {
  // console.log("🚀 ~  Kanban:");

  //redux
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );

  return (
    <Box
      className={`kanban_1_step`}
      sx={{
        height: `calc(100vh - ${Constant.HEADER_HEIGHT} - ${Constant.BANNER_HEIGHT})`,
        pt: "15px",
        backgroundColor: Color.appThemeBg,
        width: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {isAllHeaderApiFetched && (
        <>
          <BoardHeader />
          {/* <Box
            sx={{
              width: "99%",
              height: "3rem",
              display: "flex",
              justifyContent: "flex-end",
              px: "110px",
            }}
          >
            <ChartLinesToggle hideTitle hideMisc />
          </Box> */}
          <KanbanBoardView />
        </>
      )}
    </Box>
  );
};

export default Kanban;

const KanbanBoardView = () => {
  const s2300 = useMediaQuery("(min-width:2300px)");
  // const s2220 = useMediaQuery("(min-width:2220px)");
  // const s2420 = useMediaQuery("(min-width:2420px)");
  // const s2550 = useMediaQuery("(min-width:2550px)");
  let column_width = Constant.column_width;
  let start_column_extra_width = Constant.start_column_extra_width;
  if (s2300) {
    column_width = Constant.column_width_2300;
    start_column_extra_width = Constant.start_column_extra_width_2300;
  }
  // if (s2220) {
  //   column_width = Constant.column_width_2200;
  //   start_column_extra_width = Constant.start_column_extra_width_2200;
  // }
  // if (s2420) {
  //   column_width = Constant.column_width_2400;
  //   start_column_extra_width = Constant.start_column_extra_width_2400;
  // }
  // if (s2550) {
  //   column_width = Constant.column_width_2500;
  //   start_column_extra_width = Constant.start_column_extra_width_2500;
  // }

  //redux
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice?.selectionCategoriesByID
  );
  const selectedCategory = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.selectedCategory
  );
  const selectedStates = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.selectedStates
  );
  const selectedScenarios = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.selectedScenarios
  );
  const selectedCostUnits = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.selectedCostUnits
  );
  const stateValue = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.stateValue
  );
  const scenariosValue = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.scenariosValue
  );
  const groupValue = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.groupValue
  );
  const groupType = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.groupType
  );
  const indicatorType = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.indicatorType
  );
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const end_date = useSelector(
    (state) => state.boardSlice?.dataSetData?.end_date
  );
  const start_date = useSelector(
    (state) => state.boardSlice?.dataSetData?.start_date
  );
  const isFooterExpanded = useSelector(
    (state) => state.chartSlice?.isFooterExpanded
  );
  const state = useSelector((state) => state.globalSlice?.state);
  const scenario = useSelector((state) => state.globalSlice?.scenario);
  const uuid = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const columnOrder = useSelector((state) => state.boardSlice?.columnOrder);
  const isKanbanView = useSelector((state) => state.boardSlice.isKanbanView);
  const monthlyTransactions = useSelector(
    (state) => state.datasetSlice?.monthlyTransactions
  );
  const groupedMonthlyTransactions = useSelector(
    (state) => state.datasetSlice?.groupedMonthlyTransactions
  );
  const isMainDrawerOpen = useSelector(
    (state) => state.settingsSlice?.isMainDrawerOpen
  );
  const dataSetData = {
    uuid: uuid,
    start_date: start_date,
    end_date: end_date,
  };

  const appliedFilterlist = {
    kanban: {
      selectedCategory: selectedCategory,
      selectedStates: selectedStates,
      selectedScenarios: selectedScenarios,
      selectedCostUnits: selectedCostUnits,
      stateValue: stateValue,
      scenariosValue: scenariosValue,
      groupValue: groupValue,
      groupType: groupType,
      indicatorType: indicatorType,
    },
  };

  //queryState
  const isKanbanGroupFetching = useIsFetching({
    queryKey: [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        apiType: "monthly",
        start_date: null,
        end_date: null,
      },
    ],
    exect: true,
  });

  //state
  const [incomeColumns, setIncomeColumns] = useState([]);
  const [expanseColumns, setExpanseColumns] = useState([]);

  // lifeCycle method

  //updateColumnInflow
  useDebounce(
    () => {
      if (!isKanbanGroupFetching) {
        updateColumnInflow();
      }
    },
    500,
    [
      isKanbanGroupFetching,
      dataSetData?.start_date,
      dataSetData?.uuid,
      selectionCategoriesByID,
      columnOrder,
      state,
      scenario,
      monthlyTransactions,
      appliedFilterlist?.kanban?.selectedCategory,
      appliedFilterlist?.kanban?.selectedStates,
      appliedFilterlist?.kanban?.selectedScenarios,
      appliedFilterlist?.kanban?.selectedCostUnits,
      appliedFilterlist?.kanban?.groupType,
      appliedFilterlist?.kanban?.indicatorType,
      appliedFilterlist?.kanban?.stateValue?.Inflow,
      appliedFilterlist?.kanban?.groupValue?.Inflow,
      appliedFilterlist?.kanban?.scenariosValue?.Inflow,
    ],
    true
  );

  //updateColumnOutflow
  useDebounce(
    () => {
      if (!isKanbanGroupFetching) {
        updateColumnOutflow();
      }
    },
    500,
    [
      isKanbanGroupFetching,
      dataSetData?.start_date,
      dataSetData?.uuid,
      selectionCategoriesByID,
      columnOrder,
      state,
      scenario,
      monthlyTransactions,
      appliedFilterlist?.kanban?.selectedCategory,
      appliedFilterlist?.kanban?.selectedStates,
      appliedFilterlist?.kanban?.selectedScenarios,
      appliedFilterlist?.kanban?.selectedCostUnits,
      appliedFilterlist?.kanban?.indicatorType,
      appliedFilterlist?.kanban?.groupType,
      appliedFilterlist?.kanban?.stateValue?.Outflow,
      appliedFilterlist?.kanban?.groupValue?.Outflow,
      appliedFilterlist?.kanban?.scenariosValue?.Outflow,
    ],
    true
  );

  //function
  const getChildIds = (node, result = [], isFirst = false) => {
    if (!node) return;
    if (isFirst) {
      result.push(node?.uuid);
    }
    if (!Array.isArray(node.children)) return;
    for (let entry of node.children) {
      result.push(entry.uuid);
      getChildIds(entry, result);
    }
    return result;
  };

  const findParent = useCallback(
    (id) => {
      let data =
        (selectionCategoriesByID[id] && selectionCategoriesByID[id][0]) || null;
      if (data?.parent) {
        return findParent(data?.parent);
      } else {
        return data;
      }
    },
    [selectionCategoriesByID]
  );

  const getCategoryData = useCallback(
    (category, short = false) => {
      if (selectionCategoriesByID) {
        let cardCategoryParent = null;
        let rootCat = null;
        let cardCategory = selectionCategoriesByID[category][0];

        if (!!cardCategory && cardCategory?.parent) {
          cardCategoryParent = selectionCategoriesByID[cardCategory?.parent][0];
        }
        if (!!cardCategoryParent && cardCategoryParent?.parent) {
          rootCat = selectionCategoriesByID[cardCategoryParent?.parent][0];
        }
        let categoryLabel = cardCategory
          ? !!cardCategoryParent
            ? !!rootCat
              ? "../" +
                truncate(cardCategoryParent?.label, short ? 7 : 12) +
                "/" +
                truncate(cardCategory?.label, short ? 7 : 12)
              : truncate(cardCategoryParent?.label, short ? 7 : 12) +
                "/" +
                truncate(cardCategory?.label, short ? 7 : 12)
            : truncate(cardCategory?.label)
          : truncate("Uncategorized", 9);
        let categoryColor = cardCategory?.color;
        return {
          categoryLabel,
          categoryColor,
        };
      }
    },
    [selectionCategoriesByID]
  );

  const getChange = (
    groupTotal,
    groupTitle,
    categoryData,
    order,
    stateValue,
    scenariosValue,
    allColumn,
    showIDs = null,
    type = "inflow",
    group_type
  ) => {
    let prevMonthAmount = 0;
    if (new Date(dataSetData.start_date).getMonth() + 1 === Number(order.id)) {
      let data =
        groupedMonthlyTransactions[
          format(subMonths(new Date(order?.date), 1), "yyyy-MM")
        ];
      let preMonthData = [];
      if (showIDs) {
        preMonthData = data?.filter(
          (o1) =>
            showIDs?.includes(o1.category) &&
            !initialData?.calculationExcludeStates.includes(o1.state) &&
            (group_type === "states"
              ? stateValue?.some((o2) => o2 === o1.state)
              : scenariosValue?.some((o2) => o2 === o1.scenario))
        );
      } else {
        preMonthData = data?.filter(
          (o1) =>
            o1.category === categoryData.value &&
            !initialData?.calculationExcludeStates.includes(o1.state) &&
            (group_type === "states"
              ? stateValue?.some((o2) => o2 === o1.state)
              : scenariosValue?.some((o2) => o2 === o1.scenario))
        );
      }

      const allTotal = preMonthData?.reduce(
        (total, item) => parseFloat(total) + parseFloat(item[type] ?? 0),
        0
      );
      prevMonthAmount = allTotal ?? 0;
    } else {
      if (order.id !== "Unassigned") {
        let preMonthData = allColumn[
          order.id === "1" ? 12 : order.id - 1
        ]?.transactions?.find((o1) => o1.isGrouped && o1.title === groupTitle);
        prevMonthAmount = preMonthData?.gross_value ?? 0;
      }
    }
    const change = getPercentageChange(prevMonthAmount, groupTotal);
    return change;
  };

  const mergeObjects = (data) => {
    const result = {};

    data.forEach((basket) => {
      for (let [key, value] of Object.entries(basket)) {
        if (result[key]) {
          result[key] += value;
        } else {
          result[key] = value;
        }
      }
    });
    return result;
  };

  const getCounts = (data, type = "inflow_count", indicator_type) => {
    let result = [];
    data.forEach((element) => {
      if (indicator_type === "states") {
        if (
          element?.state === "Invoice sent" ||
          element?.state === "Invoice open"
        ) {
          let obj = {};
          if (element[`invoice_sent_${type}`]) {
            obj["Invoice sent"] = element[`invoice_sent_${type}`] ?? 0;
          }
          if (element[`late_invoice_sent_${type}`]) {
            obj["Invoice sent_late"] =
              element[`late_invoice_sent_${type}`] ?? 0;
          }
          if (element[`invoice_open_${type}`]) {
            obj["Invoice open"] = element[`invoice_open_${type}`] ?? 0;
          }
          if (element[`late_invoice_open_${type}`]) {
            obj["Invoice open_late"] =
              element[`late_invoice_open_${type}`] ?? 0;
          }
          result.push(obj);
        } else {
          result.push({
            [element?.state]: element[type] ?? 0,
          });
        }
      } else {
        result.push({
          [element?.scenario]: element[type] ?? 0,
        });
      }
    });

    let output = mergeObjects(result);
    return output;
  };

  const getInflowKanban = useCallback(() => {
    const {
      groupValue: groups,
      stateValue: states,
      scenariosValue: scenarios,
      selectedCategory,
      selectedStates: selectedStates1,
      selectedScenarios: selectedScenarios1,
      selectedCostUnits,
      groupType,
      indicatorType,
    } = appliedFilterlist?.kanban;
    let dummyColumn = [];
    if (
      groups &&
      groups?.Inflow &&
      states &&
      states?.Inflow &&
      scenarios &&
      scenarios?.Inflow &&
      dataSetData?.start_date &&
      dataSetData?.start_date !== "Invalid Date" &&
      dataSetData?.end_date &&
      dataSetData?.end_date !== "Invalid Date"
    ) {
      let groupValue = groups?.Inflow;
      let stateValue = state
        .filter((o1) => states.Inflow?.includes(o1.title))
        .map((o1) => o1.title);
      let scenariosValue = scenario
        .filter((o1) => scenarios.Inflow?.includes(o1.title))
        .map((o1) => o1.title);
      let selectedStates = state
        .filter((o1) => selectedStates1?.includes(o1.title))
        .map((o1) => o1.title);
      let selectedScenarios = scenario
        .filter((o1) => selectedScenarios1?.includes(o1.title))
        .map((o1) => o1.title);

      let group_type = groupType || "scenarios";
      let indicator_type = indicatorType || "states";
      let position = 100;
      let groupArray = [];
      let allGroupArray = [];
      let incomeArray = [];

      columnOrder?.forEach((order) => {
        let column = {
          id: order?.id,
          transactions: [],
        };
        let statsData = groupedMonthlyTransactions[order?.id] ?? [];

        position = 1;
        groupArray = [];
        allGroupArray = [];

        let monthlyData = statsData?.filter(
          (o1) =>
            (order.id === "Unassigned" ? !o1.month : o1.month) &&
            (selectedCategory && selectedCategory?.length > 0
              ? selectedCategory?.some((item) => o1.category === item)
              : true) &&
            (selectedStates && selectedStates?.length > 0
              ? selectedStates?.includes(o1.state)
              : !selectedStates
              ? true
              : false) &&
            (selectedScenarios && selectedScenarios?.length > 0 && o1.scenario
              ? selectedScenarios?.includes(o1.scenario)
              : !selectedScenarios || !o1.scenario
              ? true
              : false) &&
            (group_type === "states"
              ? stateValue?.some((o2) => o2 === o1.state)
              : scenariosValue?.some((o2) => o2 === o1.scenario)) &&
            (selectedCostUnits
              ? selectedCostUnits?.length > 0
                ? selectedCostUnits?.includes(o1.cost_unit)
                : selectedCostUnits?.length === 0
              : true)
        );
        incomeArray = [];
        if (groupValue !== 1) {
          const filterUnCategoryData = monthlyData?.filter(
            (o1) => !o1.category && o1.inflow_count > 0
          );

          const count = getCounts(
            filterUnCategoryData,
            "inflow_count",
            indicator_type
          );

          if (filterUnCategoryData?.length > 0) {
            position = position + 1;
            const groupedUnCategoryTotal = filterUnCategoryData?.reduce(
              (total, item) =>
                parseFloat(total) +
                parseFloat(
                  !excludeStates.includes(item?.state) ? item?.inflow ?? 0 : 0
                ),
              0
            );

            let change = getChange(
              groupedUnCategoryTotal,
              "Uncategorized",
              { value: null },
              order,
              stateValue,
              scenariosValue,
              dummyColumn,
              null,
              "Inflow",
              group_type
            );
            let obj = {
              uuid: `uncat-${order.id}`,
              title: "Uncategorized",
              gross_value: groupedUnCategoryTotal,
              position: position,
              isGrouped: true,
              columnId: order.id,
              date: order,
              id: "uncategorizedInflow",
              type: "income",
              high: change < 0 ? false : true,
              change: change,
              count: count,
            };
            groupArray.push(obj);
          }
        }
        if (groupValue === 1) {
          const filterAllGroupedData = monthlyData?.filter(
            (o1) => o1.inflow_count > 0
          );
          if (filterAllGroupedData?.length > 0) {
            position = position + 1;
            const groupedAllTotal = filterAllGroupedData?.reduce(
              (total, item) =>
                parseFloat(total) +
                parseFloat(
                  !excludeStates.includes(item?.state) ? item?.inflow ?? 0 : 0
                ),
              0
            );
            const count = getCounts(
              filterAllGroupedData,
              "inflow_count",
              indicator_type
            );

            let obj = {
              uuid: `grouped-${order.id}`,
              title: "All Categories",
              gross_value: groupedAllTotal,
              position: position,
              isGrouped: true,
              count: count,
              columnId: order.id,
              date: order,
              id: "groupedInflowAll",
              type: "income",
            };
            allGroupArray.push(obj);
          }
        }
        if (groupValue === 2) {
          const filterGroupedData = monthlyData?.filter(
            (o1) => o1.category && o1.inflow_count > 0
          );
          let categoriesIds = [
            ...new Set(filterGroupedData?.map((item) => item.category)),
          ];

          categoriesIds?.forEach((catId) => {
            position = position + 1;
            let parentCategory = findParent(catId);
            let alreadyAdded = groupArray?.find(
              (o1) => o1.uuid === parentCategory?.uuid
            );
            if (!alreadyAdded) {
              const allIds = getChildIds(parentCategory, [], true);
              let filterCategoryData = filterGroupedData?.filter((o1) =>
                allIds?.includes(o1.category)
              );
              const groupedCategoryTotal = filterCategoryData?.reduce(
                (total, item) =>
                  parseFloat(total) +
                  parseFloat(
                    !excludeStates.includes(item?.state) ? item?.inflow ?? 0 : 0
                  ),
                0
              );
              const count = getCounts(
                filterCategoryData,
                "inflow_count",
                indicator_type
              );

              let change = getChange(
                groupedCategoryTotal,
                parentCategory?.label,
                parentCategory,
                order,
                stateValue,
                scenariosValue,
                dummyColumn,
                allIds,
                "Inflow",
                group_type
              );

              let obj = {
                uuid: parentCategory?.value,
                title: parentCategory?.label,
                gross_value: groupedCategoryTotal,
                position: position,
                isGrouped: true,
                count: count,
                high: change < 0 ? false : true,
                change: change,
                columnId: order.id,
                date: order,
                id: [parentCategory?.value],
                type: "income",
              };
              groupArray.push(obj);
            }
          });
        }
        if (groupValue === 3) {
          const filterGroupedData = monthlyData?.filter(
            (o1) => o1.category && o1.inflow_count > 0
          );
          let categoriesIds = [
            ...new Set(filterGroupedData?.map((item) => item.category)),
          ];

          categoriesIds?.forEach((catId) => {
            position = position + 1;
            let parentCategory =
              selectionCategoriesByID[catId] &&
              selectionCategoriesByID[catId][0];

            if (parentCategory) {
              let filterCategoryData = filterGroupedData?.filter(
                (o1) => o1.category === parentCategory?.value
              );
              const groupedCategoryTotal = filterCategoryData?.reduce(
                (total, item) =>
                  parseFloat(total) +
                  parseFloat(
                    !excludeStates.includes(item?.state) ? item?.inflow ?? 0 : 0
                  ),
                0
              );
              const count = getCounts(
                filterCategoryData,
                "inflow_count",
                indicator_type
              );
              let change = getChange(
                groupedCategoryTotal,
                parentCategory?.label,
                parentCategory,
                order,
                stateValue,
                scenariosValue,
                dummyColumn,
                null,
                "Inflow",
                group_type
              );
              let categoryData = getCategoryData(parentCategory?.value, true);
              let obj = {
                uuid: parentCategory?.value,
                title: categoryData?.categoryLabel,
                gross_value: groupedCategoryTotal,
                position: position,
                isGrouped: true,
                count: count,
                high: change < 0 ? false : true,
                change: change,
                columnId: order.id,
                date: order,
                id: [parentCategory?.value],
                showSingleCategory: true,
                type: "income",
              };
              groupArray.push(obj);
            }
          });
        }
        if (groupValue === 4) {
          const filterAllGroupedData = monthlyData?.filter(
            (o1) => o1.inflow_count > 0
          );
          if (filterAllGroupedData?.length > 0) {
            position = position + 1;
            const groupedAllTotal = filterAllGroupedData?.reduce(
              (total, item) =>
                parseFloat(total) +
                parseFloat(
                  !excludeStates.includes(item?.state) ? item?.inflow ?? 0 : 0
                ),
              0
            );
            const count = getCounts(
              filterAllGroupedData,
              "inflow_count",
              indicator_type
            );
            let obj = {
              uuid: `grouped-${order.id}`,
              title: "All transactions",
              gross_value: groupedAllTotal,
              position: position,
              isGrouped: true,
              columnId: order.id,
              date: order,
              id: "groupedInflowAll",
              type: "income",
              count: count,
            };
            allGroupArray.push(obj);
          }
        }

        let finalArray = [...groupArray, ...allGroupArray, ...incomeArray];
        finalArray.sort((a, b) => (a.position > b.position ? 1 : -1));
        column.transactions = [...finalArray];

        if (order?.id === "Unassigned") {
          let start_date = new Date(
            format(new Date(dataSetData?.start_date), "yyyy-MM")
          );

          let expireCountFound = monthlyTransactions?.find(
            (o1) =>
              o1.month && o1.inflow_count > 0 && new Date(o1.month) < start_date
          );
          column.expire = expireCountFound ? true : false;
        }

        dummyColumn.push(column);
      });
    }

    return dummyColumn;
  }, [
    appliedFilterlist?.kanban?.groupValue?.Inflow,
    appliedFilterlist?.kanban?.stateValue?.Inflow,
    appliedFilterlist?.kanban?.scenariosValue?.Inflow,
    appliedFilterlist?.kanban?.selectedCategory,
    appliedFilterlist?.kanban?.selectedStates,
    appliedFilterlist?.kanban?.selectedScenarios,
    appliedFilterlist?.kanban?.selectedCostUnits,
    appliedFilterlist?.kanban?.groupType,
    appliedFilterlist?.kanban?.indicatorType,
    monthlyTransactions,
    dataSetData?.start_date,
    dataSetData?.uuid,
    columnOrder,
    state,
    scenario,
    groupedMonthlyTransactions,
    selectionCategoriesByID,
  ]);

  const getOutflowKanban = useCallback(() => {
    const {
      groupValue: groups,
      stateValue: states,
      scenariosValue: scenarios,
      selectedCategory,
      selectedStates: selectedStates1,
      selectedScenarios: selectedScenarios1,
      selectedCostUnits,
      groupType,
      indicatorType,
    } = appliedFilterlist?.kanban;
    let dummyColumn = [];
    if (
      groups &&
      groups?.Outflow &&
      states &&
      states?.Outflow &&
      scenarios &&
      scenarios?.Outflow &&
      dataSetData?.start_date &&
      dataSetData?.start_date !== "Invalid Date" &&
      dataSetData?.end_date &&
      dataSetData?.end_date !== "Invalid Date"
    ) {
      let groupValue = groups?.Outflow;
      let stateValue = state
        .filter((o1) => states.Outflow?.includes(o1.title))
        .map((o1) => o1.title);
      let scenariosValue = scenario
        .filter((o1) => scenarios.Outflow?.includes(o1.title))
        .map((o1) => o1.title);
      let selectedStates = state
        .filter((o1) => selectedStates1?.includes(o1.title))
        .map((o1) => o1.title);
      let selectedScenarios = scenario
        .filter((o1) => selectedScenarios1?.includes(o1.title))
        .map((o1) => o1.title);
      let group_type = groupType || "scenarios";
      let indicator_type = indicatorType || "states";
      let position = 100;
      let groupArray = [];
      let allGroupArray = [];
      let expenseArray = [];
      columnOrder?.forEach((order) => {
        let column = {
          id: order?.id,
          transactions: [],
        };
        let statsData = groupedMonthlyTransactions[order?.id] ?? [];

        position = 1;
        groupArray = [];
        allGroupArray = [];
        expenseArray = [];

        let monthlyData = statsData?.filter(
          (o1) =>
            (order.id === "Unassigned" ? !o1.month : o1.month) &&
            (selectedCategory && selectedCategory?.length > 0
              ? selectedCategory?.some((item) => o1.category === item)
              : true) &&
            (selectedStates && selectedStates?.length > 0
              ? selectedStates?.includes(o1.state)
              : !selectedStates
              ? true
              : false) &&
            (selectedScenarios && selectedScenarios?.length > 0 && o1.scenario
              ? selectedScenarios?.includes(o1.scenario)
              : !selectedScenarios || !o1.scenario
              ? true
              : false) &&
            (group_type === "states"
              ? stateValue?.some((o2) => o2 === o1.state)
              : scenariosValue?.some((o2) => o2 === o1.scenario)) &&
            (selectedCostUnits
              ? selectedCostUnits?.length > 0
                ? selectedCostUnits?.includes(o1.cost_unit)
                : selectedCostUnits?.length === 0
              : true)
        );
        if (groupValue !== 1) {
          const filterUnCategoryData = monthlyData?.filter(
            (o1) => !o1.category && o1.outflow_count > 0
          );
          if (filterUnCategoryData?.length > 0) {
            position = position + 1;
            const groupedUnCategoryTotal = filterUnCategoryData?.reduce(
              (total, item) =>
                parseFloat(total) +
                parseFloat(
                  !excludeStates.includes(item?.state) ? item?.outflow ?? 0 : 0
                ),
              0
            );
            const count = getCounts(
              filterUnCategoryData,
              "outflow_count",
              indicator_type
            );
            let change = getChange(
              groupedUnCategoryTotal,
              "Uncategorized",
              { value: null },
              order,
              stateValue,
              scenariosValue,
              dummyColumn,
              null,
              "outflow",
              group_type
            );
            let obj = {
              uuid: `uncat-${order.id}`,
              title: "Uncategorized",
              gross_value: groupedUnCategoryTotal,
              position: position,
              isGrouped: true,
              columnId: order.id,
              date: order,
              id: "uncategorizedOutflow",
              type: "expense",
              high: change < 0 ? false : true,
              change: change,
              count: count,
            };
            groupArray.push(obj);
          }
        }
        if (groupValue === 1) {
          const filterAllGroupedData = monthlyData?.filter(
            (o1) => o1.outflow_count > 0
          );
          if (filterAllGroupedData?.length > 0) {
            position = position + 1;
            const groupedAllTotal = filterAllGroupedData?.reduce(
              (total, item) =>
                parseFloat(total) +
                parseFloat(
                  !excludeStates.includes(item?.state) ? item?.outflow ?? 0 : 0
                ),
              0
            );
            const count = getCounts(
              filterAllGroupedData,
              "outflow_count",
              indicator_type
            );
            let obj = {
              uuid: `grouped-${order.id}`,
              title: "All Categories",
              gross_value: groupedAllTotal,
              position: position,
              isGrouped: true,
              count: count,
              columnId: order.id,
              date: order,
              id: "groupedOutflowAll",
              type: "expense",
            };
            allGroupArray.push(obj);
          }
        }
        if (groupValue === 2) {
          const filterGroupedData = monthlyData?.filter(
            (o1) => o1.category && o1.outflow_count > 0
          );
          let categoriesIds = [
            ...new Set(filterGroupedData?.map((item) => item.category)),
          ];

          categoriesIds?.forEach((catId) => {
            position = position + 1;
            let parentCategory = findParent(catId);
            let alreadyAdded = groupArray?.find(
              (o1) => o1.uuid === parentCategory?.uuid
            );
            if (!alreadyAdded) {
              const allIds = getChildIds(parentCategory, [], true);
              let filterCategoryData = filterGroupedData?.filter((o1) =>
                allIds?.includes(o1.category)
              );
              const groupedCategoryTotal = filterCategoryData?.reduce(
                (total, item) =>
                  parseFloat(total) +
                  parseFloat(
                    !excludeStates.includes(item?.state)
                      ? item?.outflow ?? 0
                      : 0
                  ),
                0
              );
              const count = getCounts(
                filterCategoryData,
                "outflow_count",
                indicator_type
              );

              let change = getChange(
                groupedCategoryTotal,
                parentCategory?.label,
                parentCategory,
                order,
                stateValue,
                scenariosValue,
                dummyColumn,
                allIds,
                "outflow",
                group_type
              );

              let obj = {
                uuid: parentCategory?.value,
                title: parentCategory?.label,
                gross_value: groupedCategoryTotal,
                position: position,
                isGrouped: true,
                count: count,
                high: change < 0 ? false : true,
                change: change,
                columnId: order.id,
                date: order,
                id: [parentCategory?.value],
                type: "expense",
              };
              groupArray.push(obj);
            }
          });
        }
        if (groupValue === 3) {
          const filterGroupedData = monthlyData?.filter(
            (o1) => o1.category && o1.outflow_count > 0
          );
          let categoriesIds = [
            ...new Set(filterGroupedData?.map((item) => item.category)),
          ];

          categoriesIds?.forEach((catId) => {
            position = position + 1;
            let parentCategory =
              selectionCategoriesByID[catId] &&
              selectionCategoriesByID[catId][0];

            if (parentCategory) {
              let filterCategoryData = filterGroupedData?.filter(
                (o1) => o1.category === parentCategory?.value
              );
              const groupedCategoryTotal = filterCategoryData?.reduce(
                (total, item) =>
                  parseFloat(total) +
                  parseFloat(
                    !excludeStates.includes(item?.state)
                      ? item?.outflow ?? 0
                      : 0
                  ),
                0
              );
              const count = getCounts(
                filterCategoryData,
                "outflow_count",
                indicator_type
              );
              let change = getChange(
                groupedCategoryTotal,
                parentCategory?.label,
                parentCategory,
                order,
                stateValue,
                scenariosValue,
                dummyColumn,
                null,
                "outflow",
                group_type
              );
              let categoryData = getCategoryData(parentCategory?.value, true);
              let obj = {
                uuid: parentCategory?.value,
                title: categoryData?.categoryLabel,
                gross_value: groupedCategoryTotal,
                position: position,
                isGrouped: true,
                count: count,
                high: change < 0 ? false : true,
                change: change,
                columnId: order.id,
                date: order,
                id: [parentCategory?.value],
                showSingleCategory: true,
                type: "expense",
              };
              groupArray.push(obj);
            }
          });
        }
        if (groupValue === 4) {
          const filterAllGroupedData = monthlyData?.filter(
            (o1) => o1.outflow_count > 0
          );
          if (filterAllGroupedData?.length > 0) {
            position = position + 1;
            const groupedAllTotal = filterAllGroupedData?.reduce(
              (total, item) =>
                parseFloat(total) +
                parseFloat(
                  !excludeStates.includes(item?.state) ? item?.outflow ?? 0 : 0
                ),
              0
            );
            const count = getCounts(
              filterAllGroupedData,
              "outflow_count",
              indicator_type
            );
            let obj = {
              uuid: `grouped-${order.id}`,
              title: "All Transactions",
              gross_value: groupedAllTotal,
              position: position,
              isGrouped: true,
              columnId: order.id,
              date: order,
              id: "groupedOutflowAll",
              type: "expense",
              count: count,
            };
            allGroupArray.push(obj);
          }
        }

        let finalArray = [...groupArray, ...allGroupArray, ...expenseArray];
        finalArray.sort((a, b) => (a.position > b.position ? 1 : -1));
        column.transactions = [...finalArray];
        if (order?.id === "Unassigned") {
          let start_date = new Date(
            format(new Date(dataSetData?.start_date), "yyyy-MM")
          );

          let expireCountFound = monthlyTransactions?.find(
            (o1) => o1.month && o1.outflow_count > 0 && o1.month < start_date
          );
          column.expire = expireCountFound ? true : false;
        }
        dummyColumn.push(column);
      });
    }

    return dummyColumn;
  }, [
    appliedFilterlist?.kanban?.groupValue?.Outflow,
    appliedFilterlist?.kanban?.stateValue?.Outflow,
    appliedFilterlist?.kanban?.scenariosValue?.Outflow,
    appliedFilterlist?.kanban?.selectedCategory,
    appliedFilterlist?.kanban?.selectedStates,
    appliedFilterlist?.kanban?.selectedScenarios,
    appliedFilterlist?.kanban?.selectedCostUnits,
    dataSetData?.start_date,
    appliedFilterlist?.kanban?.indicatorType,
    appliedFilterlist?.kanban?.groupType,
    monthlyTransactions,
    dataSetData?.uuid,
    columnOrder,
    state,
    scenario,
    groupedMonthlyTransactions,
    selectionCategoriesByID,
  ]);

  const updateColumnInflow = () => {
    if (
      appliedFilterlist &&
      monthlyTransactions &&
      groupedMonthlyTransactions &&
      appliedFilterlist?.kanban &&
      isAllHeaderApiFetched
    ) {
      let dummyIncomeColumn = getInflowKanban();

      setIncomeColumns([...dummyIncomeColumn]);
    } else {
      setIncomeColumns([]);
    }
  };

  const updateColumnOutflow = () => {
    if (
      appliedFilterlist &&
      monthlyTransactions &&
      groupedMonthlyTransactions &&
      appliedFilterlist?.kanban &&
      isAllHeaderApiFetched
    ) {
      let dummyExpanseColumn = getOutflowKanban();
      setExpanseColumns([...dummyExpanseColumn]);
    } else {
      setExpanseColumns([]);
    }
  };

  const setIncomeColumnsData = (data) => {
    setIncomeColumns(data);
  };

  const setExpenseColumnsData = (data) => {
    setExpanseColumns(data);
  };

  // const Key_Inflow = useMemo(() => "Inflow", []);
  // const Key_Outflow = useMemo(() => "Outflow", []);

  // const Footer = useMemo(() => <FooterCalculation />, []);
  return (
    <Box
      id={"cardContainer"}
      sx={{
        display: "inline-flex",
        flexDirection: "column",
        justifyContent: isKanbanView ? "space-between" : "flex-start",
        alignItems: "flex-start",
        width: "100%",
        position: "fixed",
        bottom: 0,
        left: {
          xs: isMainDrawerOpen
            ? Constant?.DRAWER_WIDTH
            : Constant?.DRAWER_WIDTH_CLOSED,
          s1280: 0,
        },
        overflow: "auto",
        marginTop: "15px",
        transition: `all 0.5s ease`,
        height: `calc(100vh - ${Constant.HEADER_HEIGHT} - ${Constant.BANNER_HEIGHT} - 4.5rem)`,
      }}
    >
      <Chart
        type={"kanban"}
        column_width={column_width}
        start_column_extra_width={start_column_extra_width}
      />
      {/* {isKanbanView ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "28px",
          }}
        >
          {incomeColumns?.length > 0 ? (
            <Box
              sx={{
                position: "relative",
                top: 0,
                transition: "top 500ms",
              }}
            >
              <KanbanBoard
                key={Key_Inflow}
                type={Key_Inflow}
                columns={incomeColumns}
                setColumns={setIncomeColumnsData}
                column_width={column_width}
                start_column_extra_width={start_column_extra_width}
              />
            </Box>
          ) : null}
          {expanseColumns?.length > 0 ? (
            <KanbanBoard
              key={Key_Outflow}
              type={Key_Outflow}
              columns={expanseColumns}
              setColumns={setExpenseColumnsData}
              column_width={column_width}
              start_column_extra_width={start_column_extra_width}
            />
          ) : null}
          {isFooterExpanded && Footer}
        </div>
      ) : ( */}
      <TableView />
      {/* )} */}
    </Box>
  );
};
