import { createSlice } from "@reduxjs/toolkit";
import _ from "underscore";
import { cloneDeep } from "../../Helper/data";
const initialState = {
  categories: [],
  selectionCategories: [],
  selectionCategoriesByID: [],
};
const getObj = (item) => {
  return Object.assign(item, {
    value: item.uuid,
    label: item.title,
    isExpanded: false,
    hasChildren: item.children?.length > 0,
    payment_term_value:
      item?.payment_term === "on invoicing"
        ? 0
        : item?.payment_term
          ? Number(item?.payment_term.replace("D", ""))
          : 0,
  });
};
const categorySlice = createSlice({
  name: "categorySlice",
  initialState,
  reducers: {
    setCategories: (state, action) => {
      const { data, type, newItem, specificData } = action.payload;
      const array = [];
      let specificDataById = null;
      if (type === "add") {
        state.categories.push(newItem);
      } else if (data) {
        state.categories = cloneDeep(data);
      } else if (specificData) {
        specificDataById = _.groupBy(specificData, ({ uuid }) => uuid);
      }
      function getNestedCat(element) {
        let isChildExpanded = false;
        let depth_level = 1;
        array.push(getObj(element));
        if (type === "sub" && newItem?.parent === element.uuid) {
          isChildExpanded = true;
          element.expanded = true;
          element.children?.push(getObj(newItem));
          depth_level++;
          return {
            isChildExpanded,
            depth_level,
          };
        }
        if (element.children?.length > 0) {
          let children_depth = null;
          element.children?.sort((a, b) => (a.position > b.position ? 1 : -1));

          element.children?.forEach((subElement) => {
            subElement.depth = (element?.depth || 0) + 1;
            const { isChainExpanded, depth_level: dl } =
              getNestedCat(subElement);

            if (dl && (!children_depth || children_depth < dl)) {
              children_depth = dl || 1;
            }
            subElement.depth_level = dl || 1;
            if (isChainExpanded) {
              subElement.expanded = true;
            }
          });
          element.children?.sort((a, b) => (a.position > b.position ? 1 : -1));

          depth_level = depth_level + (children_depth || 0);
        }

        if (type === "update" && newItem?.uuid === element.uuid) {
          isChildExpanded = true;
          newItem.expanded = true;
          element = Object.assign(element, getObj(newItem));

          return {
            isChildExpanded,
            depth_level,
          };
        }

        return {
          isChildExpanded,
          depth_level,
        };
      }
      if (type === "clone") {
        function addCloneCategory(children, data) {
          children?.forEach((subElement) => {
            if (subElement.uuid === data.parent) {
              subElement.children.push(data);
              return;
            } else {
              addCloneCategory(subElement.children, data);
            }
          });
        }
        if (!newItem?.parent) {
          state.categories.push(newItem);
        } else {
          state.categories?.forEach((element) => {
            if (newItem?.type === element?.type) {
              if (element.uuid === newItem.parent) {
                element.children.push(newItem);
                return;
              } else {
                addCloneCategory(element.children, newItem);
              }
            }
          });
        }
      }
      if (type === "delete") {
        function deleteCategory(children, data) {
          children?.forEach((subElement) => {
            if (subElement.uuid === data.parent) {
              subElement.children = subElement.children?.filter(
                (o1) => o1.uuid !== newItem.uuid
              );
              return;
            } else {
              deleteCategory(subElement.children, data);
            }
          });
        }
        if (!newItem?.parent) {
          state.categories = state.categories?.filter(
            (o1) => o1.uuid !== newItem.uuid
          );
        } else {
          state.categories?.forEach((element) => {
            if (newItem?.type === element?.type) {
              if (element.uuid === newItem.parent) {
                element.children = element.children?.filter(
                  (o1) => o1.uuid !== newItem.uuid
                );
                return;
              } else {
                deleteCategory(element.children, newItem);
              }
            }
          });
        }
      }

      state.categories?.sort((a, b) => (a.position > b.position ? 1 : -1));
      state.categories?.forEach((element) => {
        element.depth = 0;
        if (type === "update" && specificDataById?.[element.uuid]) {
          let newItem = specificDataById?.[element.uuid]?.[0];
          newItem.expanded = true;
          element = Object.assign(element, getObj(newItem));
        }
        const { isChainExpanded, depth_level } = getNestedCat(element);
        element.depth_level = depth_level || 1;

        if (isChainExpanded) {
          element.expanded = true;
        }
      });
      state.categories?.sort((a, b) => (a.position > b.position ? 1 : -1));

      let arrayById = _.groupBy(array, ({ value }) => value);
      state.selectionCategories = array;
      state.selectionCategoriesByID = arrayById;
    },
  },
});
export const { setCategories } = categorySlice.actions;
export default categorySlice;
