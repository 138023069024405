import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getStaffQueryKey, isPlanExpired } from "../Helper/data";
import { useCallback } from "react";
import { enqueueSnackbar } from "notistack";
import { setPlanExpiredShow } from "../store/slices/global";
import { queryClient } from "../App";

const useSubscriptions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //redux
  const dataSetList = useSelector((state) => state.boardSlice.dataSetList);
  const dataSource = useSelector((state) => state.globalSlice.dataSource);
  const subscription = useSelector(
    (state) => state.settingsSlice?.subscription
  );

  const StaffList = queryClient.getQueryData(getStaffQueryKey());
  const userList = queryClient.getQueryData(["users"]);

  //functions
  const isSubscriptionValid = useCallback(
    ({ showMessage = true, type, ds_type }) => {
      let message = ``;
      let showOverlay = false;
      let extra_condition = true;
      if (type === "data_source") {
        let data_source_count =
          dataSource?.filter(
            (o1) => o1.type === ds_type && !o1.internal_dataset
          )?.length || 0;
        let data_source_limit = null;
        if (ds_type === 1) {
          data_source_limit = subscription?.offline_data_source_limit;
          message = `You reached limit  - Please upgrade you plan.`;
        }
        if (ds_type === 12) {
          data_source_limit = subscription?.online_data_source_limit;
          message = `You reached maximum Bank account limit - Please upgrade you plan.`;
        }
        if (ds_type === 19) {
          data_source_limit = subscription?.integration_data_source_limit;
          message = `You reached maximum integrations limit - Please upgrade you plan.`;
        }

        extra_condition =
          data_source_count < data_source_limit || !data_source_limit;
      }
      if (type === "dataset") {
        message = `You reached maximum datasets limit - Please upgrade you plan.`;
        extra_condition =
          dataSetList?.length < subscription?.dataset_limit ||
          !subscription?.dataset_limit;
      }
      if (type === "users") {
        message = `You reached maximum users limit - Please upgrade you plan.`;
        const activeUserList = userList?.filter((o1) => o1.is_active);
        extra_condition = activeUserList?.length < subscription?.user_limit;
      }
      if (type === "employee") {
        message = `You reached maximum employees limit - Please upgrade you plan.`;
        extra_condition = StaffList?.length < subscription?.employee_limit;
      }
      if (isPlanExpired(subscription)) {
        showOverlay = true;
        message = `Your ${subscription?.name} plan expired - Please upgrade you plan.`;
      }

      if (!isPlanExpired(subscription) && extra_condition) {
        return true;
      } else {
        if (showMessage) {
          if (showOverlay) {
            dispatch(
              setPlanExpiredShow({
                title: "plan_expired_overlay_title",
                desc: "plan_expired_overlay_desc",
              })
            );
          } else {
            enqueueSnackbar(t(message), {
              variant: "warning",
              autoHideDuration: 5000,
              preventDuplicate: true,
            });
          }
        }

        return false;
      }
    },
    [StaffList?.length, dataSetList?.length, dataSource, dispatch, subscription, t, userList]
  );

  return [isSubscriptionValid];
};
export default useSubscriptions;
