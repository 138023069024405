import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import EndPoints from "../../APICall/EndPoints";
import APICall from "../../APICall";
import { Color, Constant, Fonts } from "../../Helper";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import "../../styles.css";
import { Popover, alpha } from "@mui/material";

import { setContacts } from "../../store/slices/global";
import DeleteIcon from "@mui/icons-material/Delete";
import { setLoading } from "../../store/slices/appmain";
import { setTransactionData } from "../../store/slices/board";
import { useState } from "react";
import useDebounce from "../../hooks/3-useDebounce/useDebounce";
import { updateBatchTransactions } from "../../Helper/data";

const ContactSelect = ({
  onClickContactTitle,
  anchorEl = null,
  handleClosePopOver,
  transactionTitle = null,
  type = 1,
  afterDelete,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const inputRef = useRef([]);
  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.globalSlice?.contacts);
  const transactionData = useSelector(
    (state) => state.boardSlice?.transactionData
  );

  //states
  const [contactItem, setContactItem] = useState({ name: "", uuid: "" });
  const [isNewAddedId, setIsNewAddedId] = useState(null);

  useEffect(() => {
    getContacts();
  }, []);

  useEffect(() => {
    if (anchorEl) {
      setIsNewAddedId(null);
    }
  }, [anchorEl]);

  useDebounce(
    () => {
      if (contactItem?.uuid) {
        updateContactByID(contactItem?.uuid, { name: contactItem?.name });
      }
    },
    1000,
    [contactItem?.name]
  );

  //api
  const getContacts = async () => {
    await APICall("get", EndPoints.customers).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setContacts(data));
      }
    });
  };

  const addContactApi = async (obj) => {
    await APICall("post", EndPoints.customers, obj).then((response) => {
      if (response.status === 201 && response.data) {
        enqueueSnackbar("New Contact Added Successfully ", {
          variant: "success",
          autoHideDuration: 2000,
        });
        dispatch(setContacts([...contacts, response.data]));
        setContactItem({
          name: response.data.name,
          uuid: response.data?.uuid,
        });
        setIsNewAddedId(response.data?.uuid);
        inputRef.current[contacts?.length]?.focus();
      }
    });
  };

  const deleteContactByIdApi = async (id) => {
    await APICall("delete", EndPoints.customers + `${id}/`).then((response) => {
      if (response.status === 204 && response) {
        let data = contacts?.filter((c) => c.uuid !== id);
        dispatch(setContacts(data));
        afterDelete();
        enqueueSnackbar("Contact Deleted Successfully ", {
          variant: "success",
          autoHideDuration: 2000,
        });
        dispatch(setLoading(true));
        let filterdata = transactionData?.filter((c) => c.contact === id);
        let array = [];
        filterdata?.forEach((element) => {
          array.push({ uuid: element?.uuid, contact: null });
        });
        updateCardsBatch(array);
      }
    });
  };

  const updateContactByID = async (id, obj) => {
    await APICall("patch", EndPoints.customers + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          let data = [...contacts];
          let index = contacts.findIndex((o1) => o1.uuid === id);
          data[index] = response.data;
          dispatch(setContacts(data));
        }
      }
    );
  };

  const updateCardsBatch = async (array) => {
    dispatch(setLoading(true));
    let response = await updateBatchTransactions(array);
    if (response) {
      updateBatchTransaction(response);
    }
  };

  //function
  const updateContact = (id, obj) => {
    let data = [...contacts];
    let index = contacts?.findIndex((o1) => o1.uuid === id);
    if (index > -1) {
      data[index] = { ...data[index], ...obj };
    }
    dispatch(setContacts(data));
  };

  const updateBatchTransaction = (array) => {
    let data = [...transactionData];
    array.forEach((element) => {
      let index = transactionData.findIndex((o1) => o1.uuid === element.uuid);
      if (index > -1) {
        data[index] = {
          ...data[index],
          contact: null,
        };
      }
    });
    dispatch(setTransactionData(data));
    dispatch(setLoading(false));
  };

  const onClickAddContact = () => {
    addContactApi({
      name: transactionTitle || "Contact",
      note: "",
      state: 1,
      type: type,
    });
  };

  const onClickDeleteContact = (e, item) => {
    e.stopPropagation();
    deleteContactByIdApi(item?.uuid);
  };

  const onClick = (e) => e.stopPropagation();

  const onKeyDown = (e, node, index) => {
    if (e.key === "Enter") {
      if (node?.name.length > 0) {
        inputRef.current[index]?.blur();
        updateContact(node?.uuid, { name: node?.name });
      }
    }
  };

  const onChange = (event, node, index) => {
    const name = event.target.value;

    inputRef.current[index]?.focus();
    setContactItem({ name, uuid: node?.uuid });
  };

  return (
    <Popover
      id={Boolean(anchorEl) ? "simple-popover-1" : undefined}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      elevation={1}
      onClose={handleClosePopOver}
      slotProps={{
        paper: {
          sx: {
            borderRadius: 1.5,
            boxShadow: Constant.OverlayBoxShadow,
          },
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box
        sx={{
          p: 1,
          width: "fit-content",
          minWidth: 400,
          minHeight: 200,
          height: "max-content",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          component={"h6"}
          sx={{
            fontFamily: Fonts.Text,
            fontWeight: 800,
            fontSize: "1rem",
            my: 1,
            width: "93%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {type === 1 ? "Customers" : "Suppliers"}
          <AddCircleIcon
            onClick={onClickAddContact}
            sx={{
              ml: 1,
              cursor: "pointer",
              color: alpha(Color.themeColor, 0.75),
              width: "1.2rem",
              height: "1.2rem",
            }}
          />
        </Typography>
        {contacts?.map((contact, index) => {
          const labelId = `list-label-${index}`;
          if (contact?.type === type) {
            return (
              <Box
                key={labelId}
                onClick={() => onClickContactTitle(contact)}
                sx={{
                  display: "flex",
                  width: "100%",
                  position: "relative",
                  alignItems: "center",
                  p: 1,
                  cursor: "pointer",
                  borderRadius: 1,
                  backgroundColor:
                    isNewAddedId === contact?.uuid &&
                    Color.theme.deepPurple[50],
                  "&:hover": {
                    backgroundColor:
                      isNewAddedId !== contact?.uuid && Color.theme.grey[100],
                    color: alpha(Color.themeColor, 0.75),
                  },
                }}
              >
                <input
                  ref={(el) => (inputRef.current[index] = el)}
                  style={{
                    width: 100,
                    border: 0,
                    display: "flex",
                    position: "relative",
                    alignItems: "center",
                    fontWeight: 500,
                    fontFamily: Fonts.Text,
                    fontSize: "0.8rem",
                    color: "inherit",
                    backgroundColor: "inherit",
                  }}
                  value={
                    contactItem?.uuid === contact?.uuid
                      ? contactItem.name
                      : contact?.name
                  }
                  onKeyDown={(e) => onKeyDown(e, contact, index)}
                  onClick={onClick}
                  onChange={(e) => onChange(e, contact, index)}
                  onFocus={(event) => event.target.select()}
                />
                <DeleteIcon
                  onClick={(e) => onClickDeleteContact(e, contact)}
                  sx={{
                    position: "absolute",
                    right: 8,
                    color: Color.theme.grey[400],
                  }}
                />
              </Box>
            );
          } else return null;
        })}
      </Box>
    </Popover>
  );
};

export default ContactSelect;
