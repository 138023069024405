import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Fonts } from "../../Helper";

const ToggleButton = ({ isExpanded, onClick, style, moreText, lessText }) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={onClick}
      size="medium"
      sx={{
        fontFamily: Fonts.Text,
        fontWeight: 600,
        width: "fit-content",
        height: "fit-content",
        textTransform: "initial",
        ...style,
      }}
    >
      {isExpanded ? lessText || t("Show Less") : moreText || t("Show More")}
    </Button>
  );
};

export default ToggleButton;
