import { Box, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import Chart from "../Chart";
import { styled } from "@mui/styles";
import { remToPx } from "../../../../Helper/data";
import { useLocation, useNavigate } from "react-router";
import useWidth from "../../../../hooks/useWidth";
import { Constant } from "../../../../Helper";
import RecurringView from "../../Employees/Component/RecurringView";

const Root = styled("div")(({ width, height }) => ({
  display: "flex",
  justifyContent: "center",
}));

const tabOptionsRevenue = [
  { id: 1, path: "overview", tab: "Overview" },
  { id: 2, path: "recurring-income", tab: "Recurring Incomes" },
];
const tabOptionsExpense = [
  { id: 1, path: "overview", tab: "Overview" },
  { id: 2, path: "recurring-expenses", tab: "Recurring Expenses" },
];
const RevenueExpense = ({ selectedItem, selectedType }) => {
  const currentWidth = useWidth();
  let width = remToPx(currentWidth, 93.75);
  let height = remToPx(currentWidth, 46);
  const location = useLocation();
  const navigate = useNavigate();

  //redux
  const [tab, setTab] = useState(1);

  //lifeCycle method
  useEffect(() => {
    let array = location.pathname.split("/");
    let key = array[array.length - 1];
    let options = selectedItem === 1 ? tabOptionsRevenue : tabOptionsExpense;
    let filter = options?.find((o1) => o1.path === key);
    if (filter) {
      setTab(filter?.id);
    }
  }, [location.pathname]);

  //function
  const handleChangeTab = (event, newValue) => {
    if (tab !== newValue) {
      let options = selectedItem === 1 ? tabOptionsRevenue : tabOptionsExpense;
      let [key] = options?.filter((o1) => o1.id === newValue);
      let array = location.pathname.split("/");
      array.pop();
      array.push(key.path);
      let url = array.join("/");
      navigate(url);
      //   setTab(newValue);
    }
  };

  return (
    <Root width={width} height={height}>
      <Box
        sx={{
          width: width,
          "& .MuiTabs-root": {
            width: "70%",
          },
        }}
      >
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          textColor="inherit"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value={1} label="Overview" />
          {selectedItem === 1 && !Constant.isProd ? (
            <Tab
              value={2}
              label={`Recurring ${selectedItem === 1 ? "Income" : "Expenses"}`}
            />
          ) : null}
        </Tabs>
        {tab === 1 && <Chart selectedItem={selectedItem} type={selectedType} />}
        {tab === 2 && <RecurringView selectedItem={selectedItem} />}
      </Box>
    </Root>
  );
};

export default RevenueExpense;
