import { useEffect, useMemo, useRef, useState } from "react";

import {
  Button,
  Typography,
  Box,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Checkbox,
  useTheme,
  OutlinedInput,
  Popover,
} from "@mui/material";

import { formatValue } from "react-currency-input-field";
import { useSnackbar } from "notistack";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { produce } from "immer";
import { useDispatch, useSelector } from "react-redux";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import TitleIcon from "@mui/icons-material/Title";
import NotesIcon from "@mui/icons-material/Notes";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import IsoIcon from "@mui/icons-material/Iso";
import { CgArrowRightR } from "react-icons/cg";

import TitleInput from "../../../components/Overlay/TitleInput";
import DescriptionInput from "../../../components/Overlay/DescriptionInput";
import CategoryTreeSelection from "../../../components/Overlay/CategoryTreeSelection";
import DropDown from "../../../components/Overlay/DropDown";
import { Color, Constant, Fonts } from "../../../Helper";
import CustomModal from "../../../components/Model/CustomModal";
import EndPoints from "../../../APICall/EndPoints";
import APICall from "../../../APICall";
import { setStageLoadingText } from "../../../store/slices/appmain";
import useSubscriptions from "../../../hooks/useSubscriptions";
import { queryClient } from "../../../App";
import IOSSwitch from "../../../components/Switchs/IOSSwitch";
import { PiMathOperationsBold } from "react-icons/pi";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const RULE_SELECT = [
  { uuid: 1, title: "Categorize" },
  // { uuid: 2, title: "Categorize and Ignore" },
  // { uuid: 3, title: t("Ignore") },
];

const RuleModel = ({
  open,
  setOpen,
  transactionsRow = null,
  rule = null,
  notEditable = false,
  modalType = "add",
  type = "manual",
  zIndex = 1305,
  preventClose,
}) => {
  const theme = useTheme();
  let itemId = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubscriptionValid] = useSubscriptions();
  const fields = [
    {
      title: t("rule_field_same_title"),
      field: "title",
    },
    {
      title: t("rule_field_similar_title"),
      field: "similar_title",
    },
    {
      title: t("rule_field_title_contains"),
      field: "title_contains",
    },
    { title: t("rule_field_amount"), field: "gross_value" },
    { title: t("rule_field_same_note"), field: "note" },
    { title: t("rule_field_similar_title"), field: "similar_note" },
    { title: t("rule_field_note_contains"), field: "note_contains" },
    { title: t("rule_field_title_or_note"), field: "title_or_note" },
  ];
  //redux state
  const auto_categorization_level = useSelector(
    (state) => state.settingsSlice?.profile?.auto_categorization_level
  );
  const state = useSelector((state) => state.globalSlice.state);
  const currencyFormate = useSelector(
    (state) => state.settingsSlice.currencyFormate
  );

  const TYPE = [
    {
      uuid: 1,
      title: t("Income"),
    },
    {
      uuid: 2,
      title: t("Expense"),
    },
  ];
  const LEVEL = [
    {
      uuid: 0,
      title: t("All"),
    },
    {
      uuid: 1,
      title: t("datasets"),
    },
    {
      uuid: 2,
      title: t("data_sources"),
    },
  ];
  //state
  const [conditions, setConditions] = useState([]);
  const [isAll, setIsAll] = useState(false);
  const [dataSetItem, setDataSetItem] = useState({
    title: "",
    note: "",
    transaction_title: "",
    data_sources: [],
    datasets: [],
    transaction_type: 1,
    isAddAll: false,
    rule_select: RULE_SELECT[0],
    level: modalType === "add" ? Number(auto_categorization_level) : 0,
  });
  const [error, setError] = useState({
    data_sources: "",
    data_sets: "",
    title: "",
    category: "",
  });
  const [alignment, setAlignment] = useState("and");

  const isGlobal =
    dataSetItem?.level === 0 ||
    dataSetItem?.level === 2 ||
    (dataSetItem?.level === 1 ? dataSetItem?.datasets?.length > 1 : true);
  const isDataSet =
    dataSetItem?.level === 1 && dataSetItem?.datasets?.length === 1
      ? dataSetItem?.datasets?.[0]
      : false;
  const addRuleMutation = useMutation({
    mutationFn: (obj) => createNewRules(obj),
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ["rules"],
      }),
  });

  //life cycle method
  useEffect(() => {
    if (open && transactionsRow) {
      let array = [];
      const title = transactionsRow?.title;

      const obj = {
        id: array.length,
        fieldName: "title",
        field: {
          title: "Title of Transaction",
          field: "title",
        },
        value: title,
      };
      array.push(obj);

      setDataSetItem((prev) => ({
        ...prev,
        title: `Manual - ${title}`,
        transaction_type: transactionsRow?.transaction_type,
        category: transactionsRow?.category,
      }));
      setConditions(array);
    }
  }, [transactionsRow, open]);

  useEffect(() => {
    if (open && rule) {
      itemId.current = rule?.uuid;
      const filter = rule.filters.filters;
      let array = [];
      setAlignment(rule.filters.operator);
      setDataSetItem({
        ...rule,
        rule_select: rule.category
          ? RULE_SELECT[0]
          : rule.category && rule.target_state
            ? RULE_SELECT[1]
            : rule.target_state
              ? RULE_SELECT[2]
              : null,
      });
      if (filter?.length > 0) {
        filter?.forEach((element, i) => {
          const fieldObj = fields.find((o1) => o1.field === element.field);
          array.push({
            id: i,
            fieldName: element.field,
            value:
              element?.field === "gross_value"
                ? Math.abs(element?.value)
                : element?.value,
            field: fieldObj,
          });
        });
        setConditions(array);
      } else {
        setIsAll(true);
      }
    }
  }, [rule, open]);

  //api
  const updateRulesByID = async (id, obj) => {
    await APICall("patch", EndPoints.transactions_rules + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          dispatch(setStageLoadingText(null));
          resetRules();
        }
      }
    );
  };

  const createNewRules = async (obj) => {
    await APICall("post", EndPoints.transactions_rules, obj).then(
      (response) => {
        if (response.status === 201 && response.data) {
          enqueueSnackbar(t("New_Rules_Added_Successfully"));
          if (preventClose) {
            preventClose.current = false;
          }
          setOpen(false);
          if (transactionsRow?.onClickExclude) {
            transactionsRow?.onClickExclude();
          } else {
            dispatch(setStageLoadingText(null));
          }
        }
      }
    );
  };

  //functions
  const resetRules = () => {
    setTimeout(() => {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === "rules",
      });
    }, 0);
  };

  const handleEditChange = (e) => {
    let text = e.target.value;
    if (error?.["title"]) setError({ ...error, title: "" });
    setDataSetItem({ ...dataSetItem, title: text });
    if (text !== "") {
    } else {
      if (error?.["title"]) setError({ ...error, title: "title_empty_error" });
    }
  };

  const handleEditDescriptionChange = (e) => {
    let text = e.target.value;
    setDataSetItem({ ...dataSetItem, note: text });
  };

  const onClickCategoryTitle = (e, item) => {
    if (error?.["category"]) setError({ ...error, category: "" });
    setDataSetItem({ ...dataSetItem, category: item?.uuid });
  };

  const onDelete = () => {
    if (error?.["category"]) {
      setError({ ...error, category: "select_category" });
    }
    setDataSetItem({ ...dataSetItem, category: null });
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const onClickAddCondition = () => {
    let array = [...conditions];
    let obj = {
      id: conditions.length,
      field: null,
      value: null,
    };
    array.push(obj);
    setConditions(array);
    if (error?.["filter"]) setError({ ...error, filter: "" });
  };

  const handleClose = async () => {
    if (preventClose) {
      preventClose.current = false;
    }
    setOpen(false);
    setError({});
    setDataSetItem(null);
    setConditions([]);
    setAlignment("and");
  };

  const onSaveRule = async () => {
    if (isSubscriptionValid({ showMessage: true })) {
      if (!dataSetItem?.title || dataSetItem?.title?.trim() === "") {
        setError((prev) => ({ ...prev, title: "title_empty_error" }));
        return;
      }
      if (!dataSetItem?.transaction_type) {
        setError((prev) => ({
          ...prev,
          transaction_type: "transaction_type_empty_error",
        }));
        return;
      }

      if (dataSetItem?.level === 1 && dataSetItem?.datasets?.length === 0) {
        setError((prev) => ({
          ...prev,
          datasets: "dataset_empty_error",
        }));
        return;
      }
      if (dataSetItem?.level === 2 && dataSetItem?.data_sources?.length === 0) {
        setError((prev) => ({
          ...prev,
          data_sources: "ds_empty_error",
        }));
        return;
      }
      let filter = [];
      if (!isAll) {
        let errorText = "";
        fields?.forEach((element) => {
          const data = conditions.find((o1) => o1.fieldName === element.field);

          if (data) {
            if (!data?.value) {
              errorText = "Add_one_Condition";
            }
            const filterObj = {
              field: data?.fieldName,
              value:
                data?.fieldName === "gross_value"
                  ? dataSetItem?.type?.uuid === 1
                    ? Math.abs(data?.value || 0)
                    : "-" + (data?.value || "")
                  : data?.value || "",
            };
            filter.push(filterObj);
          }
        });
        if (filter?.length === 0) {
          errorText = "Add_one_Condition";
        }
        if (errorText) {
          setError((prev) => ({ ...prev, filter: errorText }));
          return;
        }
      }

      if (!dataSetItem?.category) {
        setError((prev) => ({ ...prev, category: "select_category" }));
        return;
      }
      let obj = {
        ...dataSetItem,
        transaction_title: "",
        state: 1,
        type,
        filters: {
          operator: alignment,
          filters: filter,
        },
      };
      if (dataSetItem?.level === 0) {
        obj.datasets = [];
        obj.data_sources = [];
      }
      if (dataSetItem?.level === 1) {
        obj.data_sources = [];
      }
      if (dataSetItem?.level === 2) {
        obj.datasets = [];
      }
      if (!isAll) {
        const isTitleAdded = filter?.find((item) => item?.field === "title");

        if (isTitleAdded) {
          obj.transaction_title = isTitleAdded?.value;
        }
      }
      if (modalType === "edit") {
        await updateRulesByID(itemId.current, obj);
        handleClose();
      } else {
        addRuleMutation.mutate(obj);
      }
    }
  };

  const onChangeFields = (e, obj, index) => {
    let array = [...conditions];
    array[index] = { ...array[index], field: obj, fieldName: obj?.field };
    setConditions(array);
  };

  const onChangeAmount = (e, index, field) => {
    let array = [...conditions];
    let value = e.target.value;
    if (field === "gross_value") {
      value = (value ?? 0)
        ?.replace(currencyFormate.prefix, "")
        .replaceAll(currencyFormate.groupSeparator, "")
        .replace(currencyFormate.decimalSeparator, ".")
        .replace(/[^0-9.-]+/, "")
        .replace("-", "")
        .trim();
    }
    if (field === "date") {
      value = format(new Date(e.target.value), "yyyy-MM-dd");
    }
    array[index] = {
      ...array[index],
      value: value,
    };
    setConditions(array);
  };

  const onChangeNote = (e, index, field) => {
    let array = [...conditions];
    const title = array?.find((o1) => o1.fieldName === "title")?.value || "";
    let value = e.target.value;

    array[index] = {
      ...array[index],
      value: value,
    };

    setConditions(array);
    if (error?.["title"]) setError({ ...error, title: "" });

    if (
      field === "note" ||
      field === "similar_note" ||
      field === "note_contains"
    ) {
      setDataSetItem({ ...dataSetItem, title: `${title} - ${value}` });
    }
  };

  const onClickConditionDelete = (index) => {
    let array = conditions?.filter((o1) => o1.id !== index);
    setConditions(array);
  };

  const onChangeSelect = (e, value) => {
    let data = state.find((o1) => o1.title === "Ignored");
    setDataSetItem({
      ...dataSetItem,
      rule_select: value ?? null,
      target_state: value.uuid === 1 ? null : data.uuid,
      category: value.uuid === 3 ? null : dataSetItem.category,
    });
  };

  const handleChangeCheck = (e) => {
    setDataSetItem({
      ...dataSetItem,
      isAddAll: e.target.checked,
    });
  };

  const onClickSwitch = (e) => {
    setIsAll(e.target.checked);
  };

  const onChangeTransactionType = (e, value) => {
    const obj = {
      transaction_type: value?.uuid,
      category: null,
    };
    setDataSetItem((prev) => ({
      ...prev,
      ...obj,
    }));
  };

  const onChangeLevel = (e, value) => {
    const obj = {
      level: value?.uuid,
      category: null,
    };
    setDataSetItem((prev) => ({
      ...prev,
      ...obj,
    }));
  };

  return (
    <CustomModal
      open={open}
      modalType={modalType}
      onClose={handleClose}
      onAdd={onSaveRule}
      heading={modalType === "edit" ? t(`edit_rule`) : t(`add_rule`)}
      rootStyle={{
        zIndex: zIndex,
        "& .MuiDialogContent-root": {
          ...theme.thinScrollBar,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: Color.white,
          width: "40rem",
          height: "fit-content",
          p: "1.5rem",
          pr: "3rem",
        }}
      >
        <div style={{ display: "flex", marginTop: "0.5rem", width: "100%" }}>
          <TitleIcon
            sx={{
              color: theme.palette.color.blueGrey[700],
              ml: "0.2rem",
              mr: "0.8rem",
              mt: dataSetItem?.title ? "0.25rem" : "0.75rem",
              fontSize: "1.4rem",
            }}
          />
          <TitleInput
            value={dataSetItem?.title || ""}
            onChange={handleEditChange}
            helperText={t(error?.["title"])}
            error={Boolean(error?.["title"])}
            hideTitle
            label={t("rule_form_title")}
            likeGoogle
            variant="filled"
          />
        </div>
        <div style={{ display: "flex", marginTop: "0.5rem", width: "100%" }}>
          <NotesIcon
            sx={{
              color: theme.palette.color.blueGrey[700],
              ml: "0.2rem",
              mr: "0.8rem",
              mt: dataSetItem?.note ? "0.25rem" : "0.75rem",
              fontSize: "1.4rem",
            }}
          />
          <DescriptionInput
            value={dataSetItem?.note || ""}
            onChange={handleEditDescriptionChange}
            hideTitle
            rows={null}
            label={t("rule_form_note")}
            likeGoogle
            variant="filled"
            sx={{ width: "100%" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "1.5rem",
            width: "100%",
          }}
        >
          <IsoIcon
            sx={{
              color: theme.palette.color.blueGrey[700],
              ml: "0.2rem",
              mt: "0.5rem",
              mr: "0.8rem",
              fontSize: "1.4rem",
            }}
          />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "90%",
            }}
          >
            <DropDown
              sx={{ width: "48%" }}
              disablePortal
              disableClearable
              hideTitle
              fontSize="0.9rem"
              name="transaction_type"
              options={TYPE}
              value={TYPE?.find(
                (o1) => o1?.uuid === dataSetItem?.transaction_type
              )}
              onChange={onChangeTransactionType}
              getOptionLabel={(option) => t(option?.title) ?? ""}
              renderOption={(option) => t(option?.title) ?? ""}
              error={error?.["transaction_type"]}
              label={t("rule_form_type")}
              tooltip={t("rule_form_type")}
              likeGoogle
              variant="filled"
              mb="0"
            />
            <DropDown
              sx={{ width: "48%" }}
              disablePortal
              disableClearable
              hideTitle
              fontSize="0.9rem"
              name="level"
              options={LEVEL}
              value={LEVEL?.find((o1) => o1?.uuid === dataSetItem?.level)}
              onChange={onChangeLevel}
              getOptionLabel={(option) => t(option?.title) ?? ""}
              renderOption={(option) => t(option?.title) ?? ""}
              error={error?.["level"]}
              label={t("Level")}
              tooltip={t("Level")}
              likeGoogle
              variant="filled"
              mb="0"
            />
          </div>
        </div>

        {/* org and DS */}
        {dataSetItem?.level === 0 ? null : (
          <div
            style={{
              display: "flex",
              marginTop: "2.5rem",
              width: "100%",
            }}
          >
            <DataUsageIcon
              sx={{
                color: theme.palette.color.blueGrey[700],
                ml: "0.2rem",
                mt: "0.25rem",
                mr: "1.65rem",
                fontSize: "1.4rem",
              }}
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "90%",
              }}
            >
              {/* disabled={modalType === "edit" || notEditable} */}

              {dataSetItem?.level === 2 ? null : (
                <PopOverView
                  width="48%"
                  title="datasets"
                  type="datasets"
                  error={error?.["datasets"]}
                  setError={setError}
                  selectedIds={dataSetItem?.datasets || []}
                  setDataSetItem={setDataSetItem}
                />
              )}
              {dataSetItem?.level === 1 ? null : (
                <PopOverView
                  width="48%"
                  title="data_sources"
                  type="data_sources"
                  error={error?.["data_sources"]}
                  setError={setError}
                  selectedIds={dataSetItem?.data_sources || []}
                  setDataSetItem={setDataSetItem}
                />
              )}
            </div>
          </div>
        )}

        {/* condition */}
        <div
          style={{
            display: "flex",
            marginTop: "2.5rem",
            width: "100%",
          }}
        >
          <PiMathOperationsBold
            style={{
              color: theme.palette.color.blueGrey[700],
              marginLeft: "0.2rem",
              marginRight: "1.65rem",
              marginTop: "0.25rem",
              fontSize: "1.4rem",
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "90%",
            }}
          >
            <Typography
              color="color.slate.600"
              variant="caption"
              fontWeight={"fontWeightMedium"}
              sx={{ mr: "0.5rem" }}
            >
              {t("Filter")}
            </Typography>
            <IOSSwitch onClick={onClickSwitch} checked={isAll} />
            <Typography
              color="color.slate.600"
              variant="caption"
              fontWeight={"fontWeightMedium"}
              sx={{ ml: "0.5rem" }}
            >
              {t("Apply to all")}
            </Typography>
          </div>
        </div>

        {/* filters */}
        {isAll ? null : (
          <div
            style={{
              marginTop: "1.5rem",
              marginLeft: "9%",
              width: "91%",
              backgroundColor: theme.palette.color.slate[50],
              borderRadius: theme.borderRadius.main,
              padding: "1rem",
            }}
          >
            <Box sx={{ fontSize: "0.85rem", fontWeight: 600 }}>
              {t("Select_Operator")} :{"  "}
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
                size="small"
                sx={{ height: "2rem" }}
              >
                <ToggleButton
                  value="and"
                  sx={{ width: "3.75rem" }}
                  disabled={notEditable}
                >
                  {t("And")}
                </ToggleButton>
                <ToggleButton
                  value="or"
                  sx={{ width: 60 }}
                  disabled={notEditable}
                >
                  {t("Or")}
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            {error?.["filter"] && (
              <Typography sx={{ color: Color.red, fontSize: "0.8rem" }}>
                {t(error?.["filter"])}
              </Typography>
            )}
            {conditions?.map((item, index) => {
              return (
                <Box
                  key={`conditions-${index}`}
                  sx={{
                    width: "100%",
                    position: "relative",
                    my: "0.5rem",
                    height: "3rem",
                    borderRadius: theme.borderRadius.main,
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: Color.white,
                    "& .MuiInputBase-root": {
                      mt: "1rem",
                    },
                    "& .MuiAutocomplete-endAdornment": {
                      top: "15%",
                    },
                    "&:hover": {
                      "& span": {
                        display: "block",
                      },
                    },
                  }}
                >
                  <DropDown
                    mb={0}
                    hideTitle
                    label={t("field")}
                    variant="standard"
                    id="blur-on-select"
                    blurOnSelect
                    value={item?.field ?? null}
                    onChange={(e, value) => onChangeFields(e, value, index)}
                    options={
                      notEditable
                        ? fields?.filter((o1) => o1?.field !== "title")
                        : fields
                    }
                    sx={{
                      width: "46%",
                      "& .MuiFormLabel-root": {
                        transform: "translate(1px, 1px) scale(1) !important",
                      },
                      "&input": {
                        fontSize: "0.85rem",
                      },
                    }}
                    disabled={notEditable ? item?.fieldName === "title" : false}
                  />
                  {item?.field && (
                    <Box
                      sx={{
                        ml: "1rem",
                        display: "flex",
                        alignItems: "end",
                        height: "3rem",
                      }}
                    >
                      {[
                        "title",
                        "similar_title",
                        "title_contains",
                        "title_or_note",
                      ].includes(item?.field?.field) && (
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          value={item?.value ?? null}
                          onChange={(e) => onChangeNote(e, index)}
                          sx={{
                            width: "12.5rem",
                            ml: "0.5rem",
                            "& input": {
                              fontSize: "0.85rem",
                            },
                          }}
                          disabled={notEditable ? item?.value : false}
                        />
                      )}
                      {["note", "similar_note", "note_contains"].includes(
                        item?.field?.field
                      ) && (
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          value={item?.value ?? null}
                          onChange={(e) => onChangeNote(e, index)}
                          sx={{
                            width: "12.5rem",
                            ml: "0.5rem",
                            "& input": {
                              fontSize: "0.85rem",
                            },
                          }}
                          disabled={notEditable ? item?.value : false}
                        />
                      )}
                      {item?.field?.field === "gross_value" && (
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          // disabled={notEditable}
                          value={formatValue({
                            value: String(item?.value ?? 0),
                            groupSeparator: currencyFormate.groupSeparator,
                            decimalSeparator: currencyFormate.decimalSeparator,
                            prefix: currencyFormate.prefix,
                          })}
                          onChange={(e) =>
                            onChangeAmount(e, index, item?.field?.field)
                          }
                          sx={{
                            width: "12.5rem",
                            ml: "0.5rem",
                            height: "max-content",
                            "& input": {
                              fontSize: "0.85rem",
                            },
                          }}
                        />
                      )}
                      {item?.field?.field === "date" && (
                        <TextField
                          id="date"
                          type="date"
                          size="small"
                          value={item?.value}
                          onChange={(e) =>
                            onChangeAmount(e, index, item?.field?.field)
                          }
                          mindate={new Date().setMonth(0)}
                          maxdate={new Date().setMonth(11)}
                          sx={{ width: "12.5rem", ml: "0.5rem" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    </Box>
                  )}

                  <Box component="span" sx={{ display: "none" }}>
                    {!notEditable && (
                      <DeleteIcon
                        onClick={() => onClickConditionDelete(item?.id)}
                        sx={{
                          position: "absolute",
                          right: 10,
                          top: 15,
                          color: Color.themeColor,
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </Box>
                </Box>
              );
            })}
            <Button
              startIcon={<AddIcon />}
              sx={{ textTransform: "capitalize", mt: "0.5rem", ml: "-0.4rem" }}
              onClick={onClickAddCondition}
              // disabled={conditions?.length > 2}
            >
              {t("Add_Condition")}
            </Button>
          </div>
        )}

        {/* Action */}
        <div
          style={{
            display: "flex",
            marginTop: "2.5rem",
            width: "100%",
            position: "relative",
          }}
        >
          <CgArrowRightR
            style={{
              color: theme.palette.color.blueGrey[700],
              marginLeft: "0.2rem",
              marginRight: "0.8rem",
              marginTop: "0.5rem",
              fontSize: "1.4rem",
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "90%",
            }}
          >
            <Box
              sx={{
                width: "30%",
                mr: "0.75rem",
                "& .MuiAutocomplete-root": {
                  height: "fit-content",
                },
              }}
            >
              <DropDown
                mb={0}
                disableClearable
                options={RULE_SELECT}
                value={dataSetItem?.rule_select}
                onChange={onChangeSelect}
                renderOption={(option) => t(option?.title) ?? ""}
                getOptionLabel={(option) => t(option?.title) ?? ""}
                hideTitle
                likeGoogle
                variant="filled"
                label={t("rule_form_select")}
              />
            </Box>
            <CategoryTreeSelection
              isDataSet={isDataSet}
              isGlobal={isGlobal}
              disabled={!dataSetItem?.transaction_type}
              hideTitle
              onClickCategoryTitle={onClickCategoryTitle}
              categoryID={dataSetItem?.category ?? null}
              onDelete={onDelete}
              type={dataSetItem?.transaction_type}
              width="13rem"
            />
          </div>
          <Typography
            sx={{
              color: error?.["category"]
                ? theme.palette.color.red[500]
                : theme.palette.color.slate[700],
              fontSize: "0.8rem",
              pt: "0.25rem",
              display: "inline-flex ",
              alignItems: "center",
              position: "absolute",
              left: "36%",
              top: "80%",
            }}
          >
            {error?.["category"]
              ? t(error?.["category"])
              : isGlobal
                ? t("Global categories")
                : t("Organization categories")}
          </Typography>
        </div>
      </Box>
    </CustomModal>
  );
};

export default RuleModel;

const PopOverView = ({
  width,
  type,
  title,
  error,
  setError,
  setDataSetItem,
  selectedIds,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  //redux
  const dataSourceById = useSelector(
    (state) => state.globalSlice?.dataSourceById
  );
  const accountByDS = useSelector((state) => state.globalSlice?.accountByDS);
  const dataSource = useSelector((state) => state.globalSlice?.dataSource);
  const dataSetList = useSelector((state) => state.boardSlice?.dataSetList);

  //state
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const value = useMemo(() => {
    let _value = "";
    if (type === "data_sources") {
      selectedIds?.forEach((item, index) => {
        const _account = accountByDS?.[item]?.[0];
        const _ds = dataSourceById?.[item]?.[0];
        _value =
          _value +
          `${index !== 0 ? " , " : ""}` +
          `${_account?.bank_details?.title || _account?.name || _ds?.title}`;
      });
    } else {
      const _data = dataSetList?.filter((item) =>
        selectedIds?.includes(item?.uuid)
      );
      _data?.forEach((item, index) => {
        _value = _value + `${index !== 0 ? " , " : ""}` + `${item?.title}`;
      });
    }
    return _value;
  }, [accountByDS, dataSetList, dataSourceById, selectedIds, type]);

  const data = useMemo(() => {
    let _data = [];
    if (type === "data_sources") {
      _data = dataSource.filter((o1) => o1.type !== 1);
    } else {
      _data = dataSetList;
    }
    return _data;
  }, [dataSetList, dataSource, type]);

  const onOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = (event) => {
    setAnchorEl(null);
  };

  const onChangeSelections = (option) => {
    if (option === "all") {
      if (selectedIds?.length === data?.length) {
        setDataSetItem((prev) => ({
          ...prev,
          category: null,
          [type]: [],
        }));
      } else {
        setDataSetItem((prev) => ({
          ...prev,
          category: null,
          [type]: data.map((o1) => o1.uuid),
        }));
      }
    } else {
      const updatedData = produce(selectedIds, (draftState) => {
        if (draftState?.includes(option?.uuid)) {
          const index = draftState.findIndex((o1) => o1 === option?.uuid);
          if (index > -1) {
            draftState.splice(index, 1);
          }
        } else {
          draftState.push(option?.uuid);
        }
      });
      setDataSetItem((prev) => ({
        ...prev,
        category: null,
        [type]: updatedData,
      }));
    }

    setError((prev) => ({
      ...prev,
      level: null,
      transaction_type: null,
      data_sources: null,
      datasets: null,
    }));
  };

  return (
    <Box
      sx={{
        width: width || "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Typography
        sx={{
          width: "100%",
          textAlign: "start",
          color: theme.palette.color.mainTitle,
          fontWeight: 600,
          fontSize: "0.85rem",
          mb: "0.25rem",
        }}
      >
        {t(title)}
      </Typography>
      <OutlinedInput
        onClick={onOpen}
        size="small"
        type="button"
        error={Boolean(error?.[type])}
        sx={{
          width: "100%",
          cursor: "pointer",
          borderRadius: theme.borderRadius.main,
          "& input": {
            color: theme.palette.color.slate[500],
            fontWeight: 500,
            fontSize: "0.85rem",
            lineHeight: "0.85rem",
            cursor: "pointer",
            textAlign: "left",
          },
        }}
        value={t(value)}
        endAdornment={<ArrowDropDownIcon sx={{ color: Color.blueGrey500 }} />}
      />
      <Typography
        sx={{
          width: "100%",
          textAlign: "start",
          fontFamily: Fonts.Text,
          color: Color.error,
          fontSize: "0.7rem",
          position: "absolute",
          bottom: "-1.5rem",
        }}
      >
        {t(error)}
      </Typography>
      {open ? (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          slotProps={{
            paper: {
              sx: {
                borderRadius: theme.borderRadius.main,
                boxShadow: Constant.OverlayBoxShadow,
              },
            },
          }}
          sx={{
            zIndex: 1304,
            mt: "0.5rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "fit-content",
              minHeight: "3.125rem",
              p: "1rem",
              width: "fit-content",
              minWidth: "22.5rem",
              position: "relative",
            }}
          >
            <Box
              key={"all"}
              onClick={() => onChangeSelections("all")}
              style={{ cursor: "pointer" }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: "0.5rem" }}
                checked={selectedIds?.length === data?.length}
              />
              {t("All")}
            </Box>
            {data?.map((item) => {
              let title = "";
              if (type === "data_sources") {
                let _account = accountByDS?.[item?.uuid]?.[0];
                title = `${
                  _account?.bank_details?.title || _account?.name || item?.title
                } ${item?.alias ? `(${item?.alias})` : ""} - ${
                  item?.type === 10 || item?.type === 12
                    ? "Bank Account"
                    : "Integrations"
                }`;
              } else {
                title = item?.title;
              }
              return (
                <Box
                  key={item?.uuid}
                  onClick={() => onChangeSelections(item)}
                  style={{ cursor: "pointer" }}
                >
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: "0.5rem" }}
                    checked={selectedIds?.includes(item?.uuid)}
                  />
                  {title}
                </Box>
              );
            })}
          </Box>
        </Popover>
      ) : null}
    </Box>
  );
};
