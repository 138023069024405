import React, { useState } from "react";
import { Outlet, useLocation } from "react-router";
import { Box } from "@mui/material";

import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SettingsIcon from "@mui/icons-material/Settings";
import PercentIcon from "@mui/icons-material/Percent";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { TbPlug } from "react-icons/tb";
import { MdBusiness, MdSecurity } from "react-icons/md";
import Icon from "../../components/Icon";

import DrawerView from "../../components/DrawerView";
import SettingView from "./SettingView";
import { Color } from "../../Helper";
import initialData from "../../Helper/data";

import { PiMagicWand } from "react-icons/pi";
import { BsFillRocketTakeoffFill } from "react-icons/bs";
import PathHeader from "../../components/PathHeader";
import useDebounce from "../../hooks/3-useDebounce/useDebounce";

const iconStyle = {
  fontSize: "1.3rem",
};

const Settings = () => {
  const location = useLocation();
  const generalSettingMenu = [
    {
      id: 0,
      title: "Personal",
      icon: <PermIdentityIcon sx={iconStyle} />,
      divider: false,
      url: ["/settings/personal"],
    },
    {
      id: 1,
      title: "Security",
      icon: (
        <Icon icon={<MdSecurity />} fontSize={"1.3rem"} color="inherit"></Icon>
      ),
      divider: true,
      url: ["/settings/security"],
    },
    {
      id: 2,
      title: "Company Settings",
      icon: <SettingsIcon sx={iconStyle} />,
      divider: false,
      url: ["/settings/general"],
    },
    {
      id: 3,
      title: "Users",
      icon: <PeopleAltIcon sx={iconStyle} />,
      divider: true,
      url: ["/settings/users"],
    },
    {
      id: 4,
      title: "Bank & Integrations",
      icon: <Icon icon={<TbPlug />} fontSize={"1.3rem"} color="inherit"></Icon>,
      divider: false,
      url: ["/settings/Integrations"],
    },
    {
      id: 5,
      title: "datasets",
      icon: (
        <Icon icon={<MdBusiness />} fontSize={"1.3rem"} color="inherit"></Icon>
      ),
      divider: true,
      url: [`/settings/${initialData?.path?.organizations}`],
    },

    {
      id: 7,
      title: "Rule_plural",
      icon: <PiMagicWand style={iconStyle} />,
      divider: false,
      url: ["/settings/rules"],
    },
    {
      id: 8,
      title: "Vats",
      icon: <PercentIcon sx={iconStyle} />,
      divider: true,
      url: ["/settings/vats"],
    },
    {
      id: 9,
      title: "Subscription",
      icon: (
        <Icon
          icon={<BsFillRocketTakeoffFill />}
          fontSize={"1.3rem"}
          color="inherit"
        ></Icon>
      ),
      divider: false,
      url: [
        "/settings/subscription",
        "/settings/subscription/successful-payment/",
      ],
    },
  ];

  //state
  const [selectedTab, setSelectedTab] = useState(-1);

  useDebounce(
    () => {
      if (location?.pathname === "/settings") {
        setSelectedTab(-1);
      } else {
        generalSettingMenu?.forEach((element) => {
          if (element?.url?.includes(location?.pathname)) {
            setSelectedTab(element.id);
          }
        });
      }
    },
    200,
    [location?.pathname],
    true
  );

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        backgroundColor: Color.appThemeBg,
      }}
    >
      <DrawerView selectedTab={selectedTab} data={generalSettingMenu} />
      <Box
        sx={{
          width: "100%",
          maxHeight: "100%",
          position: "relative",
        }}
      >
        <PathHeader data={generalSettingMenu} />
        {selectedTab === -1 ? <SettingView /> : <Outlet />}
      </Box>
    </Box>
  );
};

export default Settings;
