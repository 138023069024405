import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { alpha, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Color } from "../../Helper";
import { getTailwindColor } from "../../Helper/data";
import { setAppliedFilterslist } from "../../store/slices/global";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
      width: 160,
    },
    sx: {
      "& .MuiList-root": {
        py: 0,
      },
    },
  },
};

export default React.forwardRef(function CustomerStateToggle(props, ref) {
  const { type } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //redux
  const state = useSelector((state) => state.globalSlice.state);
  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );

  if (!appliedFilterlist?.reports?.client?.stateValue) {
    return null;
  }

  //function
  const onChange = (event) => {
    const {
      target: { value },
    } = event;
    let selectedFilters = appliedFilterlist?.reports;
    let selectedClient = appliedFilterlist?.reports?.client;
    let selectedFormate = appliedFilterlist?.reports?.client?.stateValue;
    let filterObj = {
      ...appliedFilterlist,
      reports: {
        ...selectedFilters,
        client: {
          ...selectedClient,
          stateValue: {
            ...selectedFormate,
            [type]: typeof value === "string" ? value?.split(",") : value,
          },
        },
      },
    };
    dispatch(setAppliedFilterslist(filterObj));
  };

  //render function
  return (
    <Box
      {...props}
      ref={ref}
      sx={{
        display: "flex",
        width: "fit-content",
        flexWrap: "wrap",
      }}
    >
      <FormControl sx={{ width: 160 }}>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          displayEmpty
          value={appliedFilterlist?.reports?.client?.stateValue[type]}
          onChange={onChange}
          input={
            <OutlinedInput
              sx={{
                textAlign: "left",
                p: "0 !important",
                fontSize: "0.9rem",
                color:
                  appliedFilterlist?.reports?.client?.stateValue[type]
                    ?.length === 0
                    ? alpha(Color.purple, 0.4)
                    : alpha(Color.themeColor, 0.75),
              }}
            />
          }
          renderValue={(selected) => {
            if (!selected || selected?.length === 0) {
              return <em>States</em>;
            }
            return selected?.join(", ");
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          {state?.map((item) => (
            <MenuItem
              key={item?.uuid}
              value={item?.title}
              sx={{
                backgroundColor: alpha(getTailwindColor(item?.color), 0.1),
                p: 0,
              }}
            >
              <Checkbox
                sx={{
                  "& .MuiSvgIcon-root": { width: "0.9em", height: "0.9em" },
                }}
                checked={
                  appliedFilterlist?.reports?.client?.stateValue[type]?.indexOf(
                    item?.title
                  ) > -1
                }
              />
              <ListItemText
                primary={t(item?.title)}
                sx={{ "& span": { fontSize: "0.9rem" } }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
});
