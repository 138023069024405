import { Color } from "../../Helper/Color";
import { Box } from "@mui/material";

const FieldSet = ({ active, onClick, selected, activeColor }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        borderRadius: 2.5,
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        border:
          active || selected
            ? `2px solid ${activeColor ? activeColor : Color.themeColor2}`
            : 0,
      }}
    ></Box>
  );
};
export default FieldSet;
