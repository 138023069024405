import { forwardRef, useImperativeHandle, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOnBoardingList } from "../../store/slices/global";
import { Constant } from "../../Helper";
import DSConnections from "../../Pages/Settings/DataSource/DSConnections";

const OnBoardingFunctions = forwardRef((props, _ref) => {
  const dispatch = useDispatch();
  const dsRef = useRef(null);

  const onBoardingList = useSelector((state) => {
    return state?.globalSlice?.onBoardingList;
  });

  //functions
  const updateCheckedProperty = (uuid, payload) => {
    const traverseAndUpdate = (nodes) => {
      return nodes?.map((node) => {
        if (node?.uuid === uuid) {
          return { ...node, ...payload };
        }
        if (node?.children?.length > 0) {
          const children = traverseAndUpdate(node?.children);
          const isAllChecked = children?.every((child) => child?.checked);
          return { ...node, checked: isAllChecked, children: children };
        }
        return node;
      });
    };

    const updatedData = traverseAndUpdate(onBoardingList);
    dispatch(setOnBoardingList(updatedData));
  };

  const findObjectByTitle = (data, title) => {
    if (data?.length > 0) {
      for (const child of data) {
        if (child.title === title) {
          return child;
        }
        const result = findObjectByTitle(child?.children, title);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  const updateOnboardingList = (obj) => {
    if (obj?.uuid) {
      updateCheckedProperty(obj?.uuid, obj?.payload || { checked: true });
    }
    if (obj?.isAccount) {
      const titleToFind = "Connect your Bank Account";
      const foundObject = findObjectByTitle(onBoardingList, titleToFind);
      if (foundObject) {
        updateCheckedProperty(
          foundObject?.uuid,
          obj?.payload || { checked: true }
        );
      } else {
      }
    }
  };

  const onClickAction = (item) => {
    const title = item?.title?.replace("title", "") + "btn";

    if (title === "onboarding_01_01_btn") {
      const card =
        Constant.integrations.IntegrationsByName?.["FinApi_Account"]?.[0];

      if (card) {
        dsRef.current?.handleClickCard(card);
      }
    }
  };

  useImperativeHandle(_ref, () => ({
    updateOnboardingList: (obj) => {
      updateOnboardingList(obj);
    },
    onClickAction: (item) => {
      onClickAction(item);
    },
  }));

  return <DSConnections ref={dsRef} />;
});

export default OnBoardingFunctions;
