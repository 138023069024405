import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchText: "",
};

const rulesSlice = createSlice({
  name: "rulesSlice",
  initialState,
  reducers: {
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
  },
});
export const { setSearchText } = rulesSlice.actions;
export default rulesSlice;
