import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpenSnackbar: false,
  errorMessage: "",
  isAllHeaderApiFetched: false,
  isOnBoardingOverlayOpen: false,
  overlay: null,
};

const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  reducers: {
    setIsOpenSnackbar: (state, action) => {
      state.isOpenSnackbar = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setIsAllHeaderApiFetched: (state, action) => {
      state.isAllHeaderApiFetched = action.payload;
    },
    setIsOnBoardingOverlayOpen: (state, action) => {
      state.isOnBoardingOverlayOpen = action.payload;
    },
    setOverlay: (state, action) => {
      state.overlay = action.payload;
    },
  },
});
export const {
  setIsOpenSnackbar,
  setErrorMessage,
  setIsAllHeaderApiFetched,
  setIsOnBoardingOverlayOpen,
  setOverlay,
} = commonSlice.actions;
export default commonSlice;
