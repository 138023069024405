import React, { useState } from "react";
import UploadStep from "./UploadStep";
import * as XLSX from "xlsx";
import SelectSheetStep from "./SelectSheetStep";
import SelectHeaderStep from "./SelectHeaderStep";
import MatchColumnsStep from "./MatchColumnsStep";
import { StepType } from "./helper";

const UploadFlow = ({ state, onNext, onBack }) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedSheetName, setSelectedSheetName] = useState("");

  const mapWorkbook = (workbook, sheetName = "") => {
    const worksheet = workbook.Sheets[sheetName || workbook.SheetNames[0]];
    const data = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      blankrows: false,
      raw: false,
    });
    return data;
  };

  switch (state.type) {
    case StepType.upload:
      return (
        <UploadStep
          onContinue={async (workbook, file) => {
            setUploadedFile(file);
            const isSingleSheet = workbook.SheetNames.length === 1;
            if (isSingleSheet) {
              try {
                const mappedWorkbook = mapWorkbook(workbook);
                setSelectedSheetName(workbook.SheetNames?.[0]);
                onNext({
                  type: StepType.selectHeader,
                  data: mappedWorkbook,
                });
              } catch (e) {
                console.log("error", e.message);
              }
            } else {
              onNext({ type: StepType.selectSheet, workbook });
            }
          }}
        />
      );
    case StepType.selectSheet:
      return (
        <SelectSheetStep
          sheetNames={state.workbook.SheetNames}
          onContinue={async (sheetName) => {
            try {
              const mappedWorkbook = mapWorkbook(state.workbook, sheetName);
              setSelectedSheetName(sheetName);
              onNext({
                type: StepType.selectHeader,
                data: mappedWorkbook,
              });
            } catch (e) {
              console.log("error ", e.message);
            }
          }}
          onBack={onBack}
        />
      );
    case StepType.selectHeader:
      return (
        <SelectHeaderStep
          data={state.data}
          onContinue={async (headerValues, data) => {
            try {
              onNext({
                type: StepType.matchColumns,
                data,
                headerValues,
              });
            } catch (e) {
              console.log("error", e);
            }
          }}
          onBack={onBack}
        />
      );
    case StepType.matchColumns:
      return (
        <MatchColumnsStep
          data={state.data}
          headerValues={state.headerValues}
          onContinue={async (values, rawData, columns) => {
            try {
              onNext({
                type: StepType.finalStep,
                data: values,
                rawData: rawData,
                columns,
                uploaded_file: uploadedFile,
                sheet_name: selectedSheetName,
              });
            } catch (e) {
              console.log("error", e);
            }
          }}
        />
      );
    default:
      return null;
  }
};

export default UploadFlow;
