import * as React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Dialog, Box, Typography, useTheme, Stack } from "@mui/material";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { tree } from "./Tree/data";
import APICall from "../../../APICall";
import EndPoints from "../../../APICall/EndPoints";
import Translate from "../../../hooks/HOC/Translate";
import CategoryChip from "../../../components/CategoryChip";
import { setCategories } from "../../../store/slices/category";
import TailwindButton from "../../../components/Overlay/TailwindButton";
import CategoryTreeSelect from "../../../components/Overlay/CategoryTreeSelect";

const CategoryDnDAttentionOverlay = ({
  open,
  setOpen,
  title,
  message,
  payload,
  onCloseCallback,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  //state
  const [isMoving, setIsMoving] = React.useState(false);
  const [item, setItem] = React.useState({ category: payload?.node?.uuid });
  const [error, setError] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);

  //api
  const getCategories = async () => {
    // let endUrl = "";
    // if (!use_global_categories) {
    //   endUrl = `?dataset=${dataset}`;
    // } else {
    //   endUrl = `?is_global=true`;
    // }
    await APICall("get", EndPoints.category)
      .then((response) => {
        if (response.status === 200 && response.data) {
          dispatch(setCategories({ data: response.data.results, type: "all" }));
        }
      })
      .finally(() => {
        onClose();
      });
  };

  const moveTransactions = async (node, obj) => {
    await APICall(
      "post",
      EndPoints.category + `${node?.uuid}/move_transactions/`,
      obj,
      {
        doNotCatchRespond: true,
      }
    ).then((response) => {
      if (response.status === 200 && response?.data?.success) {
      } else {
        setIsMoving(false);
      }
    });
  };

  const batchUpdateCategory = async (payload) => {
    await APICall("put", EndPoints.category + `batch_update/`, payload).then(
      (response) => {
        if (response.status === 200 && response.data) {
          getCategories();
        }
      }
    );
  };

  //functions
  const onClose = () => {
    setOpen(false);
    setIsMoving(false);
    setItem({});
    setError({});
    if (onCloseCallback) onCloseCallback();
  };

  const onMove = async () => {
    if (item?.category) {
      const { nextParentNode, node, treeData } = payload;
      setIsMoving(true);
      const updatedFlattenData = tree.flattenTree(treeData);
      let batchArray = [];
      updatedFlattenData?.forEach((item) => {
        batchArray.push({
          uuid: item?.uuid,
          expanded: item?.expanded,
          position: item.position,
          parent: item.parent,
          title: item.title,
        });
      });
      await moveTransactions(nextParentNode, { category: item?.category });
      await batchUpdateCategory(batchArray);
    } else {
      setError({ category: "please select not used category" });
    }
  };

  const onDelete = () => {
    setItem({ category: null });
  };

  const onClickCategoryTitle = (e, item) => {
    setItem({ category: item?.uuid });
    setError({});
    handleClosePopOver();
  };

  const handleClosePopOver = (e, item) => {
    setAnchorEl(null);
  };

  const onClickCategory = (e) => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <div>
      <CategoryTreeSelect
        anchorEl={anchorEl}
        handleClosePopOver={handleClosePopOver}
        onClickCategoryTitle={onClickCategoryTitle}
        type={payload?.categoryType}
        hiddenCategory={[payload?.nextParentNode?.uuid]}
      />
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ zIndex: 1308 }}
        PaperProps={{
          style: {
            minWidth: "65rem",
            maxWidth: "70rem",
            height: "20rem",
            padding: "2rem",
            boxShadow:
              "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
            borderRadius: 8,
          },
        }}
      >
        <Typography
          variant="div"
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
            fontWeight: "600",
            color: "color.slate.500",
          }}
        >
          <InfoOutlinedIcon
            sx={{
              color: "color.slate.500",
              mr: "1rem",
            }}
          />

          {t(title)}
        </Typography>
        <Typography
          my="1.5rem"
          variant="body1"
          sx={{
            fontSize: "1rem",
            color: "color.description",
          }}
        >
          <Translate i18nkey={message} />
        </Typography>
        <Stack sx={{ mb: "2rem" }}>
          <Stack direction={"row"} alignItems={"center"} sx={{ gap: "1rem" }}>
            {item?.category ? (
              <CategoryChip
                // onClick={onClickCategory}
                onDelete={onDelete}
                categoryId={item?.category}
              />
            ) : null}
            <Typography
              onClick={onClickCategory}
              variant="subtitle2"
              sx={{
                cursor: "pointer",
                width: "fit-content",
                borderRadius: theme.borderRadius.main,
                backgroundColor: theme.palette.color.slate[200],
                color: theme.palette.color.slate[700],
                px: "0.5rem",
                py: "0.25rem",
              }}
            >
              {item?.category ? t("Assign new category") : t("Select Category")}
            </Typography>
          </Stack>
          {error?.category ? (
            <Typography
              onClick={onClickCategory}
              variant="caption"
              color="error"
              sx={{
                width: "fit-content",
              }}
            >
              {t(error?.category)}
            </Typography>
          ) : null}
        </Stack>

        <Box
          sx={{
            bottom: "1.5rem",
            position: "absolute",
            mt: "2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "95%",
          }}
        >
          <TailwindButton
            type="cancel"
            text={t("Cancel")}
            onClick={onClose}
            backgroundColorCancel={theme.palette.color.slate[200]}
            sx={{
              ml: 0,
              borderRadius: 50,
            }}
          />
          <Stack direction="row" alignItems={"center"}>
            {/* <TailwindButton
              text={t("category_dnd_action_unCategorize")}
              onClick={onUnCategorized}
              backgroundColorCancel={theme.palette.color.slate[200]}
              sx={{
                ml: 0,
                borderRadius: 50,
              }}
            /> */}
            <TailwindButton
              loading={isMoving}
              text={t("category_dnd_action_move")}
              onClick={onMove}
              backgroundColorCancel={theme.palette.color.slate[200]}
              sx={{
                borderRadius: 50,
              }}
            />
          </Stack>
        </Box>
      </Dialog>
    </div>
  );
};

export default CategoryDnDAttentionOverlay;
