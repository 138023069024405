import React, { useCallback, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { fetchConnectedBookedBalances } from "../../../Helper/data";
import { format, startOfMonth, subMonths } from "date-fns";

import { Box, Skeleton, Typography } from "@mui/material";

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  AreaChart,
  Area,
  Text,
} from "recharts";

import { Color, Fonts } from "../../../Helper";
import { formatValue } from "react-currency-input-field";
import DashboardChartTooltip from "./DashboardChartTooltip";
import { useTranslation } from "react-i18next";
import { addMonths } from "date-fns";
import { endOfMonth } from "date-fns";
import { useEffect } from "react";
import { useDeferredTimeout } from "../../../hooks/useDeferredTimeout";

const strokeDasharray = [
  "0",
  "8 4",
  "2 2",
  "5 5 1 2",
  "10 10 10",
  "20 5 8 10",
  "10 2 1 20",
  "8 5 8 20",
  "8 5 8 5 8 20",
  "10 4 4 3 4 10",
];

const Chart = ({
  setIsInfoShowed,
  dS,
  dataset,
  skeletonSx,
  Xaxis,
  Yaxis,
  margin = {
    top: 60,
    right: 40,
    left: 10,
    bottom: 10,
  },
  tick = {
    fontSize: "0.75rem",
    fontWeight: 600,
    fontFamily: Fonts.Text,
  },
}) => {
  const { t } = useTranslation();
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );

  const isFirstLoginOverlayOpen = useSelector(
    (state) => state.globalSlice.isFirstLoginOverlayOpen
  );

  const currencyFormate = useSelector(
    (state) => state.settingsSlice.currencyFormate
  );
  const accounts = useSelector((state) => state.globalSlice.accounts);
  const testLoading = useSelector((state) => state.appSlice.testLoading);

  const orgDS = dS;
  const BookedParams = useMemo(() => {
    const connectedDS = orgDS?.map((o1) => o1.uuid);
    let apiParams = [];
    let datasetLinkedAccount = [];
    const end_date = format(endOfMonth(addMonths(new Date(), 1)), "yyyy-MM-dd");
    const start_date = format(
      startOfMonth(subMonths(new Date(), 4)),
      "yyyy-MM-dd"
    );
    if (connectedDS?.length > 0) {
      datasetLinkedAccount = accounts?.filter((o1) =>
        connectedDS?.includes(o1?.data_source)
      );

      if (datasetLinkedAccount?.length > 0) {
        datasetLinkedAccount?.forEach((element) => {
          element?.balances.forEach((o1) => {
            if (o1.name === "booked") {
              apiParams.push({
                accountID: element?.uuid,
                balanceID: o1?.uuid,
              });
            }
          });
        });
      }
    }

    return { apiParams, start_date, end_date };
  }, [orgDS, accounts]);
  const { apiParams, start_date, end_date } = BookedParams;
  const _apiParams = useDeferredTimeout({ value: apiParams, timeoutMs: 2000 });

  const tickFormatter = (tick) => {
    let value = formatValue({
      value: String(tick ?? 0),
      groupSeparator: currencyFormate.groupSeparator,
      decimalSeparator: currencyFormate.decimalSeparator,
      prefix: currencyFormate.prefix,
    });
    return value;
  };

  const tickFormatterX = (tick) => {
    let value = format(new Date(tick), "MMM yy");
    return value;
  };

  const addMonthWithForecast = (existingData, forecastMonth) => {
    const dampingFactor = 0.001; // Adjust the damping factor as needed

    const forecastData = { month: forecastMonth };

    existingData.forEach((obj) => {
      for (const key in obj) {
        if (key !== "month") {
          if (!forecastData[key]) {
            forecastData[key] = obj[key];
          } else {
            forecastData[key] =
              obj[key] * dampingFactor +
              forecastData[key] * (1 - dampingFactor);
          }
        }
      }
    });
    return forecastData;
  };

  //query
  const Booked_Balance = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataset,
        apiType: "booked_balance",
        start_date: start_date,
        end_date: end_date,
        type: "monthly",
        apiParams: _apiParams,
      },
    ],
    queryFn: () => {
      const result = fetchConnectedBookedBalances(
        _apiParams?.slice(),
        start_date,
        end_date,
        "monthly"
      );
      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    enabled:
      isAllHeaderApiFetched &&
      !isFirstLoginOverlayOpen &&
      !!dataset &&
      !!orgDS &&
      !!start_date &&
      _apiParams?.length > 0,
    priority: 5,
    staleTime: 30000,
    select: (data) => {
      const newMonth = format(addMonths(new Date(), 1), "yyyy-MM");
      let newData = addMonthWithForecast(data?.data?.reverse(), newMonth);
      return { ...data, newData: [newData, ...data?.data] };
    },
  });

  const gradientOffset = useCallback(
    (key = "Liquidity") => {
      if (Booked_Balance?.data?.newData) {
        const dataMax = Math.max(
          ...Booked_Balance?.data?.newData?.map((i) => i[key])
        );
        const dataMin = Math.min(
          ...Booked_Balance?.data?.newData?.map((i) => i[key])
        );
        if (dataMax <= 0) {
          return 0;
        } else if (dataMin >= 0) {
          return 1;
        } else {
          let number = dataMax / (dataMax - dataMin);

          if (!isNaN(number)) {
            return number;
          }
        }
      }
    },
    [Booked_Balance?.data?.newData]
  );

  useEffect(() => {
    if (
      !Booked_Balance?.isFetching &&
      (!Booked_Balance?.data?.newData?.length ||
        Booked_Balance?.data?.newData?.length === 0)
    ) {
      setIsInfoShowed(true);
    } else {
      setIsInfoShowed(false);
    }
  }, [Booked_Balance?.data?.newData?.length, Booked_Balance?.isFetching]);

  return (
    <>
      {Booked_Balance?.isFetching || testLoading ? (
        <Skeleton
          animation="wave"
          variant="rounded"
          sx={{ width: "100%", height: "100%", ...skeletonSx }}
        />
      ) : Booked_Balance?.data?.newData?.length > 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={Booked_Balance?.data?.newData || []} margin={margin}>
            <CartesianGrid
              strokeDasharray="1"
              horizontal={false}
              vertical={false}
            />
            <XAxis
              dataKey="month"
              tickLine={false}
              stroke={Color.tailwind.slate[400]}
              reversed
              tick={tick}
              tickFormatter={tickFormatterX}
              interval={2}
              // ticks={["2023-02","2023-05","2023-07"]}
              {...Xaxis}
            />
            <YAxis
              tickLine={false}
              stroke={Color.tailwind.slate[400]}
              tick={tick}
              tickFormatter={tickFormatter}
              includeHidden
              {...Yaxis}
            />
            {_apiParams?.map((item, index) => {
              return (
                <React.Fragment key={item?.accountID}>
                  <defs>
                    <linearGradient
                      id={`${item?.accountID}`}
                      x1="0"
                      y1="0"
                      x2="0"
                      y2={"1"}
                    >
                      <stop offset={0} stopColor={"green"} stopOpacity={1} />
                      <stop
                        offset={gradientOffset(item?.accountID)}
                        stopColor={"green"}
                        stopOpacity={0}
                      />
                      <stop
                        offset={gradientOffset(item?.accountID)}
                        stopColor={Color.red}
                        stopOpacity={0}
                      />
                      <stop offset={1} stopColor={Color.red} stopOpacity={1} />
                    </linearGradient>
                    <linearGradient
                      id={`${item?.accountID}_line`}
                      x1="0"
                      y1="0"
                      x2="0"
                      y2={"1"}
                    >
                      <stop
                        offset={gradientOffset(item?.accountID)}
                        stopColor={"green"}
                        stopOpacity={1}
                      />
                      <stop
                        offset={gradientOffset(item?.accountID)}
                        stopColor={Color.red}
                        stopOpacity={1}
                      />
                    </linearGradient>
                  </defs>

                  <Area
                    type="monotone"
                    dataKey={item?.accountID}
                    stroke={`url(#${item?.accountID}_line)`}
                    fill={`url(#${item?.accountID})`}
                    dot={false}
                    strokeDasharray={strokeDasharray[index]}
                    fillOpacity={0.1}
                    // fill={Color.tailwind.slate[500]}
                  />
                </React.Fragment>
              );
            })}
            <Tooltip
              cursor={{ fill: "transparent" }}
              content={<DashboardChartTooltip />}
            />
            <ReferenceLine
              y={0}
              fill={Color.tailwind.slate[200]}
              strokeWidth={1}
            />
            <ReferenceLine
              x={format(new Date(), "yyyy-MM")}
              stroke={Color.tailwind.slate[200]}
              strokeWidth={1}
              label={<CustomizedLabel label={format(new Date(), "MMM yy")} />}
            />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <Typography
          alignSelf={"center"}
          sx={{
            fontSize: "0.87rem",
            fontFamily: Fonts.Text,
            color: Color.grey,
            fontWeight: 500,
          }}
        >
          {t("No_Data_Found")}
        </Typography>
      )}
    </>
  );
};

export default Chart;

const CustomizedLabel = ({ viewBox, label }) => {
  const { x, y } = viewBox;
  const rectWidth = 40;
  const rectHeight = 16;
  const rectX = x - rectWidth / 2;
  const rectY = y + rectHeight;

  return (
    <>
      <rect
        x={rectX}
        y={rectY}
        width={rectWidth}
        height={rectHeight}
        fill={Color.tailwind.purple[400]}
        rx={5}
        z={50}
        style={{ zIndex: 888 }}
      />
      <Text
        x={rectX + rectWidth / 2}
        y={rectY + rectHeight / 2}
        fontSize={8}
        fontWeight="bold"
        fill="#fff"
        textAnchor="middle"
        dominantBaseline="central"
      >
        {label}
      </Text>
    </>
  );
};
