import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  FormControlLabel,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { RiPresentationFill } from "react-icons/ri";
import { FaEye, FaFileExcel, FaGlobeAmericas } from "react-icons/fa";
import { AiOutlineAppstoreAdd } from "react-icons/ai";

import { setStepIndex, setSteps, startTour } from "../../store/slices/tour";
import {
  resetKanbanFilters,
  setJoyRidePayload,
  updateKanbanFilters,
} from "../../store/slices/global";

import { Color, Constant, Fonts, Images } from "../../Helper";
import HelpOverlay from "../HelpOverlay.js";
// import ChartOptionsPopOver from "./ChartOptionsPopOver";
import { Emitter, remToPx } from "../../Helper/data";
import CardOptionsPopOver from "./CardOptionsPopOver";
import MonthSkipView from "./MonthSkipView";
import { toggleView } from "../../store/slices/board";
import Icon from "../Icon";
import useWidth from "../../hooks/useWidth";
import DSConnections from "../../Pages/Settings/DataSource/DSConnections";
import { setChartExapanded } from "../../store/slices/chart";
import CostUnitDropdown from "../Overlay/CostUnitDropdown";
import ScenarioDropdown from "../Overlay/ScenarioDropdown";
import TailwindButton from "../Overlay/TailwindButton";
import useStatusHook from "../../hooks/useStatusHook";
import ExportDialogView from "./ExportDialogView";

const BoardHeader = () => {
  const currentWidth = useWidth();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const childRef = useRef(null);
  const chartOptionRef = useRef(null);
  const cardOptionRef = useRef(null);
  let width = remToPx(currentWidth, 58.5);

  //redux
  const isFilterOpen = useSelector((state) => state.boardSlice?.isFilterOpen);
  const selectedStates = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.kanban?.selectedStates
  );
  const selectedScenarios = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.kanban?.selectedScenarios
  );
  const selectedCostUnits = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.kanban?.selectedCostUnits
  );
  const selectedCategory = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.kanban?.selectedCategory
  );
  const scenario = useSelector((state) => state.globalSlice?.scenario);
  const state = useSelector((state) => state.globalSlice?.state);
  const isKanbanView = useSelector((state) => state.boardSlice.isKanbanView);
  const beta_enabled = useSelector(
    (state) => state.settingsSlice?.profile?.beta_enabled
  );
  //state
  const [filtered, setFiltered] = useState([]);

  const xlg = useMediaQuery("(min-width:1080px)");
  const s1280 = useMediaQuery("(min-width:1280px)");
  const mid = useMediaQuery("(min-width:1536px)");
  const s1920 = useMediaQuery("(min-width:1920px)");

  if (xlg) {
    width = remToPx(currentWidth, 65);
  }
  if (s1280) {
    width = remToPx(currentWidth, 118);
  }
  if (mid) {
    width = remToPx(currentWidth, 125);
  }
  if (s1920) {
    width = remToPx(currentWidth, 130);
  }

  //lifecycle
  useEffect(() => {
    Emitter.on("Event_Close_Grouped_Options", () => {
      cardOptionRef.current.handleClose();
    });
    Emitter.on("Event_Open_Grouped_Options", (index = 11) => {
      cardOptionRef.current.openManualy();
      chartOptionRef.current.handleClose();
      childRef.current.handleClose();
      if (index) {
        setTimeout(() => {
          dispatch(setSteps({ key: "kanban", t }));
          dispatch(setStepIndex(index));
          dispatch(startTour());
        }, 500);
      }
    });
    Emitter.on("Event_Open_Chart_Options", () => {
      cardOptionRef.current.handleClose();
      childRef.current.handleClose();
      chartOptionRef.current.openManualy();
      setTimeout(() => {
        dispatch(setSteps({ key: "kanban", t }));
        dispatch(setStepIndex(16));
        dispatch(startTour());
      }, 500);
    });
    Emitter.on("Event_Open_Help_Overlay", () => {
      cardOptionRef.current.handleClose();
      chartOptionRef.current.handleClose();
      childRef.current.openManualy(true);
    });

    return () => {
      Emitter.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    let array = [];
    if (!isFilterOpen) {
      if (selectedStates?.length !== state.length) {
        array.push("state");
      }
      if (selectedScenarios?.length !== scenario.length) {
        array.push("scenario");
      }
      if (selectedCostUnits?.length > 0) {
        array.push("cost_unit");
      }
      if (selectedCategory?.length > 0) {
        array.push("category");
      }
      setFiltered(array);
    }
  }, [
    isFilterOpen,
    scenario.length,
    selectedCategory?.length,
    selectedCostUnits?.length,
    selectedScenarios?.length,
    selectedStates?.length,
    state.length,
  ]);

  //function
  const onClickRestart = () => {
    dispatch(resetKanbanFilters());
  };

  return (
    <Box
      display="flex"
      alignItems={"center"}
      // width={width}
      position="relative"
      zIndex={100}
      sx={{ mx: { xs: "2rem", md: "178px", s2133: "170px" } }}
    >
      {Constant?.isProd || !beta_enabled ? null : <CostUnitDropdown />}
      <MonthSkipView />

      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          width: "80%",
        }}
      >
        <ScenarioDropdown />

        <div
          style={{
            display: "inline-flex",
            position: "relative",
            alignItems: "center",
          }}
        >
          <Box
            display="inline-flex"
            alignItems="center"
            justifyContent={"flex-start"}
            backgroundColor={Color.tailwind.purple[200]}
            borderRadius={14}
            pr={isKanbanView ? "4px" : 0}
            sx={{
              position: "absolute",
              left: 0,
              top: "-1.5rem",
              zIndex: 2,
            }}
          >
            <TableSwitch />

            {isKanbanView ? <CardOptionsPopOver ref={cardOptionRef} /> : null}
          </Box>
        </div>
      </div>

      <Box
        display="inline-flex"
        alignItems="center"
        justifyContent={"flex-end"}
        minWidth={"50%"}
        width={"fit-content"}
      >
        <DemoData />
        {beta_enabled ? <ExportDialogView /> : null}

        {/* <ChartOptionsPopOver ref={chartOptionRef} /> */}
        <HelpOverlay
          ref={childRef}
          text={"liquidity_planning_insights_header_title"}
          supportView={
            <FormControlLabel
              color="primary"
              control={
                <RestartAltIcon
                  onClick={onClickRestart}
                  sx={{
                    mr: "1rem",
                    fontSize: "1.8rem",
                    mx: "0.5rem",
                  }}
                />
              }
              label={t("reset_filters")}
              sx={{ fontWeight: 300 }}
            />
          }
          hideDefault
          // tourKey={!isTable ? "kanban" : null}
          // path={!isTable ? "Kanban" : null}
          data={[
            {
              id: 1,
              image: Images.quick_start_insights,
              linkText: "liquidity_planning_insights_title_1",
              link: `https://finban.io/liquiditaetsplanung-mit-finban/`,
              description: "liquidity_planning_insights_des_1", //
            },
            {
              id: 2,
              image: Images.bank_integrations_insights,
              linkText: "liquidity_planning_insights_title_2",
              link: `https://finban.io/integrationen/`,
              description: "liquidity_planning_insights_des_2",
            },
            {
              id: 3,
              image: Images.open_booked_planned_insights,
              linkText: "liquidity_planning_insights_title_3",
              link: `https://finban.io/documentation/ist-zahlen-offene-posten-und-planwerte/`,
              description: "liquidity_planning_insights_des_3",
            },
          ]}
        />
      </Box>
    </Box>
  );
};
export default React.memo(BoardHeader);

const DemoData = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dsRef = useRef(null);
  const isAddAccount = useRef(null);
  let title = "";
  let icon = null;
  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );

  const DEMO = useMemo(() => {
    return datasetAccountList?.find((o1) => o1.title?.toLowerCase() === "demo");
  }, [datasetAccountList]);
  const accountDsList = useMemo(() => {
    return datasetAccountList?.filter((o1) => !o1.internal_dataset);
  }, [datasetAccountList]);

  //functions
  const onClickLink = async () => {
    if (isAddAccount.current) {
      dispatch(setJoyRidePayload({ key: "open_integration_overlay" }));
    } else {
      await dsRef.current?.checkDemoDsConnections();
    }
  };

  if (dataSetData?.title?.toLowerCase() === "demo" || DEMO) {
    title = t("Attention! Demo Data. Click here to remove");
    icon = (
      <Icon
        icon={<RiPresentationFill />}
        fontSize={"1.2rem"}
        color={Color.tailwind.yellow[700]}
      ></Icon>
    );
    isAddAccount.current = false;
  }
  if (accountDsList?.length === 0) {
    title = t("No Bank account / Integration assigned");
    icon = (
      <Icon
        icon={<AiOutlineAppstoreAdd />}
        fontSize={"1.2rem"}
        color={Color.tailwind.yellow[700]}
      ></Icon>
    );
    isAddAccount.current = true;
  }
  if (
    accountDsList?.length !== 0 &&
    (dataSetData?.title?.toLowerCase() !== "demo" || !DEMO)
  ) {
    return null;
  }
  return (
    <>
      <DSConnections ref={dsRef} />
      <Button
        onClick={onClickLink}
        variant="outlined"
        size="small"
        startIcon={icon}
        sx={{
          mr: "1rem",
          display: "flex",
          width: "fit-content",
          backgroundColor: Color.tailwind.yellow[300],
          color: Color.tailwind.yellow[700],
          borderColor: Color.tailwind.yellow[400],
          borderRadius: 1.5,
          p: "0.5rem 1rem",
          textTransform: "initial",
          fontSize: "0.7rem",
          fontFamily: Fonts.Text,
          zIndex: 1,
          "&:hover": {
            borderColor: Color.tailwind.yellow[400],
            backgroundColor: Color.tailwind.yellow[400],
          },
          "& svg": {
            fontSize: "0.8rem",
          },
        }}
      >
        {title}
      </Button>
    </>
  );
};

const TableSwitch = () => {
  const dispatch = useDispatch();
  const ToggleOptions = [
    {
      value: 2,
      label: "Table-View", //overview //default
      icon: <FaGlobeAmericas />,
    },
    {
      value: 1,
      label: "Card-View", //focus
      icon: <FaEye />,
    },
  ];

  const isKanbanView = useSelector((state) => state.boardSlice.isKanbanView);

  const onChange = () => {
    dispatch(toggleView());
  };

  useEffect(() => {
    if (Constant.isProd || Constant.isTest || Constant.isStag) {
      if (isKanbanView) {
        dispatch(toggleView());
      }
    }
  }, [dispatch, isKanbanView]);

  useEffect(() => {
    dispatch(updateKanbanFilters({ precisionValue: 1 }));
    dispatch(setChartExapanded(true));
  }, []);

  return null;
  // return (
  //   <ToggleTheme
  //     options={ToggleOptions}
  //     value={isKanbanView ? 1 : 2}
  //     onChange={onChange}
  //   />
  // );
};
