import React from "react";

import { formatValue } from "react-currency-input-field";
import { useSelector } from "react-redux";
import { format } from "date-fns";

const CustomizedAxisTick = React.memo((props) => {
  const {
    x,
    y,
    payload,
    dy = 0,
    dx = 0,
    formatted = false,
    formatDate,
    highlightValue,
    absoluteValue,
  } = props;

  const currencyFormate = useSelector(
    (state) => state.settingsSlice.currencyFormate
  );

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={dy}
        dx={dx}
        fontSize={"0.625rem"}
        fontWeight={highlightValue === payload?.value ? 900 : 500}
        textAnchor="middle"
        fill="#666"
        // transform="rotate(-90)"
      >
        {formatted
          ? formatValue({
              value: String(
                parseInt(
                  absoluteValue
                    ? Math.abs(payload?.value ?? 0)
                    : payload?.value ?? 0
                )
              ),
              groupSeparator: currencyFormate.groupSeparator,
              decimalSeparator: currencyFormate.decimalSeparator,
              prefix: currencyFormate.prefix,
            })
          : formatDate && payload?.value
          ? format(new Date(payload?.value), "MMM-yyyy")
          : payload?.value}
      </text>
    </g>
  );
});
export default CustomizedAxisTick;
