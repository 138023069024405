import {
  Box,
  CircularProgress,
  Radio,
  Typography,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import APICall from "../../../APICall";
import EndPoints from "../../../APICall/EndPoints";
import { Color } from "../../../Helper";
import { setStageLoadingText } from "../../../store/slices/appmain";
import {
  setIsAllHeaderApiFetched,
  setIsOpenSnackbar,
} from "../../../store/slices/common";
import {
  setAccounts,
  setAddFrom,
  setDataSource,
  setIsAccountSelectOverlayOpen,
} from "../../../store/slices/global";

import CustomModal from "../../../components/Model/CustomModal";
import { queryClient } from "../../../App";
import OnBoardingFunctions from "../../../components/Stepper/OnBoardingFunctions";

const AccountsSelectOverlay = ({ open }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const theme = useTheme();
  const onbRef = useRef(null);

  //redux
  const addFrom = useSelector((state) => state.globalSlice.addFrom);
  const profile = useSelector((state) => state.settingsSlice?.profile);

  //state
  const [dsLoading, setDsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [disableADD, setDisableADD] = useState(true);
  const [accountList, setAccountList] = useState([]);
  const type = "finapi";

  //life cycle method
  useEffect(() => {
    if (open && addFrom?.integration_id) {
      getAccountList(addFrom?.integration_id);
    }
  }, [addFrom?.integration_id, open]);

  //api
  const addAccount = async (obj, integration_id) => {
    setLoader(true);
    await APICall(
      "post",
      EndPoints.integrations + integration_id + `/${type}/accounts/`,
      obj
    ).then(async (response) => {
      if (response.status === 201 && response.data) {

        if (response?.data?.[0]?.uuid) {
          const account = await getAccountByID(response.data[0]?.uuid);
          if (account?.bank_details?.title) {
            await updateDataSourceByID(integration_id, {
              title: account?.bank_details?.title,
            });
          }
        }
        onbRef.current?.updateOnboardingList({
          isAccount: true,
          payload: {
            checked: true,
          },
        });
        enqueueSnackbar(t("New_Account_Added_Successfully"), {
          variant: "success",
          autoHideDuration: 2000,
        });
        setTimeout(() => {
          getAccountsApi(false);
          getDataSource(true); //necessary
        }, 0);
      } else {
        clearAddFrom();
      }
    });
  };

  const getAccountByID = async (id, obj) => {
    let data = null;
    await APICall("get", EndPoints.accounts + `${id}/`).then((response) => {
      if (response.status === 200 && response.data) {
        data = response.data;
      }
    });
    return data;
  };

  const updateDataSourceByID = async (id, obj) => {
    await APICall("patch", EndPoints.integrations + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
        }
      }
    );
  };

  const getDataSource = async (allowClose = false) => {
    await APICall("get", EndPoints.integrations).then((response) => {
      if (response.status === 200 && response.data) {
        dispatch(setDataSource([...response.data.results]));
        dispatch(setIsAllHeaderApiFetched(true));
        if (allowClose) {
          onClose(true);
        }
      }
    });
  };

  const getAccountsApi = async () => {
    await APICall("get", EndPoints.accounts).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setAccounts(data));
      }
    });
  };

  const getAccountList = async (integration_id) => {
    setDsLoading(true);
    setDisableADD(true);
    await APICall(
      "get",
      EndPoints.integrations + integration_id + `/${type}/accounts/`
    ).then((response) => {
      if (response.status === 200 && response.data) {
        const data = initSelect(response.data);
        setAccountList(data);
        setDsLoading(false);
      } else {
        dispatch(setIsOpenSnackbar(false));
      }
    });
  };

  //function
  const initSelect = (data, id = null) => {
    return data.map((item) => ({
      ...item,
      isSelected: id ? (id === item.id ? !item?.isSelected : false) : false,
    }));
  };

  const onAddAccount = () => {
    let selectedAccount = accountList?.find((o1) => o1.isSelected);
    let obj = {
      id: selectedAccount?.id,
    };
    if (selectedAccount) {
      addAccount(obj, addFrom?.integration_id);
    }
  };

  const onClickList = (item) => {
    let updatedItemList = accountList.map((element) => {
      if (element.id === item.id) {
        setDisableADD(false);
        return { ...element, isSelected: true };
      } else {
        return { ...element, isSelected: false };
      }
    });
    setAccountList(updatedItemList);
  };

  const handleClose = () => {
    clearAddFrom();
    onClose();
  };

  const onClose = (doReset = false) => {
    dispatch(setIsAccountSelectOverlayOpen(null));
    if (doReset) {
      resetKanban();
    }
  };

  const resetKanban = async () => {
    global.allowFetch = { Inflow: [], Outflow: [] };
    let options = {
      predicate: (query) => query.queryKey[0] === "transactions",
    };
    queryClient.resetQueries(options);
  };

  const clearAddFrom = () => {
    dispatch(setAddFrom(null));
    dispatch(setStageLoadingText(null));
    setLoader(false);
  };

  //render function
  const AccountsViewList = ({ item }) => {
    const theme = useTheme();
    return (
      <Box
        onClick={() => onClickList(item)}
        sx={{
          display: "inline-flex",
          alignItems: "center",
          cursor: "pointer",
          my: "0.5rem",
          borderRadius: 2,
          backgroundColor: item?.isSelected
            ? theme.palette.color.slate[100]
            : null,
          "&:hover": {
            backgroundColor: theme.palette.color.slate[50],
          },
        }}
      >
        <Radio
          edge="start"
          checked={item?.isSelected}
          tabIndex={-1}
          disableRipple
          sx={{ pl: "2rem" }}
        />
        <Typography
          variant="subtitle1"
          fontWeight={"fontWeightMedium"}
          color={"color.slate.500"}
          sx={{
            width: "20rem",
            alignItems: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {item?.name}
        </Typography>

        <Typography
          variant="subtitle1"
          fontWeight={"fontWeightMedium"}
          color={"color.slate.500"}
          sx={{
            width: "15rem",
            alignItems: "center",
          }}
        >
          {item?.type}
        </Typography>
        <Typography
          variant="subtitle1"
          fontWeight={"fontWeightMedium"}
          color={"color.slate.500"}
          sx={{
            width: "15rem",
            alignItems: "center",
          }}
        >
          {item?.iban}
        </Typography>
      </Box>
    );
  };

  return (
    <CustomModal
      addText={t("Add")}
      heading={t("Select_Account")}
      open={open}
      backdropClose={false}
      loadingAdd={loader}
      disabled={disableADD}
      onAdd={onAddAccount}
      onClose={handleClose}
      backdropStyle={{
        backdropFilter: "blur(6px)",
        WebkitBackdropFilter: "blur(6px)",
      }}
    >
      <OnBoardingFunctions ref={onbRef} />
      <Box
        sx={{
          backgroundColor: Color.BodyBG,
          borderBottomLeftRadius: theme.borderRadius.main,
          borderBottomRightRadius: theme.borderRadius.main,
          p: "2.5rem",
          display: "flex",
          flexDirection: "column",
          height: "50vh",
          width: "60rem",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            cursor: "pointer",
            minHeight: "2.5rem",
            borderRadius: 2,
          }}
        >
          <Typography
            variant="h6"
            component={"h6"}
            color="color.mainTitle"
            fontWeight={"fontWeightBold"}
            sx={{
              width: "20rem",
              minWidth: "0.063rem",
              alignItems: "center",
              ml: "3.25rem",
            }}
          >
            {t("Name")}
          </Typography>

          <Typography
            variant="h6"
            component={"h6"}
            color="color.mainTitle"
            fontWeight={"fontWeightBold"}
            sx={{
              width: "15rem",
              minWidth: "7.82rem",
              alignItems: "center",
            }}
          >
            {t("Type")}
          </Typography>
          <Typography
            variant="h6"
            component={"h6"}
            color="color.mainTitle"
            fontWeight={"fontWeightBold"}
            sx={{
              minWidth: "15rem",
              alignItems: "center",
            }}
          >
            {t("IBAN")}
          </Typography>
        </Box>
        {dsLoading ? (
          <Box
            sx={{
              flex: 1,
              pr: "1.5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress
              color="inherit"
              size={23}
              sx={{
                color: Color.theme.deepPurple[500],
              }}
            />
          </Box>
        ) : (
          accountList?.map((item, index) => {
            return (
              <AccountsViewList key={item?.id} item={item} index={index} />
            );
          })
        )}
      </Box>
    </CustomModal>
  );
};

export default AccountsSelectOverlay;
