import React, { memo, useCallback, useMemo, useState, useEffect } from "react";
import {
  Alert,
  alpha,
  Box,
  Link,
  Paper,
  Typography,
  useMediaQuery,
  Popper,
} from "@mui/material";
import { styled } from "@mui/styles";
import { Color, Fonts, Images } from "../../../Helper";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  subMonths,
  startOfMonth,
  endOfMonth,
  subYears,
  eachMonthOfInterval,
  isSameMonth,
  format,
} from "date-fns";
import { useTranslation } from "react-i18next";
import Placeholder from "../../../components/Placeholder";
import CostRatioReturnsCharts from "../../../components/Charts/CostRatioReturnsCharts";
import useWidth from "../../../hooks/useWidth";
import initialData, { isPlanExpired, remToPx } from "../../../Helper/data";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarnView from "../../../components/WarnView";
import { setWarnInfoOverlayStatus } from "../../../store/slices/settings";
const PREFIX = "Employess";
const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  table: `${PREFIX}-table`,
};

const Root = styled("div")(({ width, height }) => ({
  display: "flex",
  justifyContent: "center",
  [`& .${classes.paper}`]: {
    "& .css-mcts47-MuiPaper-root": {
      boxShadow:
        "0px 3px 1px -2px rgb(0 0 0 / 16%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    },
    borderRadius: "5px",
    backgroundColor: Color.white,
    overflowY: "auto",
    height: height,
    position: "relative",
    "& MuiDataGrid-virtualScroller": {
      width: width,
    },
    "& .super-app-theme--cell": {
      backgroundColor: alpha(Color.headerIconLight, 0.3),
    },
  },
  [`& .${classes.table}`]: {
    border: 0,
    "& .MuiDataGrid-columnHeaderTitle": {
      fontSize: "0.8rem",
      fontWeight: 600,
    },
  },
}));

const CostRatioReturns = () => {
  let currentDate = useMemo(() => new Date(), []);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentWidth = useWidth();
  const s1745 = useMediaQuery("(min-width:1745px)");
  let width = remToPx(currentWidth, s1745 ? 125 : 115);
  let height = remToPx(currentWidth, s1745 ? 43.74 : 44.15);
  //redux
  const currencyFormate = useSelector(
    (state) => state.settingsSlice.currencyFormate
  );
  const selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );
  const isFetching = useSelector((state) => state.appSlice.isFetching);
  const monthlyTransactions = useSelector(
    (state) => state.datasetSlice?.monthlyTransactions
  );
  const subscription = useSelector(
    (state) => state.settingsSlice?.subscription
  );
  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  //states
  const [showInfo, setShowInfo] = useState(false);
  const [warnFor, setWarnfor] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rows, setRows] = useState([]);

  const [chartData, setChartData] = useState([]);

  const planExpired = useMemo(() => {
    return isPlanExpired(subscription);
  }, [subscription]);

  const variableCategoriesId = useMemo(() => {
    let filters = selectionCategories?.filter(
      (o1) => o1.type === 2 && o1.type_of_cost === "variable"
    );
    return filters.map((o1) => o1.value);
  }, [selectionCategories]);

  const fixCategoriesId = useMemo(() => {
    let filters = selectionCategories?.filter(
      (o1) => o1.type === 2 && o1.type_of_cost === "fix"
    );
    return filters.map((o1) => o1.value);
  }, [selectionCategories]);

  const totalRevenue = useMemo(() => {
    let total = monthlyTransactions
      ?.filter(
        (o1) =>
          o1.month && !initialData?.calculationExcludeStates?.includes(o1.state)
      )
      .reduce(
        (total, item) => parseFloat(total) + parseFloat(item?.inflow ?? 0),
        0
      );
    return total;
  }, [monthlyTransactions]);

  let data = useMemo(() => {
    let earliestMonth = monthlyTransactions[0]?.month
      ? new Date(monthlyTransactions[0]?.month)
      : null;
    let icon = false;
    setWarnfor(null);
    let start_date = appliedFilterlist?.reports?.report?.start_date;
    let prev_start_date = appliedFilterlist?.reports?.report?.start_date
      ? subYears(new Date(appliedFilterlist?.reports?.report?.start_date), 1)
      : null;

    if (start_date && earliestMonth && prev_start_date) {
      if (
        format(new Date(earliestMonth), "yyyy-MM") >
          format(new Date(start_date), "yyyy-MM") &&
        format(new Date(earliestMonth), "yyyy") ===
          format(new Date(start_date), "yyyy")
      ) {
        icon = true;
        setWarnfor("currentyear");
      }
      if (
        format(new Date(earliestMonth), "yyyy-MM") ===
        format(new Date(start_date), "yyyy-MM")
      ) {
        icon = true;
        setWarnfor("currentyear");
      }
      if (
        format(new Date(earliestMonth), "yyyy-MM") <
          format(new Date(start_date), "yyyy-MM") &&
        format(new Date(earliestMonth), "yyyy-MM") >=
          format(new Date(prev_start_date), "yyyy-MM")
      ) {
        icon = true;
        setWarnfor("lastyear");
      }
    }
    return [
      {
        id: 1,
        title: "Revenue",
        key: "Revenue",
        total: "Total",
        icon: icon,
        description: t("Cost_Ratio_Revenue_description"),
      },
      {
        id: 2,
        title: "Variable Cost",
        key: "VariableCost",
        total: "Total",
        description: t("Cost_Ratio_VariableCost_description"),
      },
      {
        id: 3,
        title: "Gross Profit",
        key: "GrossProfit",
        total: "Total",
        description: t("Cost_Ratio_GrossProfit_description"),
      },
      {
        id: 4,
        title: "Fix Costs",
        key: "FixCosts",
        total: "Total",
        info: true,
        description: t("Cost_Ratio_FixCosts_description"),
      },
      {
        id: 5,
        title: "Return On sales",
        key: "OperatingIncome",
        total: "Total",
        description: t("Cost_Ratio_OperatingIncome_description"),
      },
    ];
  }, [
    appliedFilterlist?.reports?.report?.start_date,
    monthlyTransactions,
    t,
  ]);
  let key = ["currentYear", "preYear", "longGrowth", "preMonth", "shortGrowth"];
  //data
  const Column = useMemo(
    () => [
      {
        field: "title",
        cellClassName: "super-app-theme--cell",
        width: width * 0.7 * 0.175,
        editable: false,
        sortable: false,
        renderHeader: (params) => headerCell(params?.colDef),
        renderCell: (params) => {
          return (
            <div
              style={{
                display: "inline-flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <span
                style={{
                  textAlign: "left",
                  color: Color.theme.blueGrey[900],
                  fontWeight: 500,
                  fontFamily: Fonts?.Text,
                  fontSize: "1rem",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "pre",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {params?.value ? params?.value : ""}{" "}
                {params?.row?.icon ? (
                  <div onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
                    <ErrorOutlineIcon
                      sx={{
                        fontSize: "1.1rem",
                        cursor: "pointer",
                        color: Color.theme.orange[500],
                        mt: "0.3rem",
                        ml: "0.3rem",
                      }}
                    />
                  </div>
                ) : null}
                {params?.row?.info && showInfo ? (
                  <WarnView
                    showInfo={showInfo}
                    warnKey={"cost_ratio_warning_uncategorized"}
                    infoKey={"cost_ratio_Info_no_enough_data"}
                    iconStyle={{ mt: "0.4rem", ml: "0.3rem" }}
                  />
                ) : null}
              </span>
            </div>
          );
        },
      },
      {
        field: "currentYear",
        headerName: "Financial year",
        subTitle: "01.01.year - 31.12.year",
        cellClassName: "super-app-theme--cell",
        width: width * 0.7 * 0.14,
        editable: false,
        sortable: false,
        renderHeader: (params) => headerCell(params?.colDef),
        renderCell: (params) => {
          let number = parseFloat(params?.value).toFixed(1);
          let value = number > 0 ? `${number} %` : `${number} %`;

          return (
            <span
              style={{
                color: Color.theme.blueGrey[900],
                fontWeight: 600,
                fontFamily: Fonts?.Text,
                fontSize: "0.9rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "pre",
                display: "inline-flex",
                flexDirection: "column",
              }}
            >
              {params?.value || params?.value === 0 ? value : ""}
            </span>
          );
        },
      },
      {
        field: "preYear",
        headerName: "Previous year",
        subTitle: "01.01.year - 31.12.year",
        cellClassName: "super-app-theme--cell",
        width: width * 0.7 * 0.12,
        editable: false,
        sortable: false,
        renderHeader: (params) => headerCell(params?.colDef),
        renderCell: (params) => {
          let number = parseFloat(params?.value).toFixed(1);
          let value = number > 0 ? `${number} %` : `${number} %`;

          return (
            <span
              style={{
                color: Color.theme.blueGrey[900],
                fontWeight: 600,
                fontFamily: Fonts?.Text,
                fontSize: "0.9rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "pre",
                display: "inline-flex",
                flexDirection: "column",
              }}
            >
              {params?.value || params?.value === 0 ? value : ""}
            </span>
          );
        },
      },
      {
        field: "longGrowth",
        headerName: "Growth in the long term",
        subTitle: "Current year compared to previous year",
        cellClassName: "super-app-theme--cell",
        width: width * 0.135,
        editable: false,
        sortable: false,
        renderHeader: (params) => headerCell(params?.colDef),
        valueGetter: ({ value }) => parseFloat(value),
        renderCell: (params) => {
          let number = parseFloat(params?.value).toFixed(1);
          let value = number > 0 ? `+${number} %` : `${number} %`;

          return (
            <span
              style={{
                color: Color.theme.green[500],
                fontWeight: 600,
                fontFamily: Fonts?.Text,
                fontSize: "0.9rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "pre",
                display: "inline-flex",
                flexDirection: "column",
              }}
            >
              {params?.value || params?.value === 0 ? value : ""}
            </span>
          );
        },
      },
      {
        field: "preMonth",
        headerName: "Previous Month",
        subTitle: "01.01.year - 31.12.year",
        cellClassName: "super-app-theme--cell",
        width: width * 0.7 * 0.13,
        editable: false,
        sortable: false,
        type: "text",
        renderHeader: (params) => headerCell(params?.colDef),
        renderCell: (params) => {
          let number = parseFloat(params?.value).toFixed(1);
          let value = number > 0 ? `${number} %` : `${number} %`;

          return (
            <span
              style={{
                color: Color.theme.blueGrey[900],
                fontWeight: 600,
                fontFamily: Fonts?.Text,
                fontSize: "0.9rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "pre",
                display: "inline-flex",
                flexDirection: "column",
              }}
            >
              {params?.value || params?.value === 0 ? value : ""}
            </span>
          );
        },
      },
      {
        field: "shortGrowth",
        headerName: "Growth short-term",
        subTitle: "3-month average compared with prior-year period",
        cellClassName: "super-app-theme--cell",
        width: width * 0.7 * 0.24,
        editable: false,
        sortable: false,
        renderHeader: (params) => headerCell(params?.colDef),
        valueGetter: ({ value }) => parseFloat(value),
        renderCell: (params) => {
          let number = parseFloat(params?.value).toFixed(1);
          let value = number > 0 ? `+${number} %` : `${number} %`;
          return (
            <span
              style={{
                color: Color.theme.green[500],
                fontWeight: 600,
                fontFamily: Fonts?.Text,
                fontSize: "0.9rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "pre",
                display: "inline-flex",
                flexDirection: "column",
              }}
            >
              {params?.value || params?.value === 0 ? value : ""}
            </span>
          );
        },
      },
    ],
    [
      width,
      showInfo,
      appliedFilterlist?.reports?.report?.start_date,
      appliedFilterlist?.reports?.report?.end_date,
    ]
  );

  useEffect(() => {
    if (
      subscription?.cost_ratio_returns_view &&
      isAllHeaderApiFetched &&
      !isFetching &&
      !planExpired &&
      variableCategoriesId &&
      fixCategoriesId &&
      appliedFilterlist?.reports?.report &&
      appliedFilterlist?.reports?.report?.start_date &&
      appliedFilterlist?.reports?.report?.end_date
    ) {
      let yearGrowthObj = null;
      key.forEach((element, index) => {
        let obj = null;
        if (index === 0) {
          obj = getCurrentYear(totalRevenue);
          yearGrowthObj = {
            ...yearGrowthObj,
            current: obj,
          };
        }
        if (index === 1) {
          obj = getPreYear(totalRevenue);
          yearGrowthObj = {
            ...yearGrowthObj,
            prev: obj,
          };
        }
        if (index === 2) {
          obj = getYearDifference(yearGrowthObj);
        }
        if (index === 3) {
          obj = getPrevMonthData(totalRevenue);
        }
        if (index === 4) {
          obj = get3MonthShare(totalRevenue);
        }
        if (obj) {
          data.forEach((item) => {
            item[element] = obj[item?.key];
          });
        }
      });
      getChartData();
    }
    setRows(data);
  }, [
    totalRevenue,
    monthlyTransactions,
    currencyFormate,
    variableCategoriesId,
    fixCategoriesId,
    appliedFilterlist?.reports?.report,
    planExpired,
    isAllHeaderApiFetched,
    isFetching,
    subscription?.cost_ratio_returns_view,
  ]);

  //function
  const getPercentageChange = (oldNumber, newNumber) => {
    var decreaseValue = parseFloat(newNumber) - parseFloat(oldNumber);
    let divide = 1;
    divide =
      oldNumber === 0
        ? parseFloat(newNumber) < 0
          ? -1
          : 1
        : parseFloat(oldNumber);
    return ((Math.abs(decreaseValue) / divide) * 100.0).toFixed(1);
  };

  const getGrowth = (prevPer = 0, current = 0) => {
    let Growth = 100;
    if (prevPer !== 0) {
      Growth = getPercentageChange(prevPer, current);
    }
    if ((prevPer === 0 && current === 0) || current === 0) {
      Growth = 0;
    }
    return Growth;
  };

  const getCurrentYear = useCallback(
    (totalRevenue) => {
      let { selectedState, selectedScenarios, start_date, end_date } =
        appliedFilterlist?.reports?.report;
      const currentYearStartDate = start_date;
      const currentYearEndDate = end_date;
      let CurrentYearData = monthlyTransactions?.filter(
        (o1) =>
          o1.month &&
          !initialData?.calculationExcludeStates?.includes(o1.state) &&
          selectedState?.some((o2) => o2 === o1.state) &&
          selectedScenarios?.some((o2) => o2 === o1.scenario) &&
          new Date(o1.month) >= new Date(currentYearStartDate) &&
          new Date(o1.month) <= new Date(currentYearEndDate)
      );
      let variableData = CurrentYearData?.filter((o1) =>
        variableCategoriesId.some((o2) => o2 === o1.category)
      );
      let fixData = CurrentYearData?.filter((o1) =>
        fixCategoriesId.some((o2) => o2 === o1.category)
      );
      let Revenue = CurrentYearData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.inflow ?? 0)),
        0
      );
      let Expense = CurrentYearData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
        0
      );
      let VariableCost = variableData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
        0
      );
      let FixCosts = fixData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
        0
      );
      let GrossProfit = Revenue - VariableCost;
      let OperatingIncome = Revenue - Expense;

      let Revenue_Growth = getGrowth(totalRevenue, Revenue);
      let VariableCost_Growth = getGrowth(totalRevenue, VariableCost);
      let FixCosts_Growth = getGrowth(totalRevenue, FixCosts);
      let GrossProfit_Growth = getGrowth(totalRevenue, GrossProfit);
      let OperatingIncome_Growth = getGrowth(totalRevenue, OperatingIncome);
      let obj = {
        Revenue_orignal: Revenue,
        VariableCost_orignal: VariableCost,
        FixCosts_orignal: FixCosts,
        GrossProfit_orignal: GrossProfit,
        OperatingIncome_orignal: OperatingIncome,
        Revenue: Revenue_Growth,
        VariableCost: VariableCost_Growth,
        FixCosts: FixCosts_Growth,
        GrossProfit: GrossProfit_Growth,
        OperatingIncome: OperatingIncome_Growth,
      };

      return obj;
    },
    [
      totalRevenue,
      appliedFilterlist?.reports?.report,
      monthlyTransactions,
      variableCategoriesId,
      fixCategoriesId,
    ]
  );

  const getPreYear = useCallback(
    (totalRevenue) => {
      let { selectedState, selectedScenarios, start_date, end_date } =
        appliedFilterlist?.reports?.report;
      const preYearStartDate = subYears(new Date(start_date), 1);
      const preYearEndDate = subYears(new Date(end_date), 1);

      let CurrentYearData = monthlyTransactions?.filter(
        (o1) =>
          o1.month &&
          !initialData?.calculationExcludeStates?.includes(o1.state) &&
          selectedState?.some((o2) => o2 === o1.state) &&
          selectedScenarios?.some((o2) => o2 === o1.scenario) &&
          new Date(o1.month) >= new Date(preYearStartDate) &&
          new Date(o1.month) <= new Date(preYearEndDate)
      );
      let variableData = CurrentYearData?.filter((o1) =>
        variableCategoriesId.some((o2) => o2 === o1.category)
      );
      let fixData = CurrentYearData?.filter((o1) =>
        fixCategoriesId.some((o2) => o2 === o1.category)
      );
      let Revenue = CurrentYearData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.inflow ?? 0)),
        0
      );
      let Expense = CurrentYearData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
        0
      );
      let VariableCost = variableData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
        0
      );
      let FixCosts = fixData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
        0
      );
      let GrossProfit = Revenue - VariableCost;
      let OperatingIncome = Revenue - Expense;
      let Revenue_Growth = getGrowth(totalRevenue, Revenue);
      let VariableCost_Growth = getGrowth(totalRevenue, VariableCost);
      let FixCosts_Growth = getGrowth(totalRevenue, FixCosts);
      let GrossProfit_Growth = getGrowth(totalRevenue, GrossProfit);
      let OperatingIncome_Growth = getGrowth(totalRevenue, OperatingIncome);
      let obj = {
        Revenue_orignal: Revenue,
        VariableCost_orignal: VariableCost,
        FixCosts_orignal: FixCosts,
        GrossProfit_orignal: GrossProfit,
        OperatingIncome_orignal: OperatingIncome,
        Revenue: Revenue_Growth,
        VariableCost: VariableCost_Growth,
        FixCosts: FixCosts_Growth,
        GrossProfit: GrossProfit_Growth,
        OperatingIncome: OperatingIncome_Growth,
      };
      return obj;
    },
    [
      totalRevenue,
      appliedFilterlist?.reports?.report,
      monthlyTransactions,
      variableCategoriesId,
      fixCategoriesId,
    ]
  );

  const getYearDifference = ({ current, prev }) => {
    let Revenue = getGrowth(
      Number(prev?.Revenue_orignal) / 12,
      Number(current?.Revenue_orignal) / 12
    );
    let VariableCost = getGrowth(
      Number(prev?.VariableCost_orignal) / 12,
      Number(current?.VariableCost_orignal) / 12
    );
    let FixCosts = getGrowth(
      Number(prev?.FixCosts_orignal) / 12,
      Number(current?.FixCosts_orignal) / 12
    );
    let GrossProfit = getGrowth(
      Number(prev?.GrossProfit_orignal) / 12,
      Number(current?.GrossProfit_orignal) / 12
    );
    let OperatingIncome = getGrowth(
      Number(prev?.OperatingIncome_orignal) / 12,
      Number(current?.OperatingIncome_orignal) / 12
    );

    let obj = {
      Revenue,
      VariableCost,
      FixCosts,
      GrossProfit,
      OperatingIncome,
    };

    return obj;
  };

  const getPrevMonthData = useCallback(
    (totalRevenue) => {
      const result = subMonths(currentDate, 1);
      const prevMonthStartDate = startOfMonth(result);
      const prevMonthEndDate = endOfMonth(result);
      let { selectedState, selectedScenarios } =
        appliedFilterlist?.reports?.report;
      let prevMonthData = monthlyTransactions?.filter(
        (o1) =>
          o1.month &&
          !initialData?.calculationExcludeStates?.includes(o1.state) &&
          selectedState?.some((o2) => o2 === o1.state) &&
          selectedScenarios?.some((o2) => o2 === o1.scenario) &&
          new Date(o1.month) >= new Date(prevMonthStartDate) &&
          new Date(o1.month) <= new Date(prevMonthEndDate)
      );
      let variableData = prevMonthData?.filter((o1) =>
        variableCategoriesId.some((o2) => o2 === o1.category)
      );
      let fixData = prevMonthData?.filter((o1) =>
        fixCategoriesId.some((o2) => o2 === o1.category)
      );
      let Revenue = prevMonthData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.inflow ?? 0)),
        0
      );
      let Expense = prevMonthData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
        0
      );
      let VariableCost = variableData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
        0
      );
      let FixCosts = fixData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
        0
      );
      let GrossProfit = Revenue - VariableCost;
      let OperatingIncome = Revenue - Expense;

      let Revenue_Growth = getGrowth(totalRevenue, Revenue);
      let VariableCost_Growth = getGrowth(totalRevenue, VariableCost);
      let FixCosts_Growth = getGrowth(totalRevenue, FixCosts);
      let GrossProfit_Growth = getGrowth(totalRevenue, GrossProfit);
      let OperatingIncome_Growth = getGrowth(totalRevenue, OperatingIncome);
      let obj = {
        Revenue: Revenue_Growth,
        VariableCost: VariableCost_Growth,
        FixCosts: FixCosts_Growth,
        GrossProfit: GrossProfit_Growth,
        OperatingIncome: OperatingIncome_Growth,
      };
      return obj;
    },
    [
      totalRevenue,
      appliedFilterlist?.reports?.report?.selectedState,
      appliedFilterlist?.reports?.report?.selectedScenarios,
      monthlyTransactions,
      variableCategoriesId,
      fixCategoriesId,
    ]
  );

  const get3MonthShare = useCallback(
    (totalRevenue) => {
      const result = subMonths(currentDate, 3);
      const prev3MonthStartDate = startOfMonth(result);
      const prevPer3MonthStartDate = subYears(prev3MonthStartDate, 1);
      const endDate = endOfMonth(currentDate);
      const pervPerEndDate = subYears(endDate, 1);
      let { selectedState, selectedScenarios } =
        appliedFilterlist?.reports?.report;
      let current3MonthData = monthlyTransactions?.filter(
        (o1) =>
          o1.month &&
          !initialData?.calculationExcludeStates?.includes(o1.state) &&
          selectedState?.some((o2) => o2 === o1.state) &&
          selectedScenarios?.some((o2) => o2 === o1.scenario) &&
          new Date(o1.month) >= new Date(prev3MonthStartDate) &&
          new Date(o1.month) <= new Date(endDate)
      );
      let prevPer3MonthData = monthlyTransactions?.filter(
        (o1) =>
          o1.month &&
          !initialData?.calculationExcludeStates?.includes(o1.state) &&
          selectedState?.some((o2) => o2 === o1.state) &&
          selectedScenarios?.some((o2) => o2 === o1.scenario) &&
          new Date(o1.month) >= new Date(prevPer3MonthStartDate) &&
          new Date(o1.month) <= new Date(pervPerEndDate)
      );
      let currentVariableData = current3MonthData?.filter((o1) =>
        variableCategoriesId.some((o2) => o2 === o1.category)
      );
      let currentFixData = current3MonthData?.filter((o1) =>
        fixCategoriesId.some((o2) => o2 === o1.category)
      );
      let prePerVariableData = prevPer3MonthData?.filter((o1) =>
        variableCategoriesId.some((o2) => o2 === o1.category)
      );
      let prePerFixData = prevPer3MonthData?.filter((o1) =>
        fixCategoriesId.some((o2) => o2 === o1.category)
      );
      let currentRevenue =
        current3MonthData?.reduce(
          (total, item) =>
            parseFloat(total) + parseFloat(Math.abs(item?.inflow ?? 0)),
          0
        ) / 3;
      let prevPerRevenue =
        prevPer3MonthData?.reduce(
          (total, item) =>
            parseFloat(total) + parseFloat(Math.abs(item?.inflow ?? 0)),
          0
        ) / 3;
      let currentExpense =
        current3MonthData?.reduce(
          (total, item) => parseFloat(total) + parseFloat(item?.outflow ?? 0),
          0
        ) / 3;
      let prevPerExpense =
        prevPer3MonthData?.reduce(
          (total, item) => parseFloat(total) + parseFloat(item?.outflow ?? 0),
          0
        ) / 3;
      let currentVariableCost =
        currentVariableData?.reduce(
          (total, item) =>
            parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
          0
        ) / 3;
      let currentFixCosts =
        currentFixData?.reduce(
          (total, item) =>
            parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
          0
        ) / 3;
      let prePerVariableCost =
        prePerVariableData?.reduce(
          (total, item) =>
            parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
          0
        ) / 3;
      let prePerFixCosts =
        prePerFixData?.reduce(
          (total, item) =>
            parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
          0
        ) / 3;

      let Revenue = getGrowth(prevPerRevenue, currentRevenue);
      let VariableCost = getGrowth(prePerVariableCost, currentVariableCost);
      let FixCosts = getGrowth(prePerFixCosts, currentFixCosts);
      let currentGrossProfit = currentRevenue - currentVariableCost;
      let prePerGrossProfit = prevPerRevenue - prePerVariableCost;
      let GrossProfit = getGrowth(prePerGrossProfit, currentGrossProfit);
      let currentOperatingIncome = currentRevenue - currentExpense;
      let prePerOperatingIncome = prevPerRevenue - prevPerExpense;
      let OperatingIncome = getGrowth(
        prePerOperatingIncome,
        currentOperatingIncome
      );
      let obj = {
        Revenue,
        VariableCost,
        FixCosts,
        GrossProfit,
        OperatingIncome,
      };
      return obj;
    },
    [
      totalRevenue,
      appliedFilterlist?.reports?.report?.selectedState,
      appliedFilterlist?.reports?.report?.selectedScenarios,
      monthlyTransactions,
      variableCategoriesId,
      fixCategoriesId,
    ]
  );

  const getChartData = useCallback(() => {
    let { selectedState, selectedScenarios, start_date, end_date } =
      appliedFilterlist?.reports?.report;
    let last12M_start_date = new Date(start_date);
    let last12M_end_date = new Date(end_date);
    let preYear_start_date = new Date(subYears(last12M_start_date, 1));
    let filterData = monthlyTransactions?.filter(
      (o1) =>
        o1.month &&
        !initialData?.calculationExcludeStates?.includes(o1.state) &&
        selectedState?.some((o2) => o2 === o1.state) &&
        selectedScenarios?.some((o2) => o2 === o1.scenario) &&
        new Date(o1.month) >= new Date(preYear_start_date) &&
        new Date(o1.month) <= new Date(last12M_end_date)
    );
    const result = eachMonthOfInterval({
      start: last12M_start_date,
      end: last12M_end_date,
    });
    let dummy = [];
    result.forEach((element, index) => {
      let currentData = filterData?.filter((o1) =>
        isSameMonth(new Date(o1.month), element)
      );
      let currentVariableData = currentData?.filter((o1) =>
        variableCategoriesId.some((o2) => o2 === o1.category)
      );
      let currentFixData = currentData?.filter((o1) =>
        fixCategoriesId.some((o2) => o2 === o1.category)
      );
      let currentRevenue = currentData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.inflow ?? 0)),
        0
      );
      let currentExpense = currentData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
        0
      );
      let currentVariableCost = currentVariableData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
        0
      );
      let currentFixCosts = currentFixData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
        0
      );

      let currentOperatingIncome = currentRevenue - currentExpense;
      let VariableCost_Growth = getGrowth(totalRevenue, currentVariableCost);
      let FixCosts_Growth = getGrowth(totalRevenue, currentFixCosts);
      let OperatingIncome_Growth = getGrowth(
        totalRevenue,
        currentOperatingIncome
      );

      dummy[index] = {
        ...dummy[index],
        id: index,
        due_date_12M: format(element, "MM-yyyy"),
        [`VariableCost_12M`]: Number(VariableCost_Growth),
        [`FixCosts_12M`]: Number(FixCosts_Growth),
        [`OperatingIncome_12M`]: Number(OperatingIncome_Growth),
      };
    });
    let fix = filterData?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          fixCategoriesId?.includes(item.category)
            ? item?.outflow_count ?? 0
            : 0
        ),
      0
    );
    let variable = filterData?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          variableCategoriesId?.includes(item.category)
            ? item?.outflow_count ?? 0
            : 0
        ),
      0
    );
    let revenue = filterData?.reduce(
      (total, item) =>
        parseFloat(total) + parseFloat(Math.abs(item?.inflow_count ?? 0)),
      0
    );
    if (monthlyTransactions?.length === 0) {
      dispatch(
        setWarnInfoOverlayStatus({
          key: new Date(),
          errorKey: "common_content_no_transctions",
          type: "error",
          uiType: "alert",
        })
      );
    } else {
      dispatch(setWarnInfoOverlayStatus(null));
    }
    if (
      monthlyTransactions?.length !== 0 &&
      (fix === 0 || variable === 0 || revenue === 0)
    ) {
      dispatch(
        setWarnInfoOverlayStatus({
          key: new Date(),
          warnKey: "cost_ratio_warning_uncategorized",
          type: "warning",
          uiType: "alert",
        })
      );
    }
    let uncategorized = monthlyTransactions
      ?.filter(
        (o1) =>
          !o1.category &&
          !initialData?.calculationExcludeStates?.includes(o1.state)
      )
      ?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(item?.inflow_count ?? 0) +
          parseFloat(item?.outflow_count ?? 0),
        0
      );
    if (uncategorized > 10) {
      setShowInfo("info");
    }
    if (
      monthlyTransactions?.length !== 0 &&
      uncategorized < 10 &&
      fix !== 0 &&
      variable !== 0 &&
      revenue !== 0
    ) {
      setShowInfo(false);
    }

    setChartData(dummy);
  }, [
    appliedFilterlist?.reports?.report,
    variableCategoriesId,
    fixCategoriesId,
    totalRevenue,
    monthlyTransactions,
  ]);

  const onMouseEnter = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const onMouseLeave = () => {
    setAnchorEl(null);
  };

  if (
    !appliedFilterlist?.reports?.report?.start_date ||
    !appliedFilterlist?.reports?.report?.end_date
  ) {
    return null;
  }
  //RenderFunction
  const headerCell = (props) => {
    const { headerName: title, subTitle, field } = props;
    let value = subTitle;

    if (field === "preMonth") {
      const result = subMonths(currentDate, 1);
      const prevMonthStartDate = format(startOfMonth(result), "dd.MM.yyyy");
      const prevMonthEndDate = format(endOfMonth(result), "dd.MM.yyyy");
      value = `${prevMonthStartDate} - ${prevMonthEndDate}`;
    }
    if (field === "currentYear") {
      const currentYearStartDate = format(
        new Date(appliedFilterlist?.reports?.report?.start_date),
        "dd.MM.yyyy"
      );
      const currentYearEndDate = format(
        new Date(appliedFilterlist?.reports?.report?.end_date),
        "dd.MM.yyyy"
      );
      value = `${currentYearStartDate} - ${currentYearEndDate}`;
    }
    if (field === "preYear") {
      const preYearStartDate = format(
        subYears(new Date(appliedFilterlist?.reports?.report?.start_date), 1),
        "dd.MM.yyyy"
      );
      const preYearEndDate = format(
        subYears(new Date(appliedFilterlist?.reports?.report?.end_date), 1),
        "dd.MM.yyyy"
      );
      value = `${preYearStartDate} - ${preYearEndDate}`;
    }
    return (
      <Typography
        component="div"
        variant="h6"
        sx={{
          width: "15%",
          color: Color?.blueGrey800,
          fontFamily: Fonts?.Text,
          fontSize: "0.9rem",
          fontweight: 500,
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
        }}
      >
        {title}
        <Typography
          component="span"
          variant="span"
          sx={{
            color: Color?.grey500,
            fontFamily: Fonts?.Text,
            fontSize: ".7rem",
            fontweight: 400,
          }}
        >
          {value}
        </Typography>
      </Typography>
    );
  };

  const ChartIndicator = ({ title, color = Color.black, type = "solid" }) => {
    return (
      <Typography sx={{ display: "flex", flexDirection: "column", mx: "1rem" }}>
        {title}
        <span
          style={{
            height: "0.15625rem",
            width: "100%",
            borderBottom: `3px ${type} ${color}`,
          }}
        />
      </Typography>
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  return (
    <Root width={width} height={height}>
      <Popper
        key={id}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="right"
        modifiers={[
          {
            name: "arrow",
            enabled: true,
          },
        ]}
      >
        <Alert severity="warning">
          {warnFor === "lastyear"
            ? "The earliest transaction is within the last year - the calculation may be inaccurate."
            : warnFor === "currentyear"
            ? "The earliest transaction is within this year - the calculation may be inaccurate."
            : ""}
        </Alert>
      </Popper>

      <Box sx={{ width: width }}>
        {!subscription?.cost_ratio_returns_view ? (
          <Placeholder
            image={Images.cost_ratio_placeholder}
            insightText={"Cost Ratios & Returns"}
            // description={
            //   subscription?.cost_ratio_returns_view && showInfo ? (
            //     <span>
            //       {" "}
            //       We cannot show any interesting here - we need more data.
            //       Please follow our
            //       <Link
            //         color="inherit"
            //         href={
            //           "https://finban.io/documentation/reports/setup-cost-ratio-returns"
            //         }
            //         target="_blank"
            //         sx={{
            //           fontFamily: Fonts.Text,
            //           color: Color.themeColor2,
            //           ml: "0.5rem",
            //         }}
            //       >
            //         instructions.
            //       </Link>
            //     </span>
            //   ) : null
            // }
            height={height + remToPx(currentWidth, 6.25)}
            width={width}
            style={{
              mt: "1.5rem",
            }}
          />
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                flex: 1,
                width: width,
                justifyContent: "space-between",
              }}
            >
              <Paper
                className={classes.paper}
                sx={{
                  width: "70%",
                }}
              >
                <DataGrid
                  className={classes.table}
                  rows={rows}
                  columns={Column}
                  rowHeight={remToPx(currentWidth, 7.885)}
                  disableColumnMenu
                  disableSelectionOnClick
                  hideFooter
                />
              </Paper>

              <Box
                sx={{
                  height: height,
                  width: "30%",
                  border: `2px solid ${Color.theme.grey[200]}`,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    height: 53,
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <ChartIndicator title={"Last 12 Month"} />
                  <ChartIndicator
                    title={""}
                    color={"transparent"}
                    type={"dotted"}
                  />
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    height: height - 56,
                    borderTop: `1px solid ${Color.theme.grey[200]}`,
                    display: "flex",
                  }}
                >
                  <CostRatioReturnsCharts data={chartData} height={height} />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Root>
  );
};

export default memo(CostRatioReturns);
