import React, { useCallback, useRef } from "react";
import { WebFormLanguage, load } from "@finapi/web-form";
import { useDispatch, useSelector } from "react-redux";
import {
  setAccounts,
  setAddFrom,
  setDataSource,
  setIsAccountSelectOverlayOpen,
} from "../../../store/slices/global";
import { setStageLoadingText } from "../../../store/slices/appmain";
import APICall from "../../../APICall";
import EndPoints from "../../../APICall/EndPoints";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { queryClient } from "../../../App";
import DSConnections from "./DSConnections";
import { setIsAllHeaderApiFetched } from "../../../store/slices/common";
import { Box } from "@mui/material";

function FinApiWebForm({
  token,
  language,
}: {
  token: string;
  language: string;
}) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dsRef = useRef({
    fetchAllTransaction: (p: any) => {},
    fetchNewTransaction: (p: any) => {},
  });

  const addFrom = useSelector((state: any) => state.globalSlice.addFrom);

  const getDataSource = async () => {
    await APICall("get", EndPoints.integrations).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setDataSource(data));
      }
    });
  };

  const getAccountsApi = async () => {
    await APICall("get", EndPoints.accounts).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setAccounts(data));
      }
    });
  };

  const getDataSourceById = async (DSID: string) => {
    dispatch(setStageLoadingText("Updating Consents .."));
    await APICall("get", EndPoints.integrations + `${DSID}/`).then(
      async (response) => {
        if (response.status === 200 && response.data) {
          if (addFrom?.isFetchNew) {
            dispatch(setIsAccountSelectOverlayOpen(null));
            dsRef.current?.fetchNewTransaction(response.data);
          } else if (addFrom?.isFetchAll) {
            dispatch(setIsAccountSelectOverlayOpen(null));
            dsRef.current?.fetchAllTransaction(response.data);
          } else {
            await getDataSource();
            await getAccountsApi();
            setTimeout(() => {
              queryClient.resetQueries({
                queryKey: [
                  "integrations",
                  {
                    data_source: addFrom?.integration_id,
                    apiType: "data_source_finapi_connections",
                  },
                ],
              });
              dispatch(setIsAccountSelectOverlayOpen(null));
              dispatch(setAddFrom(null));
              dispatch(setIsAllHeaderApiFetched(true));
              dispatch(setStageLoadingText(null));
            }, 0);
          }

          enqueueSnackbar(t("Consents Updated Successfully"), {
            variant: "success",
            autoHideDuration: 5000,
          });
        }
      }
    );
  };

  const createWebForm = useCallback(async (target: HTMLElement) => {
    load(
      target,
      {
        token: token,
        targetUrl: process.env.REACT_APP_FINAPI_TARGET_URL,
        layoutConfig: "xs",
        backToAppUrl: process.env.REACT_APP_REDIRECT_URL,
        customerSupportUrl: "https://finapi.io",
        language: language as WebFormLanguage | undefined,
      },
      {
        onLoaded: function () {},
        onComplete: function () {
          if (addFrom?.type === "Consents") {
            getDataSourceById(addFrom?.integration_id);
          } else {
            setTimeout(() => {
              dispatch(
                setIsAccountSelectOverlayOpen({
                  open: true,
                  bankType: "finapi",
                })
              );
            }, 1000);
          }
        },
        onFail: function () {
          dispatch(setIsAccountSelectOverlayOpen(null));
          dispatch(setAddFrom(null));
          dispatch(setIsAllHeaderApiFetched(true));
          // handle "onFail" event
        },
        onAbort: function () {
          dispatch(setAddFrom(null));
          dispatch(setIsAccountSelectOverlayOpen(null));
          dispatch(setIsAllHeaderApiFetched(true));
          // handle "onAbort" event
        },
        onLoadError: function () {
          dispatch(setIsAccountSelectOverlayOpen(null));
          dispatch(setAddFrom(null));
          dispatch(setIsAllHeaderApiFetched(true));
          console.log("onLoadError:");
          // handle "onLoadError" event
        },
      }
    );
  }, []);

  const containerRef = (container: HTMLDivElement) => {
    if (container) {
      createWebForm(container);
    }
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        "& form": {
          width: "100%",
        },
      }}
    >
      <DSConnections ref={dsRef} />
    </Box>
  );
}

export default FinApiWebForm;
