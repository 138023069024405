import { Card } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Color, Fonts } from "../../../Helper";
import { format } from "date-fns";
import { formatValue } from "react-currency-input-field";
const strokeDasharray = [
  "0",
  "8 4",
  "2 2",
  "5 5 1 2",
  "10 10 10",
  "20 5 8 10",
  "10 2 1 20",
  "8 5 8 20",
  "8 5 8 5 8 20",
  "10 4 4 3 4 10",
];
const DashboardChartTooltip = React.memo(({ active, payload }) => {
  const { t } = useTranslation();
  const currencyFormate = useSelector(
    (state) => state.settingsSlice.currencyFormate
  );
  const accountsById = useSelector((state) => state.globalSlice.accountsById);
  if (active && payload && payload.length) {
    return (
      <Card
        elevation={3}
        style={{
          width: "fit-content",
          minWidth: "12.5rem",
          height: "fit-content",
          borderRadius: 8,
          backgroundColor: Color.white,
          display: "inline-flex",
          justifyContent: "flex-start",
          padding: "0.5rem",
          flexDirection: "column",
          zIndex: 1400,
          boxShadow: "0 4px -2px 0 rgba(0,0,0,0.13)",
        }}
      >
        <p
          style={{
            display: "inline-flex",
            color: Color.black,
            fontSize: "0.8rem",
            fontFamily: Fonts.Text,
            fontWeight: 600,
            minWidth: "6.25rem",
            textAlign: "left",
            marginBottom: "0.5rem",
          }}
        >
          {format(new Date(payload[0]?.payload?.month), "MMM yyyy")}
        </p>
        {payload?.map((entry, index) => {
          let account =
            accountsById &&
            accountsById[entry?.name] &&
            accountsById[entry?.name][0];
          return (
            <div
              key={`data-${index}`}
              style={{
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  borderRadius: 12,
                  marginRight: "0.5rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <svg width="60" height="2">
                  <line
                    x1="0"
                    y1="1"
                    x2="100"
                    y2="1"
                    stroke="blue"
                    strokeWidth="1"
                    strokeDasharray={strokeDasharray[index]}
                  />
                </svg>
              </div>
              <p
                style={{
                  fontSize: "0.7rem",
                  fontFamily: Fonts.Text,
                  fontWeight: 400,
                  width: "8.25rem",
                  textAlign: "left",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  lineHeight: "normal",
                }}
              >
                {t(account?.name)}
              </p>
              <p
                style={{
                  fontSize: "0.8rem",
                  fontFamily: Fonts.Text,
                  fontWeight: 600,
                  marginLeft: "0.5rem",
                }}
              >
                {formatValue({
                  value: String(parseFloat(entry?.value ?? 0).toFixed(2)),
                  groupSeparator: currencyFormate.groupSeparator,
                  decimalSeparator: currencyFormate.decimalSeparator,
                  prefix: currencyFormate.prefix,
                })}
              </p>
            </div>
          );
        })}
      </Card>
    );
  }

  return null;
});

export default DashboardChartTooltip;
