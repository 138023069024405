import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Button, ButtonGroup, Tooltip } from "@mui/material";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import HomeIcon from "@mui/icons-material/Home";

import {
  startOfMonth,
  format,
  addMonths,
  subMonths,
  eachMonthOfInterval,
  endOfMonth,
} from "date-fns";

import {
  setColumnOrder,
  setDataSetData,
  setdatasetList,
} from "../../store/slices/board";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { Constant } from "../../Helper";
import { queryClient } from "../../App";
import { getTailwindColor } from "../../Helper/data";
import theme from "../../theme";

const skipCount = 1;
const MonthSkipView = ({ color = "slate" }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const timerRunning = useRef(null);
  //redux
  const uuid = useSelector((state) => state.boardSlice?.dataSetData?.uuid);

  const start_date = useSelector(
    (state) => state.boardSlice?.dataSetData?.start_date
  );
  const dataSetList = useSelector((state) => state.boardSlice?.dataSetList);

  const dataSetData = {
    uuid: uuid,
    start_date: start_date,
  };

  //function
  const setAllowColumnFetch = (date) => {
    if (date) {
      let inarray = global.allowFetch?.Inflow || [];
      let outarray = global.allowFetch?.Outflow || [];
      inarray.push(date);
      outarray.push(date);
      global.allowFetch = { Inflow: inarray, Outflow: outarray };
    } else {
      global.allowFetch = { Inflow: [], Outflow: [] };
      let options = {
        predicate: (query) =>
          query.queryKey[0] === "transactions" &&
          query.queryKey[1]?.apiType === "normal_monthly_transactions",
        type: "active",
      };
      queryClient.invalidateQueries(options);
    }
  };

  const onClickRightArrow = () => {
    let dataset_start_date = null;
    let dataset_end_date = null;

    dataset_start_date = format(
      addMonths(new Date(dataSetData?.start_date), skipCount),
      "yyyy-MM-dd"
    );
    dataset_end_date = format(
      endOfMonth(
        addMonths(new Date(dataset_start_date), Constant.column_count)
      ),
      "yyyy-MM-dd"
    );
    setAllowColumnFetch(
      format(startOfMonth(new Date(dataset_end_date)), "yyyy-MM-dd")
    );
    updateColumnOrder(dataset_start_date, dataset_end_date);
    updateDataSetData(dataset_start_date, dataset_end_date);
  };

  const onClickRightArrow2 = () => {
    let dataset_start_date = null;
    let dataset_end_date = null;
    dataset_start_date = format(
      addMonths(new Date(dataSetData?.start_date), Constant.column_count),
      "yyyy-MM-dd"
    );
    dataset_end_date = format(
      endOfMonth(
        addMonths(new Date(dataset_start_date), Constant.column_count)
      ),
      "yyyy-MM-dd"
    );
    setAllowColumnFetch();
    updateColumnOrder(dataset_start_date, dataset_end_date);
    updateDataSetData(dataset_start_date, dataset_end_date);
  };

  const onClickHome = () => {
    const dataset_start_date = format(
      subMonths(startOfMonth(new Date()), skipCount),
      "yyyy-MM-dd"
    );
    const dataset_end_date = format(
      endOfMonth(
        addMonths(new Date(dataset_start_date), Constant.column_count)
      ),
      "yyyy-MM-dd"
    );
    setAllowColumnFetch();
    updateColumnOrder(dataset_start_date, dataset_end_date);
    updateDataSetData(dataset_start_date, dataset_end_date);
  };

  const onClickLeftArrow = () => {
    let dataset_start_date = null;
    let dataset_end_date = null;
    dataset_start_date = format(
      subMonths(new Date(dataSetData?.start_date), skipCount),
      "yyyy-MM-dd"
    );
    dataset_end_date = format(
      endOfMonth(
        addMonths(new Date(dataset_start_date), Constant.column_count)
      ),
      "yyyy-MM-dd"
    );
    setAllowColumnFetch(dataset_start_date);
    updateColumnOrder(dataset_start_date, dataset_end_date);
    updateDataSetData(dataset_start_date, dataset_end_date);
  };

  const onClickLeftArrow2 = () => {
    let dataset_start_date = null;
    let dataset_end_date = null;
    dataset_start_date = format(
      subMonths(new Date(dataSetData?.start_date), Constant.column_count),
      "yyyy-MM-dd"
    );
    dataset_end_date = format(
      endOfMonth(
        addMonths(new Date(dataset_start_date), Constant.column_count)
      ),
      "yyyy-MM-dd"
    );
    setAllowColumnFetch();
    updateColumnOrder(dataset_start_date, dataset_end_date);
    updateDataSetData(dataset_start_date, dataset_end_date);
  };

  const updateDataSetData = (s_date, e_date) => {
    let obj = {
      start_date: s_date,
      end_date: e_date,
    };
    dispatch(setDataSetData({ partial: obj }));
    if (timerRunning.current) clearTimeout(timerRunning.current);
    timerRunning.current = setTimeout(() => {
      let data = [...dataSetList];
      let index = dataSetList.findIndex((o1) => o1.uuid === dataSetData.uuid);
      if (index > -1) {
        data[index] = { ...data[index], ...obj };
      }
      dispatch(setdatasetList(data));
      updateDatasetByID(dataSetData.uuid, obj);
    }, 5000);
  };

  //api
  const updateColumnOrder = (start_date, end_date) => {
    var result = eachMonthOfInterval({
      start: new Date(start_date),
      end: new Date(end_date),
    });
    let dateArray = [];
    result.forEach((element) => {
      let date = format(new Date(element), "yyyy-MM-dd");
      let month = format(new Date(element), "yyyy-MM");
      dateArray.push({ id: month, date });
    });
    dateArray.splice(0, 0, { id: "Unassigned", date: null });
    dispatch(setColumnOrder(dateArray));
  };

  const updateDatasetByID = async (id, obj) => {
    await APICall("patch", EndPoints.datasets + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
        }
      }
    );
  };

  return (
    <ButtonGroup
      className={"kanban_4_step"}
      variant="text"
      aria-label="text button group"
      sx={{
        p: "4px",
        borderRadius: theme.borderRadius.main,
        mr: "1rem",
        "& .MuiSvgIcon-root": {
          fontSize: "1.6rem",
          color: getTailwindColor(color, 600),
        },
        "& .MuiButtonBase-root": {
          py: "3px",
          "&:hover": {
            "& svg": {
              color: theme.palette.primary.main,
            },
          },
        },
        border: `1px solid ${getTailwindColor(color, 300)}`,
        backgroundColor: theme.palette.color.white,
      }}
    >
      <Tooltip arrow title={t("tooltip_filter_left_Most_Arrow")} position="top">
        <Button
          onClick={onClickLeftArrow2}
          sx={{
            borderColor: `${getTailwindColor(color, 300)} !important`,
          }}
        >
          <KeyboardDoubleArrowLeftIcon />
        </Button>
      </Tooltip>
      <Tooltip arrow title={t("tooltip_filter_left_Arrow")} position="top">
        <Button
          onClick={onClickLeftArrow}
          sx={{
            borderColor: `${getTailwindColor(color, 300)} !important`,
          }}
        >
          <KeyboardArrowLeftIcon />
        </Button>
      </Tooltip>
      <Tooltip arrow title={t("tooltip_filter_home")} position="top">
        <Button
          onClick={onClickHome}
          sx={{
            borderColor: `${getTailwindColor(color, 300)} !important`,
          }}
        >
          <HomeIcon />
        </Button>
      </Tooltip>
      <Tooltip arrow title={t("tooltip_filter_right_Arrow")} position="top">
        <Button
          onClick={onClickRightArrow}
          sx={{
            borderColor: `${getTailwindColor(color, 300)} !important`,
          }}
        >
          <KeyboardArrowRightIcon />
        </Button>
      </Tooltip>
      <Tooltip
        arrow
        title={t("tooltip_filter_right_Most_Arrow")}
        position="top"
      >
        <Button
          onClick={onClickRightArrow2}
          sx={{
            borderColor: `${getTailwindColor(color, 200)} !important`,
          }}
        >
          <KeyboardDoubleArrowRightIcon />
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
};
export default MonthSkipView;
