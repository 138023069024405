import { Alert, Popper } from "@mui/material";
import { useTranslation } from "react-i18next";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useState } from "react";
const WarnView = ({
  showInfo = false,
  warnKey,
  infoKey,
  errorKey,
  fontSize = "1.1rem",
  iconStyle,
  hideIcon = true,
}) => {
  const { t } = useTranslation();
  const [anchorEl2, setAnchorEl2] = useState(null);

  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popper2" : undefined;

  const onMouseEnter2 = (e) => {
    setAnchorEl2(e.currentTarget);
  };

  const onMouseLeave2 = () => {
    setAnchorEl2(null);
  };

  //render function
  return (
    <div style={{ display: "inline-flex" }}>
      <Popper
        key={id2}
        id={id2}
        open={open2}
        anchorEl={anchorEl2}
        placement="right"
        modifiers={[
          {
            name: "arrow",
            enabled: true,
          },
        ]}
        sx={{
          ".MuiAlert-icon": {
            display: hideIcon ? "none" : "inline-flex",
          },
        }}
      >
        {showInfo ? (
          <Alert
            severity={showInfo}
            sx={{
              alignItems: "center",
            }}
          >
            {showInfo === "warning" ? t(warnKey) : null}
            {showInfo === "info" ? t(infoKey) : null}
            {showInfo === "error" ? t(errorKey) : null}
          </Alert>
        ) : null}
      </Popper>
      <div onMouseLeave={onMouseLeave2} onMouseEnter={onMouseEnter2}>
        <ErrorOutlineIcon
          color={showInfo ?? "transparent"}
          sx={{
            fontSize: fontSize,
            cursor: "pointer",
            ...iconStyle,
          }}
        />
      </div>
    </div>
  );
};

export default WarnView;
