import { useEffect, useRef, forwardRef, useState, useMemo } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "underscore";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { IoMdAlert } from "react-icons/io";
import { PiArrowsClockwiseBold, PiGearSix } from "react-icons/pi";
import { FaPlugCirclePlus } from "react-icons/fa6";
import { MdCloudSync, MdOutlineEdit } from "react-icons/md";
import { TbPlugConnected, TbPlugConnectedX } from "react-icons/tb";

import {
  TextField,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  ListItemText,
  Stack,
  Tooltip,
  circularProgressClasses,
  useTheme,
} from "@mui/material";

import { getIntegrationCard, remToPx, truncate } from "../../../Helper/data";
import {
  setDataSetAccountList,
  setDataSetData,
  setdatasetList,
} from "../../../store/slices/board";
import { setRefreshData } from "../../../store/slices/appmain";
import { setDataSource, setJoyRidePayload } from "../../../store/slices/global";
import { setSteps, startTour } from "../../../store/slices/tour";
import { setStepIndex } from "../../../store/slices/tour";

import useWidth from "../../../hooks/useWidth";
import useStatusHook from "../../../hooks/useStatusHook";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";

import Icon from "../../Icon";
import EndPoints from "../../../APICall/EndPoints";
import APICall from "../../../APICall";
import { queryClient } from "../../../App";
import IntegrationsDialogView from "./IntegrationsDialogView";
import CustomPopover from "../../PopOver";
import DataSetDropDown from "./DataSetDropDown";
import DsRemoveAttentionOverlay from "./DsRemoveAttentionOverlay";
import TailwindButton from "../../Overlay/TailwindButton";
import StatusView from "../../../Pages/Settings/DataSource/StatusView";
import DSConnections from "../../../Pages/Settings/DataSource/DSConnections";
import { Constant } from "../../../Helper";

const DSSelectorView = forwardRef((props, _ref) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentWidth = useWidth();
  const theme = useTheme();
  const dsRef = useRef(null);

  const isDSChanged = useRef(false);
  const popupRef = useRef(null);
  let abortController = new AbortController();
  let aborted = abortController.signal.aborted;

  //redux
  const joyRidePayload = useSelector(
    (state) => state.globalSlice.joyRidePayload
  );
  const beta_enabled = useSelector(
    (state) => state.settingsSlice?.profile?.beta_enabled
  );
  const accounts = useSelector((state) => state.globalSlice.accounts);
  const dataSource = useSelector((state) => state.globalSlice.dataSource);
  const consentAlert = useSelector((state) => state.globalSlice.consentAlert);
  const uuid = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const title = useSelector((state) => state.boardSlice?.dataSetData?.title);
  const refreshData = useSelector((state) => state.appSlice?.refreshData);
  const isConsentAlert = consentAlert !== 0;
  const isFirstFetchDs = useSelector(
    (state) => state.globalSlice.isFirstFetchDs
  );
  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );

  const dataSetData = {
    uuid: uuid,
    title: title,
  };

  const accountByDS = useMemo(
    () => _.groupBy(accounts, ({ data_source }) => data_source),
    [accounts]
  );

  //state
  const [showAlert, setShowAlert] = useState(false);
  const [isOpen, setIsOpen] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  //api
  const updateAccountLinkedToDataSet = async (
    dataset_id,
    integration_id,
    obj
  ) => {
    await APICall(
      "patch",
      EndPoints.datasets + `${dataset_id}/data_sources/${integration_id}/`,
      obj
    ).then((response) => {
      if (response.status === 200 && response.data && aborted === false) {
      }
    });
  };

  const getDataSource = async () => {
    await APICall("get", EndPoints.integrations).then((response) => {
      if (response.status === 200 && response.data && aborted === false) {
        let data = response.data.results;
        dispatch(setDataSource(data));
      }
    });
  };

  //lifecycle method
  useEffect(() => {
    return () => {
      setShowAlert(false);
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (joyRidePayload?.next?.key === "dataset_header_step_1") {
      onClickPopOver();
      setTimeout(() => {
        dispatch(setSteps({ key: "dataset_header", t }));
        dispatch(setStepIndex(joyRidePayload?.next?.payload?.index));
        dispatch(startTour());
        dispatch(setJoyRidePayload(null));
      }, 800);
    }
    if (joyRidePayload?.prev?.key === "dataset_header_step_0") {
      setAnchorEl(null);
      dispatch(setJoyRidePayload(null));
    }
    if (joyRidePayload?.key === "open_integration_overlay") {
      onClickPopOver();
      dispatch(setJoyRidePayload(null));
    }
  }, [dispatch, joyRidePayload, t]);

  useDebounce(
    () => {
      if (dataSetData?.uuid && dataSource) {
        getDSdatasetsConsents(dataSource);
      }
    },
    500,
    [dataSource, accounts, dataSetData?.uuid],
    true
  );

  useDebounce(
    () => {
      if (dataSetData?.uuid && datasetAccountList?.length > 0) {
        const accountNotFound = datasetAccountList?.find(
          (o1) =>
            (o1.type === 1 && !o1.internal_dataset && !o1.account) ||
            (o1.type === 19 && o1.is_connected && !o1.last_sync_date)
        );

        if (
          Boolean(accountNotFound) &&
          isFirstFetchDs === 0 &&
          global?.isFirstFetchUuid?.length === 0
        ) {
          setShowAlert(true);
        } else {
          setShowAlert(false);
        }
      }
    },
    5000,
    [dataSetData?.uuid, datasetAccountList]
  );

  //functions
  const onChangeSelectedAccount = (option) => {
    const updatedData = [
      ...datasetAccountList?.map((item) => {
        if (item.uuid === option.uuid) {
          const dataSourceId = option.datasets.find(
            (o1) => o1.dataset === dataSetData?.uuid
          );
          const obj = { ...item, active: !item?.active };
          updateAccountLinkedToDataSet(dataSetData?.uuid, dataSourceId?.uuid, {
            active: !item?.active,
          });

          return obj;
        }
        return item;
      }),
    ];
    isDSChanged.current = true;
    dispatch(setDataSetAccountList(updatedData));
  };

  const onClickPopOver = () => {
    setAnchorEl(popupRef.current);
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
    if (isDSChanged.current) {
      setTimeout(() => {
        let options = {
          predicate: (query) =>
            ["transactions", "List", "invoices"].includes(query.queryKey[0]) &&
            query.queryKey[1].dataset === dataSetData?.uuid,
          type: "active",
        };
        queryClient.invalidateQueries(options, { cancelRefetch: true });
        dispatch(setRefreshData(!Boolean(refreshData)));
      }, 500);
      isDSChanged.current = false;
    }
  };

  const getDSdatasetsConsents = async (dataSourceArray) => {
    let connectedData = [];
    dataSourceArray.forEach((element) => {
      const isDataSetAdded = element?.datasets?.filter(
        (o1) => o1.dataset === dataSetData?.uuid
      );
      if (isDataSetAdded?.length > 0) {
        const account = accountByDS?.[element?.uuid]?.[0];
        const card = getIntegrationCard({ ds: element });

        const obj = {
          ...element,
          active: isDataSetAdded?.[0]?.active,
          card: card,
          account: account,
          position: element?.internal_dataset ? 0 : element.type,
        };
        connectedData.push(obj);
      }
    });
    connectedData?.sort((a, b) => (a.position > b.position ? 1 : -1));
    setTimeout(() => {
      dispatch(setDataSetAccountList([...connectedData]));
    }, 200);
  };

  const onManage = () => {
    navigate(`/settings/Integrations`);
    setAnchorEl(null);
  };

  const onClickAssign = () => {
    setIsOpen("assign");
  };

  const onClickAdd = () => {
    setIsOpen("add");
  };

  const handleClickClose = () => {
    setIsOpen(null);
    if (isDSChanged.current) {
      getDataSource();
    }
  };

  const onClickErrorLogs = (ds, error) => {
    dsRef?.current?.onClickErrorLogs(ds, error);
  };

  const tooltip =
    isFirstFetchDs !== 0 &&
    datasetAccountList.find((o1) => global?.isFirstFetchUuid?.includes(o1.uuid))
      ? t("integrations_fetching")
      : !showAlert && !isConsentAlert
        ? ""
        : // ? t("Integrations")
          isConsentAlert
          ? ""
          : t("Integration is not connected.");

  const ChipView = forwardRef((props, _ref) => {
    return (
      <Tooltip arrow title={tooltip}>
        <Chip
          ref={_ref}
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {truncate(dataSetData?.title, 13)}
              <Box
                sx={{
                  borderRadius: theme.borderRadius.main,
                  backgroundColor: theme.palette.color.slate[300],
                  py: "0.35rem",
                  px: "0.5rem",
                  ml: "0.5rem",
                }}
              >
                {datasetAccountList?.length
                  ? datasetAccountList?.length - 1
                  : 0}
              </Box>
            </Box>
          }
          onDelete={() => {}}
          deleteIcon={
            <ArrowDropDownIcon
              sx={{
                color: `${theme.palette.color.slate[700]} !important`,
                mr: "0px !important",
                transform: `rotate(${anchorEl ? 180 : 0}deg)`,
                transition: "all 0.3s ease-in-out",
              }}
            />
          }
          icon={
            <Box
              sx={{
                display: "flex",
              }}
            >
              {isFirstFetchDs !== 0 &&
              datasetAccountList.find((o1) =>
                global?.isFirstFetchUuid?.includes(o1.uuid)
              ) ? (
                <CircularProgress
                  size={18}
                  sx={{
                    p: "0px !important",
                    mr: "0.25rem",
                    color: `${theme.palette.color.slate[700]} !important`,
                  }}
                />
              ) : showAlert || isConsentAlert ? (
                <Icon
                  icon={<IoMdAlert />}
                  fontSize={{ xs: "1.2rem", small: "1.5rem" }}
                  color={
                    showAlert
                      ? theme.palette.color.red[500]
                      : theme.palette.color.blue[500]
                  }
                ></Icon>
              ) : (
                <BusinessIcon
                  sx={{
                    color: theme.palette.color.slate[700],
                    fontSize: "1.5rem",
                  }}
                />
              )}
            </Box>
          }
          sx={{
            color: theme.palette.color.slate[700],
            backgroundColor: theme.palette.color.slate[200],
            height: "fit-content",
            borderRadius: theme.borderRadius.main,
            ml: "0.5rem",
            p: { xs: "0.25rem", small: "0.5rem" },
            pl: { xs: "0.5rem", md: "0.75rem" },
            cursor: "pointer",
            "& span": {
              fontSize: { xs: "0.7rem", md: "0.85rem" },
              lineHeight: { xs: "0.7rem", md: "0.85rem" },
              fontWeight: 600,
              pr: "0.5rem",
              pl: "0.5rem",
            },

            "& .MuiChip-icon": {
              ml: "0.1rem",
              mb: "0.1rem",
              mr: 0,
            },
            "&:hover span": {
              color: `${theme.palette.primary.main} !important`,
            },
          }}
          {...props}
        />
      </Tooltip>
    );
  });

  const isAvailableDs = useMemo(() => {
    return dataSource?.find(
      (o1) =>
        !o1.internal_dataset &&
        o1.state === 1 &&
        !o1.datasets?.find((o2) => o2.dataset === dataSetData?.uuid)
    );
  }, [dataSetData?.uuid, dataSource]);

  return (
    <>
      <IntegrationsDialogView
        open={Boolean(isOpen)}
        type={isOpen}
        handleClickClose={handleClickClose}
        isDSChanged={isDSChanged}
      />
      <DSConnections ref={dsRef} />

      <CustomPopover
        type="anchor"
        anchorEl={anchorEl}
        ref={popupRef}
        onClose={onClosePopOver}
        onClick={onClickPopOver}
        width={"fit-content"}
        border="none"
        justifyContent="flex-start"
        iconSize={"1.375rem"}
        icon
        iconStyle={{ p: 0 }}
        paperStyle={{
          border: 0,
          cursorCol: "pointer",
        }}
        iconView={<ChipView />}
        hoverBG={"transparent"}
      >
        <Box
          className={"dataset_header_step_1"}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            minHeight: "3.125rem",
            width: "65rem",
            position: "relative",
            padding: "0.5rem",
            paddingTop: "1rem",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <DataSetView />
            {!Constant?.isProd && beta_enabled ? <SyncAllButton /> : null}
          </Stack>
          {datasetAccountList?.map((item) => {
            return (
              <div key={item?.uuid}>
                <Box
                  sx={{
                    px: "1rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    position: "relative",
                    py: 0,
                    height: "3rem",
                    width: "100%",
                    flexGrow: "unset",
                    cursor: "auto",
                    "&: hover": {
                      "& #action-fetch-new-button": {
                        backgroundColor: theme.palette.color.sky[50],
                        color: theme.palette.color.sky[600],
                        "& svg": {
                          color: theme.palette.color.sky[600],
                        },
                      },
                    },
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {item?.account?.bank_details?.logo_url ? (
                      <img
                        component="img"
                        style={{
                          width: remToPx(currentWidth, 3),
                          height: remToPx(currentWidth, 1.5),
                          objectFit: "contain",
                          p: "1rem",
                        }}
                        src={item?.account?.bank_details?.logo_url}
                        alt="Logo"
                      />
                    ) : item?.card?.icon ? (
                      <Icon
                        icon={item?.card?.icon}
                        fontSize={"1.3rem"}
                        color="inherit"
                        style={{
                          mx: "0.5rem",
                          "& svg": {
                            width: remToPx(currentWidth, 1.9),
                            height: "auto",
                          },
                        }}
                      ></Icon>
                    ) : (
                      <img
                        component="img"
                        style={{
                          width: remToPx(currentWidth, 3),
                          height: remToPx(currentWidth, 1.5),
                          objectFit: "contain",
                          p: "1rem",
                        }}
                        src={item?.card?.img}
                        alt="Logo"
                      />
                    )}
                    <TitleView item={item} />
                  </div>

                  <Stack
                    flexDirection={"row-reverse"}
                    alignItems={"center"}
                    sx={{
                      position: "absolute",
                      right: "1rem",
                      backgroundColor: theme.palette.color.white,
                    }}
                  >
                    <RemoveIcon item={item} isDSChanged={isDSChanged} />
                    <Tooltip
                      arrow
                      placement="top"
                      title={
                        item?.active
                          ? t("Disable Integration")
                          : t("Enable Integration")
                      }
                    >
                      <Button
                        size="small"
                        onClick={() => onChangeSelectedAccount(item)}
                        sx={{
                          marginInline: "1rem",
                          display: "flex",
                          minWidth: "auto",
                          textTransform: "none",
                          fontSize: "0.7rem",
                          height: "1.85rem",
                          fontWeight: 500,
                          mr: "1rem",
                          "& svg": {
                            color: item?.active
                              ? theme.palette.color.slate[300]
                              : theme.palette.color.slate[600],
                            fontSize: "1.2rem !important",
                          },
                          backgroundColor: theme.palette.color.slate[50],
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: item?.active
                              ? theme.palette.color.green[100]
                              : theme.palette.color.slate[100],
                            "& svg": {
                              color: item?.active
                                ? theme.palette.color.green[600]
                                : theme.palette.color.slate[600],
                            },
                          },
                        }}
                      >
                        {item?.active ? (
                          <VisibilityOutlinedIcon />
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </Button>
                    </Tooltip>

                    {!item.internal_dataset ? (
                      <StatusView
                        uuid={item?.uuid}
                        type="alert"
                        refetch
                        onClickErrorLogs={onClickErrorLogs}
                      />
                    ) : (
                      <div
                        style={{
                          width: "8.2rem",
                        }}
                      ></div>
                    )}
                    <FetchNewButton getDataSource={getDataSource} DS={item} />
                  </Stack>
                </Box>
                <Divider
                  variant="middle"
                  flexItem
                  sx={{
                    mx: "2rem",
                    borderColor: theme.palette.color.slate[100],
                  }}
                />
              </div>
            );
          })}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0.75rem",
              mt: "1rem",
              cursor: "pointer",
              "&:hover": {
                color: theme.palette.primary.main,
              },
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <TailwindButton
                icon={
                  <Icon
                    icon={<FaPlugCirclePlus />}
                    fontSize={"1rem"}
                    color="inherit"
                    style={{ marginRight: "0.5rem" }}
                  ></Icon>
                }
                onClick={onClickAdd}
                className={"dataset_header_step_2"}
                text={t("Add new Integration")}
                tooltip={t("Add new Integration Tooltip")}
                backgroundColor={theme.palette.primary.main}
                sx={{
                  fontSize: "0.8rem",
                  lineHeight: "0.8rem",
                }}
              />
              {!!isAvailableDs ? (
                <TailwindButton
                  variant="outlined"
                  icon={
                    <Icon
                      icon={<TbPlugConnected />}
                      fontSize={"1rem"}
                      color="inherit"
                      style={{ marginRight: "0.5rem" }}
                    ></Icon>
                  }
                  text={t("Assign available Integration")}
                  tooltip={t("Assign available Integration Tooltip")}
                  onClick={onClickAssign}
                  color={theme.palette.primary.main}
                  sx={{
                    fontSize: "0.8rem",
                    lineHeight: "0.8rem",
                  }}
                />
              ) : null}

              <Tooltip arrow title={t("Manage Integrations of")}>
                <Box
                  sx={{
                    ml: "0.5rem",
                    p: { xs: "0.25rem", small: "0.5rem" },
                    display: "inline-flex",
                    alignItem: "center",
                    JustifyContent: "center",
                    color: theme.palette.primary.main,
                    "&:hover": {
                      borderRadius: 10,
                      opacity: 1,
                      backgroundColor: theme.palette.primary[50],
                    },
                  }}
                >
                  <Icon
                    onClick={onManage}
                    icon={<PiGearSix />}
                    fontSize={{ xs: "1.5rem", small: "1.3rem" }}
                    color={theme.palette.primary.main}
                    style={{ cursor: "pointer" }}
                  ></Icon>
                </Box>
              </Tooltip>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <DataSetDropDown />
              <OrgSettingsView />
            </div>
          </Box>
        </Box>
      </CustomPopover>
    </>
  );
});
export default DSSelectorView;

const TitleView = ({ item }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isFirstFetchDs = useSelector(
    (state) => state.globalSlice.isFirstFetchDs
  );

  const isBankFetching =
    item.type === 12 &&
    !item.last_sync_date &&
    isFirstFetchDs !== 0 &&
    global.isFirstFetchUuid?.includes(item.uuid);

  let tooltip = "";
  if (item.internal_dataset) {
    tooltip = t("Internal data source tooltip");
  } else if (item?.account?.bank_details?.title) {
    tooltip = `${item?.account?.bank_details?.title}${
      item?.account?.iban ? ` / ${item?.account?.iban}` : ""
    }`;
  } else {
    tooltip = item?.title;
  }

  return (
    <Tooltip title={tooltip} followCursor>
      <ListItemText
        primary={
          isBankFetching
            ? t("bank details fetching")
            : item.internal_dataset
              ? t("Internal data source")
              : `${item?.title}`
        }
        sx={{
          flex: "unset",
          mx: "1rem",
          width: "35rem",
          "& span": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.primary.main,
            fontWeight: 500,
          },
        }}
      />
    </Tooltip>
  );
};

const RemoveIcon = ({ item, isDSChanged }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const param = useRef(null);

  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const removeDataSourceToDataSet = async (dataset_id, item, isForce) => {
    setLoading(true);
    const obj = item?.datasets?.find((o1) => o1.dataset === dataset_id);
    let url = `${dataset_id}/data_sources/${obj?.uuid}/`;
    if (isForce) {
      url = url + "?force=true/";
    }
    await APICall("delete", EndPoints.datasets + url, null, {
      doNotCatchRespond: true,
    })
      .then((response) => {
        if (response.status === 204) {
          getDataSource();
          isDSChanged.current = true;
        }
        if (response.status === 400 && response?.data?.[0]) {
          param.current = {
            dataset_id,
            item,
          };
          setOpen(true);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getDataSource = async () => {
    await APICall("get", EndPoints.integrations).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setDataSource(data));
        setLoading(false);
        setOpen(false);
      }
    });
  };

  const onCloseCallback = () => {
    param.current = null;
    setLoading(false);
  };

  const onAdd = () => {
    removeDataSourceToDataSet(
      param.current?.dataset_id,
      param.current?.item,
      true
    );
  };

  if (item?.internal_dataset) {
    return (
      <div
        style={{
          width: "2rem",
        }}
      ></div>
    );
  }
  return (
    <div
      style={{
        width: "2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DsRemoveAttentionOverlay
        open={open}
        setOpen={setOpen}
        onCloseCallback={onCloseCallback}
        onAdd={onAdd}
        title="Attention"
        message="ds_remove_attention_ds_have_transaction_with_org_category"
      />
      {loading ? (
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CircularProgress
            variant="determinate"
            sx={{
              color: theme.palette.color.slate[200],
            }}
            size={22}
            thickness={4}
            value={100}
          />
          <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
              color: theme.palette.primary.main,
              animationDuration: "550ms",
              position: "absolute",
              left: 0,
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: "round",
              },
            }}
            size={22}
            thickness={4}
          />
        </div>
      ) : (
        <Tooltip arrow placement="top" title={t("Remove Integration")}>
          <Button
            size="small"
            onClick={() => removeDataSourceToDataSet(dataset, item)}
            sx={{
              display: "flex",
              minWidth: "auto",
              textTransform: "none",
              fontSize: "0.7rem",
              height: "1.85rem",
              fontWeight: 500,
              backgroundColor: theme.palette.color.slate[50],
              alignItems: "center",
              justifyContent: "center",
              "& svg": {
                color: theme.palette.color.slate[300],
                fontSize: "1.2rem !important",
              },
              "&:hover": {
                backgroundColor: theme.palette.color.red[100],
                "& svg": {
                  color: theme.palette.color.red[600],
                },
              },
            }}
          >
            <TbPlugConnectedX />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

const FetchNewButton = ({ DS }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dsRef = useRef(null);

  const FinApiQueryKey = [
    "integrations",
    {
      data_source: DS?.uuid,
      apiType: "data_source_finapi_connections",
    },
  ];
  const IntegrationsQueryKey = [
    "integrations",
    {
      data_source: DS?.uuid,
      apiType: "data_source_external_connections",
    },
  ];

  const data_source_by_uuid_finapi_connections = useStatusHook(null, {
    queryKey: FinApiQueryKey,
  });
  const data_source_by_uuid_external_connections = useStatusHook(null, {
    queryKey: IntegrationsQueryKey,
  });

  const isValidDs = () => {
    if (DS.type === 10) {
      return false;
    }

    if (
      DS.type === 12 &&
      data_source_by_uuid_finapi_connections?.data !== "COMPLETED" &&
      data_source_by_uuid_finapi_connections?.data !== "UPDATED"
    ) {
      return false;
    }
    if (
      DS.type === 19 &&
      data_source_by_uuid_external_connections?.data !== "UPDATED"
    ) {
      return false;
    }
    return true;
  };

  const fetchNewTransaction = () => {
    dsRef.current?.fetchNewTransaction(DS);
  };

  if (!DS || !DS?.last_sync_date || DS?.type === 1 || DS?.type === 2) {
    return null;
  }
  if (!isValidDs()) {
    return null;
  }
  return (
    <>
      <DSConnections ref={dsRef} />
      <Button
        id="action-fetch-new-button"
        size="small"
        onClick={fetchNewTransaction}
        sx={{
          display: "flex",
          minWidth: "auto",
          textTransform: "none",
          fontSize: "0.7rem",
          height: "1.85rem",
          fontWeight: 500,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: theme.palette.color.slate[50],
          color: theme.palette.color.slate[300],
          px: "0.5rem",
          mr: "1rem",
          "& svg": {
            color: theme.palette.color.slate[300],
            fontSize: "1.2rem !important",
            mr: "0.5rem",
          },
          "&:hover": {
            backgroundColor: theme.palette.color.sky[100],
          },
        }}
      >
        <PiArrowsClockwiseBold />
        {t("Check_for_new_transactions")}
      </Button>
    </>
  );
};

const OrgSettingsView = () => {
  const theme = useTheme();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const onClickDataSetSetting = () => {
    navigate(`/settings/organizations`);
  };

  return (
    <Tooltip arrow title={t("org_settings_tooltip")}>
      <Box
        className="dashboard_overview_8_step"
        sx={{
          ml: "0.5rem",
          p: { xs: "0.25rem", small: "0.5rem" },
          display: "inline-flex",
          alignItem: "center",
          JustifyContent: "center",
          "&:hover": {
            borderRadius: 10,
            opacity: 1,
            backgroundColor: theme.palette.color.slate[200],
          },
        }}
      >
        <Icon
          onClick={onClickDataSetSetting}
          icon={<PiGearSix />}
          fontSize={{ xs: "1.5rem", small: "1.3rem" }}
          color={theme.palette.color.slate[800]}
          style={{ cursor: "pointer" }}
        ></Icon>
      </Box>
    </Tooltip>
  );
};

const DataSetView = () => {
  const dispatch = useDispatch();
  const isDataUpdated = useRef(false);
  const theme = useTheme();

  //redux state
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const dataSetList = useSelector((state) => state.boardSlice?.dataSetList);

  //api
  const updateDataSetByID = async (id, obj) => {
    await APICall("patch", EndPoints.datasets + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
        }
      }
    );
  };

  //function
  const handleEditChange = (e) => {
    const { value } = e.target;
    isDataUpdated.current = true;
    if (!value && value?.trim() === "") {
    } else {
      dispatch(setDataSetData({ partial: { title: value } }));
    }
  };

  const handleEditBlurChange = (e) => {
    if (isDataUpdated.current) {
      let { value } = e.target;
      let obj = {
        title: value,
      };
      let data = [...dataSetList];
      let index = dataSetList?.findIndex((o1) => o1.uuid === dataSetData?.uuid);
      if (index > -1) {
        data[index] = { ...data[index], ...obj };
      }
      dispatch(setdatasetList(data));
      updateDataSetByID(dataSetData?.uuid, obj);
    }
  };

  return (
    <Box
      sx={{
        pl: "1.25rem",
        mb: "0.5rem",
        display: "flex",
        width: "fit-content",
        alignItems: "center",
        position: "relative",
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: "0",
          borderRadius: theme.borderRadius.main,
        },
        "&:hover": {
          "& svg": {
            color: theme.palette.color.slate[700],
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px",
            borderRadius: theme.borderRadius.main,
            borderColor: theme.palette.color.slate[700],
          },
        },

        "& input": {
          borderRadius: theme.palette.color.slate[700],
          color: theme.palette.color.slate[700],
          fontSize: "1.3rem",
          fontFamily: theme.typography.fontFamily,
          fontWeight: 600,
          pl: "0.5rem",
          py: "0.25rem",
          pr: "1.75rem",
          textOverflow: "ellipsis",
        },
        "& svg": {
          borderRadius: theme.borderRadius.main,
          color: theme.palette.color.slate[200],
          position: "absolute",
          right: "0.5rem",
          fontSize: "1.3rem",
        },
      }}
    >
      <TextField
        id="outlined-size-small"
        defaultValue=""
        value={dataSetData?.title}
        onChange={handleEditChange}
        onBlur={handleEditBlurChange}
        size="small"
      />
      <MdOutlineEdit />
    </Box>
  );
};

const SyncAllButton = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dsRef = useRef(null);

  const syncAllIntegrations = () => {
    dsRef.current?.syncAllIntegrations();
  };

  return (
    <>
      <DSConnections ref={dsRef} />
      <Button
        size="small"
        onClick={syncAllIntegrations}
        sx={{
          display: "flex",
          minWidth: "auto",
          textTransform: "none",
          fontSize: "0.7rem",
          height: "1.85rem",
          fontWeight: 500,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: theme.palette.color.slate[50],
          color: theme.palette.color.slate[300],
          px: "0.5rem",
          mr: "1rem",
          "& svg": {
            color: theme.palette.color.slate[300],
            fontSize: "1.2rem !important",
            mr: "0.5rem",
          },
          "&:hover": {
            "& svg": {
              color: theme.palette.primary.main,
            },
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary[100],
          },
        }}
      >
        <MdCloudSync />
        {t("sync_all_integrations")}
      </Button>
    </>
  );
};
