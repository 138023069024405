import { useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { updateLanguage } from "../Helper/data";
import APICall from "../APICall";
import EndPoints from "../APICall/EndPoints";
import { setStageLoadingText } from "../store/slices/appmain";
import {
  setAddFrom,
  setIsAccountSelectOverlayOpen,
  setIsDsUuidLoading,
} from "../store/slices/global";
import { queryClient } from "../App";
import ConfirmationModal from "../components/Model/ConfirmationModal";
import AccountsSelectOverlay from "../Pages/Settings/DataSource/AccountsSelectOverlay";
import CategoryTreeSelect from "../components/Overlay/CategoryTreeSelect";
import SettingForm from "../Pages/Datasets/Kanban/SettingForm";
import AddTransactionModal from "../Pages/Datasets/Kanban/Overlay/AddTransactionModal";
import GroupSettingsModal from "../Pages/Datasets/Kanban/Overlay/GroupSettingsModal";
import RecurOverlay from "../Pages/Datasets/Kanban/Overlay/RecurOverlay";
import DrawerModal from "../components/TableEditModal/DrawerModal";
import DrawerModalEditForm from "../components/TableEditModal/DrawerModalEditForm";

import ReconcileListViewModal from "../Pages/Datasets/Transactions/Components/ReconcileListViewModal";
import MuiSnackbar from "../components/MuiSnackbar";
import FinApiOverlay from "../Pages/Datasets/Kanban/Overlay/FinApiOverlay";
import registerServiceWorker, {
  unregisterServiceWorker,
} from "../serviceWorkerRegistration";
import useDebounce from "../hooks/3-useDebounce/useDebounce";
import PlanExpiredAlert from "../components/PlanExpiredAlert";
import { setIsAllHeaderApiFetched } from "../store/slices/common";
import Loader from "../components/Loader";
import SpinLoader from "../components/Spinner";

const CommonView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const last_sync_date = useRef(null);
  const versionCheckInterval = useRef(null);
  //redux
  const popupStatus = useSelector((state) => state.datasetSlice?.popupStatus);
  const popupStatus2 = useSelector((state) => state.datasetSlice?.popupStatus2);
  const popupStatus3 = useSelector((state) => state.datasetSlice?.popupStatus3);
  const popupStatus4 = useSelector((state) => state.datasetSlice?.popupStatus4);
  const addFrom = useSelector((state) => state.globalSlice.addFrom);
  const profile = useSelector((state) => state.settingsSlice?.profile);
  const loading = useSelector((state) => state.appSlice.loading);
  const isFetching = useSelector((state) => state.appSlice?.isFetching);
  const isLoading = useSelector((state) => state.appSlice?.isLoading);
  const showSpinner = useSelector((state) => state.appSlice?.showSpinner);
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const isAccountSelectOverlayOpen = useSelector(
    (state) => state.globalSlice.isAccountSelectOverlayOpen
  );
  const auth = useSelector((state) => state.auth);

  let showHeader =
    auth?.token &&
    auth?.refreshToken &&
    !location.pathname.includes("/login") &&
    !location.pathname.includes("/register") &&
    !location.pathname.includes("/registration/") &&
    !location.pathname.includes("/auth/");

  //api
  const getDataSourceById = async (DSID) => {
    dispatch(setStageLoadingText("Updating Consents .."));
    await APICall("get", EndPoints.integrations + `${DSID}/`).then(
      (response) => {
        if (response.status === 200 && response.data) {
          // if (
          //   last_sync_date.current?.toString() ===
          //   response?.data?.last_sync_date?.toString()
          // ) {
          //   setTimeout(() => {
          //     getDataSourceById(DSID);
          //   }, 3000);
          // } else {
          queryClient.resetQueries({
            queryKey: [
              "integrations",
              {
                data_source: addFrom?.integration_id,
                apiType: "data_source_finapi_connections",
              },
            ],
          });
          setTimeout(() => {
            dispatch(setIsAccountSelectOverlayOpen(null));
            dispatch(setAddFrom(null));
            dispatch(setIsAllHeaderApiFetched(true));
            dispatch(setStageLoadingText(null));
          });
          enqueueSnackbar(t("Consents Updated Successfully"), {
            variant: "success",
            autoHideDuration: 5000,
          });
          // }
        }
      }
    );
  };

  const updateApiKey = async (integration_id, obj) => {
    await APICall(
      "patch",
      EndPoints.integrations + `${integration_id}/external/config/`,
      obj
    ).then((response) => {
      if (response.status === 200 && response) {
        setTimeout(() => {
          dispatch(setIsDsUuidLoading(integration_id));
          dispatch(setAddFrom(null));
          queryClient.resetQueries({
            queryKey: [
              "integrations",
              {
                data_source: integration_id,
                apiType: "data_source_external_connections",
              },
            ],
          });
        }, 500);
      }
    });
  };

  const getFinApiDataSourceConnection = async (DSID) => {
    let status = null;

    await APICall(
      "get",
      EndPoints.integrations + `${DSID}/finapi/connections/`
    ).then((response) => {
      if (response.status === 200 && response.data) {
        status = response?.data?.[0]?.status;
        if (status === "IN_PROGRESS") {
          setTimeout(() => {
            getFinApiDataSourceConnection(DSID);
          }, 2000);
        }
        if (status === "COMPLETED") {
          dispatch(setStageLoadingText(null));
          dispatch(
            setIsAccountSelectOverlayOpen({
              open: true,
              bankType: "finapi",
            })
          );
        }
      }
    });
  };

  //lifecycle method
  useLayoutEffect(() => {
    dispatch(setIsAllHeaderApiFetched(false));
  }, []);

  useEffect(() => {
    registerServiceWorker();
    // unregisterServiceWorker();
    clearInterval(versionCheckInterval.current);
    versionCheckInterval.current = setInterval(() => {
      checkVersionUpdate();
    }, 60000);

    return () => {
      clearInterval(versionCheckInterval.current);
    };
  }, []);

  useEffect(() => {
    if (showHeader) {
      checkIsUserValid();
    } else {
      updateLanguage();
    }
  }, [showHeader]);

  useDebounce(
    () => {
      window.addEventListener("focus", checkIsUserValid);

      checkIsUserValid();

      return () => {
        window.removeEventListener("focus", checkIsUserValid);
      };
    },
    500,
    [showHeader, location.pathname],
    true
  );

  useEffect(() => {
    if (isAllHeaderApiFetched) {
      checkNavigation();
    }
  }, [isAllHeaderApiFetched]);

  const checkNavigation = async () => {
    let pathname = window.location.href;

    let defaultPathname = "/settings/Integrations";
    //tink
    // if (pathname.includes("data-sources/callback?")) {
    //   if (pathname.includes("error")) {
    //     if (addFrom?.targetUrl) {
    //       navigate(addFrom?.targetUrl);
    //     } else {
    //       navigate(defaultPathname);
    //     }
    //     enqueueSnackbar(t("tink_error_msg"), {
    //       variant: "error",
    //       autoHideDuration: 5000,
    //     });
    //     dispatch(setAddFrom(null));
    //     return;
    //   }

    //   if (addFrom) {
    //     navigate(addFrom?.targetUrl || defaultPathname);

    //     if (
    //       addFrom?.type === "Consents" &&
    //       !pathname.includes("error=INTERNAL_ERROR&error_reason=") &&
    //       addFrom?.integration_id
    //     ) {
    //       last_sync_date.current = addFrom?.last_sync_date;
    //       getDataSourceById(addFrom?.integration_id);
    //     }
    //   } else {
    //     navigate(defaultPathname);
    //   }
    // }

    //lexoffice
    if (pathname.includes("client_id") && pathname.includes("state")) {
      if (pathname.includes("error")) {
        if (addFrom?.targetUrl) {
          navigate(addFrom?.targetUrl);
        } else {
          navigate(defaultPathname);
        }
        enqueueSnackbar(t("lexoffice_error_msg"), {
          variant: "error",
          autoHideDuration: 5000,
        });
        dispatch(setAddFrom(null));
        return;
      }
      if (addFrom) {
        navigate(addFrom?.targetUrl || defaultPathname);
      } else {
        navigate(defaultPathname);
      }
    }

    //finapi
    if (pathname.includes("code") && pathname.includes("state")) {
      if (addFrom) {
        let url = new URL(pathname);
        let searchParams = url.searchParams;
        let authorization_code = searchParams.get("code");
        if (addFrom?.integration_id) {
          updateApiKey(addFrom?.integration_id, { authorization_code });
        }
        navigate(addFrom?.targetUrl || defaultPathname);
      } else {
        navigate(defaultPathname);
      }
    }
    if (pathname.includes("isFinAPi")) {
      navigate(addFrom?.targetUrl || defaultPathname);

      if (addFrom?.type === "Consents") {
        last_sync_date.current = addFrom?.last_sync_date;
        getDataSourceById(addFrom?.integration_id);
      } else {
        dispatch(setStageLoadingText(t("checking_finApi_account_connection")));
        await getFinApiDataSourceConnection(addFrom?.integration_id);
      }
    }
  };

  const checkIsUserValid = () => {
    if (showHeader) {
      const EMAIL = localStorage.getItem("LoggedInUser");
      if (!profile?.email) {
        window.location.href = "/login";
        return;
      }
      if (!EMAIL) {
        localStorage.setItem("LoggedInUser", profile?.email);
        return;
      }
      if (EMAIL && EMAIL !== profile?.email) {
        localStorage.setItem("LoggedInUser", profile?.email);
        window.location.href = "/dashboard";
        emptyCacheStorage(true, 0);
      }
    }
  };

  const checkVersionUpdate = () => {
    if (
      "serviceWorker" in navigator &&
      process.env.REACT_APP_MODE !== "development"
    ) {
      navigator?.serviceWorker?.ready?.then(async (registration) => {
        try {
          const onLine =
            navigator && "connection" in navigator ? navigator.onLine : true;
          const response = onLine
            ? await fetch("/sw.js", {
                cache: "no-store",
                "cache-control": "no-cache",
              })
            : undefined;
          response && response.status === 200 && (await registration?.update());
        } catch (e) {
          console.log("cannot ping/update sw.js", e);
        }
      });
    }
  };

  const emptyCacheStorage = async (update = false, count = 2000) => {
    if ("caches" in window) {
      const cacheKeys = await window.caches.keys();
      await Promise.all(
        cacheKeys.map((key) => {
          window.caches.delete(key);
        })
      );
      queryClient.clear();
      if (update) {
        setTimeout(() => {
          window.location.replace(window.location.href);
        }, count);
      }
    }
  };

  return (
    <>
      <ChatWidget />
      {showSpinner ? (
        <>
          <SpinLoader loading={loading || isFetching || isLoading} />
          <Loader loading={loading || isFetching || isLoading} />
        </>
      ) : null}
      <MuiSnackbar />
      <PlanExpiredAlert />
      {isAccountSelectOverlayOpen?.open &&
        !isAccountSelectOverlayOpen?.isBank && (
          <AccountsSelectOverlay open={true} />
        )}
      {isAccountSelectOverlayOpen?.open &&
        isAccountSelectOverlayOpen?.bankType === "finapi" &&
        isAccountSelectOverlayOpen?.isBank && <FinApiOverlay />}
      {popupStatus?.from === "column_add_Transaction" && popupStatus?.open && (
        <AddTransactionModal />
      )}
      {popupStatus?.from === "column_add_recurrence" && popupStatus?.open && (
        <RecurOverlay />
      )}
      {popupStatus?.from === "column_reconcile" && popupStatus?.open && (
        <ReconcileListViewModal />
      )}
      {/* <ReconcileModal /> */}
      {popupStatus2?.from === "card_setting" && <GroupSettingsModal />}
      {/*for delete, warning ,for ask confirmation */}
      {popupStatus3?.open && <ConfirmationModal />}{" "}
      {/*for category selection ,form for state,scenario,cost unit, due date ,invoice date, date ,recurring date range*/}
      {popupStatus4?.open && popupStatus4?.overlay_type === "category" && (
        <CategoryTreeSelect
          anchorEl={popupStatus4?.anchorEl}
          onClickCategoryTitle={popupStatus4?.payload?.onClickCategoryTitle}
          handleClosePopOver={popupStatus4?.payload?.handleClosePopOver}
          allowParentCategory={popupStatus4?.payload?.allowParentCategory}
          hiddenCategory={popupStatus4?.payload?.hiddenCategory}
          ruleTitle={popupStatus4?.payload?.ruleTitle}
          type={popupStatus4?.payload?.type}
          fromSequence={popupStatus4?.payload?.fromSequence}
        />
      )}
      {popupStatus4?.open && popupStatus4?.overlay_type === "form" && (
        <SettingForm
          open={popupStatus4.open}
          onSave={popupStatus4?.payload?.onSave}
          type={popupStatus4?.payload?.type}
          groupedCard={popupStatus4?.payload?.groupedCard}
          fromSequence={popupStatus4?.payload?.fromSequence}
        />
      )}
      {popupStatus2?.open && popupStatus2?.overlay_type === "drawer_modal" && (
        <DrawerModal />
      )}
      <DrawerModalEditFormView />
    </>
  );
};
export default CommonView;

const DrawerModalEditFormView = () => {
  const transactionsOverlayStatus = useSelector(
    (state) => state.datasetSlice?.transactionsOverlayStatus
  );
  return (
    <>
      {transactionsOverlayStatus?.open &&
        transactionsOverlayStatus?.overlay_type ===
          "drawer_modal_edit_form" && <DrawerModalEditForm />}
    </>
  );
};

const ChatWidget = () => {
  const location = useLocation();
  const scriptRef = useRef(null);
  const showHeader =
    !location.pathname.includes("/login") &&
    !location.pathname.includes("/register");

  const REACT_APP_CHAT_BOT_URL = useMemo(() => {
    return showHeader && process.env.REACT_APP_CHAT_BOT_URL
      ? (function (d, w, c) {
          w.BrevoConversationsID = process.env.REACT_APP_CHAT_BOT_ID;
          w[c] =
            w[c] ||
            function () {
              (w[c].q = w[c].q || []).push(arguments);
            };
          var s = d.createElement("script");
          s.async = true;
          s.type = "text/javascript";
          s.src = process.env.REACT_APP_CHAT_BOT_URL;
          if (d.head) {
            if (showHeader) {
              scriptRef.current = s;
              d.head.appendChild(s);
            } else {
              if (scriptRef.current) {
                d.head.removeChild(scriptRef.current);
                scriptRef.current = null;
              }
            }
          }
        })(document, window, "BrevoConversations")
      : null;
  }, [showHeader]);

  if (!showHeader) {
    const chatScript = document.getElementById("brevo-conversations");
    if (chatScript) {
      chatScript.remove();
    }
  }

  return <script>{REACT_APP_CHAT_BOT_URL}</script>;
};
