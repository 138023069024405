import { forwardRef, useEffect, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";

import APICall from "../../../../../APICall";
import EndPoints from "../../../../../APICall/EndPoints";
import { queryClient } from "../../../../../App";
import { Constant } from "../../../../../Helper";
import {
  setReportPage,
  setLayouts,
  setReportFilter,
} from "../../../../../store/slices/report";
import { useDeferredTimeout } from "../../../../../hooks/useDeferredTimeout";
import useDebounce from "../../../../../hooks/3-useDebounce/useDebounce";
import ComponentLoader from "../../../../../components/ComponentLoader";
import store from "../../../../../store";
import { cloneDeep } from "../../../../../Helper/data";
import { produce } from "immer";

const CommonFunctions = forwardRef((props, _ref) => {
  const dispatch = useDispatch();

  //redux
  const Page = useSelector((state) => state.reportSlice.Page);
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const dataset = useDeferredTimeout({ value: dataSetData?.uuid });
  //api
  const getReportPages = async () => {
    let result = null;
    await APICall(
      "get",
      `${EndPoints.reporting_dashboards}?dataset=${dataSetData?.uuid}`
    ).then((response) => {
      if (response.status === 200 && response.data) {
        result = response.data?.results;
      }
    });
    return result;
  };

  const batchUpdateWidgets = async (payload) => {
    await APICall(
      "put",
      `${EndPoints.reporting_dashboards}${Page?.uuid}/widgets/batch_update/`,
      payload
    ).then((response) => {
      if (response.status === 200 && response.data) {
      }
    });
  };

  const ReportPages = useQuery({
    queryKey: [
      "ReportPages",
      {
        dataset: dataset,
      },
    ],
    queryFn: () => {
      const result = getReportPages();
      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    refetchOnMount: true,
    priority: 1,
    enabled: !!dataset,
  });

  useDebounce(
    () => {
      if (!Page && !ReportPages?.isFetching && ReportPages?.data?.[0]) {
        const _page = ReportPages?.data?.[0];
        updatePageLayout({
          widgets: cloneDeep(_page?.widgets),
        });
        dispatch(
          setReportFilter({
            key: "Page",
            value: _page,
          })
        );
      }
    },
    700,
    [Page, ReportPages?.status, ReportPages?.isFetching, dataset, dispatch]
  );

  //functions
  const updatePageWidgets = ({
    widgets,
    updateBatchPosition,
    updateLayout,
    isAdd,
    isDelete,
    deletedId,
  }) => {
    let updateWidgetPositions = [];
    const updateWidgets = produce(widgets, (draft) => {
      draft?.forEach((item, index) => {
        item.position = index + 1;
        updateWidgetPositions.push({
          uuid: item?.uuid,
          position: item.position,
        });
      });
    });

    const queryKey = ["ReportPages", { dataset: dataset }];
    queryClient?.setQueryData(queryKey, (oldData) => {
      const UpdatedData = produce(oldData, (draft) => {
        const index = draft?.findIndex((item) => item?.uuid === Page?.uuid);
        if (index > -1) {
          draft[index].widgets = updateWidgets;
        }
      });
      return UpdatedData;
    });
    if (updateLayout) {
      updatePageLayout({
        widgets: cloneDeep(updateWidgets),
        isAdd,
        isDelete,
        deletedId,
      });
    }
    dispatch(
      setReportPage({
        key: "widgets",
        value: updateWidgets,
      })
    );
    if (updateBatchPosition && updateWidgetPositions?.length > 0) {
      batchUpdateWidgets(updateWidgetPositions);
    }
  };

  const updatePageLayout = ({ widgets, isAdd, isDelete, deletedId }) => {
    let sumOfX = 0;
    let sumOfY = 0;
    let y = 0;
    let layoutArray = [];
    let layout = {};
    if (isAdd) {
      const storeLayout = store.getState()?.reportSlice?.layouts?.lg || [];
      layoutArray = [...storeLayout];
      sumOfY = Math.max.apply(
        null,
        storeLayout?.map((obj) => obj?.y)
      );
      sumOfX = storeLayout?.reduce(
        (sum, item) => sum + (item?.y === sumOfY ? item?.w || 0 : 0),
        0
      );

      const latestAddedWidget = widgets?.[widgets.length - 1];
      const widget =
        Constant.ReportWidgetOptionsByType?.[latestAddedWidget?.type]?.[0];
      y = Math.max.apply(
        null,
        storeLayout?.filter((obj) => obj?.y === sumOfY)?.map((obj) => obj?.h)
      );
      if (12 - sumOfX < latestAddedWidget?.width) {
        sumOfX = 0;
        sumOfY += y;
        y = 0;
      }
      layout = {
        i: latestAddedWidget?.uuid,
        x: sumOfX,
        y: sumOfY,
        w: latestAddedWidget.width,
        h: latestAddedWidget.height,
        minW: widget.width,
        minH: widget.height,
      };

      layoutArray.push(layout);
    } else if (isDelete) {
      const storeLayout = store.getState()?.reportSlice?.layouts?.lg || [];
      layoutArray = storeLayout?.filter((item) => item?.i !== deletedId);
    } else {
      widgets
        ?.sort((a, b) => a.position - b.position)
        ?.forEach((item) => {
          const widget = Constant.ReportWidgetOptionsByType?.[item?.type]?.[0];

          if (y < item?.height) {
            y = item?.height;
          }
          if (12 - sumOfX < item.width) {
            sumOfX = 0;
            sumOfY += y;
            y = 0;
          }

          layout = {
            i: item?.uuid,
            x: sumOfX,
            y: sumOfY,
            w: item.width,
            h: item.height,
            minW: widget.width,
            minH: widget.height,
          };

          layoutArray.push(layout);
          sumOfX += layout?.w || 0;
        });
    }

    dispatch(
      setLayouts({
        type: "add_all",
        obj: { lg: layoutArray },
      })
    );
  };

  useImperativeHandle(_ref, () => {
    return {
      updatePageWidgets,
      updatePageLayout,
    };
  });

  if (props?.showLoader && ReportPages?.isFetching) {
    return (
      <ComponentLoader
        loading
        hideNoDataPlaceholder
        height={"30rem"}
        size={50}
      />
    );
  } else {
    return null;
  }
});

export default CommonFunctions;
