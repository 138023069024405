import { closeSnackbar, enqueueSnackbar } from "notistack";
import { Workbox } from "workbox-window";
import i18n from "./i18n";
import { Color, Fonts } from "./Helper";
import { queryClient } from "./App";
import { removeAllCookies } from "./Helper/data";

export default function registerServiceWorker() {
  if ("development" === process.env.REACT_APP_MODE) {
    return;
  }
  // Check if the serviceWorker Object exists in the navigator object ( means if browser supports SW )
  if ("serviceWorker" in navigator) {
    const wb = new Workbox("/sw.js");
    const action = (snackbarId) => (
      <>
        <button
          onClick={() => {
            closeSnackbar(snackbarId);
            wb.messageSkipWaiting();
            queryClient?.clear();
            emptyCacheStorage(true);
          }}
          style={{
            color: Color.tailwind.green[700],
            fontFamily: Fonts.Text,
            backgroundColor: Color.tailwind.green[50],
            fontWeight: 600,
            padding: "0.5rem 1rem",
            borderRadius: "0.5rem",
            fontSize: ".9rem",
            cursor: "pointer",
          }}
        >
          {i18n.t("Reload Page")}
        </button>
      </>
    );
    const showSkipWaitingPrompt = async (event) => {
      // When `event.wasWaitingBeforeRegister` is true, a previously
      // updated service worker is still waiting.
      // You may want to customize the UI prompt accordingly.

      // This code assumes your app has a promptForUpdate() method,
      // which returns true if the user wants to update.
      // Implementing this is app-specific; some examples are:
      // https://open-ui.org/components/alert.research or
      // https://open-ui.org/components/toast.research

      // const swVersion = await wb.messageSW({ type: "GET_VERSION" });
      const token = localStorage.getItem("token");
      if (token) {
        enqueueSnackbar(i18n.t("app_version_update_message"), {
          action,
          variant: "success",
          autoHideDuration: 500000,
          preventDuplicate: true,
        });
      } else {
        wb.messageSkipWaiting();
        queryClient?.clear();
        emptyCacheStorage();
      }
    };
    // Assuming the user accepted the update, set up a listener
    // that will reload the page as soon as the previously waiting
    // service worker has taken control.
    // wb.addEventListener("controlling", () => {
    //   // console.log("🚀 /controlling:");
    //   // At this point, reloading will ensure that the current
    //   // tab is loaded under the control of the new service worker.
    //   // Depending on your web app, you may want to auto-save or
    //   // persist transient state before triggering the reload.
    //   // window.location.reload();
    // });
    // wb.addEventListener("activate", (event) => {
    // console.log("🚀 / activate:", event);
    // });
    wb.addEventListener("activated", (event) => {
      // console.log("🚀 / activated:", event);
      // `event.isUpdate` will be true if another version of the service
      // worker was controlling the page when this version was registered.
      if (!event.isUpdate) {
        // showSkipWaitingPrompt(event);
        console.log("Service worker activated for the first time!");

        // If your service worker is configured to precache assets, those
        // assets should all be available now.
      }
    });
    // wb.addEventListener("installed", (event) => {
    //   // console.log("🚀 /installed:", event);
    //   /**
    //    * We have the condition - event.isUpdate because we don't want to show
    //    * this message on the very first service worker installation,
    //    * only on the updated
    //    */
    //   if (event.isUpdate) {
    //     // showSkipWaitingPrompt(event);
    //   }
    // });
    wb.addEventListener("waiting", (event) => {
      console.log(
        `A new service worker has installed, but it can't activate` +
          `until all tabs running the current version have fully unloaded.`
      );
      showSkipWaitingPrompt(event);
    });
    wb.addEventListener("message", (event) => {
      if (event.data.type === "CACHE_UPDATED") {
        const { updatedURL } = event.data.payload;

        console.log(`A newer version of ${updatedURL} is available!`);
      }
    });

    // Add an event listener to detect when the registered
    // service worker has installed but is waiting to activate.
    // wb.update();
    wb.register();
  }
}
export function unregisterServiceWorker() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error("unregisterServiceWorker : ", error.message);
      });
  }
}

const emptyCacheStorage = async (update = false, count = 2000) => {
  removeAllCookies();
  if ("caches" in window) {
    const cacheKeys = await window.caches.keys();
    await Promise.all(
      cacheKeys.map((key) => {
        window.caches.delete(key);
      })
    );
    if (update) {
      setTimeout(() => {
        window.location.replace(window.location.href);
      }, count);
    }
  }
};
