import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Color, Fonts } from "../../Helper";
import { useTranslation } from "react-i18next";
import TailwindButton from "../Overlay/TailwindButton";
import { Box, Typography } from "@mui/material";

export default function DeleteConfirmationModal({
  open,
  onClose,
  onOk,
  message = "",
  title,
  cancel,
  confirm,
  hideDelete = false,
  children = null,
  loading = false,
  type,
}) {
  const { t } = useTranslation();

  const ActionPanel = () => {
    return (
      <>
        <Typography
          variant="h3"
          sx={{
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
            fontWeight: "600",
            color: Color.black,
          }}
        >
          {title ? title : t("Delete")}
        </Typography>
        <Box
          sx={{
            my: "1.5rem",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: "1.2rem",
              color: "#555",
              fontFamily: Fonts.Text,
            }}
          >
            {message}
          </Typography>
          {children}
        </Box>
        <Box
          sx={{
            marginTop: "1rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <TailwindButton
            text={confirm ? confirm : t("Confirm")}
            backgroundColor={
              type === "delete"
                ? Color.tailwind.red[500]
                : Color.tailwind.green[500]
            }
            onClick={onOk}
            loading={loading}
            sx={{ ml: 0 }}
          />
          {!hideDelete && (
            <TailwindButton
              type="cancel"
              text={cancel ? cancel : t("Cancel")}
              onClick={onClose}
            />
          )}
        </Box>
      </>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ zIndex: 1320 }}
      PaperProps={{
        style: {
          padding: "2rem",
          boxShadow:
            "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
          borderRadius: 8,
        },
      }}
    >
      <ActionPanel />
    </Dialog>
  );
}
