import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { remToPx } from "../../Helper/data";
import useWidth from "../../hooks/useWidth";
import CustomizedAxisTick from "./CustomizedAxisTick";

const MultiLineCharts = React.memo(
  ({ data, keyData, type = "Income" }) => {
    const currentWidth = useWidth();
  
    return (
      <ResponsiveContainer width="100%" height="99%">
        <LineChart
          width={"31.25rem"}
          height={"18.75rem"}
          data={data}
          margin={{
            top: remToPx(currentWidth, 1.25),
            right: remToPx(currentWidth, 1.875),
            left: remToPx(currentWidth, 1.25),
            bottom: remToPx(currentWidth, 0.3125),
          }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
            horizontal={false}
          />
          <XAxis
            dataKey="formatedDate"
            tickCount={12}
            minTickGap={1}
            type="category"
            allowDataOverflow={false}
            orientation={type === "Income" ? "bottom" : "top"}
            tick={
              <CustomizedAxisTick
                dy={
                  type === "Income"
                    ? remToPx(currentWidth, 1)
                    : -remToPx(currentWidth, 1)
                }
                dx={0}
              />
            }
          />
          <YAxis
            type="number"
            tickCount={12}
            dataKey={(v) => parseInt(v.gross_value)}
            interval={"preserveStartEnd"}
            tick={
              <CustomizedAxisTick
                dy={remToPx(currentWidth, 0.2)}
                dx={-remToPx(currentWidth, 1)}
                formatted
              />
            }
            // orientation={type === "Income" ? "left" : "right"}
          />
          <Tooltip cursor={{ fill: "transparent" }} />
          <Legend wrapperStyle={{ display: "none" }} />

          {keyData?.map((entry, index) => {
            return (
              <Line
                key={`cell-${index}`}
                dataKey={`${entry.title}`}
                stroke={entry.color}
                strokeWidth={2}
                type="monotone"
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    );
  },
  (prev, next) => {
    return prev.data === next.data && prev.keyData === next.keyData;
  }
);
export default MultiLineCharts;
