// material-ui
import { styled } from "@mui/material/styles";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import Translate from "../../hooks/HOC/Translate";
import { getTailwindColor } from "../../Helper/data";
import { Color } from "../../Helper";

// styles
const CardStyle = styled(Card)(({ color }) => ({
  background: getTailwindColor(color, 50),
  overflow: "hidden",
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    width: "15rem",
    height: "15rem",
    border: "1.5rem solid ",
    borderColor: getTailwindColor(color, 200),
    borderRadius: "50%",
    top: "54%",
    right: "-50%",
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: "15rem",
    height: "15rem",
    border: "3px solid ",
    borderColor: getTailwindColor(color, 300),
    borderRadius: "50%",
    top: "77%",
    right: "-15%",
  },
}));

// ==============================|| PROFILE MENU - UPGRADE PLAN CARD ||============================== //

const UpgradePlanCard = ({
  title,
  description,
  showButton = true,
  ...props
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  const onClickUpgradeToday = () => {
    navigate("/settings/subscription");
  };
  return (
    <CardStyle elevation={0} color={"violet"} {...props}>
      <CardContent sx={{ p: "1rem" }}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 500,
                fontSize: "0.9rem",
                color: theme.palette.primary.main,
              }}
            >
              {t(title)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 400,
                fontSize: "0.7rem",
                position: "relative",
                zIndex: 1,
                color: theme.palette.primary.main,
              }}
            >
              <Translate i18nkey={description} />
            </Typography>
          </Grid>
          {showButton ? (
            <Grid item>
              <Stack direction="row">
                <Button
                  onClick={onClickUpgradeToday}
                  variant="contained"
                  sx={{
                    mt: "1rem",
                    p: "0.5rem 1rem",
                    backgroundColor: theme.palette.primary.dark,
                    boxShadow: "none",
                    textTransform: "capitalize",
                    color: Color.white,
                    fontSize: "0.7rem",
                    fontWeight: 700,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.darkHover,
                    },
                  }}
                >
                  {t("Upgrade today")}
                </Button>
              </Stack>
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
    </CardStyle>
  );
};

export default UpgradePlanCard;
