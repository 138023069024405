import React from "react";
import {
  Tooltip,
  ResponsiveContainer,
  Sankey,
  Rectangle,
  Layer,
} from "recharts";
import { Color, Fonts } from "../../Helper";
import { useSelector } from "react-redux";
import { useState } from "react";
import { alpha, Box, Typography } from "@mui/material";
import useWidth from "../../hooks/useWidth";
import { remToPx } from "../../Helper/data";

function CustomNode(props) {
  let { x, y, width, height, index, payload, containerWidth } = props;
  let customHeight = height < 1 ? 1 : height;
  const currencyFormate = useSelector(
    (state) => state.settingsSlice.currencyFormate
  );
  const isOut = x + width + 6 > containerWidth;
  return (
    <Layer key={`CustomNode${index}`}>
      <Rectangle
        x={x}
        y={y}
        width={width}
        height={customHeight}
        fill={payload?.color}
        fillOpacity="1"
      />
      <text
        textAnchor={isOut ? "end" : "start"}
        x={isOut ? x - 6 : x + width + 6}
        y={y + customHeight / 2}
        fontSize="0.875rem"
        stroke={payload?.color}
      >
        {payload?.name}
      </text>
      <text
        textAnchor={isOut ? "end" : "start"}
        x={isOut ? x - 6 : x + width + 6}
        y={y + customHeight / 2 + 13}
        fontSize="0.75rem"
        stroke={payload?.color}
        strokeOpacity="0.5"
      >
        {Intl.NumberFormat("en-US", {
          notation: "compact",
          maximumFractionDigits: 1,
          style: "currency",
          currency: currencyFormate.prefix === "€" ? "EUR" : "USD",
        }).format(
          payload?.totalRevenue ? payload?.totalRevenue : payload?.value ?? 0
          // payload?.value ?? 0
        )}
      </text>
    </Layer>
  );
}

function CustomLink(props) {
  const {
    sourceX,
    targetX,
    sourceY,
    targetY,
    sourceControlX,
    targetControlX,
    linkWidth,
    index,
    payload,
  } = props;
  // console.log("🚀 ~ CustomLink ~ props", props);
  let color = alpha(payload?.source?.color ?? Color.theme.grey[500], 0.2);
  let width = linkWidth < 2 ? 2 : linkWidth;
  // const width = 20;
  const [fill, setFill] = useState(color);
  return (
    <Layer key={`CustomLink${index}`}>
      <path
        d={`
            M${sourceX},${sourceY + width / 2}
            C${sourceControlX},${sourceY + width / 2}
              ${targetControlX},${targetY + width / 2}
              ${targetX},${targetY + width / 2}
            L${targetX},${targetY - width / 2}
            C${targetControlX},${targetY - width / 2}
              ${sourceControlX},${sourceY - width / 2}
              ${sourceX},${sourceY - width / 2}
            Z
          `}
        fill={fill}
        strokeWidth={0}
        stroke={Color.theme.grey[500]}
        onMouseEnter={() => {
          setFill(alpha(Color.themeColor2, 0.3));
        }}
        onMouseLeave={() => {
          setFill(color);
        }}
      />
    </Layer>
  );
}

const SankryChart = React.memo(
  ({ data }) => {
    const currentWidth = useWidth();
    const currencyFormate = useSelector(
      (state) => state.settingsSlice.currencyFormate
    );

    return (
      <ResponsiveContainer width="100%" height="100%">
        <Sankey
          width={"100%"}
          height={"100%"}
          margin={{
            top: remToPx(currentWidth, 1.25),
            bottom: remToPx(currentWidth, 1.25),
          }}
          data={data}
          nodeWidth={remToPx(currentWidth, 1.875)}
          nodePadding={remToPx(currentWidth, 3.75)}
          linkCurvature={0.6}
          iterations={0}
          link={<CustomLink />}
          node={<CustomNode containerWidth={remToPx(currentWidth, 6)} />}
        >
          <Tooltip
            wrapperStyle={{
              backgroundColor: "transparent",
            }}
            contentStyle={{
              border: 0,
              position: "relative",
              backgroundColor: "transparent",
            }}
            itemStyle={{ color: "transparent" }}
            formatter={(value, name, item) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    p: "1rem",
                    backgroundColor: Color.white,
                    width: "fit-content",
                    height: "fit-content",
                    position: "absolute",
                    borderRadius: 2,
                    border: `1px solid ${Color.theme.grey[300]}`,
                  }}
                >
                  {item?.payload?.payload?.source ? (
                    <>
                      <Typography
                        sx={{
                          fontFamily: Fonts.Text,
                          fontWeight: 700,
                          color: `${
                            item.payload.payload?.source?.color ??
                            Color.theme.grey[500]
                          }`,
                        }}
                      >
                        {item.payload.payload?.source?.name}
                        {" ~ "}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: Fonts.Text,
                          fontWeight: 700,
                          ml: "0.3rem",
                          color: `${
                            item.payload.payload.target?.color ??
                            Color.theme.grey[500]
                          }`,
                        }}
                      >
                        {item.payload.payload.target?.name}:{" "}
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      sx={{
                        fontFamily: Fonts.Text,
                        fontWeight: 700,
                        color: `${
                          item?.payload?.payload?.color ?? Color.theme.grey[500]
                        }`,
                      }}
                    >
                      {item?.payload?.payload?.name} :
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      color:
                        (item.payload.payload?.source
                          ? item?.payload?.payload?.source?.color
                          : item?.payload?.payload?.color) ??
                        Color.theme.grey[500],
                      fontFamily: Fonts.Text,
                      fontWeight: 500,
                      ml: "0.3rem",
                    }}
                  >
                    {Intl.NumberFormat("en-US", {
                      notation: "compact",
                      maximumFractionDigits: 1,
                      style: "currency",
                      currency: currencyFormate.prefix === "€" ? "EUR" : "USD",
                    }).format(
                      item?.payload?.payload?.totalRevenue
                        ? item?.payload?.payload?.totalRevenue
                        : value ?? 0
                    )}
                  </Typography>
                </Box>
              );
            }}
          />
        </Sankey>
      </ResponsiveContainer>
    );
  },
  (prev, next) => {
    return prev.data === next.data && prev.data?.length === next.data?.length;
  }
);
export default SankryChart;
