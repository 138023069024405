import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography, useTheme } from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { setPath } from "../../store/slices/pathHeader";

const PathHeader = ({ onClickLink, data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  //redux state
  const path = useSelector((state) => state.pathHeader?.path);
  const linkText = useSelector((state) => state.pathHeader?.linkText);

  //life cycle method
  useEffect(() => {
    if (location?.pathname === "/settings") {
      dispatch(
        setPath([
          {
            id: 2,
            title: t("Account Settings"),
          },
        ])
      );
    } else {
      data?.forEach((element) => {
        if (element.url.includes(location?.pathname)) {
          dispatch(
            setPath([
              {
                id: 2,
                title: t(element?.title),
              },
            ])
          );
        }
      });
    }
  }, [data, dispatch, location?.pathname, t]);

  //functions
  const onClickHome = () => {
    navigate("/");
  };

  const onClickPath = (item) => {
    if (item?.url) {
      navigate(item?.url);
    }
  };

  const onClickBack = () => {
    window.history.back();
  };

  return (
    <Box
      sx={{
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "absolute",
        left: 16,
        top: 8,
        zIndex: 1,
      }}
    >
      <Button
        size="small"
        variant="text"
        startIcon={<ArrowBackIcon size="1.6rem" />}
        onClick={onClickBack}
        sx={{
          fontWeight: 600,
          fontSize: "0.9rem",
          lineHeight: "0.9rem",
          mr: "0.5rem",
          color: theme.palette.color.slate[500],
          p: 0,
        }}
      >
        Back
      </Button>
      <HomeIcon
        onClick={onClickHome}
        sx={{
          color: theme.palette.color.slate[500],
          fontSize: "1.45rem",
          cursor: "pointer",
        }}
      />
      {path?.map((item) => (
        <Box
          key={item?.id}
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: item?.url ? "pointer" : "auto",
          }}
          onClick={() => onClickPath(item)}
        >
          <FiberManualRecordIcon
            sx={{
              color: theme.palette.color.slate[500],
              fontSize: "0.5rem",
              mx: "0.5rem",
              "&:hover": {
                backgroundColor: theme.palette.color.slate[100],
              },
            }}
          />
          <Typography
            sx={{
              color: theme.palette.color.slate[item?.url ? 500 : 700],
              fontSize: "0.9rem",
              fontWeight: item?.url ? 500 : 700,
              borderRadius: 10,
              px: "0.5rem",
              py: "0.25rem",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor:
                  theme.palette.color.slate[item?.url ? 100 : 50],
              },
            }}
          >
            {item?.title}
          </Typography>
        </Box>
      ))}
      {linkText ?? (
        <Button onClick={() => onClickLink(linkText)}>
          <Typography
            sx={{
              color: theme.palette.color.slate[50],
              fontSize: "0.9rem",
              fontWeight: 500,
              textDecorationLine: "underline",
              textTransform: "capitalize",
            }}
          >
            {linkText}
          </Typography>
        </Button>
      )}
    </Box>
  );
};
export default PathHeader;
