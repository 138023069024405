import { Color } from "../../Helper/Color";
import { Box } from "@mui/material";
import FieldSet from "./FieldSet";
import ClearIcon from "@mui/icons-material/Clear";

const RoundFilterWrapper = (props) => {
  let {
    children,
    width,
    opacity,
    mx = "0.5rem",
    style = null,
    active = false,
    selected = false,
    hideClear = false,
    activeColor,
    defaultValue,
    onClickClear,
    ...rest
  } = props;

  const onClick = (e) => {
    e.stopPropagation();
    onClickClear(defaultValue);
  };

  return (
    <Box
      {...rest}
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        backgroundColor: Color.white,
        p: "0.5rem",
        width: width ? width : "fit-content",
        borderRadius: 2.5,
        opacity: opacity ? opacity : 1,
        boxShadow:
          "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 2px 14px -19px rgb(0 0 0 / 14%), 0px 2px 6px 0px rgb(0 0 0 / 12%)",
        height: "3.125rem",
        color: Color.themeColor,
        px: "1rem",
        mx: mx,
        cursor: "pointer",
        position: "relative",
        transition: `all 0.5s ease`,
        ...style,
      }}
    >
      <FieldSet active={active} selected={selected} activeColor={activeColor} />
      {children}
      {!hideClear && selected ? (
        <span onClick={onClick}>
          <ClearIcon
            sx={{
              position: "absolute",
              top: "30%",
              right: "5%",
              fontSize: "1.2rem",
              zIndex: 555,
              // opacity: active ? 1 : 0.2,
            }}
          />
        </span>
      ) : null}
    </Box>
  );
};
export default RoundFilterWrapper;
