import { useTranslation } from "react-i18next";
import { Stack, Typography, useMediaQuery } from "@mui/material";

import useWidth from "../../../../hooks/useWidth";
import ComponentLoader from "../../../../components/ComponentLoader";
import ThemeToggle from "../../../../components/ToggleGroupeView/ThemeToggle";
import BarCharts from "../../../../components/Charts/BarCharts";
import { cloneDeep, remToPx } from "../../../../Helper/data";

const AreaChartView = ({
  style,
  loading,
  value,
  onChange,
  chartData,
  chartKeys,
  showFilter,
  filterOptions = [],
  heading,
}) => {
  const currentWidth = useWidth();
  const { t } = useTranslation();
  const s1745 = useMediaQuery("(min-width:1745px)");
  return (
    <div style={style}>
      {loading ? (
        <ComponentLoader
          loading
          isSkeleton
          height="18rem"
          skeletonCount={1}
          placeHolderHeight="18rem"
          sx={{
            width: "100%",
          }}
          skeltonSx={{
            transform: "scale(1,1)",
          }}
        />
      ) : chartData?.length > 0 ? (
        <Stack
          direction="column"
          alignItems={"start"}
          sx={{ width: "100%", height: "90%" }}
        >
          <Typography
            variant="subtitle1"
            fontWeight={"fontWeightMediumBold"}
            color="color.slate.700"
            mt="0.25rem"
            mb="1rem"
            display={"flex"}
            alignItems={"start"}
            justifyContent={"space-between"}
            width="100%"
            lineHeight={"normal"}
            textAlign={"left"}
          >
            {t(heading)}
            {showFilter ? (
              <ThemeToggle
                value={value}
                onChange={onChange}
                options={filterOptions}
              />
            ) : null}
          </Typography>
          <BarCharts
            data={chartData || []}
            hideDefault
            barsData={cloneDeep(chartKeys || [])}
            tooltipProps={{
              position: {
                x: s1745 ? -remToPx(currentWidth, 21) : 0,
                y: s1745 ? -35 : -remToPx(currentWidth, 16),
              },
            }}
            yAxisProps={{
              dataKey: undefined,
            }}
          />
        </Stack>
      ) : null}
    </div>
  );
};

export default AreaChartView;
