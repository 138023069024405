import React, { forwardRef } from "react";
import { Tooltip, styled } from "@mui/material";
import MuiButton, { LoadingButtonOwnProps } from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";

type ThemeButtonType = "add" | "action" | "manage" | "manage2" | "default";

interface ComponentProps extends LoadingButtonOwnProps {
  onClick?: () => void;
  icon?: undefined | React.ReactNode;
  tooltip?: undefined | string;
  label?: string;
  sx?: any;
  theme?: any;
  width?: string | "fit-content";
  isHighlighted?: boolean;
  buttontype?: ThemeButtonType;
}

const Button = styled(MuiButton)(
  ({ theme, width, isHighlighted, buttontype }: ComponentProps) => ({
    backgroundColor: isHighlighted
      ? theme?.palette?.primary.main
      : theme?.palette?.color?.slate[buttontype === "manage2" ? 100 : 200],
    color: isHighlighted
      ? theme?.palette?.color?.white
      : theme?.palette?.color?.slate[600],
    borderRadius: theme.borderRadius.main,
    fontWeight:
      buttontype === "manage2" || buttontype === "default"
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightBold,
    fontSize: "0.8rem",
    lineHeight: "1.25rem",
    width: width,
    paddingBlock: "0.5rem",
    paddingInline: "0.75rem",
    "&  svg": {
      fontSize: "1.3rem",
      marginRight: buttontype === "default" ? 0 : "0.5rem",
      color: isHighlighted
        ? theme?.palette?.color?.white
        : theme?.palette?.color?.slate[600],
    },
    "&: hover": {
      backgroundColor: isHighlighted
        ? theme?.palette?.primary?.dark
        : theme?.palette?.color?.slate[buttontype === "manage2" ? 200 : 300],
    },
  })
);
const ActionButton = forwardRef(
  (
    { onClick, icon, tooltip, label, buttontype, ...props }: ComponentProps,
    ref: any
  ) => {
    const { t } = useTranslation();
    return (
      <Tooltip arrow placement={"top"} title={tooltip ? t(tooltip) : tooltip}>
        <Button onClick={onClick} buttontype={buttontype} ref={ref} {...props}>
          {icon}
          {label ? t(label) : null}
        </Button>
      </Tooltip>
    );
  }
);

export default ActionButton;
