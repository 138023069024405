import { Color, Fonts } from "../../Helper";
import CustomPopover from "../PopOver";
import {
  Box,
  Checkbox,
  Chip,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { AiOutlineBarChart } from "react-icons/ai";
import Tooltip from "@mui/material/Tooltip";
import RoundFilterWrapper from "../RoundFilterWrapper/index";
import { alpha } from "@mui/material/styles";
import Icon from "../Icon";
import CustomerStateToggle from "./CustomerStateToggle";

import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import { useDispatch, useSelector } from "react-redux";
import { setAppliedFilterslist } from "../../store/slices/global";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import CategoryTreeSelect from "../Overlay/CategoryTreeSelect";
const CustomerHeader = ({
  scatterChartData,
  width = "93.75rem",
  setScatterChartData,
  tab,
  type = "Income",
  isCatOverlayOpen = false,
  setIsCatOverlayOpen = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );
  const selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice?.selectionCategoriesByID
  );
  const [anchorEl, setAnchorEl] = useState(null);

  let selectionList = [
    {
      id: 1,
      key: "new",
      title: "New",
      tooltipTitle: type === "Income" ? "New Customers" : "New Suppliers",
    },
    {
      id: 2,
      key: "top",
      title: "Top",
      tooltipTitle: type === "Income" ? "Top Customers" : "Top Suppliers",
    },
    {
      id: 3,
      key: "trouble",
      title: "Trouble",
      tooltipTitle:
        type === "Income" ? "Problematic Customers" : "Problematic Suppliers",
    },
  ];
  let frequencyList = [
    {
      id: 1,
      key: "irregular",
      title: "Irregular",
    },
    {
      id: 2,
      key: "weekly",
      title: "Weekly",
    },
    {
      id: 3,
      key: "monthly",
      title: "Monthly",
    },
    {
      id: 4,
      key: "yearly",
      title: "Yearly",
    },
  ];

  const calender = [
    { title: "irregular", color: Color.purple, colorbg: Color.purple50 },
    { title: "daily", color: Color.pink, colorbg: Color.pink50 },
    { title: "weekly", color: Color.cyan, colorbg: Color.cyan50 },
    { title: "monthly", color: Color.blue, colorbg: Color.blue50 },
    { title: "yearly", color: Color.indigo, colorbg: Color.indigo50 },
  ];

  const subscription = useSelector(
    (state) => state.settingsSlice?.subscription
  );

  //function
  const onChangeLegend = (e, index) => {
    let data = [...scatterChartData];
    data[index].isSelected = !data[index].isSelected;
    let key = data?.filter((o1) => o1.isSelected)?.map((o1) => o1.title);
    let selectedFilters = appliedFilterlist?.reports;
    let selectedClient = appliedFilterlist?.reports?.client;
    let selectedChartKey = appliedFilterlist?.reports?.client?.chartKey;
    let filterObj = {
      ...appliedFilterlist,
      reports: {
        ...selectedFilters,
        client: {
          ...selectedClient,
          chartKey: {
            ...selectedChartKey,
            [type]: key,
          },
        },
      },
    };
    dispatch(setAppliedFilterslist(filterObj));
    setScatterChartData(data);
  };

  const handleFormat = (event, newValues) => {
    let selectedFilters = appliedFilterlist?.reports;
    let selectedClient = appliedFilterlist?.reports?.client;
    let selectedValue = appliedFilterlist?.reports?.client?.formate;
    let filterObj = {
      ...appliedFilterlist,
      reports: {
        ...selectedFilters,
        client: {
          ...selectedClient,
          formate: {
            ...selectedValue,
            [type]: newValues,
          },
        },
      },
    };
    dispatch(setAppliedFilterslist(filterObj));
  };

  const handleFrequency = (event, newValues) => {
    let selectedFilters = appliedFilterlist?.reports;
    let selectedClient = appliedFilterlist?.reports?.client;
    let selectedValue = appliedFilterlist?.reports?.client?.frequency;
    let filterObj = {
      ...appliedFilterlist,
      reports: {
        ...selectedFilters,
        client: {
          ...selectedClient,
          frequency: {
            ...selectedValue,
            [type]: newValues,
          },
        },
      },
    };
    dispatch(setAppliedFilterslist(filterObj));
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
    setIsCatOverlayOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsCatOverlayOpen(true);
  };

  const onClickCategoryTitle = (e, item) => {
    let selectedFilters = appliedFilterlist?.reports;
    let selectedClient = appliedFilterlist?.reports?.client;
    let selectedValue = appliedFilterlist?.reports?.client?.selectedCategory;
    let selectedCategory = appliedFilterlist?.reports?.client
      ?.selectedCategory
      ? appliedFilterlist?.reports?.client?.selectedCategory[type] ?? []
      : [];
    let data = selectedCategory ? [...selectedCategory] : [];
    let find = data?.filter((o1) => o1 === item?.uuid);
    if (find?.length > 0) {
      data = data?.filter((o1) => o1 !== item?.uuid);
    } else {
      let selectedItem = selectionCategories?.find(
        (o1) => o1?.value === item?.uuid
      );
      data.push(selectedItem?.value);
    }
    let filterObj = {
      ...appliedFilterlist,
      reports: {
        ...selectedFilters,
        client: {
          ...selectedClient,
          selectedCategory: {
            ...selectedValue,
            [type]: data,
          },
        },
      },
    };
    dispatch(setAppliedFilterslist(filterObj));
  };

  const handleDelete = (id) => {
    let selectedFilters = appliedFilterlist?.reports;
    let selectedClient = appliedFilterlist?.reports?.client;
    let selectedValue = appliedFilterlist?.reports?.client?.selectedCategory;
    let selectedCategory = appliedFilterlist?.reports?.client
      ?.selectedCategory
      ? appliedFilterlist?.reports?.client?.selectedCategory[type] ?? []
      : [];
    let data = selectedCategory ? [...selectedCategory] : [];
    let removed = data?.filter((o1) => o1 !== id);
    let filterObj = {
      ...appliedFilterlist,
      reports: {
        ...selectedFilters,
        client: {
          ...selectedClient,
          selectedCategory: {
            ...selectedValue,
            [type]: removed,
          },
        },
      },
    };
    dispatch(setAppliedFilterslist(filterObj));
  };

  if (!appliedFilterlist?.reports?.client) {
    return null;
  }
  if (
    !appliedFilterlist?.reports?.client?.formate ||
    !appliedFilterlist?.reports?.client?.formate[type]
  ) {
    return null;
  }
  if (
    !appliedFilterlist?.reports?.client?.frequency ||
    !appliedFilterlist?.reports?.client?.frequency[type]
  ) {
    return null;
  }

  //render Function
  const popOver = () => {
    const label = { inputProps: { "aria-label": "Checkbox demo" } };
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CustomPopover
          title={"Chart Options"}
          width={"10.5rem"}
          color={Color.themeColor}
          border="none"
          justifyContent="center"
          iconSize={"1.375rem"}
          ml={"1rem"}
        >
          {" "}
          <Box
            sx={{
              p: "0.5rem",
              width: "15.625rem",
              height: "fit-content",
              minHeight: "12.5rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            {scatterChartData?.map((item, index) => {
              if (item) {
                return (
                  <Box
                    key={`datakey-${index}`}
                    sx={{ display: "inline-flex", alignItems: "center" }}
                  >
                    <Checkbox
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      {...label}
                      checked={item?.isSelected}
                      onChange={(e) => onChangeLegend(e, index)}
                      size="small"
                      sx={{
                        color: item?.color,
                        "&.Mui-checked": {
                          color: item?.color,
                        },
                      }}
                    />
                    {item?.isSelected ? (
                      <span
                        style={{
                          color: item?.color,
                          fontSize: "0.8rem",
                          fontWeight: 500,
                          fontFamily: Fonts.Text,
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {item?.title}
                      </span>
                    ) : (
                      <del
                        style={{
                          color: item?.color,
                          fontSize: "0.8rem",
                          fontWeight: 500,
                          fontFamily: Fonts.Text,
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {item?.title}
                      </del>
                    )}
                  </Box>
                );
              }
              return null;
            })}
          </Box>
        </CustomPopover>
      </Box>
    );
  };

  return (
    <>
      {Boolean(anchorEl) && (
        <CategoryTreeSelect
          anchorEl={anchorEl}
          handleClosePopOver={handleClosePopOver}
          onClickCategoryTitle={onClickCategoryTitle}
          type={2}
        />
      )}
      <Box
        sx={{
          width: width,
          display: "flex",
          alignItems: "center",
          position: "relative",
          justifyContent: "flex-end",
          mt: "1rem",
        }}
      >
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            zIndex: 100,

            "& .MuiOutlinedInput-notchedOutline": {
              border: `0px none ${Color.headerIconLight}`,
            },
            "& .Mui-selected": {
              "& p": {
                color: alpha(Color.themeColor, 0.75),
                fontWeight: 500,
              },
              backgroundColor: `${alpha(Color.themeColor, 0.1)} !important`,
            },
            "& .MuiToggleButtonGroup-grouped": {
              margin: "0.25rem",
              border: 0,
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: alpha(Color.themeColor, 0.1),
              },
              "&:not(:first-of-type)": {
                borderRadius: 1,
              },
              "&:first-of-type": {
                borderRadius: 1,
              },
            },
          }}
        >
          {tab === "0" && (
            <>
              <RoundFilterWrapper opacity={0.8} mx={"1rem"}>
                <Tooltip
                  title={"Toggle Frequency"}
                  disableFocusListener
                  placement="top"
                  arrow
                >
                  <ScatterPlotIcon
                    sx={{
                      fontSize: "1rem",
                      color: alpha(Color.themeColor, 0.7),
                      mr: "0.5rem",
                    }}
                  />
                </Tooltip>

                <ToggleButtonGroup
                  size="small"
                  value={
                    appliedFilterlist?.reports?.client?.frequency[type] || []
                  }
                  onChange={handleFrequency}
                  aria-label="text formatting"
                >
                  {frequencyList?.map((item) => {
                    return (
                      <ToggleButton
                        value={item?.key}
                        aria-label={item?.key}
                        key={item?.id}
                      >
                        <Tooltip
                          title={item?.tooltipTitle}
                          disableFocusListener
                          placement="top"
                          arrow
                        >
                          <p>{item?.title}</p>
                        </Tooltip>
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
              </RoundFilterWrapper>
              <RoundFilterWrapper
                opacity={1}
                sx={{
                  "& #customized-hook-demo": {
                    borderRadius: 2,
                    height: "1.625rem",
                  },
                }}
              >
                <LocalOfferIcon
                  sx={{
                    fontSize: "1.2rem",
                    color: Color.themeColor,
                    mr: "0.5rem",
                    ml: "0.5rem",
                    opacity: 0.2,
                  }}
                />
                <Tooltip
                  title={t("tooltip_Category_Filter")}
                  disableFocusListener
                  arrow
                  placement="top"
                >
                  <Box
                    sx={{
                      display: "inline-flex",
                      width: "18.75rem",
                      height: "1.875rem",
                      alignItems: "center",
                      borderRadius: "0.875rem",
                      backgroundColor: Color.hederEleBg,
                      scrollbarWidth: "none",
                      "&:hover": {
                        backgroundColor: alpha(Color.themeColor, 0.1),
                      },
                      "&::-webkit-scrollbar": {
                        width: "0px",
                        height: "0px",
                        background:
                          "transparent" /* make scrollbar transparent */,
                      },
                      overflowX: "scroll",
                    }}
                    onClick={handleClick}
                  >
                    {appliedFilterlist?.reports?.client?.selectedCategory &&
                    appliedFilterlist?.reports?.client?.selectedCategory[
                      type
                    ] &&
                    appliedFilterlist?.reports?.client?.selectedCategory[type]
                      ?.length > 0 ? (
                      appliedFilterlist?.reports?.client?.selectedCategory[
                        type
                      ]?.map((ele) => {
                        let item = selectionCategoriesByID[ele]
                          ? selectionCategoriesByID[ele][0] ?? null
                          : null;
                        return (
                          <Chip
                            key={item?.value}
                            size="small"
                            label={item?.label}
                            onDelete={() => handleDelete(item?.value)}
                            sx={{
                              height: "1.7rem",
                              color: Color.black,
                              fontSize: "0.6rem",
                              fontWeight: 500,
                              fontFamily: Fonts.Text,
                              backgroundColor: alpha(
                                item?.color ?? Color.unCategorizedCardBg,
                                0.4
                              ),
                              borderRadius: 1,
                              ml: "0.25rem",
                            }}
                          />
                        );
                      })
                    ) : (
                      <Typography
                        sx={{
                          width: "fit-content",
                          display: "inline-flex",
                          alignItems: "center",
                          fontSize: "0.8rem",
                          fontWeight: 500,
                          fontFamily: Fonts.Text,
                          textAlign: "left",
                          color: alpha(Color.themeColor, 0.6),
                          ml: "1rem",
                        }}
                      >
                        {t("Category_Filter")}
                      </Typography>
                    )}
                  </Box>
                </Tooltip>
              </RoundFilterWrapper>
            </>
          )}
          {tab === "1" &&
          subscription?.clients_chart &&
          subscription?.suppliers_chart ? (
            <RoundFilterWrapper opacity={0.8} mx={"1rem"}>
              <Tooltip
                title={
                  type === "Income"
                    ? "Toggle customer charts"
                    : "Toggle supplier charts"
                }
                disableFocusListener
                placement="top"
                arrow
              >
                <ScatterPlotIcon
                  sx={{
                    fontSize: "1rem",
                    color: alpha(Color.themeColor, 0.7),
                    mr: "0.5rem",
                  }}
                />
              </Tooltip>

              <ToggleButtonGroup
                size="small"
                value={appliedFilterlist?.reports?.client?.formate[type]}
                onChange={handleFormat}
                aria-label="text formatting"
              >
                {selectionList?.map((item) => {
                  return (
                    <ToggleButton
                      value={item?.key}
                      aria-label={item?.key}
                      key={item?.id}
                    >
                      <Tooltip
                        title={item?.tooltipTitle}
                        disableFocusListener
                        placement="top"
                        arrow
                      >
                        <p>{item?.title}</p>
                      </Tooltip>
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </RoundFilterWrapper>
          ) : (
            <span style={{ height: "3.125rem" }}></span>
          )}
          {tab === "1" &&
            subscription?.clients_chart &&
            subscription?.suppliers_chart && (
              <RoundFilterWrapper
                opacity={0.8}
                width={tab === "1" ? "26.75rem" : "13.125rem"}
                mx={0}
              >
                <Box
                  variant="h6"
                  sx={{
                    mr: "0.5rem",
                    opacity: 0.5,
                    fontFamily: Fonts.Text,
                    fontSize: "1rem",
                  }}
                >
                  <Icon
                    icon={<AiOutlineBarChart />}
                    fontSize={"1.375rem"}
                  ></Icon>
                </Box>
                {tab === "1" && popOver()}
                <Tooltip
                  title={"Group States"}
                  disableFocusListener
                  placement="top"
                  arrow
                >
                  <CustomerStateToggle type={type} />
                </Tooltip>
              </RoundFilterWrapper>
            )}
          {tab !== "0" && tab !== "1" && (
            <RoundFilterWrapper opacity={0.8} width={"16rem"} mx={0}>
              <Box
                variant="h6"
                sx={{
                  mr: "0.5rem",
                  opacity: 0.5,
                  fontFamily: Fonts.Text,
                  fontSize: "1rem",
                }}
              >
                <Icon icon={<AiOutlineBarChart />} fontSize={"1.375rem"}></Icon>
              </Box>
              {tab === "1" && popOver()}
              <Tooltip
                title={"Group States"}
                disableFocusListener
                placement="top"
                arrow
              >
                <CustomerStateToggle type={type} />
              </Tooltip>
            </RoundFilterWrapper>
          )}
        </Box>
      </Box>
    </>
  );
};
export default CustomerHeader;
