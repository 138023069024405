import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { memo, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIsFetching } from "@tanstack/react-query";

import AccountView from "./AccountView";
import HeaderView from "../HeaderView";
import { queryClient } from "../../../App";
import { getTailwindColor } from "../../../Helper/data";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import IntegrationsDialogView from "../../../components/Header/DatasetHeader/IntegrationsDialogView";
import { setDataSource } from "../../../store/slices/global";
import EndPoints from "../../../APICall/EndPoints";
import APICall from "../../../APICall";
import { useDispatch } from "react-redux";
import { Constant } from "../../../Helper";

const BankAccount = ({
  type = "all",
  setTab,
  tab,
  accountingTab,
  setAccountingTab,
}) => {
  const navigate = useNavigate();
  const isDSChanged = useRef(false);
  const dispatch = useDispatch();

  //redux
  const dataSource = useSelector((state) => state.globalSlice.dataSource);

  //state
  const [value, setValue] = useState({
    connected: [],
    disconnected: [],
    archived: [],
  });
  const [accountingValue, setAccountingValue] = useState({
    connected: [],
    disconnected: [],
    archived: [],
  });
  const [isOpen, setIsOpen] = useState(false);

  //data
  const typeList = useMemo(() => {
    return dataSource?.map((o1) => o1.type);
  }, [dataSource]);

  //api
  const getDataSource = async () => {
    await APICall("get", EndPoints.integrations).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setDataSource(data));
      }
    });
  };

  //functions
  const onClickEdit = () => {
    navigate("/settings/Integrations");
  };

  const handleClickClose = () => {
    setIsOpen(false);
    if (isDSChanged.current) {
      getDataSource();
    }
  };
  const onClickAddBank = () => {
    setIsOpen("add");
  };

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "30rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <IntegrationsDialogView
        open={Boolean(isOpen)}
        type={isOpen}
        handleClickClose={handleClickClose}
        isDSChanged={isDSChanged}
      />
      {/* {Constant.IntegrationsTypeOptions?.map((item) => {
        return (
          <Typography
            key={item?.value}
            onClick={() => onClickMenu(item)}
            variant="subtitle2"
            fontWeight={"fontWeightBold"}
            sx={{
              cursor: "pointer",
              lineHeight: "normal",
              width: "16rem",
              borderRadius: theme.borderRadius.main,
              backgroundColor:
                tab === item?.value
                  ? theme.palette.color.slate[200]
                  : "transparent",
              color: theme.palette.color.slate[tab === item?.value ? 700 : 500],
              p: "0.75rem",
              "&:hover": {
                backgroundColor: theme.palette.color.slate[200],
              },
            }}
          >
            {t(item?.label)}
          </Typography>
        );
      })} */}
      <HeaderView
        title="Bank Accounts"
        onClickEdit={onClickEdit}
        isManage
        component={
          <DsCountView
            value={value}
            setValue={setValue}
            setTab={setTab}
            tab={tab}
            dsType={[10, 12]}
          />
        }
      />
      <AccountView
        dsType={[10, 12]}
        type={type}
        tab={tab}
        onClickAddBank={onClickAddBank}
        sx={{ mt: "1.5rem" }}
        // showTotal
      />

      <HeaderView
        title="Integrations"
        sx={{ mt: "4rem" }}
        onClickEdit={onClickEdit}
        component={
          <DsCountView
            value={accountingValue}
            setValue={setAccountingValue}
            setTab={setAccountingTab}
            tab={accountingTab}
            dsType={[19]}
          />
        }
      />
      <AccountView
        dsType={[19]}
        type={type}
        tab={accountingTab}
        onClickAddBank={onClickAddBank}
        sx={{ mt: "1.5rem" }}
      />

      {typeList?.includes(2) ? (
        <>
          <HeaderView
            title="Misc"
            sx={{ mt: "4rem" }}
            onClickEdit={onClickEdit}
          />
          <AccountView
            dsType={[2]}
            type={type}
            sx={{ mt: "1.5rem" }}
            onClickAddBank={onClickAddBank}
          />
        </>
      ) : null}
    </Box>
  );
};

export default BankAccount;
const DsCountView = memo(({ setValue, value, setTab, tab, dsType }) => {
  const { t } = useTranslation();
  const accounts = useSelector((state) => state.globalSlice.accounts);
  const dataSource = useSelector((state) => state.globalSlice.dataSource);
  const dataSourceById = useSelector(
    (state) => state.globalSlice.dataSourceById
  );
  const isFetchingIntegrations = useIsFetching({ queryKey: ["integrations"] });

  const updateCount = () => {
    let connected = [];
    let disconnected = [];
    let archived = [];
    if (dsType?.includes(19)) {
      dataSource?.forEach((ds) => {
        if (!ds?.internal_dataset && ds?.uuid && dsType.includes(ds?.type)) {
          if (ds?.state === 2) {
            archived.push(ds?.uuid);
            return;
          }
          if (ds?.state === 3) {
            disconnected.push(ds?.uuid);
            return;
          }
          const queryData = queryClient.getQueryData([
            "integrations",
            {
              data_source: ds?.uuid,
              apiType:
                ds?.type === 12
                  ? "data_source_finapi_connections"
                  : "data_source_external_connections",
            },
          ]);
          if (
            queryData === "COMPLETED" ||
            queryData === "UPDATED" ||
            queryData === "AUTHENTICATING"
          ) {
            connected.push(ds?.uuid);
          } else {
            disconnected.push(ds?.uuid);
          }
        }
      });
    } else {
      accounts?.forEach((o1) => {
        const ds = dataSourceById?.[o1?.data_source]?.[0];
        if (
          o1?.name !== "DEMO" &&
          !ds?.internal_dataset &&
          ds?.uuid &&
          dsType.includes(ds?.type)
        ) {
          if (dataSourceById?.[o1?.data_source]?.[0]?.state === 2) {
            archived.push(o1.data_source);
            return;
          }
          if (dataSourceById?.[o1?.data_source]?.[0]?.state === 3) {
            disconnected.push(o1.data_source);
            return;
          }
          const queryData = queryClient.getQueryData([
            "integrations",
            {
              data_source: ds?.uuid,
              apiType:
                ds?.type === 12
                  ? "data_source_finapi_connections"
                  : "data_source_external_connections",
            },
          ]);
          if (
            queryData === "COMPLETED" ||
            queryData === "UPDATED" ||
            queryData === "AUTHENTICATING"
          ) {
            connected.push(o1.data_source);
          } else {
            disconnected.push(o1.data_source);
          }
        }
      });
    }

    setValue({ connected, disconnected, archived });
  };

  const onClick = (tabValue) => {
    setTab(tabValue);
  };

  useDebounce(
    () => {
      if (dataSourceById && accounts && isFetchingIntegrations === 0) {
        updateCount();
      }
    },
    300,
    [accounts, dataSourceById, isFetchingIntegrations],
    true
  );

  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        gap: "1rem",
        marginRight: "1rem",
      }}
    >
      <Button
        onClick={() => onClick("all")}
        style={{
          fontSize: "0.8rem",
          fontWeight: "500",
          color: getTailwindColor("slate", tab === "all" ? 800 : 500),
          backgroundColor:
            tab === "all" ? getTailwindColor("slate", 200) : "unset",
        }}
      >
        {t("All")}
      </Button>

      <Button
        onClick={() => onClick("connected")}
        style={{
          fontSize: "0.8rem",
          fontWeight: "500",
          color: getTailwindColor("slate", tab === "connected" ? 800 : 500),
          minWidth: "4rem",
          backgroundColor:
            tab === "connected" ? getTailwindColor("slate", 200) : "unset",
        }}
      >
        {t("connection_state_connected")} ({value?.connected?.length || 0})
      </Button>

      <Button
        onClick={() => onClick("disconnected")}
        style={{
          fontSize: "0.8rem",
          fontWeight: "500",
          color: getTailwindColor("slate", tab === "disconnected" ? 800 : 500),
          backgroundColor:
            tab === "disconnected" ? getTailwindColor("slate", 200) : "unset",
        }}
      >
        {t("connection_state_disconnected")} ({value?.disconnected?.length || 0}
        )
      </Button>
    </div>
  );
});
