import * as React from "react";
import DatasetDetailsCard from "./DatasetDetailsCard";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

const DatasetsGridView = ({ spacing }) => {
  //redux
  const dataSetList = useSelector((state) => state.boardSlice.dataSetList);
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const testLoading = useSelector((state) => state.appSlice.testLoading);
  return (
    <Box
      sx={{
        display: "flex",
        gap: `${spacing}rem ${spacing}rem`,
        flexWrap: "wrap",
        width: "fit-content",
        justifyContent: { xs: "center", md: "start" },
      }}
    >
      {(!isAllHeaderApiFetched || testLoading
        ? Array.from(new Array(3))
        : dataSetList
      )?.map((dataset, index) => {
        return (
          <DatasetDetailsCard
            key={`datasetDetailsCard-${index}`}
            dataset={dataset}
          />
        );
      })}
    </Box>
  );
};
export default DatasetsGridView;
