import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  staffData: [],
  employeeStateTab: "Planned",
  contractData: [],
};

const staffSlice = createSlice({
  name: "staffSlice",
  initialState,
  reducers: {
    setStaffData: (state, action) => {
      state.staffData = action.payload;
    },
    setEmployeeStateTab: (state, action) => {
      state.employeeStateTab = action.payload;
    },
    setContractData: (state, action) => {
      state.contractData = action.payload;
    },
  },
});
export const { setStaffData, setEmployeeStateTab, setContractData } =
  staffSlice.actions;
export default staffSlice;
