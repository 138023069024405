import { Dialog } from "@mui/material";
import ReconcileListView from "../ReconcileListView";
import { useDispatch, useSelector } from "react-redux";
import { setPopupStatus } from "../../../../store/slices/datasets";
import { useRef } from "react";

const ReconcileListViewModal = () => {
  const dispatch = useDispatch();
  let isDataUpdated = useRef(false);

  //redux
  const popupStatus = useSelector((state) => state.datasetSlice?.popupStatus);

  //functions
  const onCloseReconcile = () => {
    dispatch(setPopupStatus(null));
    if (isDataUpdated.current) {
      popupStatus?.payload?.updateOverlay({ from_reconcile_overlay: true });
    }
  };

  return (
    <Dialog
      open={popupStatus?.open}
      onClose={onCloseReconcile}
      sx={{
        zIndex: 1304,
        "& .MuiPaper-root": {
          position: "relative",
          maxWidth: "none",
          width: "88vw",
          height: "70vh",
        },
      }}
    >
      <ReconcileListView
        isDataUpdated={isDataUpdated}
        startDate={popupStatus?.payload?.startDate}
        endDate={popupStatus?.payload?.endDate}
        type={popupStatus?.payload?.type}
      />
    </Dialog>
  );
};

export default ReconcileListViewModal;
