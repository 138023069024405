import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loanData: [],
  loanStateTab: "Planned",
  loanTab: "loan",
  loanSearchText: "",
  loanCategory: [],
};

const loanSlice = createSlice({
  name: "loanSlice",
  initialState,
  reducers: {
    setLoanData: (state, action) => {
      state.loanData = action.payload;
    },
    setLoanStateTab: (state, action) => {
      state.loanStateTab = action.payload;
    },
    setLoanTab: (state, action) => {
      state.loanTab = action.payload;
    },
    setLoanSearchText: (state, action) => {
      state.loanSearchText = action.payload;
    },
    setLoanCategory: (state, action) => {
      state.loanCategory = action.payload;
    },
  },
});
export const {
  setLoanData,
  setLoanStateTab,
  setLoanTab,
  setLoanSearchText,
  setLoanCategory,
} = loanSlice.actions;
export default loanSlice;
