import React from "react";
import { formatValue } from "react-currency-input-field";
import { Divider, Paper, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Color, Fonts } from "../../Helper";
import { getTailwindColor } from "../../Helper/data";

const CustomTooltip = ({
  active,
  payload,
  barsData,
  dynamicColor,
  showOriginalColor,
}) => {
  const { t } = useTranslation();
  const currencyFormate = useSelector(
    (state) => state.settingsSlice.currencyFormate
  );
  if (active && payload && payload.length) {
    let payloadData = payload?.[0]?.payload;
    return (
      <Paper
        sx={{
          p: "1rem",
          borderRadius: 2,
          backgroundColor: Color.white,
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          boxShadow: "0 4px -2px 0 rgba(0,0,0,0.13)",
        }}
      >
        <Typography
          sx={{
            textAlign: "left",
            fontSize: "0.8rem",
            fontFamily: Fonts.Text,
            fontWeight: 500,
            width: "100%",
            mb: "0.5rem",
          }}
        >
          {" "}
          {payloadData?.due_date}:
        </Typography>

        {barsData
          ?.sort((a, b) => a?.position - b?.position)
          ?.map((item) => {
            const value = item?.absoluteValue
              ? Math.abs(payloadData?.[item?.dataKey] || 0)
              : payloadData?.[item?.dataKey] || 0;
            if (item?.hideTooltip || (!item?.hideBar && value === 0)) {
              return null;
            }
            return (
              <Stack key={item?.key} sx={{ minWidth: "10rem" }}>
                {item?.showTopSeparator ? (
                  <Divider sx={{ width: "100%", my: "0.5rem" }} />
                ) : null}
                <Stack
                  direction={"row"}
                  sx={{ width: "100%" }}
                  alignItems={"center"}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{
                        width: "0.6rem",
                        height: "0.6rem",
                        borderRadius: 6,
                        backgroundColor: dynamicColor
                          ? payloadData?.[item?.dataKey] >= 0
                            ? getTailwindColor("green", item?.shade || 500)
                            : getTailwindColor("red", item?.shade || 500)
                          : showOriginalColor
                            ? item?.color
                            : getTailwindColor(item?.color, item?.shade || 500),
                        marginRight: "0.5rem",
                      }}
                    ></span>
                    <Typography
                      sx={{
                        fontSize: "0.7rem",
                        lineHeight: "0.7rem",
                        fontFamily: Fonts.Text,
                        fontWeight: item?.isBold ? 800 : 500,
                        display: "flow-root",
                        textAlign: "left",
                        width: "9rem",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {t(item?.key)}
                    </Typography>
                  </div>

                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      fontFamily: Fonts.Text,
                      fontWeight: item?.isBold ? 800 : 500,
                      ml: "0.5rem",
                      minWidth: "7rem",
                      textAlign: "right",
                    }}
                  >
                    {payloadData &&
                      formatValue({
                        value: String(
                          parseFloat(
                            item?.absoluteValue
                              ? Math.abs(payloadData?.[item?.dataKey] || 0)
                              : payloadData?.[item?.dataKey] || 0
                          ).toFixed(2)
                        ),
                        groupSeparator: currencyFormate.groupSeparator,
                        decimalSeparator: currencyFormate.decimalSeparator,
                        prefix: currencyFormate.prefix,
                      })}
                  </Typography>
                </Stack>
              </Stack>
            );
          })}
      </Paper>
    );
  }

  return null;
};
export default CustomTooltip;
