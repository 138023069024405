import {
  alpha,
  Box,
  Button,
  Chip,
  List,
  Paper,
  Tab,
  Tabs,
  Typography,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { formatValue } from "react-currency-input-field";
import _ from "underscore";
import { enqueueSnackbar } from "notistack";

import { format, subMonths, subYears, eachQuarterOfInterval } from "date-fns";

import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Popover from "@mui/material/Popover";

import { Color, Constant, Fonts, Images } from "../../../Helper";
import initialData, {
  getPercentageChange,
  getShare,
  randomColor,
  remToPx,
  updateBatchTransactions,
} from "../../../Helper/data";

import APICall from "../../../APICall";
import EndPoints from "../../../APICall/EndPoints";

import { setContacts } from "../../../store/slices/global";
import { setTransactionData } from "../../../store/slices/board";
import { setLoading } from "../../../store/slices/appmain";
import { setTransactionsOverlayStatus } from "../../../store/slices/datasets";
import { setStepIndex, setSteps, startTour } from "../../../store/slices/tour";

import Placeholder from "../../../components/Placeholder";
import WarnView from "../../../components/WarnView";
import CustomerHeader from "../../../components/CustomerHeader";
import ContactSelect from "../../../components/ContactSelect";
import MultiLineCharts from "../../../components/Charts/MultilLineCharts";
import ScatterChartView from "../../../components/Charts/ScatterChart";

import useWidth from "../../../hooks/useWidth";

const PREFIX = "Customer";
const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  table: `${PREFIX}-table`,
};
const color = [
  Color.theme.red[500],
  Color.theme.amber[500],
  Color.theme.blue[500],
  Color.theme.green[500],
  Color.theme.orange[500],
];
const Root = styled("div")(({ width, height, tab }) => ({
  [`& .${classes.root}`]: {
    display: "flex",
    justifyContent: "space-between",
    width: width,
    alignItems: "center",
    "& .MuiTab-root": {
      minHeight: "3.4375rem",
      maxWidth: "14.4375rem",
      color: Color.deepPurple700,
      textTransform: "capitalize",
    },
    "& .MuiTabs-flexContainer": {
      alignItems: "center",
    },
  },
  [`& .${classes.paper}`]: {
    "& .MuiPaper-root": {
      boxShadow:
        "0px 3px 1px -2px rgb(0 0 0 / 16%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    },
    width: tab === "2" ? "65%" : width,
    borderRadius: "5px",
    backgroundColor: Color.white,
    overflowY: "auto",
    height: height,
    position: "relative",
    "& MuiDataGrid-virtualScroller": {
      width: width,
    },
    "& .super-app-theme--cell": {
      backgroundColor: alpha(Color.headerIconLight, 0.3),
    },
  },
  [`& .${classes.table}`]: {
    border: 0,
    "& .MuiDataGrid-columnHeaderTitle": {
      fontSize: "0.8rem",
      fontWeight: 600,
    },
  },
}));

const actionList = [
  { uuid: 1, title: "Remove customer" },
  { uuid: 2, title: "Set customer" },
];

const tabOptions = [
  { id: "1", path: "customers-portfolio-analysis" },
  { id: "2", path: "top-customers" },
  { id: "5", path: "customers-list" },
  { id: "6", path: "merge-customers" },
];

const Clients = ({ setAllowJoyRide, allowJoyRide }) => {
  const currentWidth = useWidth();
  let width = remToPx(currentWidth, 93.75);
  let height = remToPx(currentWidth, 43.75);
  let selectedIds = useRef([]);
  let transactionTitle = useRef(null);
  let updateType = useRef("single");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  //redux
  const currencyFormate = useSelector(
    (state) => state.settingsSlice.currencyFormate
  );
  const transactionData = useSelector(
    (state) => state.boardSlice?.transactionData
  );
  const contacts = useSelector((state) => state.globalSlice?.contacts);
  const subscription = useSelector(
    (state) => state.settingsSlice?.subscription
  );
  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );
  let customers = useMemo(() => {
    return contacts?.filter((o1) => o1.type === 1);
  }, [contacts]);

  //states
  const [tab, setTab] = useState("1");
  const [rows, setRows] = useState([]);
  const [scatterChartData, setScatterChartData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [keyData, setKeyData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [showInfo, setShowInfo] = useState(false);

  const topCustomerColumn = useMemo(
    () => [
      {
        field: "title",
        headerName: "Title",
        cellClassName: "super-app-theme--cell",
        width: (width - 500) * 0.17,
        editable: false,
        renderCell: (params) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                component={"h6"}
                sx={{
                  color: Color.black,
                  fontWeight: 400,
                  fontFamily: Fonts.Text,
                  fontSize: "0.8rem",
                }}
              >
                {params.value}
              </Typography>
              <span
                style={{
                  height: "0.125rem",
                  backgroundColor: params?.row?.color,
                  width: "100%",
                }}
              ></span>
            </div>
          );
        },
      },
      {
        field: "last12MT",
        headerName: "Last 12 month",
        cellClassName: "super-app-theme--cell",
        width: (width - 500) * 0.15,
        editable: false,
        valueFormatter: ({ value }) =>
          `${formatValue({
            value: String(parseInt(value)),
            groupSeparator: currencyFormate.groupSeparator,
            decimalSeparator: currencyFormate.decimalSeparator,
            prefix: currencyFormate.prefix,
          })}`,
        valueGetter: ({ value }) => parseFloat(value),
      },

      {
        field: "preYear",
        headerName: "Prev Year",
        cellClassName: "super-app-theme--cell",
        width: (width - 500) * 0.15,
        editable: false,
        valueFormatter: ({ value }) =>
          value
            ? `${formatValue({
                value: String(parseInt(value)),
                groupSeparator: currencyFormate.groupSeparator,
                decimalSeparator: currencyFormate.decimalSeparator,
                prefix: currencyFormate.prefix,
              })}`
            : null,
        valueGetter: ({ value }) => value && parseFloat(value),
      },
      {
        field: "share",
        headerName: "Share of revenue",
        cellClassName: "super-app-theme--cell",
        width: (width - 500) * 0.15,
        editable: false,
        valueFormatter: ({ value }) => `${value} %`,
        valueGetter: ({ value }) => parseFloat(value),
      },
      {
        field: "changeSum",
        headerName: "Change to prev Year (SUM)",
        cellClassName: "super-app-theme--cell",
        width: (width - 500) * 0.21,
        editable: false,
        valueGetter: ({ value }) => parseFloat(value),
        renderCell: (params) => {
          return (
            <Typography
              variant="h6"
              component={"h6"}
              sx={{
                color: params?.value < 0 ? Color.red : Color.green,
                fontWeight: 800,
                fontFamily: Fonts.Text,
                fontSize: "0.8rem",
              }}
            >
              {params.value || params.value === 0
                ? formatValue({
                    value: String(parseInt(params.value)),
                    groupSeparator: currencyFormate.groupSeparator,
                    decimalSeparator: currencyFormate.decimalSeparator,
                    prefix: currencyFormate.prefix,
                  })
                : null}
            </Typography>
          );
        },
      },
      {
        field: "change",
        headerName: "Change to prev Year (%)",
        cellClassName: "super-app-theme--cell",
        width: (width - 500) * 0.2,
        editable: false,
        valueGetter: ({ value }) => parseFloat(value),
        renderCell: (params) => {
          return (
            <Typography
              variant="h6"
              component={"h6"}
              sx={{
                color:
                  params?.row?.preYear === 0
                    ? Color.black
                    : params?.value < 0
                      ? Color.red
                      : Color.green,
                fontWeight: 800,
                fontFamily: Fonts.Text,
                fontSize: "0.8rem",
              }}
            >
              {params?.row?.preYear === 0
                ? "n / a"
                : params.value || params.value === 0
                  ? `${params.value} %`
                  : null}
            </Typography>
          );
        },
      },
    ],
    [
      currencyFormate.decimalSeparator,
      currencyFormate.groupSeparator,
      currencyFormate.prefix,
      width,
    ]
  );

  const column = useMemo(
    () => [
      {
        field: "title",
        headerName: "Title",
        cellClassName: "super-app-theme--cell",
        width: width * 0.16,
        editable: false,
        renderCell: (params) => {
          return (
            <Typography
              onClick={() => onClickTitle(params)}
              variant="h6"
              component={"h6"}
              sx={{
                color: params?.value < 0 ? Color.red : Color.green,
                fontWeight: 400,
                fontFamily: Fonts.Text,
                fontSize: "0.8rem",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {params.value}
            </Typography>
          );
        },
      },
      {
        field: "sum",
        headerName: "Sum",
        cellClassName: "super-app-theme--cell",
        width: width * 0.16,
        editable: false,
        valueGetter: ({ value }) => parseFloat(value),
        renderCell: (params) => {
          return (
            <Typography
              variant="h6"
              component={"h6"}
              sx={{
                color: params?.value < 0 ? Color.red : Color.green,
                fontWeight: 800,
                fontFamily: Fonts.Text,
                fontSize: "0.8rem",
              }}
            >
              {formatValue({
                value: String(params.value),
                groupSeparator: currencyFormate.groupSeparator,
                decimalSeparator: currencyFormate.decimalSeparator,
                prefix: currencyFormate.prefix,
              })}
            </Typography>
          );
        },
      },
      {
        field: "occurrence",
        headerName: "Occurrence",
        cellClassName: "super-app-theme--cell",
        width: width * 0.07,
        editable: false,
      },
      {
        field: "share",
        headerName: "Share of revenue",
        cellClassName: "super-app-theme--cell",
        width: width * 0.1,
        editable: false,
        valueFormatter: ({ value }) => `${value} %`,
        valueGetter: ({ value }) => parseFloat(value),
      },
      {
        field: "change",
        headerName: "Change compare to prev year",
        cellClassName: "super-app-theme--cell",
        width: width * 0.16,
        editable: false,
        valueFormatter: ({ value }) => `${value} %`,
        valueGetter: ({ value }) => parseFloat(value),
      },
      {
        field: "isIgnored",
        headerName: "Ignore",
        cellClassName: "super-app-theme--cell",
        width: width * 0.08,
        editable: false,
        renderCell: (params) => {
          if (params?.row?.isIgnored) {
            return (
              <Tooltip title={"Ignored"}>
                <VisibilityOffIcon
                  onClick={(e) => handleClickIgnore(e, params)}
                  sx={{ color: Color.theme.grey[400], cursor: "pointer" }}
                />
              </Tooltip>
            );
          } else {
            return (
              <strong>
                <Chip
                  onClick={(e) => handleClickIgnore(e, params)}
                  label={"Ignore"}
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: 600,
                    height: "1.5625rem",
                    minWidth: "5.625rem",
                    borderRadius: 1,
                    fontFamily: Fonts.Text,
                    color: alpha(Color.themeColor, 0.75),
                    backgroundColor: Color.blueGrey50,
                    border: `1px solid ${Color.blueGrey400}`,
                  }}
                />
              </strong>
            );
          }
        },
      },
      {
        field: "add",
        headerName: "Add to customer",
        cellClassName: "super-app-theme--cell",
        width: width * 0.1,
        editable: false,
        renderCell: (params) => {
          if (params?.row?.customerName) {
            return (
              <Typography
                onClick={(e) => handleClickAdd(e, params)}
                variant="h6"
                component={"h6"}
                sx={{
                  fontFamily: Fonts.Text,
                  fontSize: "0.875rem",
                  cursor: "pointer",
                }}
              >
                {params?.row?.customerName}
              </Typography>
            );
          } else {
            return (
              <strong>
                <Chip
                  onClick={(e) => handleClickAdd(e, params)}
                  label={"Add to customer"}
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: 600,
                    height: "1.5625rem",
                    minWidth: "5.625rem",
                    borderRadius: 1,
                    fontFamily: Fonts.Text,
                    color: alpha(Color.themeColor, 0.75),
                    backgroundColor: Color.blueGrey50,
                    border: `1px solid ${Color.blueGrey400}`,
                  }}
                />
              </strong>
            );
          }
        },
      },
      {
        field: "delete",
        headerName: "Delete from customer",
        width: width * 0.12,
        cellClassName: "super-app-theme--cell",
        editable: false,
        renderCell: (params) => {
          return (
            <strong>
              {params?.row?.customerName && (
                <Chip
                  onClick={(e) => handleClickDelete(e, params)}
                  label={"Delete from customer"}
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: 600,
                    height: "1.5625rem",
                    minWidth: "5.625rem",
                    borderRadius: 1,
                    fontFamily: Fonts.Text,
                    color: alpha(Color.themeColor, 0.75),
                    backgroundColor: Color.blueGrey50,
                    border: `1px solid ${Color.blueGrey400}`,
                  }}
                />
              )}
            </strong>
          );
        },
      },
    ],
    [
      width,
      currencyFormate.groupSeparator,
      currencyFormate.decimalSeparator,
      currencyFormate.prefix,
      contacts,
    ]
  );

  const customerColumn = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        width: width * 0.43,
        editable: true,
      },
      {
        field: "note",
        headerName: "Note",
        width: width * 0.43,
        editable: true,
      },
      {
        field: "state",
        headerName: "Visibility",
        width: width * 0.06,
        editable: false,
        renderCell: (params) => {
          return (
            <>
              {params?.value === 1 ? (
                <VisibilityIcon
                  onClick={() => onClickVisibilty(params)}
                  sx={{ color: Color.theme.grey[400], cursor: "pointer" }}
                />
              ) : (
                <VisibilityOffIcon
                  onClick={() => onClickVisibilty(params)}
                  sx={{ color: Color.theme.grey[400], cursor: "pointer" }}
                />
              )}
            </>
          );
        },
      },
      {
        field: "delete",
        headerName: "Delete",
        width: width * 0.06,
        editable: false,
        renderCell: (params) => {
          return (
            <DeleteIcon
              onClick={() => onClickDeleteCustomer(params)}
              sx={{ color: Color.theme.grey[400], cursor: "pointer" }}
            />
          );
        },
      },
    ],
    [contacts, width]
  );

  //api
  const getCustomers = async () => {
    await APICall("get", EndPoints.customers).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setContacts(data));
      }
    });
  };

  const addCustomerApi = async (obj, type) => {
    await APICall("post", EndPoints.customers, obj).then((response) => {
      if (response.status === 201 && response.data) {
        enqueueSnackbar("New Customer Added Successfully ", {
          variant: "success",
          autoHideDuration: 2000,
        });
        let data = response.data;
        dispatch(setContacts([...contacts, data]));
        if (type) {
          dispatch(setLoading(true));
          let array = [];
          selectedIds.current?.forEach((element) => {
            array.push({ uuid: element, contact: data?.uuid });
          });
          updateCardsBatch(array);
        }
      }
    });
  };

  const deleteCustomerByIdApi = async (id) => {
    await APICall("delete", EndPoints.customers + `${id}/`).then((response) => {
      if (response.status === 204 && response) {
        let data = contacts?.filter((c) => c.uuid !== id);
        dispatch(setContacts(data));
        enqueueSnackbar("Customer Deleted Successfully ", {
          variant: "success",
          autoHideDuration: 2000,
        });
        let filterdata = transactionData?.filter((c) => c.contact === id);
        if (filterdata.length > 0) {
          let array = [];
          dispatch(setLoading(true));
          filterdata?.forEach((element) => {
            array.push({ uuid: element?.uuid, contact: null });
          });
          updateCardsBatch(array);
        }
      }
    });
  };

  const updateCustomerByID = async (id, obj, type) => {
    await APICall("patch", EndPoints.customers + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          updateCustomer(id, obj);
          if (type) {
            dispatch(setLoading(true));
            let array = [];
            selectedIds.current?.forEach((element) => {
              array.push({
                uuid: element,
                contact: response.data?.uuid,
              });
            });
            updateCardsBatch(array);
          }
        }
      }
    );
  };

  const updateCardsBatch = async (array) => {
    dispatch(setLoading(true));
    let response = await updateBatchTransactions(array);
    if (response) {
      updateBatchTransaction(response);
    }
  };

  //lifeCycle method
  useEffect(() => {
    let found = transactionData.find(
      (o1) =>
        o1.gross_value >= 0 &&
        !initialData?.calculationExcludeStates?.includes(o1.state)
    );
    if (found && transactionData?.length !== 0) {
      setShowInfo(false);
    } else {
      if (transactionData?.length === 0) {
        setShowInfo("error");
      } else {
        setShowInfo("info");
      }
    }
    if (
      tab === "1" &&
      appliedFilterlist?.reports?.client &&
      appliedFilterlist?.reports?.client?.formate?.Income &&
      appliedFilterlist?.reports?.client?.chartKey?.Income &&
      appliedFilterlist?.reports?.client?.stateValue?.Income
    ) {
      getScatterChartData();
    }
    if (
      tab === "2" &&
      appliedFilterlist?.reports?.client &&
      appliedFilterlist?.reports?.client?.stateValue?.Income
    ) {
      getTopCustomerList();
    }
    if (
      tab === "6" &&
      appliedFilterlist?.reports?.client &&
      appliedFilterlist?.reports?.client?.stateValue?.Income
    ) {
      getCustomerTransaction();
    }
  }, [
    transactionData,
    tab,
    contacts,
    appliedFilterlist?.reports?.client?.stateValue?.Income,
    appliedFilterlist?.reports?.client?.formate?.Income,
  ]);

  useEffect(() => {
    if (allowJoyRide && tab === "1") {
        dispatch(setSteps({ key: "dashboard_overview", t }));
        dispatch(setStepIndex(3));
        dispatch(startTour());
      setAllowJoyRide(false);
    }
  }, [allowJoyRide, tab]);

  useEffect(() => {
    if (tab === "5") {
      getCustomers();
    }
  }, [tab]);

  useEffect(() => {
    const array = location.pathname.split("/");
    const key = array[array.length - 1];
    const [filter] = tabOptions?.filter((o1) => o1.path === key);
    if (filter) {
      setTab(filter?.id);
    }
  }, [location.pathname]);

  //function
  const getScatterChartData = useCallback(() => {
    let endCurrentMonth = new Date();
    let startYearmonth = subMonths(endCurrentMonth, 12);
    let endPreYearMonth = subYears(endCurrentMonth, 1);
    let startPreYearMonth = subYears(startYearmonth, 1);
    let array = transactionData?.filter(
      (o1) =>
        appliedFilterlist?.reports?.client?.stateValue?.Income?.some(
          (o2) => o2 === o1.state
        ) &&
        !initialData?.calculationExcludeStates?.includes(o1.state) &&
        o1.gross_value >= 0 &&
        o1.due_date &&
        new Date(o1.due_date) >= startPreYearMonth &&
        new Date(o1.due_date) <= endCurrentMonth
    );
    const data = _.groupBy(array, ({ title }) => title.toLowerCase());
    let dataArray = [];
    Object.keys(data).map((key) => {
      const last12MonthData = data[key]?.filter(
        (o1) =>
          o1.due_date &&
          new Date(o1.due_date) >= startYearmonth &&
          new Date(o1.due_date) <= endCurrentMonth
      );
      const last12MonthTotal = last12MonthData?.reduce(
        (total, item) => parseFloat(total) + parseFloat(item.gross_value ?? 0),
        0
      );

      const pre12MonthData = data[key]?.filter(
        (o1) =>
          o1.due_date &&
          new Date(o1.due_date) >= startPreYearMonth &&
          new Date(o1.due_date) <= endPreYearMonth
      );
      const pre12MonthTotal = pre12MonthData?.reduce(
        (total, item) => parseFloat(total) + parseFloat(item.gross_value ?? 0),
        0
      );
      const totalRevenue = data[key]
        ?.filter((o1) => o1.due_date && o1.title.toLowerCase() === key)
        ?.reduce(
          (total, item) =>
            parseFloat(total) + parseFloat(item.gross_value ?? 0),
          0
        );
      let change = 0;
      if (pre12MonthTotal === 0) {
        change = 100;
      } else {
        change = getPercentageChange(pre12MonthTotal, last12MonthTotal);
      }
      let contact = contacts?.find(
        (o1) => data[key] && o1.uuid === data[key][0]?.contact
      );
      let obj = {
        title: key,
        revenue: totalRevenue,
        isSelected:
          appliedFilterlist?.reports?.client?.chartKey?.Income?.includes(key)
            ? true
            : false,
        change,
        pre12MonthTotal,
        last12MonthTotal,
        color: randomColor(),
        new: pre12MonthTotal === 0 && change === 100,
        trouble: change <= -80,
        top: false,
        customerID: contact?.uuid,
        customerName: contact?.name,
        state: contact?.state || 1,
      };
      dataArray.push(obj);
    });
    let filtered = dataArray.filter((o1) => o1.state === 1);

    filtered.sort(
      (a, b) => parseFloat(b.last12MonthTotal) - parseFloat(a.last12MonthTotal)
    );
    let dummy = filtered.map((o1) => Number(o1.revenue));
    let maxRevenue = Math.max(...dummy);
    filtered.forEach((element, i) => {
      if (i <= 4) {
        element.color = color[i];
        element.top = true;
      }
      element.trouble =
        element.change <= -80 && element?.revenue <= (maxRevenue * 1.1) / 2;
    });

    let filterData = filtered.filter(
      (o1) =>
        (!o1.new && !o1.top && !o1.trouble) ||
        appliedFilterlist?.reports?.client?.formate?.Income?.some(
          (o2) => o1[o2]
        )
    );

    let customerGroupArray = _.groupBy(
      filterData,
      ({ customerName }) => customerName
    );

    let finalarray = [];
    Object.keys(customerGroupArray).map((key) => {
      if (key !== "undefined") {
        const revenue = customerGroupArray[key]?.reduce(
          (total, item) => parseFloat(total) + parseFloat(item.revenue ?? 0),
          0
        );
        const pre12MonthTotal = customerGroupArray[key]?.reduce(
          (total, item) =>
            parseFloat(total) + parseFloat(item.pre12MonthTotal ?? 0),
          0
        );
        const last12MonthTotal = customerGroupArray[key]?.reduce(
          (total, item) =>
            parseFloat(total) + parseFloat(item.last12MonthTotal ?? 0),
          0
        );
        let change = 0;
        if (pre12MonthTotal === 0) {
          change = 100;
        } else {
          change = getPercentageChange(pre12MonthTotal, last12MonthTotal);
        }
        let max = maxRevenue > revenue ? maxRevenue : revenue;
        let obj = {
          title: key,
          revenue,
          isSelected: true,
          change,
          pre12MonthTotal,
          last12MonthTotal,
          color: randomColor(),
          new: pre12MonthTotal === 0 && change === 100,
          trouble: change <= -80 && revenue <= (max * 1.1) / 2,
          top: false,
          customerID: customerGroupArray[key][0]?.customerID,
          customerName: customerGroupArray[key][0]?.customerName,
          state: customerGroupArray[key][0]?.state,
        };
        finalarray.push(obj);
      } else {
        finalarray.push(...customerGroupArray[key]);
      }
    });
    setScatterChartData(finalarray);
  }, [transactionData, contacts, appliedFilterlist?.reports?.client]);

  const getTopCustomerList = useCallback(() => {
    let array = transactionData?.filter(
      (o1) =>
        appliedFilterlist?.reports?.client?.stateValue?.Income?.some(
          (o2) => o2 === o1.state
        ) &&
        !initialData?.calculationExcludeStates?.includes(o1.state) &&
        o1.gross_value >= 0
    );
    const data = _.groupBy(array, ({ title }) => title.toLowerCase());
    let endCurrentMonth = new Date();
    let startYearmonth = subMonths(endCurrentMonth, 12);
    let endPreYearMonth = subYears(endCurrentMonth, 1);
    let startPreYearMonth = subYears(startYearmonth, 1);
    var result = eachQuarterOfInterval({
      start: startPreYearMonth,
      end: endCurrentMonth,
    });

    const allRevenue = transactionData
      ?.filter(
        (o1) =>
          o1?.gross_value >= 0 &&
          !initialData?.calculationExcludeStates?.includes(o1.state)
      )
      ?.reduce(
        (total, item) => parseFloat(total) + parseFloat(item?.gross_value ?? 0),
        0
      );
    let dataArray = [];
    Object.keys(data).map((key, index) => {
      const last12MonthTotal = data[key]
        ?.filter(
          (o1) =>
            o1.due_date &&
            new Date(o1.due_date) >= startYearmonth &&
            new Date(o1.due_date) <= endCurrentMonth
        )
        ?.reduce(
          (total, item) =>
            parseFloat(total) + parseFloat(item?.gross_value ?? 0),
          0
        );
      const pre12MonthTotal = data[key]
        ?.filter(
          (o1) =>
            o1.due_date &&
            new Date(o1.due_date) >= startPreYearMonth &&
            new Date(o1.due_date) <= endPreYearMonth
        )
        ?.reduce(
          (total, item) =>
            parseFloat(total) + parseFloat(item?.gross_value ?? 0),
          0
        );
      let change = getPercentageChange(pre12MonthTotal, last12MonthTotal);

      const share = getShare(allRevenue, last12MonthTotal);
      let contact = contacts?.find((o1) => o1.uuid === data[key][0]?.contact);
      let obj = {
        uuid: key,
        index,
        title: key,
        last12MT: last12MonthTotal ?? 0,
        preYear: pre12MonthTotal ?? 0,
        changeSum: last12MonthTotal - pre12MonthTotal,
        change,
        share,
        customerID: contact?.uuid,
        customerName: contact?.name,
        state: contact?.state || 1,
      };
      dataArray.push(obj);
    });

    let topData = dataArray
      .filter((o1) => o1.state === 1)
      .sort((a, b) => parseFloat(b.last12MT) - parseFloat(a.last12MT))
      .splice(0, 10);
    let lastData = [];
    for (let index = 0; index < 5; index++) {
      if (dataArray?.length > 0) {
        lastData.push(dataArray?.pop());
      }
    }
    const last12MT = lastData?.reduce(
      (total, item) => parseFloat(total) + parseFloat(item.last12MT ?? 0),
      0
    );
    const share = getShare(allRevenue, last12MT);
    let lastObj = {
      uuid: "The smallest 5",
      title: "The smallest 5",
      last12MT: last12MT ?? 0,
      preYear: null,
      changeSum: null,
      change: null,
      share,
      color: null,
    };

    let dummyData = [];
    result?.forEach((element) => {
      let default_gross_value = 0;
      let obj = {
        date: element,
        formatedDate: format(element, "yyyy-QQQ"),
      };
      topData?.forEach((ele, i) => {
        let gross_value = array
          .filter(
            (o1) =>
              o1.title.toLowerCase() === ele.title &&
              o1.due_date &&
              new Date(o1.due_date) <= element
          )
          ?.reduce(
            (total, item) =>
              parseFloat(total) + parseFloat(item?.gross_value ?? 0),
            0
          );
        ele.color = color[i];
        default_gross_value =
          gross_value > default_gross_value ? gross_value : default_gross_value;

        obj[`${ele.title}-color`] = color[i] || Color.black;
        obj[`${ele.title}`] = parseInt(gross_value);
        obj[`gross_value`] = parseInt(default_gross_value);
      });

      dummyData.push(obj);
    });

    setKeyData(topData);
    setChartData(dummyData);
    setRows([...topData, lastObj]);
  }, [
    transactionData,
    appliedFilterlist?.reports?.client?.stateValue?.Income,
    contacts,
  ]);

  const getCustomerTransaction = useCallback(() => {
    let data = transactionData.filter(
      (o1) =>
        !initialData?.calculationExcludeStates?.includes(o1.state) &&
        appliedFilterlist?.reports?.client?.stateValue?.Income?.some(
          (o2) => o2 === o1.state
        ) &&
        o1.gross_value >= 0
    );
    let endCurrentMonth = new Date();
    let startYearmonth = subMonths(endCurrentMonth, 12);
    let endPreYearMonth = subYears(endCurrentMonth, 1);
    let startPreYearMonth = subYears(startYearmonth, 1);

    const allRevenue = data.reduce(
      (total, item) => parseFloat(total) + parseFloat(item.gross_value ?? 0),
      0
    );
    let array = [];
    data?.forEach((element) => {
      // let countArr = data?.filter((o1) => similarity(o1.title, element.title));
      let countArr = data?.filter(
        (o1) => o1.title.toLowerCase() === element.title.toLowerCase()
      );
      const sum = countArr.reduce(
        (total, item) => parseFloat(total) + parseFloat(item.gross_value ?? 0),
        0
      );
      const share = getShare(allRevenue, sum);
      let customerCheck = countArr[0]?.contact;
      let filter = countArr.filter((o1) => o1.contact === customerCheck);
      let customerID = null;
      let customerName = null;
      let isIgnored = null;
      if (filter.length === countArr.length) {
        customerID = customerCheck;
        if (customerID) {
          let filterCustomer = contacts?.filter(
            (o1) => o1.uuid === customerCheck
          );
          customerName = filterCustomer[0]?.name;
          isIgnored = filterCustomer[0]?.state === 2;
        }
      }
      array.push({
        uuid: element?.uuid,
        title: element?.title,
        gross_value: element?.gross_value,
        occurrence: countArr.length,
        customerId: customerID,
        customerName,
        ids: [...new Set(countArr.map((item) => item.uuid))],
        sum: parseFloat(sum).toFixed(2),
        share,
        isIgnored,
      });
    });

    let dataarray = [];
    array.forEach((element) => {
      // let countArr = array.find((o1) => similarity(o1.title, element.title));
      // let found = dataarray.filter((o1) => similarity(o1.title, element.title));
      let countArr = array.find(
        (o1) => o1.title.toLowerCase() === element.title.toLowerCase()
      );
      let found = dataarray.filter(
        (o1) => o1.title.toLowerCase() === element.title.toLowerCase()
      );
      if (countArr && found.length === 0) {
        let thisYearData = transactionData?.filter(
          (o1) =>
            o1.gross_value >= 0 &&
            o1.due_date &&
            !initialData?.calculationExcludeStates?.includes(o1.state) &&
            // similarity(o1.title, element.title) &&
            o1.title.toLowerCase() === element.title.toLowerCase() &&
            new Date(o1.due_date) >= startYearmonth &&
            new Date(o1.due_date) <= endCurrentMonth
        );
        let preYearData = transactionData?.filter(
          (o1) =>
            o1.gross_value >= 0 &&
            o1.due_date &&
            o1.title.toLowerCase() === element.title.toLowerCase() &&
            !initialData?.calculationExcludeStates?.includes(o1.state) &&
            // similarity(o1.title, element.title) &&
            new Date(o1.due_date) >= startPreYearMonth &&
            new Date(o1.due_date) <= endPreYearMonth
        );
        const thisYearTotal = thisYearData.reduce(
          (total, item) =>
            parseFloat(total) + parseFloat(item.gross_value ?? 0),
          0
        );
        const preYearTotal = preYearData.reduce(
          (total, item) =>
            parseFloat(total) + parseFloat(item.gross_value ?? 0),
          0
        );
        let change = 0;
        if (preYearTotal === 0) {
          change = 100;
        } else {
          change = getPercentageChange(preYearTotal, thisYearTotal);
        }

        dataarray.push({ ...element, change });
      }
    });
    array.sort((a, b) => parseFloat(b.occurrence) - parseFloat(a.occurrence));
    setRows(dataarray);
  }, [
    transactionData,
    contacts,
    appliedFilterlist?.reports?.client?.stateValue?.Income,
  ]);

  const handleChangeTab = (event, newValue) => {
    if (tab !== newValue) {
      let [key] = tabOptions?.filter((o1) => o1.id === newValue);
      let array = location.pathname.split("/");
      array.pop();
      array.push(key.path);
      let url = array.join("/");
      navigate(url);
      // setTab(newValue);
    }
  };

  const onClickTitle = (params) => {
    dispatch(
      setTransactionsOverlayStatus({
        type: "editDetailsCard",
        cellInfo: {
          cell: {
            figureType: params.row?.sum >= 0 ? "income" : "expense",
            modalType: "edit",
            // transactionIds: params.row?.ids,
            title: params.row?.title,
            hideDate: true,
            withUrl: false,
            multiState: appliedFilterlist?.reports?.client?.stateValue?.Income, // issue here
            type: params.row?.sum >= 0 ? "income" : "expense",
          },
          subTitle: "Transactions",
        },
      })
    );
  };

  const onClickAddCustomer = () => {
    addCustomerApi({ name: "(No title)", note: "", type: 1 });
  };

  const onClickDeleteCustomer = (item) => {
    deleteCustomerByIdApi(item?.id);
  };

  const onCellEditCommit = (data) => {
    let obj = { [data.field]: data.value };
    updateCustomerByID(data.id, obj);
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
  };

  const onClickContactTitle = (contact) => {
    let array = [];
    if (updateType.current === "single") {
      selectedIds.current?.forEach((element) => {
        array.push({ uuid: element, contact: contact?.uuid });
      });
    } else {
      selectedRowData?.forEach((element) => {
        element.ids?.forEach((id) => {
          array.push({
            uuid: id,
            contact: contact?.uuid,
          });
        });
      });
    }
    updateCardsBatch(array);
    setAnchorEl(null);
  };

  const handleClickDelete = (e, data) => {
    let ids = data?.row?.ids;

    let array = [];
    ids?.forEach((element) => {
      array.push({ uuid: element, contact: null });
    });
    updateCardsBatch(array);
  };

  const handleClickAdd = (e, data) => {
    transactionTitle.current = data?.row?.title;
    selectedIds.current = data?.row?.ids;
    updateType.current = "single";
    setAnchorEl(e.currentTarget);
  };

  const updateCustomer = (id, obj) => {
    let data = [...contacts];
    let index = contacts?.findIndex((o1) => o1.uuid === id);
    if (index > -1) {
      data[index] = { ...data[index], ...obj };
    }
    dispatch(setContacts(data));
  };

  const updateBatchTransaction = (array) => {
    let data = [...transactionData];
    array.forEach((element) => {
      let index = transactionData?.findIndex((o1) => o1.uuid === element.uuid);
      if (index > -1) {
        data[index] = {
          ...data[index],
          contact: element?.contact,
        };
      }
    });
    dispatch(setTransactionData(data));
    dispatch(setLoading(false));
  };

  const onSelectionModelChange = (ids) => {
    const selectedIDs = new Set(ids);
    let updateData = rows.filter((row) => selectedIDs.has(row.uuid.toString()));
    if (updateData?.length > 0) {
      updateType.current = "batch";
    } else {
      updateType.current = "single";
    }
    transactionTitle.current = null;
    setSelectedRowData(updateData);
  };

  const handleClickBulkAction = (e, uuid) => {
    updateType.current = "batch";
    transactionTitle.current = null;
    if (uuid === 1) {
      let array = [];
      selectedRowData?.forEach((element) => {
        element.ids?.forEach((id) => {
          array.push({
            uuid: id,
            customer: null,
          });
        });
      });
      updateCardsBatch(array);
    }

    if (uuid === 2) {
      setAnchorEl(e.currentTarget);
    }
  };

  const onClickVisibilty = (props) => {
    let { value, id } = props;
    updateCustomerByID(id, { state: value === 1 ? 2 : 1 });
  };

  const handleClickIgnore = (e, param) => {
    selectedIds.current = param?.row?.ids;
    transactionTitle.current = param?.row?.title;
    let foundAlreadyAvailabel = contacts?.filter(
      (o1) => o1.name === transactionTitle.current
    );
    if (foundAlreadyAvailabel?.length > 0) {
      updateCustomerByID(
        foundAlreadyAvailabel[0]?.uuid,
        { state: foundAlreadyAvailabel[0]?.state === 2 ? 1 : 2 },
        "update row transaction"
      );
    } else {
      addCustomerApi(
        {
          name: transactionTitle.current,
          note: "",
          state: 2,
          type: 1,
        },
        "add to row transaction"
      );
    }
  };

  const afterDeleteContact = () => {
    setAnchorEl(null);
  };

  //RenderFunction
  const ReferenceText = ({
    label,
    top,
    right,
    width = "fit-content",
    highlatedText = "",
    highlatedColor = "inherit",
  }) => {
    return (
      <Typography
        sx={{
          fontFamily: Fonts.Text,
          fontSize: "1rem",
          color: alpha(Color.themeColor, 0.75),
          fontWeight: 500,
          position: "absolute",
          width,
          top,
          right,
        }}
      >
        {label}
        <b style={{ color: highlatedColor }}>{highlatedText}</b>
      </Typography>
    );
  };

  const bulkPopOver = () => (
    <PopupState
      variant="popper"
      id="demo-mutiple-checkbox"
      popupId="demo-popup-popover"
    >
      {(popupState) => (
        <>
          <Button
            {...bindTrigger(popupState)}
            sx={{
              height: "1.875rem",
              minWidth: 0,
              p: "0.5rem",
              color: Color.black,
              backgroundColor: alpha(Color.info, 0.13),
              textTransform: "capitalize",
              fontSize: "0.8rem",
              "&:hover": {
                backgroundColor: alpha(Color.info, 0.3),
              },
            }}
          >
            Run bulk action
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            slotProps={{
              paper: {
                sx: {
                  borderRadius: 1.5,
                  boxShadow: Constant.OverlayBoxShadow,
                },
              },
            }}
            sx={{
              ml: "0.5rem",
            }}
          >
            <List
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "fit-content",
                minHeight: "3.125rem",
                width: "fit-content",
                minWidth: "18.75rem",
                position: "relative",
              }}
            >
              {actionList?.map((item) => {
                return (
                  <ListItem
                    key={item?.uuid}
                    disableGutters
                    disablePadding
                    onClick={(e) => handleClickBulkAction(e, item?.uuid)}
                  >
                    <ListItemButton
                      sx={{
                        fontFamily: Fonts?.Text,
                        "&:hover": {
                          backgroundColor: alpha(Color.themeColor, 0.1),
                          color: alpha(Color.themeColor, 0.9),
                          "& span": {
                            fontWeight: 500,
                          },
                        },
                      }}
                    >
                      <ListItemText primary={item?.title} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Popover>
        </>
      )}
    </PopupState>
  );

  return (
    <Root width={width} height={height} tab={tab}>
      <ContactSelect
        anchorEl={anchorEl}
        handleClosePopOver={handleClosePopOver}
        onClickContactTitle={onClickContactTitle}
        transactionTitle={transactionTitle.current}
        type={1}
        afterDelete={afterDeleteContact}
      />
      <CustomerHeader
        scatterChartData={scatterChartData}
        setScatterChartData={setScatterChartData}
        tab={tab}
      />
      <Box className={classes.root}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          textColor="inherit"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab
            value="1"
            label="Customer portfolio analysis"
            className="dashboard_overview_3_step"
          />
          <Tab value="2" label="Top customer analysis" />
          {/* <Tab value="3" label="Highlighting information and conclusions" /> */}
          {/* <Tab value="4" label="Proposed measures based on the analyses" /> */}
          <Tab value="5" label="Customer list" />
          <Tab value="6" label="Merge Customers" />
          {showInfo ? (
            <WarnView
              showInfo={showInfo}
              infoKey={"customer_Info_no_enough_data"}
              errorKey={"common_content_no_transctions"}
              fontSize={"1.25rem"}
              iconStyle={{
                ml: "0.5rem",
                mt: "0.5rem",
              }}
            />
          ) : null}
        </Tabs>

        {selectedRowData.length > 0 && tab === "6" && bulkPopOver()}

        {tab === "5" && (
          <Button
            onClick={onClickAddCustomer}
            disableElevation
            variant="contained"
            size="medium"
            sx={{
              textTransform: "capitalize",
              fontSize: "1rem",
              FontFamily: Fonts.Text,
              fontWeight: 800,
              height: "fit-content",
              borderRadius: 2,
              backgroundColor: alpha(Color.themeColor, 0.8),
            }}
          >
            Add Customer
          </Button>
        )}
      </Box>
      {tab === "2" ? (
        <Box
          sx={{
            display: "flex",
            width: width,
            justifyContent: "space-between",
          }}
        >
          <Paper className={classes.paper}>
            <DataGrid
              className={classes.table}
              rows={rows}
              columns={topCustomerColumn}
              pageSize={15}
              disableColumnMenu
              disableSelectionOnClick
              getRowId={(row) => row.uuid}
            />
          </Paper>

          <Box
            sx={{
              width: "35%",
              border: `3px solid ${Color.theme.grey[200]}`,
            }}
          >
            <MultiLineCharts data={chartData} keyData={keyData} />
          </Box>
        </Box>
      ) : (
        <Paper className={classes.paper}>
          {tab === "1" && (
            <>
              {!subscription?.clients_chart ? (
                <Placeholder
                  image={Images.client_portfolio}
                  insightText={"Clients"}
                />
              ) : (
                <>
                  <ScatterChartView
                    data={scatterChartData?.filter((o1) => o1.isSelected)}
                  />
                  <Box
                    sx={{
                      border: "none",
                      borderTop: `2px dashed${Color.dashedDivider}`,
                      position: "absolute",
                      top: "45%",
                      width: "94.6%",
                      right: 0,
                    }}
                  />
                  <Box
                    sx={{
                      border: "none",
                      borderRight: `2px dashed${Color.dashedDivider}`,
                      position: "absolute",
                      right: "48%",
                      height: "93%",
                      top: 0,
                    }}
                  />
                  <ReferenceText
                    label="High revenue change & high revenue - "
                    highlatedText={"Focus this customers!"}
                    width={"18.125rem"}
                    top={"1rem"}
                    right={"2rem"}
                  />
                  <ReferenceText
                    label="High revenue, low change - your good old top clients - keep going with them, but don't make too many concessions."
                    width={"29.6875rem"}
                    top={"36rem"}
                    right={"2rem"}
                  />
                  <ReferenceText
                    label="High revenue change - maybe potential top customers?"
                    top={"1rem"}
                    right={"70rem"}
                    width={"16.25rem"}
                  />
                  <ReferenceText
                    label="Low revenue & change - we may be able to "
                    highlatedText={"pass these customers?"}
                    highlatedColor={Color.error}
                    width={"16.75rem"}
                    top={"36rem"}
                    right={"70rem"}
                  />
                </>
              )}
            </>
          )}

          {(tab === "5" || tab === "6") && (
            <DataGrid
              className={classes.table}
              rows={tab === "5" ? customers : rows}
              columns={tab === "5" ? customerColumn : column}
              onCellEditCommit={onCellEditCommit}
              pageSize={15}
              disableColumnMenu
              disableSelectionOnClick
              getRowId={(row) => row.uuid}
              checkboxSelection={tab === "6"}
              onSelectionModelChange={onSelectionModelChange}
            />
          )}
          {(tab === "3" || tab === "4") && (
            <Typography
              sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Comming soon
            </Typography>
          )}
        </Paper>
      )}
      <Outlet />
    </Root>
  );
};

export default React.memo(Clients);
