import * as React from "react";

import {
  Box,
  Skeleton,
  Tooltip,
  Typography,
  Button,
  CardContent,
  Card,
  Collapse,
  useTheme,
} from "@mui/material";

import { TbChartAreaLineFilled, TbChartBubble, TbList } from "react-icons/tb";

import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { formatValue } from "react-currency-input-field";

import initialData, {
  getTailwindColor,
  getTransactionByParams,
  truncate,
} from "../../Helper/data";
import { Color, Fonts } from "../../Helper";

import ActionViewWrapper from "../../components/ActionViewWrapper.js";
import Icon from "../../components/Icon";
import Chart from "../Datasets/Dashboard/Chart";
import { setDataSetData } from "../../store/slices/board";
import { IoPeople } from "react-icons/io5";
import { BsFillTagsFill } from "react-icons/bs";

const DatasetDetailsCard = ({ dataset, sx }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  //redux
  const dataSource = useSelector((state) => state.globalSlice.dataSource);
  const currencyFormate = useSelector(
    (state) => state.settingsSlice.currencyFormate
  );
  const accounts = useSelector((state) => state.globalSlice.accounts);
  const testLoading = useSelector((state) => state.appSlice.testLoading);

  //state
  const [accountsBalance, setAccountBalance] = React.useState(0);
  const [isInfoShowed, setIsInfoShowed] = React.useState(false);

  const dS = React.useMemo(() => {
    return dataSource?.filter((o1) =>
      o1.datasets.map((o2) => o2.dataset).includes(dataset?.uuid)
    );
  }, [dataSource, dataset?.uuid]);

  //life cycle method
  React.useEffect(() => {
    const data = accounts?.filter((o1) => o1.datasets.includes(dataset?.uuid));
    const total = getTotalBalances(data);
    setAccountBalance(total);
  }, [accounts, dataset?.uuid]);

  React.useEffect(() => {
    return () => {
      setAccountBalance({});
    };
  }, []);

  //function
  const onClickLink = (e, des) => {
    e?.stopPropagation();
    dispatch(setDataSetData(dataset));
    navigate(`/${initialData?.path?.organization}/${dataset?.uuid}/${des}`);
  };

  const onClickOrg = (e) => {
    dispatch(setDataSetData(dataset));
    navigate(`/${initialData?.path?.organization}/${dataset?.uuid}/table`);
  };

  const getTotalBalances = (accounts) => {
    let accountType = initialData?.account_Type
      ?.filter((o1) => !["Loan", "CREDIT_CARD"]?.includes(o1.value))
      ?.map((o1) => o1.display_name);
    accountType.push("booked");
    const total = accounts?.reduce(
      (sum, acc) =>
        (sum += acc?.balances.reduce(
          (acc, item) =>
            parseFloat(acc) +
            parseFloat(
              accountType?.includes(item?.name)
                ? Number(item?.amount || 0) + Number(item?.ignored_value || 0)
                : 0
            ),
          0
        )),
      0
    );
    return total;
  };

  //render functions
  const HeaderView = () => {
    const balance = formatValue({
      value: String(parseFloat(accountsBalance)?.toFixed(2)),
      groupSeparator: currencyFormate.groupSeparator,
      decimalSeparator: currencyFormate.decimalSeparator,
      prefix: currencyFormate.prefix,
    });
    return (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {!dataset || testLoading ? (
          <>
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"45%"}
              height={"1.8rem"}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"45%"}
              height={"1.8rem"}
            />
          </>
        ) : (
          <>
            <Tooltip title={dataset?.title || ""}>
              <Typography
                gutterBottom
                variant="h7"
                component="div"
                sx={{
                  color: Color.tailwind.slate[800],
                  fontWeight: 600,
                  fontFamily: Fonts.Text,
                }}
              >
                {truncate(dataset?.title, 20)}
              </Typography>
            </Tooltip>
            <Tooltip title={balance}>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                fontWeight={"fontWeightMedium"}
                sx={{
                  fontSize: "0.85rem",
                  lineHeight: "0.85rem",
                  color:
                    accountsBalance >= 0
                      ? theme.palette.color.green[700]
                      : theme.palette.color.red[700],
                  backgroundColor:
                    accountsBalance >= 0
                      ? theme.palette.color.green[50]
                      : theme.palette.color.red[50],
                  borderRadius: theme.borderRadius.main,
                  p: "0.5rem",
                }}
              >
                {truncate(balance, 15)}
              </Typography>
            </Tooltip>
          </>
        )}
      </Box>
    );
  };

  return (
    <Card
      onClick={onClickOrg}
      sx={{
        width: { xs: "100%", md: "24rem" },
        minHeight: "30rem",
        height: "fit-content",
        position: "relative",
        p: "1.8rem",
        cursor: "pointer",
        borderRadius: theme.borderRadius.main,
        backgroundColor: theme.palette.color.white,
        boxShadow: theme.boxShadow,
        ...sx,
        "&:hover": {
          outline: `1px solid ${theme.palette.color.slate[200]}`,
          "#settings-icon": {
            display: dataset ? "flex" : "none",
          },
        },
      }}
    >
      <CardContent
        sx={{ p: "0px !important", position: "relative", textAlign: "center" }}
      >
        <HeaderView />
        <Box
          width={"100%"}
          height="15rem"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Chart
            dS={dS}
            setIsInfoShowed={setIsInfoShowed}
            dataset={dataset?.uuid}
            skeletonSx={{
              height: "76%",
            }}
            Xaxis={{
              hide: true,
            }}
            Yaxis={{
              hide: true,
            }}
            margin={{
              top: 20,
              right: 15,
              left: 15,
              bottom: 20,
            }}
            tick={{
              fontSize: "0.6rem",
              fontWeight: 600,
              fontFamily: Fonts.Text,
            }}
          />
        </Box>
        <LatestTransactionsView dataset={dataset} isInfoShowed={isInfoShowed} />
        {/* <IntegrationsView /> */}
      </CardContent>

      <ActionViewWrapper
        id="settings-icon"
        wrapperBackgroundColor={theme.palette.color.slate[100]}
        hoverBtnBackgroundColor={theme.palette.color.slate[200]}
        width={"2.5rem"}
        height={"2.5rem"}
        sx={{
          display: "none",
          position: "absolute",
          bottom: "1rem",
          right: "1rem",
          zIndex: 5,
        }}
      >
        <Tooltip title={t("Kanban-View")}>
          <Button
            onClick={(e) => onClickLink(e, "table")}
            className={"actionBtn"}
          >
            <Icon
              icon={<TbChartAreaLineFilled />}
              fontSize={"1.2rem"}
              color={getTailwindColor("slate", 800)}
            ></Icon>
          </Button>
        </Tooltip>

        <Tooltip title={t("List-View")}>
          <Button
            className={"actionBtn"}
            onClick={(e) => onClickLink(e, "list/bulk")}
          >
            <Icon
              icon={<TbList />}
              fontSize={"1.2rem"}
              color={getTailwindColor("slate", 800)}
            ></Icon>
          </Button>
        </Tooltip>
        <Tooltip title={t("Report-View")}>
          <Button
            className={"actionBtn"}
            onClick={(e) => onClickLink(e, "reports/overview")}
          >
            <Icon
              icon={<TbChartBubble />}
              fontSize={"1.2rem"}
              color={getTailwindColor("slate", 800)}
            ></Icon>
          </Button>
        </Tooltip>
        <Tooltip title={t("Employees-View")}>
          <Button
            className={"actionBtn"}
            onClick={(e) => onClickLink(e, "employees")}
          >
            <Icon
              icon={<IoPeople />}
              fontSize={"1.2rem"}
              color={getTailwindColor("slate", 800)}
            ></Icon>
          </Button>
        </Tooltip>
        <Tooltip title={t("Category-View")}>
          <Button
            className={"actionBtn"}
            onClick={(e) => onClickLink(e, "categories")}
          >
            <Icon
              icon={<BsFillTagsFill />}
              fontSize={"1.2rem"}
              color={getTailwindColor("slate", 800)}
            ></Icon>
          </Button>
        </Tooltip>
      </ActionViewWrapper>
    </Card>
  );
};
export default DatasetDetailsCard;

const LatestTransactionsView = ({ dataset, isInfoShowed }) => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = React.useState(false);

  const testLoading = useSelector((state) => state.appSlice.testLoading);
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const isFirstLoginOverlayOpen = useSelector(
    (state) => state.globalSlice.isFirstLoginOverlayOpen
  );

  const Latest_Transactions_All = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataset?.uuid,
        page: 1,
        page_size: 10,
        apiType: "Latest_Transactions_All",
      },
    ],
    queryFn: ({ signal }) => {
      let params = {
        config: {
          signal: signal,
        },
        dataset: dataset?.uuid,
        page: 1,
        page_size: 10,
        order_by: "-due_date",
        is_reconciled: false,
      };
      if (!dataset?.use_global_categories) {
        params.category_dataset = dataset?.uuid;
      } else {
        params.global_category = true;
      }
      const result = getTransactionByParams(params);
      if (result) {
        return result;
      }
    },
    // cacheTime: 60000,
    backgroundFetch: true,
    enabled:
      isAllHeaderApiFetched && !isFirstLoginOverlayOpen && !!dataset?.uuid,
    priority: 3,
  });

  const ItemView = ({ item }) => {
    let integration = "";
    if (item?.source === 1) {
      integration = "Manual";
    }
    if (item?.source === 2) {
      integration = "finApi";
    }
    if (item?.source === 3) {
      integration = "Misc";
    }
    if (item?.source === 4) {
      integration = item?.transaction_system;
    }
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        width={"100%"}
        mt={"0.5rem"}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            sx={{
              fontSize: "0.7rem",
              fontFamily: Fonts.Text,
              color: Color.tailwind.slate[800],
              fontWeight: 700,
              textAlign: "left",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: "60%",
            }}
          >
            {item?.title}
          </Typography>
          <Typography
            sx={{
              fontSize: "0.7rem",
              fontFamily: Fonts.Text,
              color: Color.tailwind.slate[800],
              fontWeight: 700,
            }}
          >
            {formatValue({
              value: String(item?.gross_value || 0),
              groupSeparator: dataset?.currency === "EUR" ? "." : ",",
              decimalSeparator: dataset?.currency === "EUR" ? "," : ".",
              prefix: dataset?.currency === "EUR" ? "€" : "$",
            })}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: "0.7rem",
            fontFamily: Fonts.Text,
            color: "color.description",
            textAlign: "left",
          }}
        >
          {integration}
        </Typography>
      </Box>
    );
  };

  const onClickShowAll = (e) => {
    e.stopPropagation();
    setIsExpanded((prev) => !prev);
  };

  return (
    <>
      {Latest_Transactions_All?.isFetching || testLoading ? (
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"100%"}
          height={"6.5rem"}
          sx={{ mt: "1rem" }}
        />
      ) : Latest_Transactions_All?.data?.results?.length > 0 ? (
        <Box display={"flex"} flexDirection={"column"}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography
              gutterBottom
              variant="h7"
              component="div"
              sx={{
                color: Color.tailwind.slate[800],
                fontWeight: 600,
                fontFamily: Fonts.Text,
              }}
            >
              {t("Recent Activity")}
            </Typography>
            <Button
              variant="outlined"
              size="small"
              onClick={onClickShowAll}
              sx={{
                fontSize: "0.6rem",
                height: "1.3rem",
                px: "0.3rem",
                fontFamily: Fonts.Text,
                color: Color.tailwind.slate[800],
                borderColor: Color.tailwind.slate[300],
                textTransform: "initial",
                borderRadius: 1.5,
              }}
            >
              {isExpanded ? t("Show Less") : t("Show More")}
            </Button>
          </Box>

          <Collapse
            orientation="vertical"
            in={isExpanded}
            collapsedSize={"6.5rem"}
          >
            {Latest_Transactions_All?.data?.results?.map((item) => {
              return <ItemView key={item?.uuid} item={item} />;
            })}
          </Collapse>
        </Box>
      ) : isInfoShowed ? null : (
        <Typography
          alignSelf={"center"}
          sx={{
            fontSize: "0.87rem",
            fontFamily: Fonts.Text,
            color: Color.grey,
            fontWeight: 500,
          }}
        >
          {t("No_Data_Found")}
        </Typography>
      )}
    </>
  );
};

// const IntegrationsView = ({ dS }) => {
//   const testLoading = useSelector((state) => state.appSlice.testLoading);
//   return (
//     <>
//       {testLoading ? (
//         <Skeleton
//           animation="wave"
//           variant="rounded"
//           width={"100%"}
//           height={"5rem"}
//         />
//       ) : (
//         <AccountView
//           dsType={[1, 10, 19]}
//           type={"organizations"}
//           datasetAccountList={dS}
//           sx={{ mb: "1rem" }}
//           showBorder
//         />
//       )}
//     </>
//   );
// };
