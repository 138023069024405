import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Typography, Grid, useTheme } from "@mui/material";
import { utils, writeFileXLSX, writeFile } from "xlsx";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { RiFileExcel2Fill } from "react-icons/ri";
import { AiFillFilePdf } from "react-icons/ai";
import { PiFileCsvFill } from "react-icons/pi";

import { setStageLoadingText } from "../../../../store/slices/appmain";
import store from "../../../../store";
import { getAllTransactionsByParams } from "../../../../Helper/data";
import { Constant } from "../../../../Helper";
import CustomPopoverScratch from "../../../../components/PopOver/CustomPopover";
import Translate from "../../../../hooks/HOC/Translate";

const exportButtonData = [
  {
    id: 0,
    title: "Excel Export",
    icon: <RiFileExcel2Fill color="green" />,
    value: "Excel",
  },
  {
    id: 1,
    title: "CSV Export",
    icon: <PiFileCsvFill color="#475569" />,
    value: "CSV",
  },
  {
    id: 2,
    title: "PDF Export",
    icon: <AiFillFilePdf color="red" />,
    value: "PDF",
  },
];
const IntegrationsByName = Constant?.integrations?.IntegrationsByName;
const ExportDialogView = ({
  rowCountState,
  headerData,
  getFilterListUrl,
  order_by,
  bankName,
  setTab,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );

  //functions
  const handleExport = async (format) => {
    const endUrl = getFilterListUrl(appliedFilterlist?.list);
    const params = {
      dataset: dataSetData?.uuid,
      page_size: 200,
      order_by: order_by,
      is_reconciled: false,
      detailed: 2,
      url: endUrl,
    };
    // dynamic header data as per tab selection
    store?.dispatch(setStageLoadingText(t("Exporting...")));

    const rowDataOfTransaction = await getAllTransactionsByParams(params);

    const selectionCategoriesByID =
      store.getState().categorySlice.selectionCategoriesByID;

    const costunitsById = store.getState().globalSlice.costunitsById;
    //Generate json format to make sheet
    const jsonDataForSheet = rowDataOfTransaction.map((item) => {
      let newItem = {};
      headerData.forEach(({ field }) => {
        newItem[field] = item[field];
      });
      newItem["category"] =
        selectionCategoriesByID?.[item?.category]?.[0]?.title;
      newItem["cost_unit"] = costunitsById[item?.cost_unit]?.[0]?.title;

      const dataSourceById = store?.getState()?.globalSlice?.dataSourceById;
      const accountByDS = store?.getState()?.globalSlice?.accountByDS;
      const data_source = dataSourceById?.[item?.data_source]?.[0];
      const account = accountByDS?.[data_source?.uuid]?.[0];
      let card = IntegrationsByName?.[data_source?.note]?.[0];
      if (data_source?.internal_dataset) {
        card = { title: "Internal data source" };
      }
      if (params.row?.source === 2) {
        card = { title: bankName?.[account?.uuid] };
      }
      newItem["data_source"] = account?.bank_details?.title
        ? account?.bank_details?.title
        : t(card?.name);
      return newItem;
    });
    store?.dispatch(setStageLoadingText(null));
    if (format !== "Excel" && format !== "CSV") {
      const convertedHeaderData = headerData?.map((obj) => {
        return {
          dataKey: obj.field,
          header: obj.headerName,
        };
      });
      exportToPDF(convertedHeaderData, jsonDataForSheet);
      return;
    }

    exportToSheet(jsonDataForSheet, format === "CSV");
  };

  const exportToSheet = (jsonData, isCsv = false) => {
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(jsonData);
    utils.book_append_sheet(wb, ws, "Transactions");
    if (isCsv) {
      writeFile(wb, "Finban.csv");
      return;
    }
    writeFileXLSX(wb, "Finban.xlsx");
  };

  const exportToPDF = (columns, jsonData) => {
    const doc = new jsPDF();

    autoTable(doc, {
      columns: columns,
      body: jsonData,
      headStyles: {
        halign: "left",
        fillColor: theme.palette.primary.main,
      },
      bodyStyles: {
        halign: "left",
      },
      styles: {
        fontSize: 6,
        cellPadding: 1,
      },
    });
    doc.save("Finban.pdf");
  };

  return (
    <CustomPopoverScratch
      button={<ButtonView setTab={setTab} />}
      paperStyle={{
        borderRadius: "0.5rem",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
      }}
    >
      <Grid container spacing={2} sx={{ p: "1rem", width: "20rem" }}>
        <Grid
          item
          xs={12}
          sx={{
            pl: "1rem",
          }}
        >
          <Typography variant="h6">{t("export_overlay_title")}</Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            mb: "1rem",
            color: theme.palette.color.description,
            pl: "1rem",
            fontSize: "0.8rem",
          }}
        >
          <Translate
            i18nKey="export_overlay_description"
            values={{ count: rowCountState || 0 }}
          />
        </Grid>

        {exportButtonData.map((data) => (
          <Grid item key={data.id} xs={12}>
            <Button
              variant="text"
              startIcon={data.icon}
              onClick={() => handleExport(data.value)}
              sx={{
                px: "1rem",
                py: ".75rem",
                width: "100%",
                justifyContent: "flex-start",
                color: theme.palette.color.slate[700],
                "&:hover": {
                  backgroundColor: theme.palette.color.slate[100],
                },
              }}
            >
              {t(data?.title)}
            </Button>
          </Grid>
        ))}
      </Grid>
    </CustomPopoverScratch>
  );
};

export default ExportDialogView;

const ButtonView = ({ popup_state, setTab }) => {
  const { t } = useTranslation();
  const onClick = () => {
    setTab("allArray");
    setTimeout(() => {
      popup_state?.open();
    }, 500);
  };

  return (
    <Button onClick={onClick} sx={{ mb: 1 }} variant="outlined">
      {t("Export")}
    </Button>
  );
};
