import { Paper, Typography } from "@mui/material";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Color, Constant, Fonts } from "../../Helper";
import { isPlanExpired } from "../../Helper/data";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const subscription = useSelector(
    (state) => state.settingsSlice?.subscription
  );
  let day = moment(subscription?.expiry_date ?? new Date()).diff(
    moment(),
    "days"
  );

  const onClickLink = () => {
    navigate("/settings/subscription");
  };

  const getText = () => {
    let text = "";

    if (isPlanExpired(subscription)) {
      text = t("Your subscription plan  Expired - Please upgrade you plan.", {
        subscription: subscription?.name,
      });
      return text;
    }
    if (subscription?.name === "Trial") {
      if (day >= 0 && day <= 2) {
        text = t(
          "Your subscription plan will Expire, Book now for a 25% discount.",
          {
            subscription: subscription?.name,
            day: day + 1,
            day_plural: day > 0 ? t("day_s") : t("day"),
          }
        );
        return text;
      }
      text = t("You can still test finban, Book now for a 25% discount.", {
        subscription: subscription?.name,
        day: day + 1,
        day_plural: day > 0 ? t("day_s") : t("day"),
      });
    } else {
      if (day >= 0 && day <= 2) {
        text = t("Your subscription plan will Expired in day", {
          subscription: subscription?.name,
          day: day + 1,
          day_plural: day > 0 ? t("day_s") : t("day"),
        });
        return text;
      }
    }
    return text;
  };
  return (
    <Paper
      sx={{
        fontSize: "1rem",
        paddingBlock: "0.1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexGrow: 1,
        background: "#aa00ff",
        borderRadius: 0,
      }}
      square
      elevation={0}
    >
      <Typography
        sx={{
          marginLeft: "0.625rem",
          background: "inherit",
          flexGrow: 1,
          color: Color?.white,
          fontFamily: Fonts?.Text,
          fontSize: { xs: "0.8rem", small: "1rem" },
          fontWeight: 500,
          cursor: "pointer",
          height: Constant.BANNER_HEIGHT,
        }}
        onClick={onClickLink}
      >
        {getText()}
      </Typography>
    </Paper>
  );
};
export default Banner;
