import { Box, ButtonGroup, Divider, useTheme } from "@mui/material";
import Button from "@mui/lab/LoadingButton";
import {
  endOfMonth,
  endOfYear,
  format,
  getQuarter,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears,
} from "date-fns";
import { de, enUS } from "date-fns/locale";

import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import { MdOutlineDateRange } from "react-icons/md";
import CustomPopover from "../../../PopOver";
import FilterBaseView from "../FilterBaseView";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import { Color, Fonts } from "../../../../Helper";

const MonthOptions = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const QuarterOptions = ["Q1", "Q2", "Q3", "Q4"];
const QuarterToStartMonthIndex = [0, 3, 6, 9];
const QuarterToEndMonthIndex = [2, 5, 8, 11];
const PRESETS = [
  "Last 3 Months",
  "Last 6 Months",
  "Last 12 Months",
  "Last Year",
  "This Year",
];
const DateRangePicker = ({
  start_date,
  end_date,
  placeholder = "Select Date Range",
  RangeType = "Monthly",
  onChangeDate,
  onClickReset,
  onOk,
  autoClose = true,
  showClear,
  hideHeading,
  defaultStart = startOfYear(new Date()),
  defaultEnd = endOfYear(new Date()),
}) => {
  const locale = useSelector((state) => state.settingsSlice?.profile?.locale);

  const [anchorElDate, setAnchorElDate] = useState(null);

  const onCloseDatePopOver = () => {
    setAnchorElDate(null);
  };

  const onClickDatePopOver = (e) => {
    setAnchorElDate(e.currentTarget);
  };

  let formatString = "MMM yy";
  if (RangeType === "Monthly") {
    formatString = "MMM yy";
  }
  if (RangeType === "Annually") {
    formatString = "yyyy";
  }
  if (RangeType === "Quarterly") {
    formatString = "QQQ, yyyy";
  }

  const _startDate =
    start_date && start_date !== "Invalid Date"
      ? format(new Date(start_date), formatString, {
          locale: locale === "de_DE" ? de : enUS,
        })
      : defaultStart
        ? format(defaultStart, formatString, {
            locale: locale === "de_DE" ? de : enUS,
          })
        : "";
  const _endDate =
    end_date && end_date !== "Invalid Date"
      ? format(new Date(end_date), formatString, {
          locale: locale === "de_DE" ? de : enUS,
        })
      : defaultEnd
        ? format(defaultEnd, formatString, {
            locale: locale === "de_DE" ? de : enUS,
          })
        : "";

  return (
    <>
      <CustomPopover
        type="anchor"
        anchorEl={anchorElDate}
        onClose={onCloseDatePopOver}
        paperStyle={{
          border: 0,
        }}
        iconStyle={{ p: 0 }}
        showRight
        icon
        hoverBG={"transparent"}
        justifyContent="flex-start"
        iconView={
          <FilterBaseView
            icon={
              <MdOutlineDateRange
                style={{ fontSize: "1.1rem", marginTop: "1px" }}
              />
            }
            heading={hideHeading ? null : "Date_Range"}
            tooltip={"tooltip_Date_Range"}
            placeholder={placeholder}
            maxWidth="fit-content"
            // isActive={isActive}
            isActive={false}
            value={
              _startDate || _endDate ? `${_startDate} ~ ${_endDate}` : null
            }
            onClick={onClickDatePopOver}
            onClear={onClickReset}
          />
        }
      >
        <DateView
          start_date={start_date || defaultStart}
          end_date={end_date || defaultEnd}
          onChangeDate={onChangeDate}
          RangeType={RangeType}
          onOk={onOk}
          autoClose={autoClose}
          showClear={showClear}
          onCloseDatePopOver={onCloseDatePopOver}
        />
      </CustomPopover>
    </>
  );
};

export default DateRangePicker;

const DateView = ({
  onCloseDatePopOver,
  start_date,
  end_date,
  onChangeDate,
  onOk,
  RangeType,
  autoClose,
  showClear,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    start_date: start_date,
    end_date: end_date,
  });

  useDebounce(
    () => {
      if (onChangeDate) {
        onChangeDate(selectedDate);
      }
    },
    500,
    [selectedDate]
  );

  const onClickOk = () => {
    if (onOk) {
      setLoading(true);
      onOk(selectedDate, onCloseDatePopOver);
      if (autoClose) {
        onCloseDatePopOver();
      }
    }
  };

  const onClickReset = () => {
    if (onOk) {
      const obj = {
        start_date: null,
        end_date: null,
      };
      setSelectedDate(obj);
      onOk(obj, onCloseDatePopOver);
      if (autoClose) {
        onCloseDatePopOver();
      }
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex" }}>
        {RangeType === "Monthly" ? (
          <PresetView setSelectedDate={setSelectedDate} />
        ) : null}
        <MonthView
          date={selectedDate?.start_date}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          RangeType={RangeType}
          type="start_date"
        />
        <Divider
          orientation="vertical"
          sx={{
            color: Color.tailwind.slate[700],
          }}
        />
        <MonthView
          date={selectedDate?.end_date}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          RangeType={RangeType}
          type="end_date"
        />
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {showClear ? (
          <Button
            onClick={onClickReset}
            sx={{
              width: "fit-content",
              height: "2rem",
              my: "0.8rem",
              mr: "1rem",
              color: Color.tailwind.slate[700],
              backgroundColor: Color.tailwind.slate[100],
              fontSize: "1rem",
              fontWeight: 600,
              lineHeight: "normal",
              padding: "0.5rem 1rem",
              textTransform: "initial",
              "&:hover": {
                backgroundColor: Color.tailwind.slate[200],
              },
            }}
          >
            {t("Reset Filter")}
          </Button>
        ) : null}

        <Button
          loading={loading}
          onClick={onClickOk}
          sx={{
            width: "fit-content",
            height: "2rem",
            my: "0.8rem",
            mr: "1rem",
            color: theme.palette.color.white,
            backgroundColor: Color.tailwind.slate[700],
            fontSize: "1rem",
            fontWeight: 600,
            lineHeight: "normal",
            padding: "0.5rem 1rem",
            textTransform: "initial",
            "&:hover": {
              backgroundColor: Color.tailwind.slate[800],
            },
          }}
        >
          {loading ? "" : t("Apply")}
        </Button>
      </div>
    </Box>
  );
};

const PresetView = ({ setSelectedDate }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleRangeSelection = (range) => {
    const today = new Date();
    if (range === "Last 3 Months") {
      setSelectedDate({
        start_date: format(startOfMonth(subMonths(today, 2)), "yyyy-MM-dd"),
        end_date: format(endOfMonth(today), "yyyy-MM-dd"),
      });
    }
    if (range === "Last 6 Months") {
      setSelectedDate({
        start_date: format(startOfMonth(subMonths(today, 5)), "yyyy-MM-dd"),
        end_date: format(endOfMonth(today), "yyyy-MM-dd"),
      });
    }
    if (range === "Last 12 Months") {
      setSelectedDate({
        start_date: format(startOfMonth(subMonths(today, 11)), "yyyy-MM-dd"),
        end_date: format(endOfMonth(today), "yyyy-MM-dd"),
      });
    }
    if (range === "Last Year") {
      const lastYearDate = subYears(today, 1);
      setSelectedDate({
        start_date: format(startOfYear(lastYearDate), "yyyy-MM-dd"),
        end_date: format(endOfYear(lastYearDate), "yyyy-MM-dd"),
      });
    }
    if (range === "This Year") {
      setSelectedDate({
        start_date: format(startOfYear(today), "yyyy-MM-dd"),
        end_date: format(endOfYear(today), "yyyy-MM-dd"),
      });
    }
  };
  return (
    <ButtonGroup
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: "1.5rem",
        ml: "0.8rem",
        "& .MuiButton-root": {
          borderRadius: "8px !important",
          color: theme.palette.color.slate[700],
          fontWeight: 600,
          border: "1px solid transparent",
          "&:hover": {
            backgroundColor: theme.palette.primary[50],
            borderColor: theme.palette.primary[500],
          },
        },
      }}
    >
      {PRESETS?.map((item) => (
        <Button
          key={item}
          onClick={() => handleRangeSelection(item)}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          {t(item)}
        </Button>
      ))}
    </ButtonGroup>
  );
};

const MonthView = ({
  date,
  selectedDate,
  setSelectedDate,
  type,
  RangeType,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [selectedQuarter, setSelectedQuarter] = useState(
    date ? getQuarter(new Date(date)) : getQuarter(new Date())
  );
  const [selectedMonth, setSelectedMonth] = useState(
    date ? new Date(date).getMonth() : new Date().getMonth()
  );
  const [selectedYear, setSelectedYear] = useState(
    date ? new Date(date).getFullYear() : new Date().getFullYear()
  );
  const [ListData, setListData] = useState([]);
  const [isYearView, setYearView] = useState(RangeType === "Annually");

  const YearOptions = useMemo(() => {
    const array = [];
    for (let year = 2000; year <= 2050; year++) {
      array.push(year);
    }
    return array;
  }, []);

  const handleMonthClick = (item, monthIndex) => {
    if (isYearView) {
      setSelectedYear(item);
      setDate(selectedMonth, item);
      if (RangeType !== "Annually") {
        setYearView(false);
      }
    } else {
      if (RangeType === "Monthly") {
        setSelectedMonth(monthIndex);
        setDate(monthIndex, selectedYear);
      }
      if (RangeType === "Quarterly") {
        let _quarter = Number(item?.replace("Q", ""));
        let _monthIndex = 0;
        if (type === "start_date") {
          _monthIndex = QuarterToStartMonthIndex[monthIndex];
        } else {
          _monthIndex = QuarterToEndMonthIndex[monthIndex];
        }
        setSelectedQuarter(_quarter);
        setDate(_monthIndex, selectedYear);
      }
    }
  };

  const setDate = (monthIndex, year) => {
    let _date = new Date(new Date().setMonth(monthIndex)).setFullYear(year);
    if (type === "start_date") {
      setSelectedDate((prev) => ({
        ...prev,
        [type]: format(startOfMonth(_date), "yyyy-MM-dd"),
      }));
    } else {
      setSelectedDate((prev) => ({
        ...prev,
        [type]: format(endOfMonth(_date), "yyyy-MM-dd"),
      }));
    }
  };

  const clickRight = () => {
    setSelectedYear(selectedYear + 1);
    setDate(selectedMonth, selectedYear + 1);
  };

  const clickLeft = () => {
    setSelectedYear(selectedYear - 1);
    setDate(selectedMonth, selectedYear - 1);
  };

  const onClickYear = () => {
    setListData(YearOptions);
    setYearView(true);
  };

  useEffect(() => {
    if (RangeType === "Monthly") {
      setListData(MonthOptions);
      setYearView(false);
    }
    if (RangeType === "Annually") {
      setListData(YearOptions);
      setYearView(true);
    }
    if (RangeType === "Quarterly") {
      setListData(QuarterOptions);
      setYearView(false);
    }
  }, [RangeType, YearOptions]);

  useDebounce(
    () => {
      if (isYearView) {
        setListData(YearOptions);
      } else {
        if (RangeType === "Monthly") {
          setListData(MonthOptions);
        }
        if (RangeType === "Quarterly") {
          setListData(QuarterOptions);
        }
      }
    },
    200,
    [isYearView, YearOptions]
  );

  useDebounce(
    () => {
      if (selectedDate?.start_date) {
        if (
          new Date(selectedDate?.start_date) > new Date(selectedDate?.end_date)
        ) {
          setSelectedQuarter(getQuarter(new Date(selectedDate?.start_date)));
          setSelectedMonth(new Date(selectedDate?.start_date).getMonth());
          setSelectedYear(new Date(selectedDate?.start_date).getFullYear());
          setSelectedDate((prev) => ({
            ...prev,
            end_date: format(
              endOfMonth(new Date(selectedDate?.start_date)),
              "yyyy-MM-dd"
            ),
          }));
        }

        setSelectedQuarter(getQuarter(new Date(selectedDate?.[type])));
        setSelectedYear(new Date(selectedDate?.[type]).getFullYear());
        setSelectedMonth(new Date(selectedDate?.[type]).getMonth());
      }
    },
    200,
    [selectedDate?.start_date],
    true
  );

  return (
    <Box sx={{ p: "0.5rem" }}>
      {!isYearView ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBlock: "1rem",
            }}
          >
            <WestIcon
              onClick={clickLeft}
              sx={{
                color: Color.tailwind.slate[400],
                cursor: "pointer",
                mr: "3rem",
              }}
            />
            <p
              onClick={onClickYear}
              style={{
                fontSize: "1rem",
                fontWeight: 600,
                lineHeight: "normal",
                fontFamily: Fonts.Text,
                color: Color.tailwind.slate[700],
                cursor: "pointer",
              }}
            >
              {selectedYear}
            </p>
            <EastIcon
              onClick={clickRight}
              sx={{
                color: Color.tailwind.slate[400],
                cursor: "pointer",
                ml: "3rem",
              }}
            />
          </div>
        </div>
      ) : null}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "22rem",
          maxHeight: "20rem",
          overflowY: "auto",
          ...theme.thinScrollbarStyle,
        }}
      >
        {ListData?.map((item, index) => {
          if (date?.toString() === "Invalid Date") {
            return null;
          }

          let _date = null;
          let _selectedDate = null;
          if (RangeType === "Monthly") {
            _date = date
              ? new Date(
                  new Date(new Date(date).setMonth(index)).setFullYear(
                    selectedYear
                  )
                )
              : null;
          }
          if (RangeType === "Quarterly") {
            let _quarter = Number(item?.replace("Q", ""));
            let _monthIndex = 0;
            if (type === "start_date") {
              _monthIndex = QuarterToStartMonthIndex[_quarter - 1];
            } else {
              _monthIndex = QuarterToEndMonthIndex[_quarter - 1];
            }
            _date = new Date(
              new Date(new Date(date).setMonth(_monthIndex)).setFullYear(
                selectedYear
              )
            );
          }
          if (isYearView) {
            _date = new Date(
              new Date(new Date(date).setMonth(selectedMonth || 0)).setFullYear(
                item
              )
            );
          }

          if (RangeType === "Quarterly") {
            let _monthIndex = 0;
            if (type === "start_date") {
              _monthIndex = QuarterToStartMonthIndex[selectedQuarter - 1];
            } else {
              _monthIndex = QuarterToEndMonthIndex[selectedQuarter - 1];
            }
            _selectedDate = format(
              new Date(
                new Date(new Date(date).setMonth(_monthIndex)).setFullYear(
                  selectedYear
                )
              ),
              "yyyy-MM-dd"
            );
          } else {
            _selectedDate = format(
              new Date(
                new Date(new Date(date).setMonth(selectedMonth)).setFullYear(
                  selectedYear
                )
              ),
              "yyyy-MM-dd"
            );
          }

          if (_date === "Invalid Date") {
            return null;
          }

          const isSelected =
            _date &&
            _date !== "Invalid Date" &&
            date &&
            date !== "Invalid Date" &&
            selectedYear
              ? format(_date, "yyyy-MM-dd") === _selectedDate
              : false;
          const disabled =
            type === "end_date" && selectedDate?.start_date
              ? _date < new Date(selectedDate?.start_date)
              : false;
          return (
            <Button
              key={index}
              disabled={disabled}
              onClick={() => handleMonthClick(item, index)}
              sx={{
                width: RangeType === "Quarterly" ? "9rem" : "6rem",
                flexGrow: 1,
                m: "0.25rem",
                color: isSelected ? "white" : Color.tailwind.slate[700],
                backgroundColor: isSelected
                  ? Color.tailwind.slate[500]
                  : "transparent",
                border: isSelected ? "none" : "1px solid",
                borderColor: Color.tailwind.slate[300],
                fontSize: "0.9rem",
                fontWeight: 600,
                lineHeight: "normal",
                fontFamily: Fonts.Text,
                padding: "0.5rem 1rem",
                textTransform: "initial",
                "&:hover": {
                  backgroundColor: isSelected
                    ? Color.tailwind.slate[500]
                    : Color.tailwind.slate[100],
                },
              }}
            >
              {t(item)}
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};
