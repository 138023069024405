import { Box } from "@mui/material";
import { Outlet } from "react-router";
import { useSelector } from "react-redux";

import { Color } from "../../../Helper";
import AvailableView from "./AvailableView";

const DataSource = () => {
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  if (!isAllHeaderApiFetched) {
    return null;
  }
  return (
    <Box
      sx={{
        backgroundColor: Color.appThemeBg,
        position: "relative",
        overflowY: "auto",
        height: "100%",
        pt: "3.5rem",
        ml: "90px",
      }}
    >
      <Box
        sx={{
          // maxWidth: "70%",
          // width: { xs: "90rem", s1366: "102rem", s2400: "110rem" },
          // maxWidth: { xs: "60%", s1366: "78%", s2400: "85%" },
          maxWidth: "90%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AvailableView defaultSection={10} />
      </Box>

      <Outlet />
    </Box>
  );
};

export default DataSource;
