import {
  Box,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import OverlayFooter from "../../../../../components/Overlay/OverlayFooter";

const SelectHeaderStep = ({ data, onContinue }) => {
  const [selectedRows, setSelectedRows] = useState([0]);

  const handleContinue = useCallback(async () => {
    const [selectedRowIndex] = selectedRows;
    const trimmedData = data.slice(selectedRowIndex + 1);
    await onContinue(data[selectedRowIndex], trimmedData);
  }, [onContinue, data, selectedRows]);

  const [firstRow] = data;

  const columns = Object.values(firstRow).map((value) => ({
    field: value,
    headerName: value,
  }));

  const rows = data.map((row) => {
    const rowObject = {};
    firstRow.forEach((columnName, index) => {
      rowObject[columnName] = row[index];
    });
    return rowObject;
  });

  const handleClick = (id) => {
    setSelectedRows([id]);
  };

  return (
    <Box>
      <TableContainer>
        <Table size={"small"}>
          <TableBody>
            {rows.slice(0, 20).map((row, index) => {
              const isItemSelected = selectedRows.includes(index);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  onClick={() => handleClick(index)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={index}
                  selected={isItemSelected}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell padding="checkbox">
                    <Radio
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell
                      align="right"
                      sx={{ fontWeight: index === 0 ? "bold" : "normal" }}
                    >
                      {row[column.field]}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <OverlayFooter
        onClickAction={handleContinue}
        textAdd="Next"
        sx={{ width: "100%", justifyContent: "flex-end", mt: "2rem" }}
      />
    </Box>
  );
};

export default SelectHeaderStep;
