const EndPoints = {
  sheets: "/sheets/",
  profile: "/profile/",
  master_data: "/master-data/",
  boards: "/boards/",
  datasets: "/datasets/",
  transactions: "/transactions/",
  transactionBulkList: "/transactions/group/",
  monthly_transactions: "/monthly_transactions/",
  transaction_monthly_statistic: "/transaction_monthly_statistic/",
  transaction_daily_statistic: "/transaction_daily_statistic/",
  transaction_payment_monthly_statistic:
    "/transaction_payment_monthly_statistic/",
  transaction_monthly_chart: "/transaction_monthly_chart/",
  transaction_daily_chart: "/transaction_daily_chart/",
  transaction_payment_daily_statistic: "/transaction_payment_daily_statistic/",
  transactionsPosition: "/transactions/update_position/",
  transactionsBatch: "transactions/batch_update/",
  filters: "/filters/",
  cards: "/cards/",
  sheetCategories: "/sheet_categories/",
  sheetEntries: "/sheet_entries/",
  category: "/categories/",
  recurring_rules: "/recurring_rules/",
  refreshToken: "/auth/refresh/",
  register: "/auth/register/",
  activateAccount: "/auth/register/activate/",
  password_reset: "/auth/password_reset/",
  validate_token: "/auth/password_reset/validate_token/",
  pass_confirm: "/auth/password_reset/confirm/",
  states: "/states/",
  scenarios: "/scenarios/",
  customers: "/contacts/",
  todos: "/todos/",
  accounts: "/accounts/",
  banks: "/banks/",
  transaction_systems: "/stransaction_systems/",
  costunits: "/costunits/",
  taxes: "/taxes/",
  invoices: "/invoices/",
  advanceTax: "/configuration/tax/",
  integrations: "/data_sources/",
  transactions_rules: "/transactions_rules/",
  pricing_table: "/payment/pricing-table/",
  cancelSubscription: "/payment/subscription/cancel/",
  reactivateSubscription: "/payment/subscription/reactivate/",
  upgradeSubscription: "/payment/subscription/upgrade/",
  plansList: "/payment/plans/",
  reporting_dashboards: "/reporting_dashboards/",
  users: "/users/",
};

export default EndPoints;
