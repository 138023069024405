import React, { Suspense, useLayoutEffect, useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  BrowserRouter,
} from "react-router-dom";

import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Grow } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import * as Sentry from "@sentry/react";

import store, { persistor } from "./store";
import AppRouter from "./routes";
import theme from "./theme";
import Tour from "./components/Tour";
import { removeAllCookies, removeCache } from "./Helper/data";
import { Constant } from "./Helper";
import packageJson from "../package.json";
import ErrorPage from "./Pages/ErrorPage";

Sentry.init({
  release: "finban@" + packageJson?.SW_VERSION,
  dsn: process.env.REACT_APP_SENTRY_DNS,
  environment: process.env.REACT_APP_MODE,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  ignoreErrors: [
    "TypeError: Failed to fetch",
    "TypeError: NetworkError when attempting to fetch resource.",
    "Request aborted",
    "Network Error",
    "Request failed with status code 401",
    "UnknownError: Connection to Indexed Database server lost. Refresh the page to try again",
    "Rendered fewer hooks than expected. This may be caused by an accidental early return statement",
    "Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node",
    "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node",
    `Blocked a frame with origin "https://app.finban.io" from accessing a cross-origin frame. Protocols, domains, and ports must match.`,
    `Blocked a frame with origin "https://staging.finban.io" from accessing a cross-origin frame. Protocols, domains, and ports must match.`,
    `Blocked a frame with origin "https://test.finban.io" from accessing a cross-origin frame. Protocols, domains, and ports must match.`,
  ],
  normalizeDepth: 7,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.7,
  // autoSessionTracking: false,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    // "localhost",
    /^https:\/\/test\.finban\.io\/api/,
    /^https:\/\/staging\.finban\.io\/api/,
    /^https:\/\/app\.finban\.io\/api/,
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error

  replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 1.0,
  enabled: process.env.REACT_APP_MODE !== "development",
});

//test
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
      cacheTime: (1000 * 60 * 60 * 1) / 48, // 0.5 hour
      refetchOnWindowFocus: false, // default: true
      refetchOnMount: false, // default: true
      // notifyOnChangeProps: [
      //   "isFetching",
      //   "isLoading",
      //   "isSuccess",
      //   "data",
      //   "error",
      // ],
      // staleTime: 60000,
      // refetchOnReconnect: false, // default: true
      // logger: {
      //   log: (...args) => {
      //     // Log debugging information
      //   },
      //   warn: (...args) => {
      //     // Log warning
      //   },
      //   error: (...args) => {
      //     // Log error
      //   },
      // },
    },
  },
});

// const router = createBrowserRouter([{ path: "*", Component: AppRouter }]);
const REACT_APP_CLARITY_ID = process.env.REACT_APP_CLARITY_ID
  ? (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.type = "text/javascript";
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", process.env.REACT_APP_CLARITY_ID)
  : null;

// const REACT_APP_SIB_CONVERSATIONS_WIDGET_ID = process.env
//   .REACT_APP_SIB_CONVERSATIONS_WIDGET_ID
//   ? (function (d, w, c) {
//       w.SibConversationsID = process.env.REACT_APP_SIB_CONVERSATIONS_WIDGET_ID;
//       w[c] =
//         w[c] ||
//         function () {
//           (w[c].q = w[c].q || []).push(arguments);
//         };
//       var s = d.createElement("script");
//       s.async = true;
//       s.src =
//         "https://conversations-widget.sendinblue.com/sib-conversations.js";
//       if (d.head) d.head.appendChild(s);
//     })(document, window, "SibConversations")
//   : null;

export default function App() {
  useLayoutEffect(() => {
    if (process.env.REACT_APP_PLAUSIBLE_DATA_DOMAIN) {
      const script = document.createElement("script");
      script.src = process.env.PLAUSIBLE_URL;
      script.defer = true;
      script.type = "text/javascript";
      script.setAttribute(
        "data-domain",
        process.env.REACT_APP_PLAUSIBLE_DATA_DOMAIN
      );
      document.body.append(script);
    }
  }, []);

  const onError = (error, errorInfo) => {
    if (Constant?.isTest) {
      console.log("Error  ==> ", error);
    }
    if (!Constant?.isDev) {
      const user = localStorage.getItem("LoggedInUser");

      Sentry.setExtra("onError  ==> ", {
        message: error?.message,
        response: error?.response,
        status: error?.status || error?.response?.status,
        user,
      });
      Sentry.captureException(error);
    }
    if (error?.message?.includes("Maximum call stack size exceeded")) {
      clearAllState();
      if (!window?.location?.pathname?.includes("/login")) {
        window.location.href = "/login";
      }
    }
  };

  const clearAllState = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("last_visited_dataset");
    localStorage.removeItem("LoggedInUser");
    localStorage.removeItem("persist:root");
    queryClient.clear();
    removeAllCookies();
    removeCache();
  };

  return (
    <span>
      <script>
        {REACT_APP_CLARITY_ID}

        {/* {REACT_APP_SIB_CONVERSATIONS_WIDGET_ID} */}
      </script>
      <script
        type="text/babel"
        src="https://assets.calendly.com/assets/external/widget.js"
        async
      ></script>
      {/* <div
        class="calendly-inline-widget"
        data-url="https://calendly.com/finban"
        style={{
          minWidth: "320px",
          height: "300px",
        }}

      ></div> */}
      <Sentry.ErrorBoundary
        FallbackComponent={ErrorPage}
        onError={onError}
        FallbackRender={null}
      >
        <Suspense fallback={"Loading..."}>
          <SnackbarProvider
            maxSnack={3}
            variant="success"
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            TransitionComponent={Grow}
          >
            <ThemeProvider theme={theme}>
              <Provider store={store}>
                <PersistGate persistor={persistor} loading={null}>
                  <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                      <Tour />
                      <AppRouter />
                    </BrowserRouter>
                  </QueryClientProvider>
                </PersistGate>
              </Provider>
            </ThemeProvider>
          </SnackbarProvider>
        </Suspense>
      </Sentry.ErrorBoundary>
    </span>
  );
}
