import { Box, ClickAwayListener } from "@mui/material";
import { Color, Fonts } from "../../Helper";
import { getTailwindColor } from "../../Helper/data";

const ActionViewWrapper = ({
  sx,
  onClickAway,
  children,
  width,
  height = "2.2rem",
  iconMR = 0,
  wrapperBackgroundColor,
  hoverBtnBackgroundColor,
  hoverBtnIconColor,
  btnBackgroundColor = Color.white,
  ...rest
}) => {
  return (
    <ClickAwayListener
      onClickAway={onClickAway ? onClickAway : () => undefined}
    >
      <Box
        size="small"
        {...rest}
        sx={{
          right: "1rem",
          display: "flex",
          alignSelf: "flex-end",
          justifyContent: "space-between",
          maxWidth: "fit-content",
          borderRadius: "50px",
          cursor: "pointer",
          backgroundColor:
            wrapperBackgroundColor || getTailwindColor("slate", 100),
          "& .actionBtn": {
            backgroundColor: btnBackgroundColor,
            borderRadius: 50,
            fontFamily: Fonts.Text,
            fontWeight: 600,
            fontSize: "0.8rem",
            minWidth: !width ? "auto" : "2.2rem",
            textTransform: "initial",
            width: width || "2.2rem",
            height: height,
            marginInline: "0.5rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            marginBlock: "0.37rem",
            "&: hover": {
              backgroundColor:
                hoverBtnBackgroundColor || getTailwindColor("purple", 200),
              "& .actionBtnIcon, svg": {
                color: `${hoverBtnIconColor||getTailwindColor("purple", 800)} !important`,
              },
            },
            "& .actionBtnIcon": {
              color: Color.themeColor2,
              fontSize: "1.25rem",
              margin: 0,
              marginRight: iconMR,
            },
          },
          ...sx,
        }}
      >
        {children}
      </Box>
    </ClickAwayListener>
  );
};

export default ActionViewWrapper;
