import { Box, Button, ButtonGroup, Divider, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  format,
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  subMonths,
  addMonths,
} from "date-fns";
import _ from "underscore";

import { FiLayers } from "react-icons/fi";
import { MdOutlineDateRange } from "react-icons/md";
import { TbChartAreaLineFilled, TbCircles, TbPlug } from "react-icons/tb";
import { RiArrowUpDownLine, RiFolderChartLine } from "react-icons/ri";

import LocalOfferIcon from "@mui/icons-material/LocalOffer";

import { updateFilters } from "../../../../store/slices/global";
import TransactionViewSettingPopOver from "./TransactionViewSettingPopOver";
import { Constant, Images } from "../../../../Helper";
import HelpOverlay from "../../../../components/HelpOverlay.js/index.js";
import CustomPopover from "../../../../components/PopOver";
import Toggle from "../../../../components/ToggleGroupeView/Toggle";
import DateInputPicker from "../../../../components/Overlay/DateInputPicker";
import SearchFilterView from "../../../../components/BoardHeader/SearchFilterView";
import initialData from "../../../../Helper/data";
import store from "../../../../store";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import FilterBaseView from "../../../../components/Overlay/ThemeFilter/FilterBaseView";
import ThemeFilter from "../../../../components/Overlay/ThemeFilter";
import CategoryFilterView from "../../../../components/Overlay/ThemeFilter/Component/CategoryFilterView";
import AddFilterView from "../../../../components/Overlay/ThemeFilter/AddFilterView";
import AddFilterPopOver from "../../../../components/Overlay/ThemeFilter/AddFilterPopover";

const dateOptions = [
  {
    value: 1,
    label: "Due",
  },
  {
    value: 2,
    label: "Invoiced",
  },
];
const typeOptions = [
  { uuid: "Income", title: "Income", color: "green" },
  { uuid: "Expense", title: "Expense", color: "red" },
];
const sourceOptions = [
  { uuid: "Option_Planning", title: "Option_Planning" },
  { uuid: "Option_Api", title: "Option_Api" },
  { uuid: "Option_Import", title: "Option_Import" },
  { uuid: "Option_External", title: "Option_External" },
];

const ListHeader = ({
  hideState = false,
  hideScenario = false,
  hideCostUnit = false,
  hideCategory = false,
  hideSource = false,
  optionsType = null,
  setAllowUpdate,
  width,
  tab,
}) => {
  const dispatch = useDispatch();
  const isFilterOpen = useRef(false);

  //redux state
  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );

  //state
  const [anchorEl, setAnchorEl] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [isIncludedInFilterList, setIsIncludedInFilterList] = useState([]);

  //function
  const onClose = (_selectedIds) => {
    isFilterOpen.current = false;
    setIsIncludedInFilterList(_selectedIds);
    setAnchorEl(null);
    let remove = {};
    if (
      (appliedFilterlist?.list?.start_date ||
        appliedFilterlist?.list?.end_date) &&
      !_selectedIds?.includes("date")
    ) {
      remove.start_date = null;
      remove.end_date = null;
    }
    if (
      !hideState &&
      !optionsType &&
      appliedFilterlist?.list?.selectedStates?.length > 0 &&
      !_selectedIds?.includes("state")
    ) {
      remove.selectedStates = [];
    }
    if (
      !hideState &&
      optionsType === "Open" &&
      appliedFilterlist?.list?.selectedOpenStates?.length > 0 &&
      !_selectedIds?.includes("state_open")
    ) {
      remove.selectedOpenStates = [];
    }
    if (
      !hideState &&
      optionsType === "Bank" &&
      appliedFilterlist?.list?.selectedBankStates?.length > 0 &&
      !_selectedIds?.includes("state_bank")
    ) {
      remove.selectedBankStates = [];
    }
    if (
      !hideScenario &&
      appliedFilterlist?.list?.selectedScenarios?.length > 0 &&
      !_selectedIds?.includes("scenario")
    ) {
      remove.selectedScenarios = [];
    }
    if (
      !hideCostUnit &&
      appliedFilterlist?.list?.selectedCostUnits?.length > 0 &&
      !_selectedIds?.includes("cost_unit")
    ) {
      remove.selectedCostUnits = [];
    }
    if (
      !hideCategory &&
      appliedFilterlist?.list?.selectedCategory?.length > 0 &&
      !_selectedIds?.includes("category")
    ) {
      remove.selectedCategory = [];
    }
    if (
      appliedFilterlist?.list?.typeName?.length !== 2 &&
      !_selectedIds?.includes("typeName")
    ) {
      remove.typeName = ["Expense", "Income"];
    }
    if (
      !hideSource &&
      appliedFilterlist?.list?.source?.length !== 4 &&
      !_selectedIds?.includes("source")
    ) {
      remove.source = Constant.DefaultSource;
    }
    if (
      appliedFilterlist?.list?.selectedDataSource?.length > 0 &&
      !_selectedIds?.includes("ds")
    ) {
      remove.ds = [];
    }
    if (Object?.keys(remove)?.length > 0) {
      updateFilterObj(remove);
    }
  };

  const updateFilterObj = (_obj) => {
    let obj = {
      parent_key: "list",
      obj: _obj,
    };
    dispatch(updateFilters(obj));
  };

  const onClickFilter = useCallback((e) => {
    isFilterOpen.current = true;
    setAnchorEl(e.currentTarget);
  }, []);

  const removeFilter = useCallback((key) => {
    setIsIncludedInFilterList((prev) => prev?.filter((item) => item !== key));
  }, []);

  useDebounce(
    () => {
      if (!isFilterOpen.current) {
        let array = [];
        let included_array = [...isIncludedInFilterList];

        if (
          appliedFilterlist?.list?.start_date ||
          appliedFilterlist?.list?.end_date
        ) {
          array.push("date");
          included_array.push("date");
        }
        if (appliedFilterlist?.list?.searchText?.length > 0) {
          array.push("search");
          included_array.push("search");
        }
        if (
          appliedFilterlist?.list?.selectedStates?.length > 0 &&
          !hideState &&
          !optionsType
        ) {
          array.push("state");
          included_array.push("state");
        }
        if (
          appliedFilterlist?.list?.selectedOpenStates?.length > 0 &&
          !hideState &&
          optionsType === "Open"
        ) {
          array.push("state_open");
          included_array.push("state_open");
        }
        if (
          appliedFilterlist?.list?.selectedBankStates?.length > 0 &&
          !hideState &&
          optionsType === "Bank"
        ) {
          array.push("state_bank");
          included_array.push("state_bank");
        }
        if (
          appliedFilterlist?.list?.selectedScenarios?.length > 0 &&
          !hideScenario
        ) {
          array.push("scenario");
          included_array.push("scenario");
        }
        if (
          appliedFilterlist?.list?.selectedCostUnits?.length > 0 &&
          !hideCostUnit
        ) {
          array.push("cost_unit");
          included_array.push("cost_unit");
        }
        if (
          appliedFilterlist?.list?.selectedCategory?.length > 0 &&
          !hideCategory
        ) {
          array.push("category");
          included_array.push("category");
        }

        if (appliedFilterlist?.list?.typeName?.length !== 2) {
          array.push("typeName");
          included_array.push("typeName");
        }
        if (appliedFilterlist?.list?.source?.length !== 4 && !hideSource) {
          array.push("source");
          included_array.push("source");
        }
        if (appliedFilterlist?.list?.selectedDataSource?.length > 0) {
          array.push("ds");
          included_array.push("ds");
        }
        included_array = Array.from(new Set(included_array));
        setFiltered(array);
        setIsIncludedInFilterList(included_array);
      }
    },
    500,
    [
      appliedFilterlist?.list,
      hideCategory,
      hideCostUnit,
      hideScenario,
      hideSource,
      hideState,
      optionsType,
    ],
    true
  );

  const FilterOptions = useMemo(
    () => [
      {
        uuid: "date",
        title: "add_date_filter",
        icon: <MdOutlineDateRange />,
        hide: false,
      },
      {
        uuid: "state",
        title: "add_state_filter",
        icon: <TbCircles />,
        hide: hideState || (!hideState && optionsType),
      },
      {
        uuid: "state_open",
        title: "add_state_filter",
        icon: <TbCircles />,
        hide: hideState || (!hideState && optionsType !== "Open"),
      },
      {
        uuid: "state_bank",
        title: "add_state_filter",
        icon: <TbCircles />,
        hide: hideState || (!hideState && optionsType !== "state_bank"),
      },
      {
        uuid: "scenario",
        title: "add_scenario_filter",
        icon: <TbChartAreaLineFilled />,
        hide: hideScenario,
      },
      {
        uuid: "cost_unit",
        title: "add_cost_unit_filter",
        icon: <RiFolderChartLine />,
        hide: hideCostUnit,
      },
      {
        uuid: "category",
        title: "add_category_filter",
        icon: <LocalOfferIcon />,
        hide: hideCategory,
      },
      {
        uuid: "typeName",
        title: "add_typeName_filter",
        icon: <RiArrowUpDownLine />,
        hide: false,
      },
      {
        uuid: "source",
        title: "add_source_filter",
        icon: <FiLayers />,
        hide: hideSource,
      },
      {
        uuid: "ds",
        title: "add_ds_filter",
        icon: <TbPlug />,
        hide: hideSource,
      },
    ],
    [
      hideCategory,
      hideCostUnit,
      hideScenario,
      hideSource,
      hideState,
      optionsType,
    ]
  );

  //render functions
  const DetailsView = useMemo(() => {
    return (
      <div
        style={{
          display: "inline-flex",
          alignItems: "start",
        }}
      >
        <BulkActionView tab={tab} />
        <SearchView />
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            height: "1.2rem",
            opacity: "0.6",
            alignSelf: "center",
            ml: "0.75rem",
          }}
        />
        <Box
          display="inline-flex"
          alignItems="center"
          justifyContent={"flex-start"}
          width="fit-content"
          sx={{
            minWidth: "25rem",
            transition: `all 0.3s ease-in-out`,
            zIndex: 2,
            gap: "0.5rem",
            flexWrap: "wrap",
          }}
        >
          {isIncludedInFilterList?.length > 0 ? null : (
            <AddFilterView
              isIncludedInFilterList={isIncludedInFilterList}
              onClick={onClickFilter}
            />
          )}

          {isIncludedInFilterList?.map((item) => {
            if (item === "date") {
              return (
                <DatePopOver
                  setAllowUpdate={setAllowUpdate}
                  isFilterOpen={isFilterOpen}
                  filterKey="date"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "state") {
              return (
                <StateFilter
                  type="list"
                  isFilterOpen={isFilterOpen}
                  filterKey="state"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "state_open") {
              return (
                <StateFilter
                  type="list"
                  isFilterOpen={isFilterOpen}
                  optionsType={optionsType}
                  _key={"selectedOpenStates"}
                  filterKey="state_open"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "state_bank") {
              return (
                <StateFilter
                  type="list"
                  optionsType={optionsType}
                  isFilterOpen={isFilterOpen}
                  _key={"selectedBankStates"}
                  filterKey="state_bank"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "scenario") {
              return (
                <ScenarioFilter
                  type="list"
                  isFilterOpen={isFilterOpen}
                  filterKey="scenario"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "cost_unit") {
              return (
                <CostUnitFilter
                  type="list"
                  isFilterOpen={isFilterOpen}
                  filterKey="cost_unit"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "category") {
              return (
                <CategoryFilter
                  type="list"
                  isFilterOpen={isFilterOpen}
                  filterKey="category"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "typeName") {
              return (
                <TypeFilter
                  isFilterOpen={isFilterOpen}
                  filterKey="typeName"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "source") {
              return (
                <SourceFilter
                  isFilterOpen={isFilterOpen}
                  filterKey="source"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "ds") {
              return (
                <DsFilter
                  isFilterOpen={isFilterOpen}
                  filterKey="ds"
                  removeFilter={removeFilter}
                />
              );
            }

            return null;
          })}

          {isIncludedInFilterList?.length > 0 ? (
            <AddFilterView
              isIncludedInFilterList={isIncludedInFilterList}
              onClick={onClickFilter}
            />
          ) : null}
          <ResetView
            filtered={filtered}
            setIsIncludedInFilterList={setIsIncludedInFilterList}
          />
        </Box>
      </div>
    );
  }, [
    tab,
    isIncludedInFilterList,
    onClickFilter,
    filtered,
    setAllowUpdate,
    optionsType,
    removeFilter,
  ]);

  return (
    <Box
      sx={{
        // width: "fit-content",
        width: width,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {DetailsView}
      {!!anchorEl ? (
        <AddFilterPopOver
          anchorEl={anchorEl}
          onClose={onClose}
          selectedIds={isIncludedInFilterList}
          options={FilterOptions}
        />
      ) : null}
      <HelpOverlay
        text={"List View"}
        path={"list"}
        tourKey="list"
        hideHeader
        image={Images.list_insight}
        wrapperStyle={{ mr: 0 }}
      />
    </Box>
  );
};
export default ListHeader;

const SearchView = ({ color = "slate" }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  //redux
  const listSearchText = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.list?.searchText
  );

  //state
  const [searchText, setSearchText] = useState(listSearchText);

  useEffect(() => {
    if (location?.state?.highlightSearch) {
      inputRef.current?.focus();
    }
  }, [location]);

  useDebounce(
    () => {
      updateFilterObj({ searchText });
    },
    500,
    [searchText]
  );

  //functions
  const onClickClear = () => {
    // updateFilterObj({ searchText: "" });
    setSearchText("");
  };

  const updateFilterObj = (_obj) => {
    let obj = {
      parent_key: "list",
      obj: _obj,
    };
    dispatch(updateFilters(obj));
  };

  const onChangeSearch = (e) => {
    setSearchText(e.target.value);
    // updateFilterObj({ searchText: e.target.value });
  };

  return (
    <SearchFilterView
      inputRef={inputRef}
      active={searchText?.length > 0}
      value={searchText}
      color={color}
      onClickClear={onClickClear}
      onChange={onChangeSearch}
    />
  );
};

const DatePopOver = forwardRef(
  (
    { setAllowUpdate, color = "slate", isFilterOpen, filterKey, removeFilter },
    _ref
  ) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();
    const appliedFilterlist = useSelector(
      (state) => state.globalSlice.appliedFilterlist
    );
    const profile = useSelector((state) => state.settingsSlice?.profile);

    const [anchorElDate, setAnchorElDate] = useState(null);

    //functions
    const formatDate = (date) => format(date, "yyyy-MM-dd");

    const handleRangeSelection = (range) => {
      let [start_date, end_date] = getDateRange(range);
      if (start_date && end_date) {
        updateFilterObj({
          start_date,
          end_date,
        });
      }
    };

    const updateFilterObj = (_obj) => {
      let obj = {
        parent_key: "list",
        obj: _obj,
      };
      dispatch(updateFilters(obj));
    };

    const getDateRange = (range) => {
      const today = new Date();
      switch (range) {
        case "today":
          return [formatDate(startOfDay(today)), formatDate(endOfDay(today))];
        case "thisMonth":
          return [
            formatDate(startOfMonth(today)),
            formatDate(endOfMonth(today)),
          ];
        case "nextMonth":
          const nextMonthStart = startOfMonth(addMonths(today, 1));
          const nextMonthEnd = endOfMonth(addMonths(today, 1));
          return [formatDate(nextMonthStart), formatDate(nextMonthEnd)];
        case "lastMonth":
          const lastMonthStart = startOfMonth(subMonths(today, 1));
          const lastMonthEnd = endOfMonth(subMonths(today, 1));
          return [formatDate(lastMonthStart), formatDate(lastMonthEnd)];
        case "thisYear":
          return [formatDate(startOfYear(today)), formatDate(endOfYear(today))];
        case "last6Months":
          const last6MonthsStart = startOfMonth(subMonths(today, 5));
          const last6MonthsEnd = endOfMonth(today);
          return [formatDate(last6MonthsStart), formatDate(last6MonthsEnd)];
        case "last12Months":
          const lastYearStart = startOfMonth(subMonths(today, 11));
          const lastYearEnd = endOfMonth(today);
          return [formatDate(lastYearStart), formatDate(lastYearEnd)];
        default:
          return [null, null];
      }
    };

    const onCloseDatePopOver = () => {
      if (isFilterOpen) {
        isFilterOpen.current = false;
      }
      setAnchorElDate(null);
    };

    const onClickDatePopOver = (e) => {
      if (isFilterOpen) {
        isFilterOpen.current = true;
      }
      setAnchorElDate(e.currentTarget);
    };

    const onChangeDateFilters = (e, value) => {
      updateFilterObj({ date_type: value });
    };

    const onChangeDate = (value, name) => {
      if (value?.toString() !== "Invalid Date") {
        let date = value ? format(new Date(value), "yyyy-MM-dd") : null;
        updateFilterObj({ [name]: date });
      }
    };

    const onChangeDateFocus = () => {
      setAllowUpdate(false);
    };

    const onChangeDateBlur = () => {
      setAllowUpdate(true);
    };

    const onClickReset = () => {
      updateFilterObj({
        date_type: 1,
        start_date: null,
        end_date: null,
      });
      removeFilter(filterKey);
    };

    const start_date = appliedFilterlist?.list?.start_date
      ? format(
          new Date(appliedFilterlist?.list?.start_date),
          profile?.locale === "de_DE" ? "dd.MM.yyyy" : "dd/MM/yyyy"
        )
      : "";
    const end_date = appliedFilterlist?.list?.end_date
      ? format(
          new Date(appliedFilterlist?.list?.end_date),
          profile?.locale === "de_DE" ? "dd.MM.yyyy" : "dd/MM/yyyy"
        )
      : "";

    return (
      <CustomPopover
        type="anchor"
        anchorEl={anchorElDate}
        onClose={onCloseDatePopOver}
        paperStyle={{
          border: 0,
        }}
        iconStyle={{ p: 0 }}
        showRight
        icon
        hoverBG={"transparent"}
        justifyContent="flex-start"
        iconView={
          <FilterBaseView
            icon={
              <MdOutlineDateRange
                style={{ fontSize: "1.1rem", marginTop: "1px" }}
              />
            }
            heading={"Date_Range"}
            tooltip={"tooltip_Date_Range"}
            maxWidth="fit-content"
            active={false}
            value={
              start_date || end_date ? `${start_date} ~ ${end_date}` : null
            }
            onClick={onClickDatePopOver}
            onClear={onClickReset}
          />
        }
      >
        <Box
          sx={{
            p: "1rem",
            pb: "3rem",
            position: "relative",
            width: "fit-content",
            height: "fit-content",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "30%" }}>
            <DateInputPicker
              name="start_date"
              label={"Start Date"}
              tooltip={"Start Date"}
              showFullDate
              value={
                appliedFilterlist?.list?.start_date
                  ? new Date(appliedFilterlist?.list?.start_date)
                  : null
              }
              onChange={onChangeDate}
              onFocus={onChangeDateFocus}
              onBlur={onChangeDateBlur}
              variant="filled"
              likeGoogle
              hideTitle
            />
          </Box>
          <Box sx={{ width: "30%", mx: "1rem" }}>
            <DateInputPicker
              name="end_date"
              label={"End Date"}
              tooltip={"End Date"}
              showFullDate
              value={
                appliedFilterlist?.list?.end_date
                  ? new Date(appliedFilterlist?.list?.end_date)
                  : null
              }
              onChange={onChangeDate}
              onFocus={onChangeDateFocus}
              onBlur={onChangeDateBlur}
              variant="filled"
              likeGoogle
              hideTitle
            />
          </Box>
          <Toggle
            options={dateOptions}
            value={appliedFilterlist?.list?.date_type ?? 1}
            onChange={onChangeDateFilters}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: "1rem",
          }}
        >
          <ButtonGroup
            sx={{
              ml: "1.5rem",
              "& .MuiButton-root": {
                borderRadius: "4px !important",
                textTransform: "initial",
                fontWeight: 600,
                color: theme.palette.color[color][500],
                border: "1px solid transparent",
                "&:hover": {
                  backgroundColor: theme.palette.primary[50],
                  borderColor: theme.palette.primary[500],
                },
              },
            }}
          >
            <Button onClick={() => handleRangeSelection("last12Months")}>
              {t("Last 12 Months")}
            </Button>
            <Button onClick={() => handleRangeSelection("last6Months")}>
              {t("Last 6 Months")}
            </Button>
            <Button onClick={() => handleRangeSelection("lastMonth")}>
              {t("Last Month")}
            </Button>
            <Button onClick={() => handleRangeSelection("today")}>
              {t("Today")}
            </Button>
            <Button onClick={() => handleRangeSelection("thisMonth")}>
              {t("This Month")}
            </Button>
            <Button onClick={() => handleRangeSelection("nextMonth")}>
              {t("Next Month")}
            </Button>
            <Button onClick={() => handleRangeSelection("thisYear")}>
              {t("This Year")}
            </Button>
          </ButtonGroup>
          <Button
            onClick={onClickReset}
            sx={{
              borderRadius: 0,
              border: "none",
              textTransform: "initial",
              color: theme.palette.color[color][500],
              fontWeight: 600,
              mr: "1.5rem",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {t("reset_filters")}
          </Button>
        </Box>
      </CustomPopover>
    );
  }
);

const StateFilter = ({
  type = "list",
  _key = "selectedStates",
  isFilterOpen,
  optionsType,
  filterKey,
  removeFilter,
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.globalSlice?.state);
  const selectedStates = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[_key]
  );

  const options = useMemo(() => {
    let array = state;
    if (optionsType === "Open") {
      array = state?.filter(
        (o1) => !Constant?.excludeForOpenState.includes(o1?.title)
      );
    }
    if (optionsType === "Bank") {
      array = state?.filter((o1) => ["Booked", "Ignored"].includes(o1?.title));
    }

    return array;
  }, [optionsType, state]);

  const onChangeValue = (value) => {
    dispatch(updateFilters({ parent_key: type, obj: { [_key]: value } }));
  };

  const onClickReset = () => {
    onChangeValue([]);
    removeFilter(filterKey);
  };

  return (
    <ThemeFilter
      heading="state_filter"
      tooltip="tooltip_state_filter"
      icon={<TbCircles style={{ fontSize: "1rem", marginTop: "1px" }} />}
      options={options}
      translate
      selectedIds={selectedStates}
      isActive={selectedStates?.length > 0}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const ScenarioFilter = ({
  type = "list",
  _key = "selectedScenarios",
  isFilterOpen,
  filterKey,
  removeFilter,
}) => {
  const dispatch = useDispatch();

  const scenario = useSelector((state) => state.globalSlice?.scenario);
  const selectedScenarios = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[_key]
  );

  const options = useMemo(() => {
    let array = [];
    scenario?.forEach((o1) => {
      if (o1.title === "Base") {
        array.push({ ...o1, translate: true });
      } else {
        array.push(o1);
      }
    });

    return array;
  }, [scenario]);

  const onChangeValue = (value) => {
    dispatch(updateFilters({ parent_key: type, obj: { [_key]: value } }));
  };

  const onClickReset = () => {
    onChangeValue([]);
    removeFilter(filterKey);
  };

  return (
    <ThemeFilter
      heading="scenario_filter"
      tooltip="tooltip_scenario_filter"
      icon={
        <TbChartAreaLineFilled style={{ fontSize: "1rem", marginTop: "1px" }} />
      }
      options={options}
      selectedIds={selectedScenarios}
      isActive={selectedScenarios?.length > 0}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const CostUnitFilter = ({
  type = "list",
  _key = "selectedCostUnits",
  isFilterOpen,
  filterKey,
  removeFilter,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const costunits = useSelector((state) => state.globalSlice?.costunits);
  const selectedCostUnits = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[_key]
  );

  const onChangeValue = (value) => {
    dispatch(updateFilters({ parent_key: type, obj: { [_key]: value } }));
  };

  const onClickReset = () => {
    onChangeValue([]);
    removeFilter(filterKey);
  };

  const onClickManage = () => {
    const dataset = store?.getState().boardSlice?.dataSetData?.uuid;
    if (dataset) {
      navigate(`/${initialData.path.organization}/${dataset}/cost-units`);
    }
  };

  return (
    <ThemeFilter
      heading="costunit_filter"
      tooltip="tooltip_costunit_filter"
      icon={
        <RiFolderChartLine style={{ fontSize: "1rem", marginTop: "1px" }} />
      }
      options={costunits}
      selectedIds={selectedCostUnits}
      isActive={selectedCostUnits?.length > 0}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      onClickManage={onClickManage}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const CategoryFilter = ({
  type = "list",
  _key = "selectedCategory",
  isFilterOpen,
  filterKey,
  removeFilter,
}) => {
  const dispatch = useDispatch();

  //redux
  const selectedCategory = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[_key]
  );

  //functions
  const onChangeValue = (value) => {
    dispatch(updateFilters({ parent_key: type, obj: { [_key]: value } }));
  };

  const onClickReset = () => {
    onChangeValue([]);
    removeFilter(filterKey);
  };

  return (
    <CategoryFilterView
      selectedIds={selectedCategory}
      categoryType={3}
      onClickReset={onClickReset}
      onChangeValue={onChangeValue}
      isFilterOpen={isFilterOpen}
    />
  );
};

const TypeFilter = ({
  type = "list",
  _key = "typeName",
  isFilterOpen,
  filterKey,
  removeFilter,
}) => {
  const dispatch = useDispatch();

  const typeName = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[_key]
  );

  const onChangeValue = (value) => {
    dispatch(updateFilters({ parent_key: type, obj: { [_key]: value } }));
  };

  const onClickReset = () => {
    onChangeValue(["Expense", "Income"]);
    removeFilter(filterKey);
  };

  return (
    <ThemeFilter
      heading="Type Filter"
      tooltip="tooltip_Type_filter"
      icon={
        <RiArrowUpDownLine style={{ fontSize: "1rem", marginTop: "2px" }} />
      }
      options={typeOptions}
      translate
      selectedIds={typeName}
      isActive={typeName?.length !== 2}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const SourceFilter = ({
  type = "list",
  _key = "source",
  isFilterOpen,
  filterKey,
  removeFilter,
}) => {
  const dispatch = useDispatch();

  const source = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[_key]
  );

  const onChangeValue = (value) => {
    dispatch(updateFilters({ parent_key: type, obj: { [_key]: value } }));
  };

  const onClickReset = () => {
    onChangeValue(Constant.DefaultSource);
    removeFilter(filterKey);
  };

  return (
    <ThemeFilter
      heading="Source Filter"
      tooltip="tooltip_source_filter"
      icon={<FiLayers style={{ fontSize: "1rem", marginTop: "2px" }} />}
      options={sourceOptions}
      translate
      selectedIds={source}
      isActive={source?.length !== 4}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const DsFilter = ({
  type = "list",
  _key = "selectedDataSource",
  isFilterOpen,
  filterKey,
  removeFilter,
}) => {
  const optionsById = useRef({});
  const dispatch = useDispatch();

  //state
  const [options, setOptions] = useState([]);

  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );

  const selectedDataSource = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[_key]
  );
  useEffect(() => {
    let array = [];
    datasetAccountList?.forEach((o1) => {
      if (!o1?.internal_dataset && o1?.type !== 10) {
        array.push({
          uuid: o1.uuid,
          hideIcon: true,
          title: o1?.title,
        });
      }
    });
    optionsById.current = _.groupBy(array, "uuid");
    setOptions(array);
  }, [datasetAccountList]);

  //functions
  const onChangeValue = (value) => {
    dispatch(updateFilters({ parent_key: type, obj: { [_key]: value } }));
  };

  const onClickReset = () => {
    onChangeValue([]);
    removeFilter(filterKey);
  };

  return (
    <ThemeFilter
      heading="DS Filter"
      tooltip="tooltip_ds_filter"
      icon={<TbPlug style={{ fontSize: "1rem", marginTop: "1px" }} />}
      options={options}
      translate
      selectedIds={selectedDataSource}
      isActive={selectedDataSource?.length > 0}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const ResetView = ({
  color = "slate",
  filtered,
  setIsIncludedInFilterList,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const activeFilter = filtered?.length > 0;
  if (!activeFilter) {
    return null;
  }

  //functions
  const onClickReset = () => {
    setIsIncludedInFilterList([]);
    updateFilterObj({
      searchText: "",
      typeName: ["Expense", "Income"],
      source: Constant.DefaultSource,
      selectedCategory: [],
      selectedOpenStates: [],
      selectedBankStates: [],
      selectedStates: [],
      selectedScenarios: [],
      selectedCostUnits: [],
      selectedDataSource: [],
      date_type: 1,
      start_date: null,
      end_date: null,
    });
  };

  const updateFilterObj = (_obj) => {
    let obj = {
      parent_key: "list",
      obj: _obj,
    };
    dispatch(updateFilters(obj));
  };

  return (
    <Button
      onClick={onClickReset}
      sx={{
        borderRadius: 0,
        border: "none",
        textTransform: "initial",
        color: theme.palette.color[color][500],
        fontWeight: 600,
        ml: "1.5rem",
        mr: "0.5rem",
        lineHeight: "0.875rem",
        "&:hover": {
          backgroundColor: theme.palette.color[color][100],
          textDecoration: "underline",
        },
      }}
    >
      {t("reset_filters")}
    </Button>
  );
};

const BulkActionView = ({ tab }) => {
  const popupStatus5 = useSelector(
    (state) => state?.datasetSlice?.popupStatus5
  );

  if (!popupStatus5?.payload) {
    return null;
  }
  const { selectedRowData, resetKanban } = popupStatus5?.payload;
  return (
    <>
      {popupStatus5?.overlay_type === "all" &&
      popupStatus5?.payload?.selectedRowData?.length > 0 ? (
        <TransactionViewSettingPopOver
          selectedRowData={selectedRowData}
          resetKanban={resetKanban}
          tab={tab}
        />
      ) : null}
    </>
  );
};
