import uniqBy from "lodash/uniqBy";

export const StepType = {
  upload: "upload",
  selectSheet: "selectSheet",
  selectHeader: "selectHeader",
  matchColumns: "matchColumns",
  finalStep: "finalStep",
};

export const ColumnType = {
  empty: "empty",
  ignored: "ignored",
  matched: "matched",
  matchedCheckbox: "matchedCheckbox",
  matchedSelect: "matchedSelect",
  matchedSelectOptions: "matchedSelectOptions",
};

export const fields = [
  {
    label: "Id",
    key: "id",
    fieldType: {
      type: "input",
    },
    example: "1",
    validations: [
      {
        rule: "required",
        errorMessage: "Id is required",
        level: "error",
      },
    ],
  },
  {
    label: "Title",
    key: "title",
    alternateMatches: ["name"],
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Title is required",
        level: "error",
      },
    ],
    example: "Invoice #001",
  },
  {
    label: "Gross Value",
    key: "gross_value",
    alternateMatches: ["gross amount"],
    fieldType: {
      type: "input",
    },
    example: "1000.00",
    validations: [
      {
        rule: "regex",
        // eslint-disable-next-line no-useless-escape
        value: `^([0-9]+(?:,[0-9]+)*)$`,
        errorMessage: "Please enter only decimal values",
        level: "error",
      },
    ],
  },
  {
    label: "Due Date",
    key: "due_date",
    alternateMatches: ["due date", "date", "invoice date"],
    fieldType: {
      type: "input",
    },
    example: "10.05.2024",
    validations: [
      {
        rule: "required",
        errorMessage: "Due date is required",
        level: "error",
      },
    ],
  },
  {
    label: "State",
    key: "state",
    fieldType: {
      type: "input",
    },
    example: "Paid",
    validations: [
      {
        rule: "required",
        errorMessage: "State is required",
        level: "error",
      },
    ],
  },
  {
    label: "Category",
    key: "category",
    fieldType: {
      type: "input",
    },
    example: "HR",
    validations: [
      {
        rule: "required",
        errorMessage: "Category is required",
        level: "error",
      },
    ],
  },
  {
    label: "Scenario",
    key: "scenario",
    fieldType: {
      type: "input",
    },
    example: "Base",
    validations: [
      {
        rule: "required",
        errorMessage: "Scenario is required",
        level: "error",
      },
    ],
  },
  {
    label: "Note",
    key: "note",
    fieldType: {
      type: "input",
    },
    example: "Lore ipsum - Budget #0001",
  },
  {
    label: "Tax Value",
    key: "tax_value",
    alternateMatches: ["vat", "Vat"],
    fieldType: {
      type: "input",
    },
    example: "19",
  },
  {
    label: "Cost Unit",
    key: "cost_unit",
    alternateMatches: ["cost unit"],
    fieldType: {
      type: "input",
    },
    example: "",
  },
  {
    label: "Currency",
    key: "currency",
    fieldType: {
      type: "input",
    },
    example: "EUR",
  },
];

export const booleanWhitelist = {
  yes: true,
  no: false,
  true: true,
  false: false,
};

const normalizeCheckboxValue = (value) => {
  if (value && value.toLowerCase() in booleanWhitelist) {
    return booleanWhitelist[value.toLowerCase()];
  }
  return false;
};

export const normalizeTableData = (columns, data = [], fields) =>
  data.map((row) =>
    columns.reduce((acc, column, index) => {
      const curr = row[index];
      switch (column.type) {
        case ColumnType.matchedCheckbox: {
          const field = fields.find((field) => field.key === column.value);
          if (
            "booleanMatches" in field.fieldType &&
            Object.keys(field.fieldType).length
          ) {
            const booleanMatchKey = Object.keys(
              field.fieldType.booleanMatches || []
            ).find((key) => key.toLowerCase() === curr?.toLowerCase());
            const booleanMatch =
              field.fieldType.booleanMatches?.[booleanMatchKey];
            acc[column.value] = booleanMatchKey
              ? booleanMatch
              : normalizeCheckboxValue(curr);
          } else {
            acc[column.value] = normalizeCheckboxValue(curr);
          }
          return acc;
        }
        case ColumnType.matched: {
          acc[column.value] = curr === "" ? undefined : curr;
          return acc;
        }
        case ColumnType.matchedSelect:
        case ColumnType.matchedSelectOptions: {
          const matchedOption = column.matchedOptions.find(
            ({ entry, value }) => entry === curr
          );
          acc[column.value] = matchedOption?.value || undefined;
          return acc;
        }
        case ColumnType.empty:
        case ColumnType.ignored: {
          return acc;
        }
        default:
          return acc;
      }
    }, {})
  );

export const findUnmatchedRequiredFields = (fields, columns) =>
  fields
    .filter((field) =>
      field.validations?.some((validation) => validation.rule === "required")
    )
    .filter(
      (field) =>
        columns.findIndex(
          (column) => "value" in column && column.value === field.key
        ) === -1
    )
    .map((field) => field.label) || [];

export const uniqueEntries = (data, index) =>
  uniqBy(
    data.map((row) => ({ entry: row[index] })),
    "entry"
  ).filter(({ entry }) => !!entry);

export const setColumn = (oldColumn, field, data, autoMapSelectValues) => {
  switch (field?.fieldType.type) {
    case "select":
      const fieldOptions = field.fieldType.options;
      const uniqueData = uniqueEntries(data || [], oldColumn.index);
      const matchedOptions = autoMapSelectValues
        ? uniqueData.map((record) => {
            const value = fieldOptions.find(
              (fieldOption) =>
                fieldOption.value === record.entry ||
                fieldOption.label === record.entry
            )?.value;
            return value ? { ...record, value } : record;
          })
        : uniqueData;
      const allMatched =
        matchedOptions.filter((o) => o?.value).length === uniqueData?.length;

      return {
        ...oldColumn,
        type: allMatched
          ? ColumnType.matchedSelectOptions
          : ColumnType.matchedSelect,
        value: field.key,
        matchedOptions,
      };
    case "checkbox":
      return {
        index: oldColumn.index,
        type: ColumnType.matchedCheckbox,
        value: field.key,
        header: oldColumn.header,
      };
    case "input":
      return {
        index: oldColumn.index,
        type: ColumnType.matched,
        value: field.key,
        header: oldColumn.header,
      };
    default:
      return {
        index: oldColumn.index,
        header: oldColumn.header,
        type: ColumnType.empty,
      };
  }
};
