import { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { PropTypes } from "prop-types";
// import { ReactComponent as YourSvg } from "../Helper/Images/Basic_Animations.svg";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  outline: "none",
};

export default function Loader({ loading }) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (loading) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [loading]);

  return (
    <Modal
      open={open}
      closeAfterTransition
      slotProps={{
        backdrop: {
          open: open,
          component: Backdrop,
          timeout: 500,
        },
      }}
      sx={{
        zIndex: 9999,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          {/* <YourSvg /> */}
          {/* <img src={Images.Svg.ThemeLoader} height={60} alt="App_Logo" /> */}
          {/* <CircularProgress color="secondary" /> */}
        </Box>
      </Fade>
    </Modal>
  );
}
Backdrop.defaultProps = {
  open: false,
};
Backdrop.propTypes = {
  open: PropTypes.bool.isRequired,
};
