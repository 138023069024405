import { Typography } from "@mui/material";
import { Color, Fonts } from "../../Helper";
import { useEffect, useState } from "react";
import CategoryTreeSelect from "./CategoryTreeSelect";
import CategoryChip from "../CategoryChip";
import { useTranslation } from "react-i18next";

const CategoryTreeSelection = ({
  disabled,
  onClickCategoryTitle,
  categoryID = null,
  onDelete,
  hideTitle,
  type = 3,
  cardID = null,
  isDataSet = false,
  isGlobal = false,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "center",
  },
  width,
}) => {
  const { t } = useTranslation();

  //state
  const [anchorEl, setAnchorEl] = useState(null);
  const [category, setCategoryID] = useState(categoryID);

  useEffect(() => {
    setCategoryID(categoryID);
  }, [cardID, categoryID]);

  //function
  const onClickCategory = (e) => {
    if (!disabled) {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleClosePopOver = (e) => {
    e?.stopPropagation();
    setAnchorEl(null);
  };

  const onClickTitle = (e, item) => {
    setAnchorEl(null);
    onClickCategoryTitle(e, item);
    setCategoryID(item?.uuid);
  };

  return (
    <>
      {!hideTitle && (
        <Typography
          variant="h6"
          component={"h6"}
          fontSize={"0.9rem"}
          sx={{
            color: Color.black,
            width: "41%",
            fontWeight: 700,
            mb: "0.5rem",
            fontFamily: Fonts.Text,
          }}
        >
          {t("category")}
        </Typography>
      )}
      <CategoryChip
        onClick={onClickCategory}
        onDelete={onDelete}
        showDelete={category}
        categoryId={category}
        width={width}
        disabled={disabled}
      />
      <CategoryTreeSelect
        isDataSet={isDataSet}
        isGlobal={isGlobal}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        anchorEl={anchorEl}
        handleClosePopOver={handleClosePopOver}
        onClickCategoryTitle={onClickTitle}
        type={type}
        id="another overlay"
      />
    </>
  );
};
export default CategoryTreeSelection;
