import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Page: null,
  RangeType: "",
  startDate: "",
  endDate: "",
  comparison: "",
  layouts: {},
  pagesList: [],
};

const reportSlice = createSlice({
  name: "reportSlice",
  initialState,
  reducers: {
    resetReports: (state) => {
      return initialState;
    },
    setLayouts: (state, action) => {
      if (action.payload?.type === "add_all") {
        state.layouts = action.payload?.obj;
      }
      if (action.payload?.type === "delete_all") {
        state.layouts = {};
      }
      if (action.payload?.type === "add" && state.layouts) {
        Object.keys(state.layouts)?.forEach((key) => {
          state.layouts[key].push(action.payload?.obj);
        });
      }
      if (action.payload?.type === "delete" && state.layouts) {
        Object.keys(state.layouts)?.forEach((key) => {
          state.layouts[key] = state.layouts?.[key]?.filter(
            (layout) => layout.i !== action.payload?.obj?.i
          );
        });
      }
    },
    setReportPage: (state, action) => {
      if (action.payload?.obj) {
        Object.keys(action.payload?.obj).forEach((key) => {
          state.Page[key] = action.payload?.obj[key];
        });
      } else {
        state.Page[action.payload?.key] = action.payload?.value;
      }
    },
    setReportFilter: (state, action) => {
      if (action.payload?.obj) {
        Object.keys(action.payload?.obj).forEach((key) => {
          state[key] = action.payload?.obj[key];
        });
      } else {
        state[action.payload?.key] = action.payload?.value;
      }
    },
  },
});
export const { resetReports, setReportFilter, setReportPage, setLayouts } =
  reportSlice.actions;
export default reportSlice;
