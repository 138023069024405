import { createSlice } from "@reduxjs/toolkit";

const initialState = { linkText: "", path: [] };

const pathHeaderSlice = createSlice({
  name: "pathHeaderSlice",
  initialState,
  reducers: {
    setPath(state, action) {
      state.path = action.payload;
    },
  },
});
export const { setPath } = pathHeaderSlice.actions;
export default pathHeaderSlice;
