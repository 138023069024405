import React from "react";
import { Box } from "@mui/material";
import { Color } from "../Helper";
var Spinner = require("react-spinkit");

const SpinLoader = ({ loading }) => {
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    if (loading) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [loading]);
  return (
    <Box
      sx={{
        position: "absolute",
        top: open ? "46%" : "-46%",
        right: "49%",
        zIndex: 9999999,
      }}
    >
      <Spinner name="folding-cube" color={Color?.themeColor2} />
    </Box>
  );
};
export default SpinLoader;
