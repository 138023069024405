import { Typography } from "@mui/material";
import CustomModal from "./Model/CustomModal";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { setWarnInfoOverlayStatus } from "../store/slices/settings";
import { Trans, useTranslation } from "react-i18next";
import { Color, Fonts } from "../Helper";
import OverlayHeader from "./Overlay/OverlayHeader";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
const WarnInfoOverlay = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const warnInfoOverlayStatus = useSelector(
    (state) => state.settingsSlice?.warnInfoOverlayStatus
  );
  let components = { b: <b />, i: <i />, strong: <strong />, p: <p /> };
  const onClose = () => {
    dispatch(
      setWarnInfoOverlayStatus({
        open: false,
        title: "",
        contentKey: "",
        allowClose: true,
      })
    );
  };

  //render function
  return (
    <CustomModal
      hideAction
      hideClose
      // hideClose={!warnInfoOverlayStatus?.allowClose}
      onClose={onClose}
      open={warnInfoOverlayStatus?.open}
      backdropClose={warnInfoOverlayStatus?.allowClose}
    >
      <Box
        sx={{
          width: { xs: "30rem", small: "35rem", isTablet: "40rem" },
          height: "fit-content",
          minHeight: "15rem",
          maxHeight: "80rem",
          display: "flex",
          flexDirection: "column",
          "&.MuiAlert-message": {
            fontFamily: Fonts?.Text,
            fontWeight: 600,
            color: Color.theme.blueGrey[900],
            fontSize: "1rem",
          },
        }}
      >
        <OverlayHeader
          subTitle={warnInfoOverlayStatus?.title}
          onClose={onClose}
          style={{ pl: "2rem" }}
          backgroundColor={`var(--rs-message-${warnInfoOverlayStatus?.type}-bg)`}
          subTitleStyle={{
            color: `var(--rs-message-${warnInfoOverlayStatus?.type}-icon)`,
            fontSize: { xs: "1rem", small: "1.2rem", isTablet: "1.4rem" },
          }}
          icon={
            <ErrorOutlineIcon
              color={warnInfoOverlayStatus?.type}
              sx={{
                fontSize: { xs: "1rem", small: "1.2rem", isTablet: "1.5rem" },
                mr: "0.5rem",
              }}
            />
          }
        />

        <Typography
          sx={{
            p: "3rem",
            display: "inline-flex",
            fontFamily: Fonts?.Text,
            fontWeight: 500,
            color: Color.theme.blueGrey[400],
            fontSize: "1rem",
          }}
        >
          {warnInfoOverlayStatus?.contentKey ? (
            <Trans
              i18nkey={warnInfoOverlayStatus?.contentKey}
              components={components}
            >
              {t(warnInfoOverlayStatus?.contentKey)}
            </Trans>
          ) : null}
        </Typography>
      </Box>
    </CustomModal>
  );
};

export default WarnInfoOverlay;
