import {
  Box,
  Dialog,
  DialogContent,
  Fade,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import React, { useState } from "react";

import UploadFlow from "./UploadFlow";
import { StepType } from "./helper";
import { Color } from "../../../../Helper";
import theme from "../../../../theme";

const steps = ["uploadStep", "selectHeaderStep", "matchColumnsStep"];

const SheetComponent = ({ open, onClose, handleFinalSubmit }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [state, setState] = useState({ type: StepType.upload });

  const handleOnNext = (stepState) => {
    setState(stepState);
    if (stepState.type !== StepType.selectSheet)
      setActiveStep((prev) => prev + 1);
    if (stepState.type === StepType.finalStep) {
      const headers = stepState.columns
        .filter((item) => item.type === "matched")
        .reduce((acc, item) => {
          acc[item.header] = item.value;
          return acc;
        }, {});
      const finalObject = {
        headers,
        uploaded_file: stepState.uploaded_file,
        sheet_name: stepState.sheet_name,
      };
      handleFinalSubmit(finalObject);
    }
  };

  return (
    <Dialog
      open={!!open}
      onClose={onClose}
      TransitionComponent={Fade}
      PaperProps={{
        style: {
          minWidth: "50rem",
          maxWidth: "none",
          minHeight: "35rem",
          width: "auto",
        },
      }}
      sx={{
        "& button": {
          textTransform: "capitalize",
        },
      }}
    >
      <DialogContent
        sx={{ height: "100%", p: "2rem", backgroundColor: Color.BodyBG }}
      >
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"center"}
          width="100%"
          mb="1rem"
        >
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "40rem",

              "& .MuiStepLabel-iconContainer": {
                pr: "0.5px !important",
              },
              "& .MuiSvgIcon-root.Mui-completed": {
                color: theme.palette.color.green[500],
              },
              "& .Mui-disabled": {
                "& svg": {
                  color: "transparent",
                },
                "& .MuiStepLabel-iconContainer": {
                  pr: "0.25px !important",
                  borderRadius: "50rem",
                  border: `1px solid ${theme.palette.primary.main}`,
                },
                "& text": {
                  fill: theme.palette.primary.main,
                },
              },
            }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <UploadFlow state={state} onNext={handleOnNext} />
      </DialogContent>
    </Dialog>
  );
};

export default SheetComponent;
